<template>
  <div id="HealthScoreAssessment">
    <LoadingAnimation v-if="isLoadingAnimationShown" title="nearlyThere" />
    <template v-else-if="isBeforeResultsScreenShown">
      <BeforeResultsScreen :questionnaireId="initialQuestionnaireName" />
    </template>
    <template v-else>
      <div class="progress-bar">
        <div
          class="progress-bar-inner"
          :style="{ width: progressNumber + '%' }"
        ></div>
      </div>
      <div v-if="questionData" class="container">
        <h2 class="title">{{ questionData.question_text }}</h2>
        <p
          v-if="questionData.sub_text"
          class="subtitle"
          v-html="questionData.sub_text"
        ></p>
        <QuestionTypeSelect
          v-if="isQuestionTypeSelect"
          :optionsArray="questionData.options"
          :previousAnswer="selectedAnswer"
        />
        <QuestionTypeMultipleChoice
          v-if="isQuestionTypeMultipleChoice"
          :optionsArray="questionData.options"
          :previousAnswer="selectedAnswer"
        />
        <QuestionTypeFloat
          v-if="isQuestionTypeFloat"
          :previousAnswer="selectedAnswer"
          :previousUnit="selectedUnit"
          :questionData="questionData"
        />
        <div class="nav-buttons">
          <button
            class="btn-back"
            :disabled="isBackButtonDisabled"
            @click="handleBackButtonClick"
          >
            <i class="fa fa-angle-left" aria-hidden="true"></i> Back
          </button>
          <button
            class="btn-next"
            :disabled="isNextButtonDisabled || isAnswerQuestionLoading"
            @click="handleNextButtonClick"
          >
            <b-spinner v-if="isAnswerQuestionLoading"></b-spinner>
            <span v-else>
              Next
              <i class="fa fa-angle-right" aria-hidden="true"></i>
            </span>
          </button>
        </div>
      </div>
      <div class="d-flex justify-content-center p-4">
        <b-spinner v-if="isFirstQuestionLoading"></b-spinner>
      </div>
    </template>
  </div>
</template>

<script>
import axios from "axios";
import config from "@/config/constants";
import EventBus from "@/helpers/eventbus";
import QuestionTypeSelect from "@/components/HealthScore/QuestionTypeSelect.vue";
import QuestionTypeMultipleChoice from "@/components/HealthScore/QuestionTypeMultipleChoice.vue";
import QuestionTypeFloat from "@/components/HealthScore/QuestionTypeFloat.vue";
import BeforeResultsScreen from "../components/HealthScore/BeforeResultsScreen.vue";
import LoadingAnimation from "../components/HealthScore/LoadingAnimation.vue";

export default {
  name: "HealthScoreAssessment",
  components: {
    QuestionTypeSelect,
    QuestionTypeMultipleChoice,
    QuestionTypeFloat,
    BeforeResultsScreen,
    LoadingAnimation
  },
  data() {
    return {
      isFirstQuestionLoading: false,
      isAnswerQuestionLoading: false,
      existingAssessments: [
        "well_in_life",
        "well_in_body",
        "well_in_mind",
        "demographics"
      ],
      questionData: null,
      areDemographicsCompleted: null,
      isCurrenAssesmentCompleted: false,
      selectedAnswer: null,
      selectedUnit: null,
      isNextButtonDisabled: true,
      currentQuestionnaireName: null,
      isLastQuestion: false,
      progressNumber: 0,
      questionHistory: [],
      currentQuestionIndex: 0,
      isBackButtonDisabled: true,
      isBeforeResultsScreenShown: false,
      isLoadingAnimationShown: false,
      timeoutId: null
    };
  },
  computed: {
    initialQuestionnaireName() {
      return this.$route.params.assessment_id;
    },
    isQuestionTypeSelect() {
      return this.questionData?.question_type === "select";
    },
    isQuestionTypeMultipleChoice() {
      return this.questionData?.question_type === "multiple_choice";
    },
    isQuestionTypeFloat() {
      return ["bp", "float", "integer"].includes(this.questionData?.question_type);
    },
  },
  async mounted() {
    this.scrollToTop();
    EventBus.$emit("startHealthScoreAssessment");

    this.currentQuestionnaireName = this.initialQuestionnaireName;

    if (!this.existingAssessments.includes(this.currentQuestionnaireName)) {
      this.$router.push("/healthscore");
    } else {
      await this.getFirstUnansweredQuestion(this.currentQuestionnaireName);
    }

    if (!this.areDemographicsCompleted) {
      await this.getFirstUnansweredQuestion("demographics");
    }

    EventBus.$on("setAnswer", this.setAnswer);
  },
  beforeDestroy() {
    EventBus.$emit("finishHealthScoreAssessment");
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
  },
  methods: {
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    },
    reset() {
      this.selectedAnswer = null;
    },
    setAnswer(answer, unit) {
      if (typeof unit === "string" && unit.includes("I don't know")) {
        this.selectedUnit = "I don't know";
      } else if (unit) {
        this.selectedUnit = unit;
      } else {
        this.selectedUnit = null;
      }

      if (typeof answer === "string" && answer.includes("I don't know")) {
        this.selectedAnswer = "I don't know";
        this.isNextButtonDisabled = false;
      } else if (
        (typeof answer === "number" && answer >= 0) ||
        (answer && answer.length) ||
        (typeof answer === "object" && answer && answer.option_text)
      ) {
        this.selectedAnswer = answer;
        this.isNextButtonDisabled = false;
      } else {
        this.selectedAnswer = null;
        this.isNextButtonDisabled = true;
      }
    },
    handleBackButtonClick() {
      if (this.questionHistory.length > 0) {
        this.currentQuestionIndex -= 1;
        this.questionData = this.questionHistory[
          this.currentQuestionIndex
        ].question;
        this.selectedAnswer = this.questionHistory[
          this.currentQuestionIndex
        ].answer;
        this.selectedUnit = this.questionHistory[
          this.currentQuestionIndex
        ].unit;
        this.progressNumber = this.questionHistory[
          this.currentQuestionIndex
        ].progress;
        this.isBackButtonDisabled = this.currentQuestionIndex <= 0;
      }
      if (this.selectedAnswer) {
        this.isNextButtonDisabled = false;
      }
    },
    async handleNextButtonClick() {
      const isLastDemographicQuestion =
        this.isLastQuestion && this.currentQuestionnaireName === "demographics";

      this.scrollToTop();

      if (this.questionData) {
        this.questionHistory[this.currentQuestionIndex] = {
          question: this.questionData,
          answer: this.selectedAnswer,
          unit: this.selectedUnit,
          progress: this.progressNumber
        };
        this.isBackButtonDisabled = false;
      }

      this.currentQuestionIndex += 1;

      await this.answerQuestion();
      this.selectedAnswer = this.questionHistory[this.currentQuestionIndex]?.answer || null;
      this.selectedUnit = this.questionHistory[this.currentQuestionIndex]?.unit || null;

      if (isLastDemographicQuestion) {
        this.questionHistory = [];
        this.currentQuestionIndex = 0;
        this.isBackButtonDisabled = true;
        this.isLoadingAnimationShown = true;
        this.timeoutId = setTimeout(async () => {
          await this.getFirstUnansweredQuestion(this.initialQuestionnaireName);
          this.isLoadingAnimationShown = false;
        }, 4000);
      }

      if (!this.selectedAnswer) {
        this.isNextButtonDisabled = true;
      }
    },
    async getFirstUnansweredQuestion(questionnaireName) {
      if (this.isFirstQuestionLoading) {
        return;
      }
      try {
        this.isFirstQuestionLoading = true;
        this.currentQuestionnaireName = questionnaireName;
        const payload = {
          user_id: this.$store.getters.user_id,
          questionnaire_name: questionnaireName
        };
        const res = await axios.post(
          config.api_env +
            "/application/api/u/sapphire/healthscore/get-first-unanswered-question",
          payload,
          config.options
        );
        if (res.data.success) {
          this.progressNumber = res.data.question?.progress || 0;
          this.isCurrenAssesmentCompleted = this.progressNumber === 100;
          this.areDemographicsCompleted =
            this.currentQuestionnaireName !== "demographics" &&
            res.data?.demographics_complete === true;
          this.questionData = res.data.question?.next_question;
          this.reset();
          if (res.data?.question?.next_question?.next_question_id === null) {
            this.isLastQuestion = true;
          }
        }
      } catch (err) {
        console.error(err);
      } finally {
        this.isFirstQuestionLoading = false;
        if (
          this.isCurrenAssesmentCompleted &&
          this.currentQuestionnaireName !== "demographics"
        ) {
          this.isBeforeResultsScreenShown = true;
        }
      }
    },
    async answerQuestion() {
      if (this.isAnswerQuestionLoading) {
        return;
      }
      try {
        this.isAnswerQuestionLoading = true;
        const payload = {
          user_id: this.$store.getters.user_id,
          answer: this.selectedAnswer,
          question_id: this.questionData?.question_id,
          questionnaire_name: this.questionData?.questionnaire_name,
          unit: this.selectedUnit
        };
        const res = await axios.post(
          config.api_env +
            "/application/api/u/sapphire/healthscore/answer-question",
          payload,
          config.options
        );
        if (res.data.success) {
          this.progressNumber = res.data?.progress || 0;
          this.isCurrenAssesmentCompleted = this.progressNumber === 100;
          this.questionData = res.data?.next_question || res.data?.question;
          if (
            res.data.question?.next_question?.next_question_id === null ||
            res.data.next_question?.next_question_id === null ||
            res.data.question?.next_question_id === null
          ) {
            this.isLastQuestion = true;
          }
        }
      } catch (err) {
        console.error(err);
      } finally {
        this.isAnswerQuestionLoading = false;
        if (
          this.isCurrenAssesmentCompleted &&
          this.currentQuestionnaireName !== "demographics"
        ) {
          this.isBeforeResultsScreenShown = true;
        }
      }
    }
  }
};
</script>

<style lang="less">
@padding-x: 28px;
@max-width: calc(1110px + @padding-x * 2);

.centered {
  max-width: @max-width;
  margin: 0 auto;
}

#HealthScoreAssessment {
  background-color: #fafafa;

  .progress-bar {
    height: 8px;
    background-color: #e5e5e5;
  }

  .progress-bar-inner {
    height: 100%;
    background-color: #00adc6;
  }

  .container {
    padding: 64px @padding-x;

    .centered();
  }

  .title {
    font-family: "PublicoHeadlineWebBold";
    text-align: center;
    color: #343c3d;
    font-size: 3.6rem;
    list-style: 4.5rem;
    margin-bottom: 32px;
  }

  .subtitle {
    font-family: "SourceSansProSemibold";
    text-align: left;
    font-size: 2rem;
    line-height: 2rem;
    color: #343c3d;
    margin-bottom: 32px;
  }

  .nav-buttons {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    margin-top: 64px;
  }

  .btn-back {
    font-family: "SourceSansProSemibold";
    font-size: 1.6rem;
    line-height: 2rem;
    border: 1px solid #3a5e94;
    border-radius: 6px;
    padding: 16px 24px;
    background-color: #fff;
    color: #3a5e94;

    &:hover {
      border: 1px solid #00006d;
      color: #00006d;
    }

    &:focus {
      outline: 4px solid #ffbc11;
    }

    &:disabled {
      border: 1px solid #cccccc;
      color: #757575;
    }
  }

  .btn-next {
    font-family: "SourceSansProSemibold";
    font-size: 1.6rem;
    line-height: 2rem;
    border: 1px solid #00008f;
    border-radius: 6px;
    padding: 16px 24px;
    background-color: #00008f;
    color: #fff;

    &:hover {
      background-color: #00006d;
    }

    &:focus {
      outline: 4px solid #ffbc11;
    }

    &:disabled {
      background-color: #e5e5e5;
      border: 1px solid #e5e5e5;
      color: #757575;
    }
  }

  .fa-angle-left {
    margin-right: 8px;
  }

  .fa-angle-right {
    margin-left: 8px;
  }
}
</style>
