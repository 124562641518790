<template>
  <div class="Question border-brand-highlight-1 py-5">
    <div class="numer-question-item" v-if="question.type === 'numeric'">
      <div class="d-flex flex-row align-items-center">
        <div
          class="question-chart-container bg-brand-secondary"
          v-if="question.last_answers.length > 0"
        >
          <LineChart
            :chart-data="datacollection"
            :options="chartoptions"
          ></LineChart>
        </div>
        <div class="question">
          <h3 class="question-title brand-text">
            {{ question.text }}
          </h3>
          <p class="question-description" v-if="question.description">
            {{ question.description }}
          </p>
          <!-- SOMETHING HERE TO SWITCH BETWEEN SLIDER AND INPUT -->
          <div
            v-if="question.layout === 'scale'"
            class="text-center percentage mb-0"
          >
            {{ answer }}
          </div>
          <b-form-input
            type="range"
            size="lg"
            :id="'question-slider-' + question.id"
            class="question-slider w-100 my-3"
            :min="question.min_val"
            :max="question.max_val"
            v-model="answer"
            v-if="question.layout === 'scale'"
            debounce="300"
            color="black"
          />

          <b-form-input
            type="number"
            size="lg"
            :id="'question-' + question.id"
            class="number-input border-brand-highlight-1"
            :min="question.min_val"
            :max="question.max_val"
            v-model="answer"
            v-else
            debounce="500"
          />
          <div
            class="d-flex flex-row justify-content-between"
            v-if="question.layout === 'scale'"
          >
            <div class="d-flex flex-column align-items-center">
              <div>
                <!--                <i class="far fa-frown fa-3x"></i>-->
              </div>
              <div class="percentage">
                {{
                  question.min_val || question.min_val === 0
                    ? question.min_val
                    : 0
                }}
              </div>
            </div>
            <div class="d-flex flex-column align-items-center">
              <div>
                <!--                <i class="far fa-smile fa-3x"></i>-->
              </div>
              <div class="percentage">
                {{ question.max_val ? question.max_val : 100 }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="text-question-item"
      v-if="question.type === 'text' || question.type === 'time'"
    >
      <h3 class="question-title brand-text">{{ question.text }}</h3>
      <p class="question-description" v-if="question.description">
        {{ question.description }}
      </p>
      <div class="row no-gutters mt-3">
        <b-form-textarea
          class="text-input border-brand-highlight-1"
          size="lg"
          rows="3"
          v-model.trim="answer"
          v-if="question.type === 'text' && question.layout === 'long_text'"
        />
        <b-form-input
          class="time-input border-brand-highlight-1"
          type="time"
          size="lg"
          v-model="answer"
          v-else-if="question.type === 'time'"
          debouce="500"
        />
        <b-form-input
          class="text-input border-brand-highlight-1"
          type="text"
          size="lg"
          v-model.trim="answer"
          v-else
          debouce="500"
        />
      </div>
      <div
        v-if="
          question.last_answer !== 0 && options.journal_type !== 'worksheet'
        "
      >
        <div
          v-if="
            question.last_answers.length < 3 &&
              options.journal_type !== 'worksheet'
          "
          class="d-flex "
        >
          <div
            class="border-brand-highlight-1 mt-5 prev_answer_box prev_answer_box_notslick"
            v-for="(prevAns, index) in question.last_answers"
            :key="index"
          >
            <div class="date-card border-brand-highlight-1 " v-if="index < 8">
              <div
                class="date-card-date bg-brand-highlight-1"
                :class="{ inactive: !prevAns.active }"
                @click="selectDate(prevAns)"
              >
                {{ getFormatedDate(prevAns.timestamp, "Do MMM") }}
              </div>
              <div class="date-card-day">
                {{ getDayString(prevAns.timestamp) }}
              </div>
              <div class="date-card-text m-4">{{ prevAns.text }}</div>
            </div>
          </div>
        </div>
        <slick ref="slick" :options="slickOptions" class="w-100" v-else>
          <div
            class="border-brand-highlight-1 mt-5 prev_answer_box"
            v-for="(prevAns, index) in question.last_answers"
            :key="index"
          >
            <div class="date-card border-brand-highlight-1" v-if="index < 8">
              <div
                class="date-card-date bg-brand-highlight-1"
                :class="{ inactive: !prevAns.active }"
                @click="selectDate(prevAns)"
              >
                {{ getFormatedDate(prevAns.timestamp, "Do MMM") }}
              </div>
              <div class="date-card-day">
                {{ getDayString(prevAns.timestamp) }}
              </div>
              <div class="date-card-text m-4">{{ prevAns.text }}</div>
            </div>
          </div>
        </slick>
      </div>
    </div>
    <div class="text-question-item" v-if="question.type === 'date'">
      <h3 class="question-title brand-secondary">{{ question.text }}</h3>
      <p class="question-description w-50" v-if="question.description">
        {{ question.description }}
      </p>
      <div class="row no-gutters mt-3">
        <b-form-input
          class="text-input border-brand-highlight-1"
          type="date"
          size="lg"
          v-model.trim="answer"
          debouce="500"
        />
      </div>
      <div
        class="d-flex flex-row flex-wrap row mt-2 mb-5"
        v-if="question.last_answer !== 0"
      >
        <div
          class="col-4 border-brand-highlight-1 mt-5"
          v-for="(prevAns, index) in question.last_answers"
          :key="index"
        >
          <div
            class="date-card border-brand-highlight-1"
            v-if="index < 3 && options.journal_type !== 'worksheet'"
          >
            <div class="date-card-date bg-brand-highlight-1">
              {{ getFormatedDate(prevAns.timestamp, "Do MMM") }}
            </div>
            <div class="date-card-day">
              {{ getDayString(prevAns.timestamp) }}
            </div>
            <div class="date-card-text mt-3">{{ prevAns.text }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LineChart from "../LineChart.vue";
import moment from "moment";
import slick from "vue-slick";

export default {
  components: {
    LineChart,
    slick
  },
  name: "Question",
  props: ["question", "selectedDate", "options"],
  data() {
    return {
      answer: "",
      content: "",
      datacollection: {},
      dataSet: {},
      chartoptions: {
        height: 100,
        responsive: true,
        label: false,
        elements: {
          point: {
            radius: 5
          }
        },
        tooltips: {
          xPadding: 15,
          caretSize: 0,
          yPadding: 5,
          cornerRadius: 12,
          backgroundColor: "#FFFFFF",
          bodyFontColor: "#000000",
          displayColors: false,
          bodyFontFamily: "PublicoHeadlineWebBold",
          callbacks: {
            title: function() {
              return null;
            }
          }
        },
        layout: {
          padding: 15
        },
        legend: {
          display: false
        },
        scales: {
          xAxes: [
            {
              gridLines: {
                drawBorder: null,
                display: null
              },
              ticks: {
                drawOnChartArea: null,
                beginAtZero: null,
                display: null
              }
            }
          ],
          yAxes: [
            {
              gridLines: {
                drawBorder: null,
                display: null
              },
              ticks: {
                beginAtZero: null,
                display: null,
                suggestedMax: null
              }
            }
          ]
        },
        plugins: {
          title: {
            display: true
          }
        }
      },
      slickOptions: {
        nextArrow:
          '<div class="h-100 slack-arrow-container-right">' +
          '<button class=" bg-brand-highlight-1 slick-arrow mx-3" type="button" style="border-radius: 50%"><i class="fas fa-chevron-right text-white fa-2xs"></i></button>' +
          "</div>",
        prevArrow:
          '<div class="h-100 slack-arrow-container-left">' +
          '<button class=" bg-brand-highlight-1 slick-arrow mx-3" type="button" style="border-radius: 50%"><i class="fas fa-chevron-left text-white fa-2xs"></i></button>' +
          "</div>",
        slidesToScroll: 1,
        slidesToShow: 3,
        adaptiveHeight: true,
        infinite: false
      }
    };
  },
  mounted() {
    let self = this;
    this.question.last_answers = this.question.last_answers.filter(
      q => q.text !== ""
    );
    this.question.last_answers = this.question.last_answers.reverse();
    if (this.question.last_answers.length !== 0) {
      let dataSet = {
        labels: [],
        datasets: [
          {
            label: false,
            fill: false,
            backgroundColor: "#ED3A75",
            borderColor: "#FFFFFF",
            data: []
          }
        ]
      };
      this.question.last_answers.forEach(function(ans) {
        dataSet.labels.push(moment(ans.timestamp).format("DD/MM/YY"));
        dataSet.datasets[0].data.push(ans.text);
      });
      this.datacollection = dataSet;
    }
    if (this.question.layout === "scale") {
      this.answer = this.question.max_val / 2;
    }
    //forEach below checks if an entry has been made for selectedDate and displays the result if this is true
    if (!this.options.journal_type.includes("multi")) {
      this.question.last_answers.forEach(answer => {
        if (moment(answer.timestamp).isSame(moment(this.selectedDate), "day")) {
          this.answer = answer.text;
        }
      });
    }

    // setTimeout(() => self.$refs.slick1.setPosition(), 300);
  },
  methods: {
    log() {
      console.log(this.answer);
    },
    selectDate(prevAns) {
      this.$emit("selected-date", moment(prevAns.timestamp));
      this.answer = prevAns.text;
    },
    setAnswer(answer) {
      this.answer = answer;
    }
  },
  watch: {
    answer(newAnswer) {
      if (
        this.question.max_val !== false &&
        this.question.min_value !== false
      ) {
        if (newAnswer > this.question.max_val)
          newAnswer = this.question.max_val;
        if (newAnswer < this.question.min_val)
          newAnswer = this.question.min_val;
      }
      this.answer = newAnswer.toString();
      this.$parent.setAnswer(this.question.id, newAnswer.toString());
    }
  }
};
</script>
<style lang="less">
.Question {
  .question-title {
    font-family: PublicoHeadlineWebBold;
  }

  margin-bottom: 45px !important;
  .custom-control:focus {
    outline: none !important;
  }
  border-radius: 0 16px 16px 0;
  .numer-question-item {
    border-radius: 0 16px 16px 0;
    //border-left: 5px solid;
    border-color: inherit;
  }
  .time-question-item {
    min-height: 200px;
    border-radius: 0 16px 16px 0;
    border-left: 5px solid;
    border-color: inherit;
  }
  .question-chart-container {
    margin: 10px;
    width: 30%;
    border-radius: 16px;
    overflow: hidden;
  }
  .question {
    flex-grow: 1;
  }
  .question-title {
    font-weight: bold;
    margin-bottom: 10px;
    font-size: 2.2rem;
  }
  .question-description {
    font-size: 1.6rem;
  }
  .percentage {
    font-size: 1.6rem;
    font-weight: bold;
    margin-bottom: 10px;
  }
  .time-colon {
    font-size: 3rem;
    font-weight: bold;
    margin: 0 5px;
  }
  .date-card {
    position: relative;
    padding: 8px 10px;
    border-radius: 16px;
    border: 2px solid;
    border-color: inherit;
    font-size: 1.6rem;
    margin-bottom: 5px;
  }
  .date-card-text {
    word-wrap: break-word;
    text-align: center;
  }
  .date-card-day {
    font-weight: bold;
    border-bottom: 2px solid lightgray;
    text-align: center;
  }
  .date-card-date {
    width: 50px;
    height: 50px;
    border-radius: 50px;
    color: white;
    position: absolute;
    top: -25px;
    right: -25px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 1.3rem;
    padding: 2px;
    cursor: pointer;
  }
  .date-card-date.inactive {
    background-color: lightgray;
  }
  .text-input {
    font-size: 1.8rem;
  }
  .number-input {
    font-size: 1.8rem;
    width: 200px;
  }
  .time-input {
    font-size: 1.8rem;
    width: 200px;
  }
  .border-brand-primary {
    border-width: 2px;
    border-radius: 6px;
  }
  // Track styling
  input[type="range"]::-moz-range-progress {
    background: #cccaca;
  }
  input[type="range"]::-webkit-progress-value {
    background: black;
    color: black;
  }

  input[type="range"] {
    height: 39px;
    -webkit-appearance: none;
    width: 100%;
  }
  input[type="range"]:focus {
    outline: none;
  }
  input[type="range"]::-webkit-slider-runnable-track {
    width: 100%;
    height: 10px;
    cursor: pointer;
    animate: 0.2s;
    box-shadow: 0px 0px 0px #000000;
    background: #cccaca;
    border-radius: 30px;
    border: 0px solid #010101;
  }
  input[type="range"]::-webkit-slider-thumb {
    height: 24px;
    width: 24px;
    border-radius: 15px;
    background: #00008f;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -7px;
  }
  input[type="range"]:focus::-webkit-slider-runnable-track {
    background: #cccaca;
  }
  input[type="range"]::-moz-range-track {
    width: 100%;
    height: 10px;
    cursor: pointer;
    animate: 0.2s;
    box-shadow: 0px 0px 0px #000000;
    background: #cccaca;
    border-radius: 0px;
    border: 0px solid #010101;
  }
  input[type="range"]::-moz-range-thumb {
    box-shadow: 0px 0px 0px #000031;
    border: 3px solid #cccaca;
    height: 30px;
    width: 30px;
    border-radius: 30px;
    background: #ffffff;
    cursor: pointer;
  }
  input[type="range"]::-ms-track {
    width: 100%;
    height: 10px;
    cursor: pointer;
    animate: 0.2s;
    background: transparent;
    border-color: transparent;
    color: transparent;
  }
  input[type="range"]::-ms-fill-lower {
    background: #cccaca;
    border: 0px solid #010101;
    border-radius: 0px;
    box-shadow: 0px 0px 0px #000000;
  }
  input[type="range"]::-ms-fill-upper {
    background: #cccaca;
    border: 0px solid #010101;
    border-radius: 0px;
    box-shadow: 0px 0px 0px #000000;
  }
  input[type="range"]::-ms-thumb {
    margin-top: 1px;
    box-shadow: 0px 0px 0px #000031;
    border: 3px solid #cccaca;
    height: 30px;
    width: 30px;
    border-radius: 15px;
    background: #ffffff;
    cursor: pointer;
  }
  input[type="range"]:focus::-ms-fill-lower {
    background: #cccaca;
  }
  input[type="range"]:focus::-ms-fill-upper {
    background: #cccaca;
  }
  input[type="text"] {
    padding: 0 0.5rem;
    min-height: 45px !important;
  }
  .slick-arrow {
    padding: 0;
    display: flex;
    align-items: center;
  }
  .prev_answer_box_notslick {
    width: 30% !important;
    margin-right: 25px;
  }
  .slack-arrow-container-right {
    background-color: rgba(255, 255, 255, 0.8) !important;
    right: 0 !important;
    width: 50px !important;
  }
  .slack-arrow-container-left {
    background-color: rgba(255, 255, 255, 0.8) !important;
    left: 0 !important;
    width: 50px !important;
  }
}
</style>
