<template>
  <div id="VideoRoomContainer" class="pb-5 h-100">
    <div class="d-flex justify-content-center align-items-cente loader-container" v-if="loading">
      <Loader />
    </div>
    <div v-else class="container">
      <transition name="fade">
        <div key="2">
          <VideoRoom2 :internalBookingId="booking_id" :local_video_track="local_video_track" :local_audio_track="local_audio_track" :booking="booking" :videoTrackCreated="videoTrackCreated" :audioTrackCreated="audioTrackCreated"
            @terminateVideoAndAudio="destroyAll" />
        </div>
      </transition>

    </div>
  </div>
</template>

<script>

import Loader from "@/components/Loader";
import VideoRoom2 from "./VideoRoom2";


const { isSupported } = require('twilio-video');
const { LocalVideoTrack, LocalAudioTrack, createLocalTracks, createLocalAudioTrack, createLocalVideoTrack } = require('twilio-video');

export default {
  name: "VideoRoom",
  components: { Loader, VideoRoom2 },
  props: ['booking_id'],
  data() {
    return {
      loading: true,
      view: 'test',
      audioTrackCreated: false,
      videoTrackCreated: false,
      tests: {
        audio: {
          testing: false,
          passed: false,
          audio_level: 0,
        },
        video: {
          testing: false,
          passed: false,
        },
        network: {
          testing: false,
          passed: false,
          average_download_speed: 0
        }
      },



      video_room_id: false,
      room_sid: false,
      unique_name: false,
      friendly_name: false,
      participants: [],
      token: false,
      booking: false,

      state: {
        remote_video_active: false,
        preview_video_active: false,
        microphone_active: false,
        coach_connected: false,
      },

      local_video_track: false,
      local_audio_track: false,

      coach: false,
      room: false,
      service_provider: '',

      isSupported: isSupported,
      loopIntervalId: null,
    }
  },
  mounted() {
    this.getBooking()
  },
  provide: function () {
    return {
      createRoomTracks: this.createRoomTracks,
      createLocalAudio: this.createLocalAudio,
      destroyLocalAudio: this.destroyLocalAudio,
      createLocalVideo: this.createLocalVideo,
      destroyLocalVideo: this.destroyLocalVideo
    }
  },
  beforeDestroy() {
    this.destroyLocalVideo()
    this.destroyLocalAudio()
  },
  methods: {
    destroyAll() {
      this.destroyLocalVideo();
      this.destroyLocalAudio();
    },
    async getBooking() {
      let params = {
        path: "api/u/coaching/get-booking",
        booking_id: this.booking_id,
      }
      let res = await this.api(params)
      if (res.success === true) {
        this.booking = res.jwt;
        this.loading = false;
      }else{
        this.loading = false;
      }
    },
    // changeView(){
    //   if(this.$route.params.tests === 'device-tests'){
    //     this.$router.push({
    //       path: '/booking-video-room/'+this.$route.params.booking_id
    //     })
    //   } else {
    //     this.$router.push({
    //       path: '/booking-video-room/'+this.$route.params.booking_id+'/device-tests'
    //     })
    //   }
    // },
    // CREATE LOCAL MEDIA TRACKS
    async createRoomTracks(){
      const tracks = await createLocalTracks({
        audio: true,
        video: { height: 1080, frameRate: 24, width: 1920, aspectRatio: 16/9, facingMode: 'user' },
        bandwidthProfile: {
            video: {
                mode: 'collaboration',
                dominantSpeakerPriority: 'standard'
            }
        },
        dominantSpeaker: true,
        maxAudioBitrate: 16000, //For music remove this line
        preferredVideoCodecs: [{ codec: 'VP8', simulcast: true }],
        networkQuality: {
            local:1,
            remote: 1
        }
      });
      if(tracks[0] instanceof LocalAudioTrack){
        this.local_audio_track = tracks[0];
      }

      if(tracks[1] instanceof LocalVideoTrack){
        this.local_video_track = tracks[1];
      }
      return true;
    },
    async createLocalAudio() {
      try {
        if (this.local_audio_track instanceof LocalAudioTrack) {
          console.log('Local audio track already created ....')
        } else {
          this.local_audio_track = await createLocalAudioTrack({
            audio: true,
            dominantSpeaker: true,
            maxAudioBitrate: 16000, //For music remove this line
            networkQuality: {
              local: 1,
              remote: 1
            },

          });
        }
        return true;
      }
      catch (exception_var) {
        console.log('create local audio exception', exception_var)
      }
      finally {
        this.audioTrackCreated = true;
        console.log('create local audio track', this.local_audio_track instanceof LocalAudioTrack)
      }
    },

    async createLocalVideo() {
      try {
        if (this.local_video_track instanceof LocalVideoTrack) {
          console.log('Local video track already created ....')
        } else {
          this.local_video_track = await createLocalVideoTrack({
            aspectRatio: 1.77777777778,
            height: 1080,
            width: 1920,
            bandwidthProfile: {
              video: {
                mode: 'collaboration',
                dominantSpeakerPriority: 'standard'
              }
            },
            dominantSpeaker: true,
            maxAudioBitrate: 16000, //For music remove this line
            preferredVideoCodecs: [{ codec: 'VP8', simulcast: true }],
            networkQuality: {
              local: 1,
              remote: 1
            }
          });
        }
        return true;
      }
      catch (exception_var) {
        console.log('create local video exception', exception_var)
      }
      finally {
        this.videoTrackCreated = true;
        console.log('create local video track', this.local_video_track instanceof LocalVideoTrack)
      }
    },


    // DESTROY LOCAL MEDIA TRACKS
    async destroyLocalAudio() {
      try {
        if (this.local_audio_track instanceof LocalAudioTrack) {
          this.local_audio_track.stop()
          this.local_audio_track.detach()
          this.local_audio_track = false
        } else {
          console.log('Local audio track not here ....')
        }
      }
      catch (exception_var) {
        console.log('destroy audio video exception: ', exception_var)
      }
      finally {
        console.log('destroy audio video track res: ', this.local_video_track instanceof LocalAudioTrack)
      }
    },

    async destroyLocalVideo() {
      try {
        if (this.local_video_track instanceof LocalVideoTrack) {
          this.local_video_track.stop()
          this.local_video_track.detach()
          this.local_video_track = false
        } else {
          console.log('Local video track not here ....')
        }
      }
      catch (exception_var) {
        console.log('destroy video video exception: ', exception_var)
      }
      finally {
        console.log('destroy video video track res: ', this.local_video_track instanceof LocalVideoTrack)
      }
    }
  }
};
</script>

<style scoped lang="less">
#VideoRoomContainer {
  font-size: 1.6rem;

  .loader-container {
    height: 600px;
  }

  .img_wrapper {
    text-align: center;
    padding: 0px 20px;

    img {
      margin-bottom: 20px;
      border-radius: 50%;
      border: 4px solid #eee;
      box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.09);
    }
  }

  .meeting_time {
    padding: 30px;

    .meeting_time_inner {
      box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.2);
      background-color: #403D56;
      color: white;

      div {
        padding: 6px 10px;
      }

      margin-bottom: 15px;
      border-radius: 15px 15px 0px 15px;

      .day {
        font-size: 17px;
      }

      .hour {
        font-weight: lighter;
        font-size: 14px;
      }

      .date {
        font-size: 30px;
      }

      .month {
        font-size: 17px;
        font-weight: lighter;
      }

      .start_booking {
        position: relative;
        padding: 0px;
        min-height: 25px;

        .start_booking_inner {
          position: absolute;
          right: 0px;
          bottom: 0px;
          background-color: #00008f;
          padding: 7px 25px;
          border-radius: 15px 0px 0px 0px;

          &:hover {
            cursor: pointer;
          }
        }
      }
    }
  }

  .clock_icon {
    text-align: center;
    font-size: 50px;
  }

  .bookings-wrap {
    width: 100%;

    .booking {
      width: 100%;
      border-left-style: solid;
      border-left-width: 10px;
      font-size: 2rem;
      cursor: pointer;
      border-color: #d60570;
      color: #fff;
      font-family: ff-cocon-web-pro, sans-serif;
      background-color: #cecece;
    }
  }
}
</style>
