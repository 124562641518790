<template>
  <div id="HealthScore">
    <div class="hero">
      <div class="hero-inner">
        <div class="hero-info">
          <h1 class="hero-title">{{ mainTitle }}</h1>
          <p class="hero-paragraph mb-0">
            {{ paragraphText }}
          </p>
        </div>
        <div class="hero-image-wrapper">
          <img
            class="image"
            src="/img/health-score/health-score-hero.webp"
            alt="Two people smiling while preparing a meal together in a kitchen"
          />
        </div>
      </div>
    </div>
    <div class="main">
      <div class="main-inner">
        <h2 class="main-title">
          {{ progressTitle }}
        </h2>
        <b-spinner class="spinner mb-5" v-if="isGetProgressLoading"></b-spinner>
        <div v-else class="main-cards">
          <AssessmentCard
            v-for="(card, index) in assessmentCards"
            :key="index"
            :cardData="card"
            :healthScoreProgress="healthScoreProgress"
            :areProgressBarsShown="areProgressBarsShown"
          />
        </div>
        <div class="underline"></div>
        <p class="main-paragraph">About your data - <a class="main-link" href="/privacy-policy" target="_blank">privacy policy</a></p>
      </div>
    </div>
  </div>
</template>

<script>
import AssessmentCard from "@/components/HealthScore/AssessmentCard.vue";
import axios from "axios";
import config from "@/config/constants";
import EventBus from "@/helpers/eventbus";

export default {
  name: "HealthScore",
  components: {
    AssessmentCard
  },
  data() {
    return {
      isGetProgressLoading: false,
      healthScoreProgress: null,
      areAllAssessmentsCompleted: false,
      isAnyAssessmentStarted: false,
      areProgressBarsShown: false,
      assessmentCards: [
        {
          id: "well_in_mind",
          title: "Well in mind",
          subtitle: "Check your mental wellbeing",
          paragraph:
            "Answer a few questions and we'll help you find simple ways to reduce stress and anxiety.",
          buttonText: "Start your mental wellbeing assessment",
          imgSrc: "/img/health-score/well-in-mind.png",
          alt: "Woman meditating"
        },
        {
          id: "well_in_life",
          title: "Well in life",
          subtitle: "Check your lifestyle",
          paragraph:
            "See whether you can improve your health by making small lifestyle changes.",
          buttonText: "Start your lifestyle assessment",
          imgSrc: "/img/health-score/well-in-life.png",
          alt: "Man and woman smiling while playing football"
        },
        {
          id: "well_in_body",
          title: "Well in body",
          subtitle: "Check your physical health",
          paragraph:
            "Answer a few questions and we'll help you find ways to improve your physical health.",
          buttonText: "Start your physical health assessment",
          imgSrc: "/img/health-score/well-in-body.png",
          alt: "Girl riding a bicycle"
        }
      ]
    };
  },
  computed: {
    mainTitle() {
      return this.isAnyAssessmentStarted || this.areAllAssessmentsCompleted ? "Welcome back to your Health Score" : "Welcome to Health Score"
    },
    paragraphText() {
      return this.areAllAssessmentsCompleted ? 
      `Welcome back! You’re up to date. View your Health Score results below.` 
      : this.isAnyAssessmentStarted ? 
      `Welcome back! You can continue any assessment where you left off.` : `Answer a few questions on the three key areas of health: mind, life
            and body - it won't take long. We'll use your answers to guide you
            on the best ways to improve your health.`
    },
    progressTitle() {
      return this.areAllAssessmentsCompleted ? "Your results" : "Your progress";
    }
  },
  mounted() {
    this.scrollToTop()
    this.getHealthScoreProgress();
  },
  methods: {
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    },
    async getHealthScoreProgress() {
      if (this.isGetProgressLoading) {
        return;
      }
      try {
        this.isGetProgressLoading = true;
        const payload = {
          user_id: this.$store.getters.user_id
        };
        const res = await axios.post(
          config.api_env +
            "/application/api/u/sapphire/healthscore/healthscore-progress",
          payload,
          config.options
        );
        if (res.data.success) {
          const result = res.data;
          this.healthScoreProgress = result;
          const assessmentsResults = Object.values(result).filter(
            value => typeof value === "number"
          );
          this.areAllAssessmentsCompleted = assessmentsResults.every(
            value => value >= 100
          );
          this.isAnyAssessmentStarted = this.areAllAssessmentsCompleted
            ? true
            : assessmentsResults.some(value => value !== 0);
          this.areProgressBarsShown = this.isAnyAssessmentStarted;
        }
      } catch (err) {
        console.error(err);
      } finally {
        this.isGetProgressLoading = false;
      }
    }
  }
};
</script>

<style lang="less">
@padding-x: 28px;
@max-width: calc(1110px + @padding-x * 2);

.centered {
  max-width: @max-width;
  margin: 0 auto;
}

#HealthScore {
  background-color: #fafafa;

  .spinner {
    border-color: #00008f;
    border-right-color: transparent;
  }

  .hero {
    background-color: #ecf1f8;
  }

  .hero-inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    padding: 48px @padding-x 38px;

    .centered();
  }

  .hero-info {
    flex: 1 1 50%;
    max-width: 468px;
  }

  .hero-title {
    font-family: "PublicoHeadlineWebBold";
    color: #00008f;
    font-size: 5.6rem;
    line-height: 7rem;
    margin-bottom: 8px;
  }

  .hero-paragraph {
    font-family: "SourceSansProRegular";
    font-size: 1.8rem;
    line-height: 2.7rem;
    color: #343c3d;
  }

  .hero-image-wrapper {
    flex: 1 1 50%;
    max-width: 540px;
    height: 320px;
    border-radius: 16px;
    overflow: hidden;
  }

  .image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: left;
    color: #111b1d;
    font-family: "SourceSansProRegular";
    font-size: 1.8rem;
  }

  .main {
    background: #fafafa;
  }

  .main-inner {
    padding: 64px @padding-x;

    .centered();
  }

  .main-title {
    font-family: "PublicoHeadlineWebBold";
    color: #343c3d;
    font-size: 3.6rem;
    line-height: 4.5rem;
    margin-bottom: 24px;
  }

  .main-paragraph {
    font-family: "SourceSansProRegular";
    color: rgba(17, 27, 29, 1);
    font-size: 1.8rem;
    line-height: 2.7rem;
    margin-top: 56px;
  }

  .main-link {
    font-family: "SourceSansProBold";
    color: rgba(0, 0, 143, 1);
    font-size: 1.8rem;
    line-height: 2.7rem;
    text-decoration: underline;

    &:hover {
      text-decoration: underline;
    }
  }

  .main-cards {
    display: flex;
    gap: 24px;
    justify-content: center;
    align-items: flex-start;
    margin-bottom: 72px;
  }

  .underline {
    height: 1px;
    background-color: #cccccc;
    padding: 0 @padding-x;
  }
}

@media only screen and (max-width: 1024px) {
  #HealthScore .main-cards {
    flex-direction: column;
  }
}

@media only screen and (max-width: 764px) {
  #HealthScore {
    .hero-inner {
      flex-direction: column-reverse;
    }

    .hero-info,
    .hero-image-wrapper {
      max-width: 100%;
    }
  }
}
</style>
