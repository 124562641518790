<template>
  <div id="HealthScoreResults" v-if="questionnaireId">
    <template v-if="isQuestionnaireCompleted">
      <div class="hero">
        <div class="hero-inner">
          <div class="hero-info">
            <h1 class="hero-title">{{ heroTitle }}</h1>
            <ScoreBar
              v-if="score && (score.score || score.score === 0)"
              :score="score"
            />
            <template v-if="decodedHtml">
              <p class="hero-paragraph" v-html="decodedHtml"></p>
            </template>
            <div class="hero-nav">
              <button v-if="redirectRoute && buttonText" class="button-dark" @click="$router.push(redirectRoute)">
                {{ buttonText }}
                <i class="fa fa-angle-right ml-4" aria-hidden="true"></i>
              </button>
              <button
                class="button-light"
                @click="$router.push('/healthscore')"
              >
                Continue to next assessment
                <i class="fa fa-angle-right ml-4" aria-hidden="true"></i>
              </button>
            </div>
          </div>
          <img
            class="hero-img"
            :src="`/img/health-score/${questionnaireId}_results.png`"
            :alt="questionnaireName"
          />
        </div>
      </div>
      <div class="main">
        <div class="main-inner">
          <div v-if="services && services.length">
            <h2 class="title mb-5">Recommended services</h2>
            <div class="main-services">
              <ServiceCard
                v-for="(service, index) in services"
                :key="index"
                :service="service"
              />
            </div>
          </div>
          <b-spinner v-if="isGetReportLoading"></b-spinner>
          <ReportAccordion
            v-else-if="report"
            title="Insights"
            :content="report"
          />
          <div v-if="articles && articles.length">
            <h2 class="title mb-5">Recommended articles and videos</h2>
            <slick id='slick-id' ref="slick" :options="slickOptions">
              <ArticleCard v-for="(article, index) in articles" :key="index" :item="article"></ArticleCard>
            </slick>
          </div>
          <div class="underline top"></div>
          <EmergencyBox />
          <div class="underline bottom"></div>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="main">
        <div class="main-inner">
          <h2 class="title mb-5">The questionnaire is not completed</h2>
          <button class="button-dark" @click="$router.push('/healthscore')">
            Back to Health Score
          </button>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import axios from "axios";
import config from "@/config/constants";
import ScoreBar from "@/components/HealthScore/ScoreBar.vue";
import EmergencyBox from "../components/HealthScore/EmergencyBox.vue";
import ReportAccordion from "../components/HealthScore/ReportAccordion.vue";
import ServiceCard from "../components/HealthScore/ServiceCard.vue";
import ArticleCard from "@/components/Home/New/ArticleCard.vue"
import slick from "vue-slick"

export default {
  name: "HealthScoreResults",
  components: {
    ScoreBar,
    EmergencyBox,
    ReportAccordion,
    ServiceCard,
    ArticleCard,
    slick
  },
  data() {
    return {
      existingAssessments: [
        "well_in_life",
        "well_in_body",
        "well_in_mind",
        "demographics"
      ],
      isGetScoreLoading: false,
      isGetReportLoading: false,
      score: null,
      report: null,
      services: null,
      articles: null,
      isQuestionnaireCompleted: true,
      slickOptions: {
        initialSlide: 0,
        arrows: true,
        slidesToScroll: 1,
        slidesToShow: 3,
        nextArrow: `<button type="button" class="slick-next"><i class="fa fa-chevron-right" aria-hidden="true"></i></button>`,
        prevArrow: `<button type="button" class="slick-prev"><i class="fa fa-chevron-left" aria-hidden="true"></i></button>`,
        adaptiveHeight: true,
        infinite: false,
        responsive: [
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 1,
            },
          },
          {
            breakpoint: 951,
            settings: {
              slidesToShow: 2,
            },
          },
          {
            breakpoint: 1066,
            settings: {
              slidesToShow: 3
            }
          },
          {
            breakpoint: 1315,
            settings: {
              slidesToShow: 4
            }
          }
        ],
      },
    };
  },
  computed: {
    questionnaireId() {
      return this.$route.params.assessment_id;
    },
    questionnaireName() {
      return this.questionnaireId.split("_").join(" ");
    },
    decodedHtml() {
      return this.score?.outcome_description
        ? atob(this.score?.outcome_description)
        : null;
    },
    buttonText() {
      return this.services && this.services.length ? this.services[0].button_text : null
    },
    redirectRoute() {
      return this.services && this.services.length ? this.services[0].link : null
    },
    heroTitle() {
      const result = this.score && this.score.score ? 'score' : this.report ? 'insights' : 'result'
      return `Your ${this.questionnaireName} ${result}`
    },
  },
  mounted() {
    this.scrollToTop();

    if (!this.existingAssessments.includes(this.questionnaireId)) {
      this.$router.push("/healthscore");
    } else {
      this.getScore();
      this.getReport();
    }
  },
  methods: {
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    },
    async getScore() {
      if (this.isGetScoreLoading) {
        return;
      }
      try {
        this.isGetScoreLoading = true;
        const payload = {
          user_id: this.$store.getters.user_id,
          questionnaire_name: this.questionnaireId
        };
        const res = await axios.post(
          config.api_env + "/application/api/u/sapphire/healthscore/score",
          payload,
          config.options
        );
        if (res.data.success) {
          this.score = res.data;
          this.isQuestionnaireCompleted = true;
        } else if (
          res.data.err &&
          res.data.err.includes("Questionnaire not complete")
        ) {
          this.isQuestionnaireCompleted = false;
        }
      } catch (err) {
        console.error(err);
      } finally {
        this.isGetScoreLoading = false;
      }
    },
    async getReport() {
      if (this.isGetReportLoading) {
        return;
      }
      try {
        this.isGetReportLoading = true;
        const payload = {
          user_id: this.$store.getters.user_id,
          questionnaire_name: this.questionnaireId
        };
        const res = await axios.post(
          config.api_env + "/application/api/u/sapphire/healthscore/get-report",
          payload,
          config.options
        );
        if (res.data.success) {
          this.report = res.data?.report;
          this.services = res.data?.services;
          this.articles = res.data?.articles;
        }
      } catch (err) {
        console.error(err);
      } finally {
        this.isGetReportLoading = false;
      }
    }
  }
};
</script>

<style lang="less">
@padding-x: 28px;
@max-width: calc(1110px + @padding-x * 2);

.centered {
  max-width: @max-width;
  margin: 0 auto;
}

#HealthScoreResults {
  background-color: #fafafa;

  .hero {
    background-color: #ecf1f8;
  }

  .hero-inner {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 20px;
    padding: 48px @padding-x 38px;

    .centered();
  }

  .hero-title {
    font-family: "PublicoHeadlineWebBold";
    color: #00008f;
    font-size: 5.6rem;
    line-height: 7rem;
    margin-bottom: 8px;
  }

  .hero-img {
    width: 100%;
    max-width: 280px;
  }

  .hero-info {
    flex: 1 1 auto;
    max-width: 766px;
  }

  .hero-paragraph {
    font-family: "SourceSansProRegular";
    word-wrap: break-word;
    width: 100%;
    font-size: 2rem;
    line-height: 3rem;
    color: #343c3d;
  }

  .hero-nav {
    display: flex;
    gap: 32px;
    margin-top: 32px;
  }

  .button-dark {
    text-align: center;
    font-family: "SourceSansProSemibold";
    background-color: #00008f;
    border-radius: 6px;
    padding: 12px 16px;
    color: #ffffff;
    font-size: 1.8rem;
    line-height: 2.7rem;
    transition: background-color 0.2s;
    border: 0;

    &:hover {
      background-color: #00006d;
    }

    &:focus {
      outline: 4px solid #ffbc11;
    }
  }

  .button-light {
    text-align: center;
    font-family: "SourceSansProSemibold";
    background-color: #fff;
    border-radius: 6px;
    padding: 12px 16px;
    color: #00008f;
    font-size: 1.8rem;
    line-height: 2.7rem;
    border: 1px solid #00008f;
    transition: background-color 0.2s;

    &:hover {
      border: 1px solid #00006d;
      color: #00006d;
    }

    &:focus {
      border: 1px solid #00006d;
      outline: 4px solid #ffbc11;
    }
  }

  .main {
    color: #343c3d;
    background-color: #fafafa;

    .title {
      font-family: "PublicoHeadlineWebBold";
      font-size: 3.6rem;
      line-height: 4.5rem;
      color: #343c3d;
    }

    .paragraph {
      font-family: "SourceSansProRegular";
      font-size: 1.8rem;
      line-height: 2.7rem;
      color: #343c3d;
      margin-bottom: 32px;

      p {
        margin-bottom: 8px;
      }

      b,
      strong {
        font-family: "SourceSansProBold";
      }
    }
  }

  .main-inner {
    color: #343c3d;
    padding: 48px @padding-x 38px;

    .centered();
  }

  .main-services {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    gap: 24px;
    margin-bottom: 48px;
  }

  .underline {
    background-color: #cccccc;
    height: 1px;

    &.top {
      margin: 56px 0;
    }

    &.bottom {
      margin-top: 56px;
    }
  }

  .slick-slider {
    .slick-slide {
      transform: scale(1);
      margin: 0 20px;
    }
  }

  .slick-track {
    padding: 16px 0;
    margin-left: 0;
  }

  .slick-next, .slick-prev {
    position: absolute;
    z-index: 10;
    top: 50%;
    background-color: #00008F;
    border-radius: 40px;
    border: 2px solid #FFFFFF;
    padding: 6px 10px;
    transform: translateY(-50%);
    box-shadow: 0px 4px 4px 0px #00000026;

    .fa {
      font-size: 2rem;
      color: #fff;
    }
  }

  .slick-next {
    right: -26px;
  }

  .slick-prev {
    left: -28px;
  }

  .slick-disabled {
    cursor: auto;
    visibility: hidden;
  }
}

@media only screen and (max-width: 764px) {
  #HealthScoreResults {
    .hero-inner {
      flex-direction: column-reverse;
    }
    .hero-img {
      align-self: center;
    }
    .hero-nav {
      flex-direction: column;
      gap: 20px;
    }
  }
}
</style>
