<template>
  <div id="VideoParser">
    <video
      v-bind:autoplay="autoplay"
      poster="/img/video_backgrounds/purple.jpg"
      class="img-fluid animate__animated animate__fadeIn"
      :src="content"
      controls
    ></video>
  </div>
</template>

<script>
export default {
  components: {},
  name: "VideoParser",
  props: ["content", "options", "autoplay"],
  data() {
    return {};
  },
  mounted() {},
  methods: {}
};
</script>
<style lang="less">
#VideoParser {
  width: 100%;
  video[poster] {
    width: 100%;
  }
  .img-fluid {
    width: 100%;
    border-radius: 24px;
    overflow: hidden;
  }
}
</style>
