<template>
    <div class="d-flex flex-row align-items-center my-5" v-if="isLoading">
        <div class="mx-auto my-auto">
            <b-spinner class="brand-primary"></b-spinner>
        </div>
    </div>
    <div id="Questionnaire" v-else-if="welcomeMessageRead">
        <div>

        </div>
        <section class="pulse-container" v-if="step !== 'PROMPT'">
            <div class="pulse-form-container content-container">
                <div class="pulse-form" id="pulse-form">
                    <div class="pulse-form-header-container">
                        <div class="pulse-form-header-steps">
                            <div class="header-step" v-for="i in numOfQuestions" :key="i" :class="{'bg-brand-primary' : i <= currentQuestionNumber, 'short' : numOfQuestions > 5}"></div>
                        </div>
                    </div>
                    <div v-if="loadingQuestion">
                        <div class="d-flex flex-row align-items-center question-spinner">
                            <div class="mx-auto my-auto">
                                <b-spinner class="brand-primary"></b-spinner>
                            </div>
                        </div>
                    </div>
                    <div class="pulse-form-question-container" v-else>
                        <div class="pulse-form-question">
                            <div class="pulse-form-question-text d-flex flex-row justify-content-center">
                                <span class="question-text brand-secondary text-center">{{
                                    currentQuestion.text
                                }}</span>
                            </div>
                            <div class="pulse-form-question-answer">
                                <div class="options-button-container row d-flex flex-row justify-content-center"
                                    v-if="currentQuestion.type === 'single_choice'">
                                    <div class="d-flex flex-column w-100">
                                        <b-button v-for="(option, i) in currentQuestionOptions" :key="i"
                                            @click="toggleOption(option.id, true)" class="w-100 mb-3"
                                            :variant="option.selected ? 'cta' : 'outline'">
                                            {{ option.text }}
                                        </b-button>
                                    </div>
                                </div>
                                <!-- <div>The value you've picked is: {{ currentAnswer }}</div> -->
                            </div>
                        </div>
                        <div class="content-container d-flex flex-row flex-wrap justify-content-between" >
                            <b-button variant="outline" class="form-btn shadow py-3 px-5 mb-3" @click="previousQuestion()"
                                v-if="!onFirstQuestion">
                                <i class="fas fa-angle-left mr-3"></i><span>Back</span>
                            </b-button>
                            <b-button variant="outline" class="form-btn shadow py-3 px-5 mb-3" @click="$router.push('/mental-health-coaching/start')"
                                v-else>
                                <i class="fas fa-angle-left mr-3"></i><span>Back</span>
                            </b-button>
                            <b-button class="form-btn cta-button shadow py-3 px-5 mb-3"
                                @click="answerQuestion(currentQuestion.id)">
                                <span v-if="currentQuestionNumber === numOfQuestions">Complete Question Set</span>
                                <span v-else>Next</span>
                                <i class="fas fa-angle-right ml-3"></i>
                            </b-button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="pulse-container" v-else>
            <div class="pulse-form-container content-container">
                <div class="pulse-form" id="pulse-form">
                    <div class="
                  pulse-form-question-container
                  d-flex
                  flex-column
                  align-items-center
                ">
                        <p class="prompt-text mt-5 pt-5" v-html="promptText"></p>
                        <p v-if="promptAction === 'EAP' && !callBackSuccess" class="prompt-text">Enter Phone Number</p>
                        <input type="number" class="form-control" v-if="promptAction === 'EAP' && !callBackSuccess"
                            v-model="phoneNumber" @keydown="testPhoneInput" />
                        <p v-if="callBackSuccess" class="prompt-text">Thank you, please expect a call back from us shortly.
                        </p>
                        <b-button v-if="!callBackSuccess" variant="cta" squared size='lg' class="px-5 my-5 shadow" @click="acceptPrompt()"
                            :disabled="promptAction === 'EAP' && !eapNumberValidated">{{ promptButtonText }}</b-button>
                        <div class="prompt-text-eap pt-3" v-if="promptAction === 'EAP'">
                            <div class="text-center">
                                IE: <a href="tel:+1800903542" class="telephone-link brand-text"><b>1800 903
                                        542</b></a><br />
                                UK: <a href="tel:+08081962016" class="telephone-link brand-text"><b>0808 196
                                        2016</b></a><br />
                                International: <a href="tel:+0035315180277" class="telephone-link brand-text"><b>00353 1 518
                                        0277</b></a><br />
                            </div>
                            <hr class="horizontal-divider my-5">
                            </hr>
                            <div class="text-center pb-4">or text/whatapp us:</div>
                            <div class="row no-gutters pb-5 justify-content-center">
                                <div class="col-6 text-center pb-3">IE SMS:<br> <a href="tel:+353871452056"
                                        class="telephone-link brand-text"><b>+353 87 145 2056</b></a></div>
                                <div class="col-6 text-center pb-3">IE Whatsapp:<br> <a href="tel:+353873690010"
                                        class="telephone-link brand-text"><b>+353 87 369 0010</b></a></div>
                                <div class="col-6 text-center pb-3">UK SMS &amp; Whatsapp:<br> <a href="tel:+447418360780"
                                        class="telephone-link brand-text"><b>+44 74 1836 0780</b></a></div>
                            </div>
                        </div>


                        <!-- <b-button class="outline-button my-3 prompt-button shadow" @click="$router.push('/total-mental-health/select-coach')">I would rath talk to someone right now</b-button> -->
                    </div>
                </div>
            </div>
        </section>
    </div>

    <div id="WelcomeMessage" class="" v-else>
        <section class="pulse-container">
            <div class="welcomeMessageContainer content-container mt-5">
                <img class="img-fluid pb-5" src="/img/axa/welcome.png">
                <h1 class="welcomeHeader brand-highlight-1" v-html="welcomeHeader"></h1>
                <p class="welcomeMessage text-left mb-3" v-html="welcomeMessage"></p>
                <h3 class="p-text-lg text-left w-100 mb-3">Privacy Policy</h3>
                <p class="welcomeMessage text-left">
                    Please read the <a href="/privacy-policy" target="_blank" class="brand-primary underlined"><b>privacy policy</b></a> and tell us if you’re happy to continue.
                </p>
                <input type="number" class="form-control" v-if="promptAction === 'EAP' && !callBackSuccess"
                    v-model="phoneNumber" />
                <b-button variant="cta" size="lg" squared class=" my-5 w-100 shadow" @click="readWelcomeMessage()">I accept the privacy policy</b-button>
            </div>
        </section>
    </div>
</template>
<script>
import axios from "axios";
import config from "@/config/constants";
import CoachingProfileDetails from "@/components/TotalMentalHealth/CoachingProfileDetails";

export default {
    name: "QuestionnaireModal",
    components: { CoachingProfileDetails },
    props: [],
    data() {
        return {
            answers: [],

            isLoading: true,
            welcomeMessageRead: false,
            loadingQuestion: false,
            promptText: "",
            promptButtonText: "",
            promptAction: "",
            numOfQuestions: 1,
            phoneNumber: "",
            currentQuestionNumber: 1,
            pulseSections: [],
            currentQuestion: null,
            currentQuestionOptions: [],
            currentAnswer: [{}],
            dropdownAnswer: [],
            currentSliderValue: 0,
            previousQuestionId: null,
            callBackSuccess: false,
            allQuestions: [],
            errors: [],
            introText: "",
            step: "Question",
            currentSurvey: "WHO-5",
            welcomeMessage: "You'll go through some questions to help the coach understand how you're feeling and how they can help.",
            welcomeHeader: "Before you start "
        };
    },
    mounted() {
        let promptKey = "PROMPT-" + this.$store.getters.user.user.user_id;
        const promptValue = localStorage.getItem(promptKey);
        if (promptValue) {
            this.welcomeMessageRead = true;
            if (promptValue === "EAP") {
                this.promptText =
                "<h2 class='welcomeHeader brand-highlight-1 mb-4'>Thank you for answering these questions.</h2> <p>We really appreciate your time and patience. Based on your answers, we feel that our 24/7 Mental Health Support Team would be better suited to support you on your wellbeing journey at this time.<br><br>A member of our 24/7 Mental Health Support Team would be glad to connect with you and provide you with in the moment support. They would also be keen to better understand your current wellbeing needs and can further review whether our Coaching Service would be best placed to help you meet such needs.<br><br>Please enter your phone number below to request a call-back from our 24/7 Mental Health support team or you can reach directly on the below numbers.</p><br><br>";
                this.promptButtonText = "Request Callback";
                this.promptAction = "EAP";
            } else if (promptValue === "SelectCoach") {
                this.promptText =
                "<h2 class='welcomeHeader brand-highlight-1 mb-4'>And we're done!</h2><p>Thank you for answering these questions. Now it’s time to introduce you to our Mental Health Coaches. We wish you all the very best as you start your journey towards achieving your wellbeing goals.</p>";
                this.promptButtonText = "Choose your coach!";
                this.promptAction = "SelectCoach";
            } else if (promptValue === "SurveryCORE10") {
                this.promptText =
                "<h2 class='welcomeHeader brand-highlight-1 mb-4'>Thank you for completing the questionnaire</h2><p>There are just a few more questions we feel that we need to ask you. (This will be the final set of questions)</p>";
                this.promptButtonText = "Proceed to Survey";
                this.promptAction = "SurveyCORE10";
            }
            this.step = "PROMPT";
            this.isLoading = false;
            this.loadingQuestion = false;
        } else {
            this.getSurvey("WHO-5");
        }
    },
    methods: {
        testPhoneInput(evt) {
            if (this.phoneNumber.toString().length > 14 && evt.key !== 'Backspace') {
                evt.preventDefault();
            }
        },
        readWelcomeMessage() {
            this.welcomeMessageRead = true;
        },
        getHeaderClasses() {
            if (this.getTheme() === "theme-0") return "pulse-form-header navy-header";
            return "pulse-form-header bg-brand-secondary";
        },
        updateSuccess() {
            this.coachingDataComplete = true;
        },
        async getSurvey(survey) {
            let self = this;
            this.isLoading = true;
            const payload = {
                user_id: this.$store.getters.user_id,
                name: survey,
            };
            let res = await axios.post(
                config.api_env + "/application/api/u/surveys/get-fixed-survey",
                payload,
                config.options
            );
            if (res.data.success) {
                this.currentSurvey = survey;
                this.pulseSections = res.data.survey.sections;
                this.introText = res.data.survey.intro_text;
                this.numOfQuestions = res.data.survey.questions_count;
                this.reports = [];
                let count = 1;
                let i = 0;

                this.pulseSections.forEach(function (section) {
                    self.reports.push({
                        name: section.name,
                        reports: section.outputs,
                        selected: count === 1 ? true : false,
                        previous: i === 0 ? null : self.pulseSections[i - 1].name,
                        next:
                            i === self.pulseSections.length - 1
                                ? null
                                : self.pulseSections[i + 1].name,
                    });
                    section.activeQuestions = {
                        lower: count,
                        upper: count + section.questions.length - 1,
                    };
                    count = count + section.questions.length;
                    section.questions.forEach(function (q) {
                        self.allQuestions.push(q);
                    });
                    i = i + 1;
                });
                this.currentQuestion = this.pulseSections[0].questions[0];
                this.handleInputValues();
                this.isLoading = false;
                this.step = "QUESTION";
            } else if (res.data.err && res.data.err.includes("3994")) {
                this.$store.dispatch("logout");
                this.$router.push("/login").catch((err) => { });
            }
        },
        async acceptPrompt() {
            let action = this.promptAction;
            if (action === "SelectCoach") {
                this.$router.push("/mental-health-coaching/select-coach");
            } else if (action === "SurveyCORE10") {
                this.currentQuestionNumber = 1;
                this.getSurvey("CORE-10");
            } else if (action === "EAP") {
                if (this.phoneNumber === "") return;
                let payload = {
                    user_id: this.$store.getters.user_id,
                    phone: this.phoneNumber,
                    service_id: 0,
                };
                let res = await axios.post(
                    config.api_env + "/application/api-hc/send-eap-request",
                    payload,
                    config.options
                );
                if (res.data.sent) {
                    this.callBackSuccess = true;
                }
            }
        },
        addToAnswers(qid, answer) {
            const index = this.answers.findIndex(item => item.qid === qid);
            if (index === -1) {
                this.answers.push({ qid: qid, answer: answer[0].answer });
            } else {
                this.answers[index].answer = answer[0].answer;
            }
        },
        async answerQuestion(qid) {
            this.errors = [];
            this.loadingQuestion = true;
            if (typeof this.currentAnswer[0].answer === "undefined") {
                this.errors.push("You must enter a valid option");
            } else {
                this.currentQuestionOptions = [];
                const payload = {
                    user_id: this.$store.getters.user_id,
                    question_id: qid,
                    answer: this.currentAnswer,
                };
                let res = await axios.post(
                    config.api_env +
                    "/application/api/u/surveys/answer-fixed-survey-question",
                    payload,
                    config.options
                );
                if (res.data.success) {
                    this.addToAnswers(qid, this.currentAnswer);
                    if (res.data.next_question === false) {
                        let isEAP = false;
                        res.data.results.forEach(function (result) {
                            if (result === "Refer User to EAP") isEAP = true;
                        });
                        const promptKey = "PROMPT-" + this.$store.getters.user.user.user_id;
                        if (isEAP) {
                            localStorage.setItem(promptKey, "EAP");
                            this.promptText =
                                "<h2 class='welcomeHeader brand-highlight-1 mb-4'>Thank you for answering these questions.</h2> <p>We really appreciate your time and patience. Based on your answers, we feel that our 24/7 Mental Health Support Team would be better suited to support you on your wellbeing journey at this time than our Mental Health Coaching Team.<br><br>A member of our 24/7 Mental Health Support Team would be glad to connect with you and provide you with in the moment support. They would also be keen to better understand your current wellbeing needs and can further review whether our Coaching Service or our Open-Ended Therapy Services would be best placed to help you meet such needs.<br><br>Please enter your phone number below to request a call-back from our 24/7 Mental Health support team or you can reach directly on the below numbers.</p><br><br>";
                            this.promptButtonText = "Request Callback";
                            this.promptAction = "EAP";
                        } else if (res.data.results[0] === "Commence Coaching Program") {
                            localStorage.setItem(promptKey, "SelectCoach");
                            this.promptText =
                                "<h2 class='welcomeHeader brand-highlight-1 mb-4'>And we're done!</h2><p>Thank you for answering these questions. Now it’s time to introduce you to our Mental Health Coaches. We wish you all the very best as you start your journey towards achieving your wellbeing goals.</p>";
                            this.promptButtonText = "Choose your coach!";
                            this.promptAction = "SelectCoach";
                        } else if (res.data.results[0] === "Administer CORE-10") {
                            localStorage.setItem(promptKey, "SurveryCORE10");
                            this.promptText =
                                "<h2 class='welcomeHeader brand-highlight-1 mb-4'>Thank you for completing the questionnaire</h2><p>There are just a few more questions we feel that we need to ask you. (This will be the final set of questions)</p>";
                            this.promptButtonText = "Proceed to Survey";
                            this.promptAction = "SurveyCORE10";
                        }
                        this.step = "PROMPT";
                        this.loadingQuestion = false;
                    } else {
                        this.previousQuestionId = qid;
                        this.currentQuestionNumber++;
                        this.currentQuestion = this.allQuestions.find(
                            (x) => x.id === res.data.next_question.id
                        );
                    }
                    this.handleInputValues();
                    this.loadingQuestion = false;
                    this.currentSliderValue = document.getElementById("pulse-slider").value;
                } else if (res.data.err && res.data.err.includes("3994")) {
                    this.$store.dispatch("logout");
                    this.$router.push("/login").catch((err) => { });
                }
            }
        },
        clearInputs() {
            if (document.getElementById("pulse-text-input")) {
                document.getElementById("pulse-text-input").value = "";
            }
            if (document.getElementById("pulse-number-input")) {
                document.getElementById("pulse-number-input").value = "";
            }
            this.dropdownAnswer = [];
        },
        toggleOption(id, radio) {
            if (radio) {
                this.currentQuestionOptions.forEach(function (o) {
                    o.selected = false;
                });
            }
            let selectedOption = this.currentQuestionOptions.find((x) => x.id === id);
            selectedOption.selected = !selectedOption.selected;
            this.setButtonAnswer();
        },
        previousQuestion() {
            let self = this;
            if (this.currentQuestion.id !== this.allQuestions[0].id) {
                this.loadingQuestion = true;
                let backQuestionIndex = this.currentSurvey === "WHO-5" ? -2 : 3;
                /*backQuestionIndex === -2 because -1 for actual array index and -1 for previous entry;
                +3 for the CORE-10 (i.e -2 plus 5 so that it does not go back to the WHO-5 questions
                 */
                this.currentQuestion =
                    this.allQuestions[this.currentQuestionNumber + backQuestionIndex];
                this.handleInputValues();
                this.currentQuestionNumber--;
                this.loadingQuestion = false;
            }
        },
        setFirstQuestion() {
            this.currentQuestion = this.pulseSections[0].questions[0];
            this.handleInputValues();
            this.currentQuestionNumber = 1;
        },
        handleInputValues() {
            let self = this;
            if (
                this.currentQuestion.type === "single_choice" ||
                this.currentQuestion.type === "multi_choice"
            ) {
                this.currentQuestionOptions = [];
                this.currentQuestion.options.forEach(function (option) {
                    self.currentQuestionOptions.push({
                        id: option.id,
                        text: option.text,
                        selected: false,
                    });
                });
            }
            this.currentAnswer = [{}];
            if (this.currentQuestion.layout === "scale") {
                const index = this.answers.findIndex(item => item.qid === this.currentQuestion.id);
                let pos = Math.floor(this.currentQuestionOptions.length / 2)
                if (index !== -1) {
                    pos = this.currentQuestion.options.findIndex(q => q.text === this.answers[index].answer);
                    this.currentSliderValue = pos;
                }
                self.setSliderAnswer(pos);
                this.currentSliderValue = pos;
            } else {
                this.clearInputs();
            }
        },
        setTextAnswer() {
            this.currentAnswer = [
                {
                    id: false,
                    answer: document.getElementById("pulse-text-input").value,
                },
            ];
        },
        setNumericAnswer() {
            let value = document.getElementById("pulse-number-input").value;
            this.errors = [];
            if (
                value > this.currentQuestion.max_value ||
                value < this.currentQuestion.min_value
            ) {
                this.errors.push(
                    "Your answer must fall within the minimum and maximum values. Min: " +
                    this.currentQuestion.min_value +
                    ", Max: " +
                    this.currentQuestion.max_value
                );
                if (value > this.currentQuestion.max_value) {
                    document.getElementById("pulse-number-input").value =
                        this.currentQuestion.max_value;
                    this.currentAnswer = [
                        {
                            id: false,
                            answer: document.getElementById("pulse-number-input").value,
                        },
                    ];
                } else if (value < this.currentQuestion.min_value) {
                    document.getElementById("pulse-number-input").value =
                        this.currentQuestion.min_value;
                }
            } else {
                this.currentAnswer = [
                    {
                        id: false,
                        answer: value,
                    },
                ];
            }
        },
        setButtonAnswer() {
            let answer = [];
            this.currentQuestionOptions.forEach(function (option) {
                if (option.selected) {
                    answer.push({ id: option.id, answer: option.text });
                }
            });
            this.currentAnswer = answer;
        },
        changeSliderAnswer() {
            let option =
                this.currentQuestionOptions[document.getElementById("pulse-slider").value];
            this.currentAnswer = [
                {
                    id: option.id,
                    answer: option.text,
                },
            ];
            const index = this.currentQuestion.options.findIndex(item => option.id === item.id);
            this.currentSliderValue = index;
            this.addToAnswers(this.currentQuestion.id, option.text);
        },
        setSliderAnswer(position) {
            this.loadingQuestion = false;
            const qid = this.currentQuestion.id;
            const ansIndex = this.answers.findIndex(item => item.qid === qid);
            if (ansIndex !== -1) {
                const optionIndex = this.currentQuestionOptions.findIndex(item => item.text === this.answers[ansIndex].answer);
                this.currentAnswer = [
                    {
                        id: this.currentQuestionOptions[optionIndex].id,
                        answer: this.answers[ansIndex].answer
                    }];
                const pos = this.currentQuestion.options.findIndex(item => item.text === this.answers[ansIndex].answer);
                this.currentSliderValue = pos;
            } else {
                let option =
                    this.currentQuestionOptions[
                    position ? position : this.currentSliderValue
                    ];
                this.currentAnswer = [
                    {
                        id: option.id,
                        answer: option.text,
                    },
                ];
                this.currentSliderValue = position;
            }
        },
        selectCourse(course_id) {
            this.$router.push("/wellbeing/" + course_id).catch((err) => { });
        },
        scrollTo(id) {
            const scrollTo = document.getElementById(id);
            scrollTo.scrollIntoView({ behavior: "smooth" });
        },
    },
    watch: {
        dropdownAnswer: function (val) {
            if (val) {
                let answer = [];
                if (val.length > 0) {
                    val.forEach(function (a) {
                        answer.push({ id: a.id, answer: a.text });
                    });
                    this.currentAnswer = answer;
                } else {
                    this.currentAnswer = [{ id: val.id, answer: val.text }];
                }
            }
        },
    },
    computed: {
        eapNumberValidated() {
            return this.phoneNumber.length > 7;
        },
        onFirstQuestion() {
            return this.currentQuestionNumber === 1;
        },
        skin() {
            return this.$store.getters.skin;
        },
        componentText() {
            return this.getText.loggedIn.insights;
        },
        selectedReport() {
            let reports = this.reports.filter(function (report) {
                return report.selected;
            })[0];
            return reports ? reports : [];
        },
    },
};
</script>
<style lang="less">
#Questionnaire,
#WelcomeMessage {
    min-height: 80vh;
    .content-container{
        max-width: 700px;
    }
    .form-control {
        height: 30px;
        border-radius: 5px;
        font-size: 1.6rem;
        max-width: 300px;
    }

    .pulse-container {

        .pulse-steps-container {
            max-width: 900px;
            padding: 60px 0 40px 0;
            margin: 0 auto;

            .pulse-step {
                display: flex;

                .pulse-step-title {
                    font-size: 1.6rem;
                }
            }
        }

        .pulse-counter {
            font-size: 1.6rem;
        }

        .pulse-form {
            .pulse-form-header-container {
                .pulse-form-header {
                    font-size: 1.6rem;
                    font-weight: bold;
                    padding: 15px;
                    text-align: center;
                    color: white;

                    .skip-button {
                        text-decoration: underline;
                        cursor: pointer;
                    }
                }

                .pulse-form-header-steps {
                    min-width: 100%;
                    display: flex;
                    flex-direction: row;
                    flex-wrap: 1;
                    justify-content: center;
                    border-radius: 8px;
                    padding-top: 30px;
                    margin: 20px 10px 20px 10px;

                    .header-step {
                        background-color: lightgrey;
                        width: 20%;
                        margin: 0 ;
                        height: 10px;
                    }
                    .header-step.short{
                        width: 10%;
                    }
                    .header-step:first-child {
                        border-top-left-radius: 6px;
                        border-bottom-left-radius: 6px;

                    }
                    
                    .header-step:last-child {
                        border-top-right-radius: 6px;
                        border-bottom-right-radius: 6px;
                    }
                }
            }

            .pulse-form-question-container {
                background-color: white;
                border-radius: 0 0 16px 16px;
                padding: 30px 30px 30px 45px;
                min-height: 320px;

                .prompt-text {
                    font-family: "DMSans", serif;
                    max-width: 600px;
                    text-align: center;
                    font-size: 2rem;
                }

                .prompt-text-eap {
                    font-family: "DMSans", serif;
                    max-width: 600px;
                    text-align: left;
                    font-size: 2rem;
                }

                .pulse-form-question-text {
                    padding-bottom: 30px;

                    .question-text {
                        font-size: 2.2rem;
                        font-weight: bold;
                    }
                }

                .pulse-form-question-answer {
                    max-width: 1060px;
                    display: flex;
                    justify-content: center;
                    padding: 30px 30px;
                    margin: 0 auto;

                    .answer-button {
                        border: 3px #bbb solid;
                        color: #bbb;

                        i {
                            position: relative;
                            top: 3px;
                        }
                    }

                    .answer-button.selected {
                        border: 3px solid;
                    }

                    .pulse-number-input {
                        height: 50px;
                        width: 100%;
                        max-width: 260px;
                        text-align: center;
                        font-size: 1.8rem;
                        color: #666;
                        border: 0px;
                        outline: none !important;
                        border-radius: 16px;
                        background-color: #fafafa;

                        :focus {
                            border: 0px;
                        }

                        :focus-visible {
                            border: 0px;
                            outline: none !important;
                        }
                    }

                    .pulse-text-input {
                        height: 50px;
                        width: 100%;
                        max-width: 360px;
                        text-align: center;
                        font-size: 1.8rem;
                        color: #666;
                        border: 0px;
                        outline: none !important;
                        border-radius: 16px;
                        background-color: #fafafa;

                        :focus {
                            border: 0px;
                        }

                        :focus-visible {
                            border: 0px;
                            outline: none !important;
                        }
                    }

                    .pulse-button {}
                }

                .options-button-container {
                    width: 400px;
                    display: flex;
                    justify-content: center;

                    .options-button {
                        flex-basis: 50%;
                        max-width: 50%;

                        @media only screen and (max-width: 769px) {
                            flex-basis: 100%;
                            max-width: 100%;
                        }
                    }
                }

                .pulse-form-navigation-buttons {
                    padding-top: 30px;

                    button {
                        i {
                            position: relative;
                            top: 1px;
                        }
                    }
                }
            }
        }

        .start-over-button {
            background-color: #997c9c;
            font-size: 1.4rem;
            font-weight: 400;
            border-radius: 6px;
            padding: 12.5px 15px;
            font-family: "DMSans";
            color: white;
        }
    }

    .pulse-error {
        color: red;
        font-size: 1.6rem;
        margin-top: 10px;
    }

    .cta-button {
        padding-top: 12.5px;
        padding-bottom: 12.5px;
    }

    .outline-button {
        padding-top: 12.5px;
        padding-bottom: 12.5px;
    }

    ::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: #bbb;
        opacity: 1;
        /* Firefox */
    }

    :-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: #bbb;
    }

    ::-ms-input-placeholder {
        /* Microsoft Edge */
        color: #bbb;
    }

    .cta-button {}

    .not-active {
        color: lightgrey;
    }

    .not-active.completed {
        color: darkslategrey; //this will need to go in to theme
    }

    .form-btn {
        min-width: 170px;
    }

    .question-spinner {
        border-radius: 16px 16px 0 0;
        margin-top: 0;
        padding-top: 0;
        background-color: white;
        min-height: 320px;
    }

    //select styling
    .v-select {
        margin: 0 auto;
        max-width: 400px;
        cursor: pointer !important;

        .vs__dropdown-toggle {
            border-radius: 20px;
            border: 0px #eee solid;
            font-family: DMSans, sans-serif;
            background: #eee;
            color: #666;
            font-size: 2rem;
            position: relative;
            padding: 12.5px 10px;
            min-height: 50px;

            .vs__selected-options,
            .vs__selected,
            .vs__actions,
            svg {
                color: #666;
                fill: #aaa;
            }
        }

        &.vs--single {
            .vs__clear {
                display: none;
            }
        }

        &.vs--open {
            .vs__selected-options {
                padding-bottom: 10px;
            }
        }

        .vs__dropdown-menu {
            text-align: left;
            padding-left: 0;
            list-style-type: none;
            max-height: 200px;
            font-size: 2rem;
            color: #4e555b;

            li {
                border-bottom: 1px solid #aaa;
                padding: 10px;

                &:last-child {
                    border-bottom-width: 0;
                }
            }

            ::-webkit-scrollbar {
                width: 10px;
            }

            ::-webkit-scrollbar-track {
                border-radius: 10px;
            }

            ::-webkit-scrollbar-thumb {
                border-radius: 10px;
                background-color: #aaa;
                height: 50px;
                -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
            }
        }

        .vs__dropdown-option--selected {
            position: relative;

            &:after {
                content: " ";
                position: absolute;
                color: #aaa;
                background-image: url("/public/img/insights/tick-blue.svg");
                background-position: center;
                background-repeat: no-repeat;
                background-size: 25px;
                width: 28px;
                height: 20px;
                right: 10px;
                top: 50%;
                transform: translateY(-50%);
            }
        }

        .vs__selected-options {
            .vs__selected {
                background-color: white;
            }
        }
    }

    .select {
        border-radius: 20px;
        border: 1px solid #fff;
        font-family: DMSans, sans-serif;
        background: transparent;
    }

    // track styling
    input[type="range"] {
        height: 36px;
        -webkit-appearance: none;
        margin: 10px 0;
        width: 100%;
    }

    input[type="range"]:focus {
        outline: none;
    }

    input[type="range"]::-webkit-slider-runnable-track {
        width: 100%;
        height: 30px;
        cursor: pointer;
        animate: 0.2s;
        box-shadow: 0px 0px 0px #000000;
        background: #eee;
        border-radius: 15px;
        border: 0px solid #000000;
    }

    input[type="range"]::-webkit-slider-thumb {
        box-shadow: 0px 0px 0px #000000;
        border: 0px solid #000000;
        height: 30px;
        width: 30px;
        border-radius: 15px;
        background: #aaa;
        cursor: pointer;
        -webkit-appearance: none;
        margin-top: 0px;
    }

    input[type="range"]:focus::-webkit-slider-runnable-track {
        background: #eee;
    }

    input[type="range"]::-moz-range-track {
        width: 100%;
        height: 30px;
        cursor: pointer;
        animate: 0.2s;
        box-shadow: 0px 0px 0px #000000;
        background: #eee;
        border-radius: 15px;
        border: 0px solid #000000;
    }

    input[type="range"]::-moz-range-thumb {
        box-shadow: 0px 0px 0px #000000;
        border: 0px solid #000000;
        height: 30px;
        width: 30px;
        border-radius: 15px;
        background: #aaa;
        cursor: pointer;
    }

    input[type="range"]::-ms-track {
        width: 100%;
        height: 30px;
        cursor: pointer;
        animate: 0.2s;
        background: transparent;
        border-color: transparent;
        color: transparent;
    }

    input[type="range"]::-ms-fill-lower {
        background: #eee;
        border: 0px solid #000000;
        border-radius: 30px;
        box-shadow: 0px 0px 0px #000000;
    }

    input[type="range"]::-ms-fill-upper {
        background: #eee;
        border: 0px solid #000000;
        border-radius: 30px;
        box-shadow: 0px 0px 0px #000000;
    }

    input[type="range"]::-ms-thumb {
        margin-top: 1px;
        box-shadow: 0px 0px 0px #000000;
        border: 0px solid #000000;
        height: 30px;
        width: 30px;
        border-radius: 15px;
        background: #aaa;
        cursor: pointer;
    }

    input[type="range"]:focus::-ms-fill-lower {
        background: #eee;
    }

    input[type="range"]:focus::-ms-fill-upper {
        background: #eee;
    }

    .flex-grow {
        flex-grow: 1;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    input[type="number"] {
        -moz-appearance: textfield;
    }

    .welcomeMessageContainer {
        max-width: 600px;
        padding: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    .welcomeHeader {
        font-size: 3rem;
        margin-bottom: 3rem;
    }

    .welcomeMessage {
        font-size: 1.6rem;
        line-height: 3rem;
        text-align: center;
    }

    .welcomeTop {
        padding: 30px !important;
    }
}
</style>
