<template>
  <div id="Navigation" class="bg-white">
    <div
      class="content-container d-flex flex-row flex-nowrap justify-content-start align-items-center w-100 py-2"
    >
      <img
        @click="$router.push('/')"
        class="bupa-nav-logo clickable ml-2 ml-sm-0"
        src="/img/axa-health.svg"
        onerror="javascript:this.src='/img/logo-spectrum-life.svg'"
      />
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  name: "Navigation",
  data() {
    return {};
  },
  mounted() {},
  methods: {
    toggleDarkMode() {
      this.$store.dispatch("setDarkMode", !this.darkMode);
    },
    navigate(item) {
      if (!item.external_link) {
        if (item.asset_id) {
          this.$router.push({
            name: item.item_slug,
            params: { id: item.asset_id }
          });
        } else {
          this.$router.push({ name: item.item_slug });
        }
      } else {
        window.open("https://" + item.external_link, "_blank");
      }
    }
  },
  computed: {
    ...mapGetters(["hardcoded", "skin", "client", "nav", "darkMode"]),
    currentPage() {
      return this.$route.name;
    },
    getBupaTouchLink() {
      if (
        process.env.NODE_ENV === "development" ||
        process.env.NODE_ENV === "staging"
      ) {
        return "https://scsmoke3.np.bupa.co.uk/touchdashboard/dashboard/gp-options";
      } else {
        return "https://www.bupa.co.uk/touchdashboard/dashboard/gp-options";
      }
    },
    getLogo() {
      if (this.hardcoded && this.hardcoded.client_logo) {
        return this.hardcoded.client_logo;
      } else if (this.skin === 8) {
        return "/img/boi/boi-logo-alt.png";
      } else return this.client.client_logo;
    }
  }
};
</script>
<style lang="less">
#Navigation {
  height: 80px;
  max-height: 80px;
  border-bottom: 1px solid lightgrey;

  .logo-col {
    display: flex;
    flex-grow: 1;
    max-width: 50%;
    padding-left: calc((100% - 1170px) / 2);

    .nav-logo {
      height: 100%;
      padding: 5px;
    }
  }

  .nav-container {
    max-width: 100%;
    margin: 0;
    border-bottom: 8px solid #e4e4e7;
  }

  .nav-col {
    flex-grow: 1;
    flex-shrink: 1;
    //padding-right: calc((100% - 1170px) / 2);
    .nav-link {
      font-size: 1.6rem;
      padding-right: 25px;
    }

    .dropdown-item {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 50px;
      font-size: 1.6rem;

      .dropdown-item-inner {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        flex-grow: 1;
        align-items: center;
      }

      .nav-arrow {
        opacity: 0;
      }

      &:hover {
        font-weight: bold;

        .nav-arrow {
          opacity: 1 !important;
        }
      }

      &:active {
        background-color: #dedede;
      }
    }

    ul.dropdown-menu {
      z-index: 10000;
      border-radius: 16px;
      padding: 0;

      overflow: hidden;
    }
  }

  .triangle-div {
    flex-shrink: 1;
    width: 80px !important;
    max-height: 80px !important;
    padding-left: 10px;
    border-style: solid;
    border-width: 80px 80px 0 0;
  }

  .triangle-div-overlap {
    position: relative;
    top: -80px;
    right: 10px;
    width: 80px;
    border-style: solid;
    border-width: 80px 80px 0 0;
    border-color: white transparent transparent transparent;
  }
  .dark-mode-container {
    color: white;
    width: 125px;
    padding-right: calc(((100% - 1170px) / 2) - 125px);
    font-family: "DMSans", sans-serif;
    font-size: 1.4rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
  }
  .switch-holder {
    display: flex;
    align-items: center;
    justify-content: center;
    .switch {
      width: 50px;
      height: 19px;
      border-radius: 20px;
      cursor: pointer;
      background-color: #56554d;
      position: relative;
      .switch-text {
        color: white;
        width: 100%;
        height: 100%;
        line-height: 18px;
        font-size: 0.85rem;
      }
      &::after {
        content: "";
        width: 15px;
        height: 15px;
        background: linear-gradient(90deg, #ed3a75 0%, #6a356a 100%);
        border-radius: 50%;
        position: absolute;
        box-shadow: 1px 1px 1px #6a356a;
        top: 2px;
        left: 3px;
        transition: transform 0.25s ease-out;
      }
      &.untoggled {
        background-color: #d3d3d3;
        .switch-text {
          text-align: right !important;
          padding-right: 5px;
          transform: translate(0px, 0px);
        }
      }
      &.toggled {
        .switch-text {
          text-align: left !important;
          padding-left: 8px;
        }
        &::after {
          top: 2px;
          transform: translate(30px, 0px) rotate(180deg);
        }
      }
    }
  }
  .bupa-nav-logo {
    max-height: 70px;
  }
}
</style>
