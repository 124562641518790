<template>
  <div id="DmhLocation">
    <Loader v-if="gettingLocation"/>
    <img src="/img/dmh/map.png" class="img-fluid" alt="map" v-else-if="!longitude" />
    <div v-else>
      <h3 class="brand-text">Your location is now set:</h3>
      <h5 class="brand-text" v-if="address">Address: {{ address }}</h5>
      <LeafletMap :lng="longitude" :lat="latitude" @updateCoordinates="updateCoordinates" />
    </div>

    <div
      v-if="!longitude"
      class="location-box border-brand-primary clickable shadow mt-4"
    >
      <i class="fa-solid fa-circle-info brand-primary"></i>
      <div class="location-box-text" @click="goToLocationArticle">
        Learn how to turn on your browsers location services
        <span class="brand-text">here</span>
      </div>
    </div>
    <div class="mt-5 skip">
      <span class=" brand-text" @click="skip">Skip this step</span>
    </div>
  </div>
</template>

<script>
import LeafletMap from "@/components/LeafletMap.vue";
import Loader from "@/components/Loader.vue";

export default {
  name: "DmhLocation",
  components: {Loader, LeafletMap },
  props: ["question", "setAnswer"],
  data() {
    return {
      gettingLocation: true,
      address: null,
      longitude: null,
      latitude: null,
      checker: null,
      iOSLink: "https://support.apple.com/en-us/HT203033",
      macLink:
        "https://support.apple.com/en-gb/guide/mac-help/mh35873/15.0/mac/15.0",
      chromeLink: "https://support.google.com/chrome/answer/142065",
      firefoxLink:
        "https://support.mozilla.org/en-US/kb/does-firefox-share-my-location-websites"
    };
  },
  mounted() {
    this.getLocation();
  },
  beforeDestroy() {
    if (this.checker !== null) clearInterval(this.checker);
  },
  methods: {
    skip() {
      this.setAnswer({
        lat: null,
        lng: null,
        address: null
      });
      this.$emit("skip");
    },
    updateCoordinates(newLatLng) {
      this.updateLocation({
        coords: {
          latitude: newLatLng.lat,
          longitude: newLatLng.lng
        }
      });
    },
    async getAddress() {
      this.address = null;
      const res = await this.api({
        path: "api/u/dmh/get-address-from-coordinates",
        lat: this.latitude,
        lng: this.longitude
      });
      if (res.success) {
        this.address = res.display_name;
      }
    },
    async updateLocation(position) {
      if (this.checker !== null) clearInterval(this.checker);
      this.latitude = position.coords.latitude;
      this.longitude = position.coords.longitude;
      await this.getAddress();
      this.gettingLocation = false;
      this.setAnswer({
        lat: this.latitude,
        lng: this.longitude,
        address: this.address
      });
    },
    goToLocationArticle() {
      window.open(this.locationServiceHelpLink, "_blank");
    },
    onError() {
      if (this.checker === null) {
        this.checker = setInterval(this.getLocation, 1000);
      }
      this.gettingLocation = false;
    },
    getLocation() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          this.updateLocation,
          this.onError,
          {
            enableHighAccuracy: true,
            maximumAge: 0
          }
        );
      } else {
        this.error = "Geolocation is not supported by your browser.";
      }
    }
  },
  computed: {
    locationServiceHelpLink() {
      const userAgent = navigator.userAgent.toLowerCase();
      if (userAgent.includes("safari") && !userAgent.includes("chrome")) {
        if (
          userAgent.includes("iphone") ||
          userAgent.includes("ipad") ||
          userAgent.includes("ipod")
        ) {
          return this.iOSLink;
        } else if (
          userAgent.includes("macintosh") ||
          userAgent.includes("mac os")
        ) {
          return this.macLink;
        }
      } else if (userAgent.includes("chrome")) {
        return this.chromeLink;
      } else if (userAgent.includes("firefox")) {
        return this.firefoxLink;
      }
      return null;
    }
  }
};
</script>

<style lang="less">
#DmhLocation {
  width: 600px;
  max-width: 100%;
  margin: 0 auto;
  h3, h5 {
    font-family: "DMSans", sans-serif;
    font-size: 2.2rem;
    text-align: center;
    letter-spacing: unset;
  }
  h5 {
    font-size: 1.6rem;
  }
  .skip {
    display: flex;
    justify-content: center;
    align-items: center;
    span {
      font-family: "DMSans", serif;
      font-size: 1.6rem;
      text-decoration: underline;
      cursor: pointer;
    }
  }
  .location-box {
    background-color: white;
    max-width: 600px;
    width: 100%;
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    align-items: center;
    padding: 25px;
    border-radius: 8px;
    margin-bottom: 30px;
    min-height: 60px;
    i {
      font-size: 2rem;
    }
    .location-box-text {
      font-family: "DMSans", serif;
      font-size: 1.8rem;
      padding: 0 20px;
      width: 100%;
      span {
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
}
</style>
