<template>
  <div id="DmhTextArea">
      <div class="dmh-confirm-container w-100">
          <textarea v-model="answerText" class="dmh-confirm-input" rows="4" maxlength="300"
              placeholder="Enter text here" />
      </div>
      <div class="bottom-content">
          <span class="brand-label">Minimum 5 characters required</span>
          <span>{{ answerText.length }}/300</span>
      </div>
  </div>
</template>

<script>
export default {
  name: "DmhStart",
  props: ['question', 'answer', 'setAnswer'],
  data() {
      return {
          answerText: this.answer || '',
      };
  },
  mounted() {
      this.answerText = this.answer || '';
  },
  watch: {
      answer(newAnswer) {
          this.answerText = newAnswer || '';
      },
      answerText(newText) {
          this.setAnswer(newText);
      }
  }
};
</script>
<style lang="less">
#DmhTextArea {
  .dmh-confirm-container {
      display: flex;
      flex-direction: column;
      font-size: 1.6rem;
      background: #FFFFFF;
      border-radius: 8px;
      border: 1px solid #757575;
      padding: 15px;
      margin-bottom: 20px;

      .dmh-confirm-input {
        font-family: "SourceSansProRegular";
          font-size: 1.6rem;
      }

      textarea {
          background: #FFFFFF;
          border: none;
          width: 100%;
      }

      textarea:focus {
          outline-width: 0;
      }

  }

  .bottom-content {
      display: flex;
      gap: 8px;
      justify-content: space-between;
      align-items: center;
      font-size: 1.6rem;
      font-family: "SourceSansProRegular";
  }

  .dmh-confirm-container:focus-within {
      border-color: #1E1E22 !important;
  }
}
</style>
