<template>
  <div id="QuestionTypeMultipleChoice">
    <button
      v-for="(option, index) in optionsArray"
      :key="index"
      @click="toggleOption(option)"
      class="checkbox-button"
      :class="{ active: isOptionChecked(option) }"
    >
      <i
        v-if="isOptionChecked(option)"
        class="fa fa-check-square"
        aria-hidden="true"
      ></i>
      <span class="checkbox-button-text">{{ option.option_text }}</span>
    </button>
  </div>
</template>

<script>
import EventBus from "@/helpers/eventbus";

export default {
  name: "QuestionTypeMultipleChoice",
  props: ["optionsArray", "previousAnswer"],
  data() {
    return {
      selectedOptions: this.previousAnswer && this.previousAnswer !== "Yes" ? [...this.previousAnswer] : [],
    };
  },
  methods: {
    toggleOption(option) {
      if (this.isOptionChecked(option)) {
        this.selectedOptions = this.selectedOptions.filter(
          optionText => optionText !== option.option_text
        );
      } else {
        this.selectedOptions.push(option.option_text);
      }

      EventBus.$emit("setAnswer", this.selectedOptions);
    },
    isOptionChecked(option) {
      const isOptionSelected = this.selectedOptions.some(
        optionText => optionText === option.option_text
      );
      return isOptionSelected;
    }
  }
};
</script>

<style lang="less">
#QuestionTypeMultipleChoice {
  display: flex;
  flex-direction: column;
  gap: 32px;

  .checkbox-button {
    font-family: "SourceSansProSemibold";
    border: 2px solid #757575;
    background-color: #ffffff;
    border-radius: 6px;
    box-shadow: 0px 2px 2px 0px #33333326;
    padding: 12px 32px;
    color: #343c3d;
    text-align: left;
    font-size: 1.8rem;
    line-height: 2.8rem;

    &:hover {
      border-color: #343c3d;
    }

    &:focus {
      outline: 4px solid #ffbc11;
    }

    &.active {
      border: 2px solid #00008f;

      .checkbox-button-text::before {
        display: none;
      }
    }
  }

  .checkbox-button-text::before {
    content: "";
    position: relative;
    top: 3px;
    display: inline-block;
    width: 20px;
    height: 20px;
    border: 3px solid #757575;
    border-radius: 4px;
    margin-right: 8px;
  }

  .fa-check-square {
    position: relative;
    top: 2px;
    width: 20px;
    height: 20px;
    font-size: 2.2rem;
    color: #00008f;
    margin-right: 8px;
  }
}
</style>
