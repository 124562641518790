<template>
  <div id="MobileNav">
    <b-navbar class="py-0 pl-0 row bg-white">
      <div class="col-3 col-sm-4 nav-button">
        <b-button @click="togglePageNav">
          <font-awesome-icon
            class="brand-primary"
            icon="times"
            size="3x"
            v-if="pageNav"
          ></font-awesome-icon>
          <font-awesome-icon
            class="brand-primary"
            icon="bars"
            size="3x"
            v-else
          ></font-awesome-icon>
        </b-button>
      </div>
      <b-navbar-brand
        class="col-6 col-sm-4 px-0"
        @click="hardCodedNav($store.getters.homeRoute)"
      >
        <img
          v-if="$store.getters.skin !== 8"
          :src="getLogo"
          onerror="javascript:this.src='/img/logo-spectrum-life.svg'"
        />
        <img src="/img/boi/boi-logo-alt.png" v-else />
      </b-navbar-brand>
      <div class="col-3 col-sm-4 nav-button">
        <b-button @click="toggleSettingsNav"
          ><font-awesome-icon
            class="brand-primary"
            icon="ellipsis-h"
            size="3x"
          ></font-awesome-icon
        ></b-button>
      </div>
    </b-navbar>
    <b-collapse :visible="pageNav" class="col-12 page-nav bg-white" is-nav>
      <b-navbar-nav
        class=""
        align="center"
        v-if="$store.getters.skin === 8 && $store.getters.isIE"
      >
        <b-nav-item
          @click="hardCodedNav('/')"
          class="mx-5 py-4 brand-secondary border-brand-primary"
          :class="{ active: currentPage === 'home' }"
          id="id-home"
          >home</b-nav-item
        >
        <b-nav-item
          @click="hardCodedNav('/wellbeing')"
          class="mx-5 py-4 brand-secondary border-brand-primary"
          :class="{ active: currentPage === 'wellbeing' }"
          id="id-wellbeing"
          >wellbeing</b-nav-item
        >
        <b-nav-item
          @click="hardCodedNav('/eap')"
          class="mx-5 py-4 border-brand-primary"
          :class="{ active: currentPage === 'eap' }"
          id="id-eap"
          >EAP</b-nav-item
        >
        <b-nav-item
          @click="hardCodedNav('/events')"
          class="mx-5 py-4 border-brand-primary"
          :class="{ active: currentPage === 'events' }"
          id="id-events"
          >events</b-nav-item
        >
        <b-nav-item
          @click="hardCodedNav('/whats-happening')"
          class="mx-5 py-4"
          :class="{ active: currentPage === 'whats-happening' }"
          id="id-whats-happening"
          >what's happening?</b-nav-item
        >
      </b-navbar-nav>
      <b-navbar-nav class="" align="center" v-else>
        <div v-for="(navItem, index) in nav" :key="index">
          <b-nav-item
            v-if="
              navItem.items.length === 1 && navItem.items[0].name !== 'home'
            "
            class="mx-5 brand-primary"
            :class="{ active: currentPage === navItem }"
            :id="
              'id-' +
                navItem.items[0].name
                  .toLowerCase()
                  .split(' ')
                  .join('-')
            "
            @click="navigate(navItem.items[0])"
          >
            <span v-if="navItem.items[0].alias">{{
              navItem.items[0].alias
            }}</span>
            <span v-else>{{ navItem.items[0].name }}</span>
          </b-nav-item>
          <div class="mx-5" v-else-if="navItem.items.length > 1">
            <li
              v-if="navItem.items[0].alias"
              class="plans dropdown-toggle nav-item pb-2"
              :id="
                'id-' +
                  navItem.items[0].name
                    .toLowerCase()
                    .split(' ')
                    .join('-')
              "
              v-b-toggle.collapse-1
            >
              {{ navItem.items[0].name }}
            </li>
            <li
              class="plans dropdown-toggle nav-item pb-2"
              v-b-toggle.collapse-1
              :id="
                'id-' +
                  navItem.items[0].name
                    .toLowerCase()
                    .split(' ')
                    .join('-')
              "
              v-else
            >
              {{ navItem.items[0].name }}
            </li>
            <b-collapse
              id="collapse-1"
              toggle-class="nav-link-custom"
              class="mx-5"
            >
              <div class="row mx-0">
                <b-nav-item
                  v-for="(dropdownItem, i) in navItem.items"
                  :key="i"
                  class="col-12 px-1 brand-primary"
                  v-if="i > 0"
                  :id="'id-' + navItem.items[i].name"
                  @click="navigate(dropdownItem)"
                >
                  <span v-if="dropdownItem.alias">{{
                    dropdownItem.alias
                  }}</span>
                  <span v-else>{{ dropdownItem.name }}</span>
                </b-nav-item>
              </div>
            </b-collapse>
          </div>
        </div>
      </b-navbar-nav>
    </b-collapse>
    <div v-if="$store.getters.skin !== 20">
      <b-col
        v-if="settingsNav"
        class="col-12 settings-nav bg-brand-secondary-dark"
      >
        <b-link v-if="showSideBar" to="/settings" class="col-4 border-right"
          ><img src="/img/settings-generic.svg"
        /></b-link>
        <div
          @click="navigate('/faq')"
          v-if="showSideBar"
          class="col-4 border-right"
        >
          <img src="/img/faq-generic.svg" />
        </div>
        <div class="col-4">
          <img src="/img/logout-generic.svg" @click="logOut" />
        </div>
      </b-col>
    </div>
    <div v-else>
      <b-col
        v-if="settingsNav"
        class="col-12 settings-nav bg-brand-secondary-dark"
      >
        <div class="col-12">
          <img src="/img/logout-generic.svg" @click="logOut" />
        </div>
      </b-col>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "MobileNav",
  components: {},
  data() {
    return {
      pageNav: false,
      settingsNav: false
    };
  },
  mounted() {},
  methods: {
    hardCodedNav(url) {
      this.$router.push(url).catch(err => {});
      this.pageNav = false;
      this.settingsNav = false;
    },
    navigate(route) {
      if (route.link.toLowerCase().includes("https")) {
        window.open(route.link, "_blank");
      } else if (this.$route.path !== route.link) {
        this.$router.push({ path: route.link });
        this.$store.commit("setPageTitle", route.alias);
      }
      this.pageNav = false;
      this.settingsNav = false;
    },
    togglePageNav() {
      this.pageNav = !this.pageNav;
      this.settingsNav = false;
    },
    toggleSettingsNav() {
      this.settingsNav = !this.settingsNav;
      this.pageNav = false;
    },
    logOut() {
      this.$store.dispatch("logout");
      this.$router.push("/login").catch(err => {});
    }
  },
  computed: {
    ...mapGetters(["hardcoded", "skin", "client"]),
    nav() {
      return this.$store.state.nav;
    },
    currentPage() {
      return this.$route.name;
    },
    topLevel() {
      return Object.keys(this.nav);
    },
    showSideBar() {
      if (
        this.$store.state.accessCode ||
        this.$store.getters.subdomain.toLowerCase().includes("laya-wellbeing")
      ) {
        return false;
      } else return true;
    },
    getLogo() {
      if (this.hardcoded && this.hardcoded.client_logo) {
        return this.hardcoded.client_logo;
      } else return this.client.client_logo;
    }
  }
};
</script>
<style lang="less">
#MobileNav {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 100;
  .navbar {
    height: 80px;
    .nav-button {
      text-align: center;
      .btn {
        background: transparent;
      }
    }
    .navbar-brand {
      width: 40%;
      background-color: #fff;
      text-align: center;
      cursor: pointer;
      height: 80px;
      max-width: 247px;
      align-items: center;
      display: flex;
      img {
        max-width: 100%;
        margin: auto;
        max-height: 80%;
        //this section to be edited when BOI assets are ready
      }
    }
  }
  .page-nav {
    .nav-item {
      position: relative;
      border-bottom: solid 1px;
      a {
        font-size: 1.7rem;
        font-family: "DMSans", sans-serif;
      }
      &.active {
        a {
          font-family: "PublicoHeadlineWebBold", sans-serif;
        }
        &:before {
        }
      }
    }
    .dropdown-menu {
      position: relative;
    }
    .plans {
      font-size: 1.7rem;
    }
  }
  .settings-nav {
    height: 70px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      padding: 0;
      width: 31px;
      margin: auto;
    }
    .border-right {
      border-right: 1px solid #baa7bc !important;
    }
  }
}
</style>
