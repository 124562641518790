<template>
  <div id="ContentParser">
    <div v-if="!isLoadingContent">
      <h1 class="task-modal-title brand-text">
        {{ taskTitle }}
      </h1>
      <p class="task-modal-date" v-if="showDate()">
        {{ getFormatedDate(selectedDate, "dddd Do MMM") }}
      </p>
      <div class="content-parser-container">
        <div
          class="mb-5 content-parser-container-inner"
          :class="{
            'w-100': item.type === 'accordion' || item.type === 'carousel'
          }"
          v-for="item in content"
          :key="item.id"
        >
          <TextParser
            :content="item.content"
            :options="item.options"
            v-if="item.type === 'text'"
          />
          <ImageParser
            :content="item.content"
            :options="item.options"
            v-if="item.type === 'image'"
          />
          <AudioParser
            :content="item.content"
            :options="item.options"
            v-if="item.type === 'audio'"
          />
          <VideoParser
            :autoplay="item.autoplay"
            :content="item.content"
            :options="item.options"
            v-if="item.type === 'video'"
          />
          <QuestionaireParser
            :selectedDate="selectedDate"
            :content="item.content"
            :options="item.options"
            v-if="item.type === 'journal'"
            v-on:selected-date="selectDate"
          />
          <SliderParser
            :content="item.content"
            v-if="item.type === 'carousel'"
          />
          <AccordionParser
            :content="item.content"
            v-if="item.type === 'accordion'"
          />
        </div>
      </div>
      <div class="d-flex flex-row justify-content-center">
        <div class="error-message">{{ errorMessage }}</div>
      </div>
      <div class="row">
        <b-button
          class="cta-button task-complete-button mx-auto mb-5"
          @click="completeTask(id)"
          v-if="isQuestionaire"
          >Save Entry</b-button
        >
        <b-button
          class="cta-button task-complete-button mx-auto mb-5"
          @click="completeTask(id, true)"
          v-else-if="!completed"
          >Complete Task</b-button
        >
        <b-button
          class="cta-button task-complete-button mx-auto mb-5"
          @click="completeTask(id, false)"
          v-else-if="completed"
          >Mark as Unread</b-button
        >
      </div>
    </div>
    <div
      class="
        d-flex
        flex-row
        justify-content-center
        align-items-center
        spinner-container
      "
      v-else
    >
      <b-spinner class="brand-primary"></b-spinner>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import EventBus from "../../helpers/eventbus";
import TextParser from "./TextParser.vue";
import ImageParser from "./ImageParser.vue";
import AudioParser from "./AudioParser.vue";
import VideoParser from "./VideoParser.vue";
import QuestionaireParser from "./QuestionaireParser.vue";
import SliderParser from "./SliderParser.vue";
import AccordionParser from "./AccordionParser.vue";
import config from "@/config/constants";

export default {
  components: {
    TextParser,
    ImageParser,
    AudioParser,
    VideoParser,
    QuestionaireParser,
    SliderParser,
    AccordionParser
  },
  name: "ContentParser",
  props: ["id", "selectedDate", "setAsJournal"],
  data() {
    return {
      isLoadingContent: true,
      answerArray: [],
      errorMessage: "",
      content: "",
      taskTitle: "",
      completed: false
    };
  },
  mounted() {
    this.getTaskContent(this.id, this.selectedDate);
  },
  methods: {
    showDate() {
      if (this.content[0].type !== "journal") return false;
      if (this.content[0].options.journal_type === "worksheet") return false;
      return true;
    },
    setAnswerArray(arr) {
      this.answerArray = arr;
    },
    async getTaskContent(id, date) {
      if (date !== null) moment(date).format("YYYY-MM-DD");
      const payload = {
        user_id: this.$store.getters.user_id,
        task_id: id,
        date: date
          ? moment(date).format("YYYY-MM-DD")
          : moment().format("YYYY-MM-DD")
      };
      let res = await this.axios.post(
        process.env.VUE_APP_API_PATH +
          "/application/api/u/coaching/get-task-content",
        payload,
        config.options
      );
      if (res.data.success) {
        this.taskTitle = res.data.task.title;
        let autoplayAvailable = true;
        const content = res.data.task.content.map(item => {
          if (item.type === "video" && autoplayAvailable) {
            item.autoplay = true;
            autoplayAvailable = false;
          } else if (item.type === "video") {
            item.autoplay = false;
          }
          return item;
        });
        this.content = content;
        if (this.content[0].type === "journal") {
          this.$emit("setAsJournal");
        }
        this.completed = res.data.task.completed;
        this.isLoadingContent = false;
      } else {
      }
    },
    async saveAnswers() {
      let date =
        moment(this.selectedDate).format("YYYY-MM-DD") +
        " " +
        moment().format("HH:mm:ss");
      const payload = {
        user_id: this.$store.getters.user_id,
        answers: this.answerArray,
        task_id: this.id,
        date: date
      };
      try {
        let res = await this.axios.post(
          process.env.VUE_APP_API_PATH +
            "/application/api/u/coaching/save-answers",
          payload,
          config.options
        );
        if (res.data.success) {
          return res.data.success;
        } else {
          this.errorMessage = "Please ensure all questions have been answered";
          return res.data.success;
        }
      } catch (e) {
        if (e.response.data.message.includes("blank")) {
          this.errorMessage = "Please ensure all questions have been answered";
        } else {
          this.errorMessage = e.response.data.message;
        }
      }
    },
    async completeTask(id, mark_as_read = true) {
      let path = "/application/api/u/coaching/complete-task";
      if (!mark_as_read) {
        path = "/application/api/u/coaching/complete-task-toggle";
      }
      let answersSaved = true;
      if (this.answerArray.length !== 0) {
        answersSaved = await this.saveAnswers();
      }
      if (answersSaved) {
        if (
          moment(this.selectedDate).format("DD/MM/YYYY") ===
          moment().format("DD/MM/YYYY")
        ) {
          const payload = {
            user_id: this.$store.getters.user_id,
            task_id: id
          };
          let res = await this.axios.post(
            process.env.VUE_APP_API_PATH + path,
            payload,
            config.options
          );
          if (res.data.success) {
            this.$emit("closeJournal");
            this.$bvModal.hide("task-modal");
            EventBus.$emit("getCoachingData");
            EventBus.$emit("getCalendar", id);
          } else {
            console.log("failure");
          }
        } else {
          this.$emit("closeJournal");
          this.$bvModal.hide("task-modal");
          EventBus.$emit("getCoachingData");
          EventBus.$emit("getCalendar", id);
        }
      }
    },
    selectDate(timestamp) {
      this.$emit("selected-date", timestamp);
    }
  },
  computed: {
    isQuestionaire() {
      let isQuestionaire = false;
      this.content.forEach(function(content) {
        if (content.type === "journal") isQuestionaire = true;
      });
      return isQuestionaire;
    }
  },
  watch: {
    answerArray(newArray) {
      this.$emit("setAnswerArray", newArray);
    }
  }
};
</script>
<style lang="less">
#ContentParser {
  h1 {
    font-family: PublicoHeadlineWebBold;
  }
  .content-parser-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px 30px 0 30px;

    .content-parser-container-inner {
      width: 100%;
    }
  }
  .error-message {
    font-size: 1.8rem;
    color: #cc5555;
    font-weight: bold;
    margin-bottom: 25px;
  }
  .task-modal-date {
    font-size: 1.6rem;
    color: lightgray;
    margin: 10px auto;
    text-align: center;
  }
  .spinner-container {
    height: 300px;
  }
}
</style>
