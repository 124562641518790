<template>
    <div id="DmhSelect" class="border-brand-highlight-1">
        <div :class="[answer === i ? 'border-brand-highlight-1' : 'not-selected-border']" class="dmh-option  clickable shadow"
         v-for="(option, i) in question.options"
            @click="selectOptionIndex(i)">
            <div class="dmh-option-icon"><i class="ml-3 fa-regular" :class="[answer === i ? 'fa-circle-dot brand-highlight-1' : 'fa-circle']"></i></div>
            <div class="dmh-option-text brand-text">{{ option.option_text }}</div>
        </div>
    </div>
</template>
  
<script>

export default {
    name: "DmhStart",
    props: ['question', 'answer', 'setAnswer', 'answerQuestion'],
    components: {
    },
    mounted() { },
    data() {
        return {

        };
    },
    mounted() {
    },
    methods: {
        selectOptionIndex(i) {
            this.setAnswer(i);
        },
        getClass(text) {
            switch (text) {
                case "I'd like to talk to someone about my mental health.":
                    return "fa-solid fa-head-side-virus";
                case "I'd like to learn more about the service.":
                    return "fa-solid fa-book-open-reader";
                case "Financial Information":
                    return "fa-solid fa-balance-scale";
                case "Legal Information":
                    return "fa-solid fa-book-open-reader";
                case "I'd like to learn more about mental health and Wellbeing.":
                    return "fa-solid fa-heart-pulse";
                case "I'd like to get access another service like financial or legal.":
                    return "fa-solid fa-coins";
                case "Video":
                    return "fa-solid fa-video";
                case "Voice Only":
                    return "fa-solid fa-microphone";
                default:
                    return ""
            }
        }
    },
    computed: {
    },
};
</script>
<style lang="less">
#DmhSelect {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 650px;
    width: 100%;
    .not-selected-border{
        border: 2px solid #757575;
    }
    .border-brand-highlight-1{
        border: 3px solid #00008F;
    }
    .dmh-option {
        background-color: white;
        max-width: 650px;
        width: 100%;
        display: flex;
        flex-grow: 1;
        flex-direction: row;
        align-items: center;
        padding: 15px 25px;
        border-radius: 8px;
        margin-bottom: 30px;

        .dmh-option-text {
            font-family: 'DMSans', serif;
            font-weight: 600;
            font-size: 1.6rem;
            padding: 0 20px;
            width: 100%;
        }
        .dmh-option-icon{
            font-size: 2.4rem;
            color: #757575;
        }

    }
}</style>
  