<template>
    <div id="DigitalClinicsMeetingRoom">
        <div v-if="loading" class="d-flex align-items-center h-100 ">
            <Loader class="py-5 px-5 my-5"></Loader>
        </div>
        <div v-else-if="isEarly" class="mx-4 animate__animated animate__fadeIn">
            <WaitingRoom :booking="booking" @timeoutOver="setTimeoutOver" />
        </div>
        <VideoRoomContainer :booking="booking" class="animate__animated animate__fadeIn" v-else />
    </div>
</template>
<script>
import moment from "moment";
import Loader from "@/components/Loader";
import WaitingRoom from "./WaitingRoom.vue";
import VideoRoomContainer from "./VideoRoomContainer.vue";

export default {
    props: ['booking'],
    components: {
        Loader,
        WaitingRoom,
        VideoRoomContainer
    },
    name: "MeetingRoomModal",
    data() {
        return {
            loading: true,
            timeoutOver: false,
            isEarly: true,
        };
    },
    mounted() {
        let self = this;
        const now = moment();
        const start = moment(this.booking.startTime);
        // this.isEarly = start.diff(now, 'minutes') > 1;
        setTimeout(() => {
            self.loading = false;
        }, 300)
    },
    methods: {
        setTimeoutOver() {
            this.isEarly = false;
        },
    },
    computed: {
        envString() {
            return process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'staging';
        }
    },

};
</script>
<style lang="less">
#digital_clinics_modal___BV_modal_outer_ {
    z-index: 1000000 !important;
    * {
        scrollbar-width: thin;
        scrollbar-color: #aaa;
    }

    /* Chrome, Edge, and Safari */
    *::-webkit-scrollbar {
        width: 8px;
    }

    *::-webkit-scrollbar-track {
        background: rbga(0,0,0,0);
        margin: 10px
    }

    *::-webkit-scrollbar-thumb {
        background-color: #aaa;
        border-radius: 10px;
        border: 5px none #ffffff;
        opacity: 0;
    }
    .hidden-link{
        opacity: 0;
        cursor: pointer;
    }
}

#DigitalClinicsMeetingRoom {
    max-height: 93vh;
    overflow-y: auto;
    overflow-x: hidden;
}
</style>