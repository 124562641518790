<template>
    <div>
        <div v-if="event.e_type == 'event_series'" class="">
            <div class="py-3" v-for="ed in event.e_dates">
                <div class="event-info-item-wrap my-3 p-text d-flex flex-row align-items-center">
                    <div class="event-info-icon">
                        <i class="fas fa-clock brand-highlight-1" />
                    </div>
                    <div class=" event-info-item">
                        {{ getDateHours(ed.ed_start_date_time) }} - {{ getHoursMinutes(ed.ed_end_date_time) }}
                    </div>
                </div>
                <div v-if="event.e_is_virtual_event !== 1" class="event-info-item-wrap my-3 p-text d-flex flex-row align-items-center">
                    <div class="event-info-icon">
                        <i class="fas fa-map-marker-alt brand-highlight-1" />
                    </div>
                    <div class="event-info-item">
                        {{ event.e_locations }}
                    </div>
                </div>
                <div v-if="event.e_is_virtual_event !== 1 && event.e_room_info" class="event-info-item-wrap my-3 p-text d-flex flex-row align-items-center">
                    <div class="event-info-icon">
                        <i class="fas fa-map-marker-alt brand-highlight-1" />
                    </div>
                    <div class=" event-info-item">
                        {{componentText.room}} {{ event.e_room_info }}
                    </div>
                </div>
                <div class="event-info-item-wrap my-3 p-text d-flex flex-row align-items-center" v-if="event.e_service_provider_name">
                    <div class="event-info-icon">
                        <i class="fas fa-user brand-highlight-1" />
                    </div>
                    <div class=" event-info-item">
                        {{ event.e_service_provider_name }}
                    </div>

                </div>
                <div>
                    <BookDateButton :event_date="ed" :pay_flag="event.e_employee_pay_flag"/>
                </div>

                <div class="col-12 event-price d-flex align-items-center" v-if="event.e_employee_slot_cost > 0">Price: {{currencyString(event.e_employee_slot_cost, event.e_currency)}}  </div>

            </div>
        </div>
        <div v-else class="event-info">
            <div class="event-info-item-wrap my-3">
                <div class="event-info-icon">
                    <i class="fas fa-clock brand-highlight-1" />
                </div>
                <div class=" event-info-item">
                    {{ getDateHours(event.ed_start_date_time) }} - {{ getHoursMinutes(event.ed_end_date_time) }}
                </div>
            </div>
            <div v-if="event.e_is_virtual_event !== 1" class="event-info-item-wrap my-3">
                <div class="event-info-icon">
                    <i class="fas fa-map-marker-alt brand-highlight-1" />
                </div>
                <div class=" event-info-item">
                    {{ event.e_locations }}
                </div>
            </div>
            <div v-if="event.e_room_info && event.e_is_virtual_event !== 1" class="event-info-item-wrap my-3">
                <div class="event-info-icon">
                    <i class="fas fa-door-open brand-highlight-1" />
                </div>
                <div class=" event-info-item">
                    {{componentText.room}} {{ event.e_room_info }}
                </div>
            </div>
            <div class="event-info-item-wrap my-3" v-if="event.e_service_provider_name">
                <div class="event-info-icon">
                    <i class="fas fa-user brand-highlight-1" />
                </div>
                <div class=" event-info-item">
                    {{ event.e_service_provider_name }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import BookDateButton from "./BookDateButton";
    export default {
        name: "EventInfo",
        components : {
            BookDateButton
        },
        props: [
            'event',
        ],
        data() {
            return {

            };
        },
        mounted() {

        },
        methods: {
            reloadEvent(){
                this.$parent.getEventDate()
            }
        },
        computed:{
            componentText(){
                return this.getText.loggedIn.event.eventInfo
            }
        }
    };
</script>