<template>
    <div id="DmhConfirm">
            <div class="d-flex flex-row align-items-center justify-content-center brand-text my-4 pt-3">
                <i class="fa-solid fa-circle-check fa-3x mr-4"></i>
                <p class="p-text-lg pb-0 mb-0 text-left" style="max-width: 500px;">You have selected your first preference as <b>{{getFormatedDate(question.options[0], "dddd Do MMM")}}</b> at <b>{{getFormatedDate(question.options[0], "h:mma")}}</b></p>
            </div>
            <div class="d-flex flex-row align-items-center justify-content-center brand-text my-4 pt-3">
                <i class="fa-solid fa-circle-check fa-3x mr-4"></i>
                <p class="p-text-lg pb-0 mb-0 text-left" style="max-width: 500px;">You have selected your second preference as <b>{{getFormatedDate(question.options[1], "dddd Do MMM")}}</b> at <b>{{getFormatedDate(question.options[1], "h:mma")}}</b></p>
            </div>
    </div>
</template>
  
<script>
export default {
    name: "DmhConfirm",
    props: ['question'],
    components: {
    },
    mounted() { },
    data() {
        return {
            selectedOptionIndex: null,
        };
    },
    mounted() {

    },
    methods: {

    },
    computed: {
    },
};
</script>
<style lang="less">
#DmhConfirm {
    display: flex;
    flex-direction: column;
    align-items: center;

    .privacy-link {
        font-family: 'DMSans', sans-serif;
        text-decoration: underline;
    }
    a{
        color: #00008F;
        font-weight: 600;
    }

    p {
        font-size: 1.8rem;
        white-space: pre-wrap;
        text-align: center;
    }

    img {
        max-width: 600px;
        border-radius: 16px;
        cursor: not-allowed;
    }
}</style>
  