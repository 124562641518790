<template>
  <div class="TextParser">
    <div
      class="animate__animated animate__fadeIn"
      v-html="decodeData(content)"
    ></div>
  </div>
</template>

<script>
export default {
  components: {},
  name: "TextParser",
  props: ["content", "options"],
  data() {
    return {};
  },
  mounted() {},
  methods: {
    decodeData(data) {
      try {
        return atob(data);
      } catch (error) {
        return data;
      }
    }
  }
};
</script>
<style lang="less">
.TextParser {
  font-size: 1.6rem;
  p strong {
    font-family: PublicoHeadlineWebBold;
    margin-bottom: 2rem;
  }
  h4 {
    font-size: 1.8rem;
  }
  h3 {
    font-size: 2rem;
  }
  h2 {
    font-size: 2.4rem;
  }
  h1 {
    font-size: 2.8rem;
  }
  li {
    margin-bottom: 1.2rem;
  }
}
</style>
