var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"EvolveTask"}},[_c('div',{staticClass:"task mt-3",class:[
      _vm.task.disabled || _vm.task.completed
        ? 'bg-brand-highlight-1 white-text'
        : 'bg-white',
      _vm.task.disabled ? 'disabled-task' : '',
      _vm.task.completed ? 'completed-task' : '',
    ],on:{"click":function($event){return _vm.openTaskModal(_vm.task.id, _vm.task.title)}}},[_c('div',{staticClass:"fa-stack fa-1x mr-3"},[_c('i',{staticClass:"fas fa-circle brand-primary fa-stack-2x",class:_vm.getIconClass()}),_c('i',{staticClass:"fa-stack-1x fa-inverse",class:[_vm.activeClass]})]),(!_vm.task.completed)?_c('div',{staticClass:"task-text brand-primary"},[_vm._v(_vm._s(_vm.task.title))]):_c('div',[_c('div',{staticClass:"task-text strikethrough"},[_vm._v(_vm._s(_vm.task.title))])]),(!_vm.task.disabled)?_c('div',{staticClass:"task-time ml-auto",domProps:{"innerHTML":_vm._s(_vm.friendlyTaskDueDate(_vm.task))}}):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }