<template>
  <div id="map" :style="`height: ${height}px;`" />
</template>

<script>
import L from "leaflet";
import "leaflet/dist/leaflet.css";

export default {
  props: {
    height: {
      type: Number,
      default: 200
    },
    lat: {
      type: Number,
      required: true
    },
    lng: {
      type: Number,
      required: true
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    updateCoordinates(newLatLng) {
      this.$emit("updateCoordinates", newLatLng);
    },
    init() {
      const map = L.map("map").setView([this.lat, this.lng], 16);
      L.tileLayer("https://tile.openstreetmap.org/{z}/{x}/{y}.png", {
        maxZoom: 19
      }).addTo(map);
      const customIcon = L.icon({
        iconUrl:
          "https://spectrum-life-media.s3.eu-west-1.amazonaws.com/assets/leaflet/marker.png",
        iconSize: [38, 38],
        iconAnchor: [19, 38],
        popupAnchor: [0, -38]
      });
      const marker = L.marker([this.lat, this.lng], {
        icon: customIcon,
        draggable: true,
        autoPan: true
      })
        .addTo(map)
        .openPopup();
      marker.on("dragend", e => {
        this.updateCoordinates(e.target.getLatLng());
      });
    }
  }
};
</script>

<style>
#map {
  width: 100%;
  .leaflet-control-attribution {
    display: none;
  }
}
</style>
