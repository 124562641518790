<template>
  <div id="NotLoggedIn">
    <div class="bg-white">
      <div id="Nav" class="d-flex container flex-row align-items-center position-relative px-2 px-lg-0">
        <div class="content-container d-flex flex-row flex-wrap justify-content-between align-items-center w-100">
          <div>
            <img class="nav-logo ml-2 ml-sm-0" src="/img/axa-health.svg" />
          </div>
        </div>
      </div>
    </div>
    <div class=" error-banner" v-if="errorBanner">
      <div class="container">
        <b>Something went wrong.</b> {{ errorBannerString }}.<br /><br />Please contact
        support@supportedemail.axahealth.co.uk to resolve this
        issue.
      </div>
    </div>
    <div class="container d-flex flex-column min-height">
      <div v-if="loading" class="row mt-5 d-flex justify-content-center align-items-center">
        <Loader />
      </div>
      <div class="text-center error-message mx-auto brand-highlight-1 my-auto" v-else-if="errorPage">
        <h1>An error occurred while trying to access the service. Please contact support@spectrum.life to resolve this
          issue.</h1>
      </div>
      <div v-else class="row mt-5 d-flex justify-content-center align-items-center">
        <div class="col-12">
          <div class="login-container" v-if="!account">
            <div class="warning-container mt-5" v-if="showWarning">
              <div class="d-flex justify-content-between align-items-center mb-4">
                <h2 class="mb-0">First time here?</h2>
                <i class="fas fa-times fa-2x clickable" @click="hideWarning()"></i>
              </div>
              <div class="w-100 text-right"></div>
              <p class="p-text mb-0">We've updated our site to introduce new features and new support. If this is your
                first visit to the new look site please first register to set up your login. You'll need your company
                access code provided by your employer, or your existing AXA Health password as your access code if you
                are on a Business Health, Business Health Select, Business Protect or a PHC plan.
              </p>
            </div>
            <div class="normal-container mb-5">
              <div class="row d-flex align-items-center">
                <div class="col-12 col-md-7">
                  <h1 class="brand-highlight-1 mb-5 page-title">Support for your wellbeing every day</h1>
                  <p class="p-text mb-3">
                    Discover the support available to you as part of your employer's wellbeing services.
                  </p>
                  <p class="p-text mb-5">
                    Please first register to set up your login. You'll need your company access code or password. You
                    can then login using your personal login details.
                  </p>
                  <UserLogin />
                </div>
                <div class="image-container col-md-5 d-none d-md-flex">
                  <img class="img-fluid ml-auto" src="/img/homeHero.svg" />
                </div>
              </div>

            </div>
          </div>
          <div class="login-container" v-else>
            <div class="normal-container ">
              <div class="row d-flex align-items-center">
                <div class="col-12 col-md-6" v-if="!accessScreen">
                  <label class="dmh-confirm-field semibold">First Name</label>
                  <b-input class="dmh-confirm-input" aria-placeholder="Enter first name" v-model="f_name" />
                  <label class="dmh-confirm-field semibold">Last Name </label>
                  <b-input class="dmh-confirm-input" aria-placeholder="Enter last name" v-model="l_name" />
                  <label class="dmh-confirm-field semibold">Access Code</label>
                  <span @click="toggleAccessScreen()" class="ml-3 semibold brand-highlight-1 clickable p-text-sm"><i
                      class="far fa-circle-info fa-lg mr-2"></i>What is this?</span>
                  <b-input :style="{ borderColor: error_string ? 'red' : 'inherit' }"
                    class="dmh-confirm-input-no-margin" type="password" aria-placeholder="Enter last access code"
                    v-model="access_code" />
                  <div class="bs-box" style="min-height: 30px">
                    <label v-if="error_string" style="color: red; margin-top: 15px">This is not a valid code</label>
                  </div>
                  <div class="mt-5 mb-5 py-3">
                    <span class="terms-container p-text px-5 py-4 shadow">
                      I agree to the <a class="brand-highlight-1 semibold underlined" @click="openTerms()">Terms of
                        Service</a> <i @click="toggleTermsAgreement" class="pt-1 ml-2 brand-highlight-1 clickable"
                        :class="[termsAgreement ? 'far fa-square-check' : 'far fa-square']"></i>
                    </span>
                  </div>

                  <div class="button-wrapper mt-5 w-100">
                    <b-button :disabled="buttonActive" class="btn-height" variant="cta" size="lg" block
                      @click="processToken" squared><span v-if="!loading">Continue</span><b-spinner
                        v-else></b-spinner></b-button>
                  </div>
                </div>
                <div class=" col-12 col-md-6" v-else>
                  <h1 class="brand-highlight-1 mb-5 page-title">What is this?</h1>
                  <p class="p-text mb-5">The AXA Health wellbeing portal is only available to our corporate customers.
                    Your employer should have given you a <b>unique access code</b>. Without it, you won’t be able to
                    register.<br /><br />
                    If your Employee Assistance Programme is part of your <b>Business Heath, Business Health Select or a
                      PHC plan</b> please use your password as your <b>access code</b>.</p>
                  <b-button variant="red" squared size="lg" @click="toggleAccessScreen()"><i
                      class="far fa-caret-left mr-3"></i>Back</b-button>
                </div>
                <div class="image-container col-md-6 d-none d-md-flex">
                  <img class="img-fluid ml-auto" src="/img/homeHero.svg" />
                </div>

              </div>

            </div>
          </div>
        </div>
        <div class="col-md-4"></div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
// @ is an alias to /src
import UserLogin from "@/components/NotLoggedIn/UserLogin.vue";
import axios from "axios";
import config from "../config/constants";
import Loader from "../components/Loader";
import Footer from "../components/Footer";

export default {
  name: "Login",
  components: {
    UserLogin,
    Loader,
    Footer
  },
  data() {
    return {
      accessScreen: false,
      errorPage: false,
      errorBanner: false,
      errorBannerString: '',
      termsAgreement: false,
      showWarning: true,
      loading: false,
      warning: "",
      account: null,
      f_name: '',
      l_name: '',
      access_code: '',
      error_string: null
    };
  },
  beforeCreate() { },
  mounted() {
    this.$store.dispatch("logout");
  },
  methods: {
    openTerms() {
      window.open('/terms-and-conditions', "_blank");

    },
    toggleAccessScreen() {
      this.accessScreen = !this.accessScreen;
    },
    toggleTermsAgreement() {
      this.termsAgreement = !this.termsAgreement;
    },
    hideWarning() {
      this.showWarning = false;
    },
    async processLogin(account) {
      await this.processPreCheck(account.name, account.idTokenClaims.sub, account.idToken);
      this.account = account;
    },
    async processPreCheck(email, sso_id, token) {
      this.loading = true;
      let payload = {
        "email": email,
        "sso_id": sso_id,
        "token": token
      };
      let res = await axios.post(
        config.api_env + "/application/api/u/user/axa-sso-web-pre-check",
        payload,
        config.options
      );
      if (res.data.user_exist === true && res.data.user != []) {
        this.$store.dispatch("loginAs", res.data.user);
        this.$router.push('/');
      }
      this.loading = false
    },
    async processToken() {
      this.loading = true;
      let payload = {
        "access_token": this.access_code,
        "dob": "2001-01-01",
        "first_name": this.f_name,
        "last_name": this.l_name,
        "email": this.account.name,
        "sso_id": this.account.idTokenClaims.sub,
        "token": this.account.idToken
      };
      let res = await axios.post(
        config.api_env + "/application/api/u/user/axa-sso-web",
        payload,
        config.options
      );

      if (res.data) {
        if (res.data.user && res.data.success) {
          this.error_string = null
          this.$store.dispatch("loginAs", res.data.user);
          this.$router.push('/');
        } else if (res.data.error) {
          if (res.data.error === 'Access code not recognised') {
            this.error_string = res.data.error
          } else {
            this.errorBanner = true;
            this.errorBannerString = res.data.error;
          }
        } else {
          this.errorPage = true;
        }
      }
      this.loading = false
    },
  },
  computed: {
    buttonActive() {
      let active = true;
      if (!this.termsAgreement) {
        active = false;
      }
      if (this.f_name.length == 0 || this.l_name.length == 0 || this.access_code.length == 0) {
        active = false;
      }
      return !active;
    },
  },
  watch: {
    warning(newWarning) {
      if (newWarning) {
        this.showWarning = true;
      } else this.showWarning = false;
    }
  },
  provide: function () {
    return {
      processLogin: this.processLogin
    }
  },
};
</script>
<style lang="less">
#NotLoggedIn {
  display: flex;
  flex-direction: column;
  background-color: #fafafa;
  min-height: 100vh;

  .btn-height {
    min-height: 50px;
  }

  .header {
    background: white;
  }

  .page-title {
    font-size: 4rem;
  }

  .min-height {
    min-height: 70vh;
  }

  .error-banner {
    width: 100%;
    padding: 20px;
    color: white;
    background-color: #C91432;
    font-size: 1.6rem;
    text-align: center;
  }

  .error-message {
    max-width: 600px;
    align-self: center;
    justify-self: center;
  }

  .login-container {
    .warning-container {
      padding: 30px 35px;
      background: #e5f7fa;
      border: 1px solid #00ADC6;
      border-radius: 15px;
      margin-bottom: 30px;
    }

    .normal-container {
      background: white;
      padding: 55px 45px;

      h3 {
        font-weight: normal;
        font-size: 16px;
      }
    }
  }

  .terms-container {
    border-radius: 12px;
  }

  .warning {
    position: absolute;
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.4);
    top: 0;
    left: 0;
  }

  .dropdown-toggle {
    color: inherit;
    font-family: "DMSans", sans-serif;
    letter-spacing: 0;
    background: transparent;

    &:active {
      background: transparent;
    }
  }

  .dropdown-menu {
    font-size: 1.4rem;
  }

  label {
    font-family: "DMSans", serif;
    font-size: 1.6rem;
    margin-bottom: 10px;
  }

  .dmh-confirm-input-no-margin {
    padding: 15px;
    border: 1px solid #757575;
    border-radius: 8px;
    font-size: 1.8rem;
    min-height: 50px;
  }

  .dmh-confirm-input {
    padding: 15px;
    border: 1px solid #757575;
    border-radius: 8px;
    margin-bottom: 30px;
    font-size: 1.8rem;
    min-height: 50px;
  }

  .container {
    flex: 1 1 100%;

    .page-content {
      background-color: #fff;
      border-radius: 30px;
      overflow: hidden;
      min-height: 400px;
      padding: 0px !important;

      @media screen and (max-width: 768px) {
        border-radius: 0px;
      }

      .img-part {
        padding-left: 0px;

        .login-bg {
          -webkit-background-size: cover;
          -moz-background-size: cover;
          -o-background-size: cover;
          background-size: cover;
          position: relative;
          height: 100%;
          width: 100%;

          .heart-placeholder {
            position: absolute;
            top: 10%;
            left: 10%;
            height: 10%;
            width: 10%;

            .heart-img {
              height: 65px;
            }
          }

          .login-image {
            height: 60%;
            width: 80%;
            background-image: url("/public/img/auth/_sign-in.svg");
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
          }

          .verify-image {
            position: absolute;
            top: 20%;
            left: 20%;
            height: 60%;
            width: 60%;
            background-image: url("/public/img/auth/_verify-email.svg");
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
          }

          .sign-up-image {
            position: absolute;
            top: 10%;
            left: 10%;
            height: 80%;
            width: 80%;
            background-image: url("/public/img/auth/_sign-up.svg");
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
          }

          .forgot-pass-image {
            position: absolute;
            top: 20%;
            left: 20%;
            height: 60%;
            width: 60%;
            background-image: url("/public/img/auth/_forgot-password.svg");
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
          }
        }
      }

      .logos {
        flex-wrap: nowrap;
        justify-content: center;

        .logo {
          flex-grow: 1;
          justify-content: center;
          align-items: center;

          //height: 25px;
          img {
            max-height: 100%;
            max-width: 100%;
          }
        }
      }

      .form {
        background: #ffffff;

        .form-content {
          padding: 25px;
          min-height: 795px;

          .form-logo {
            margin: 30px auto 30px auto;

            img {
              width: 100%;
            }
          }

          p {
            font-size: 1.7rem;
            font-family: "DMSans", sans-serif;
            text-align: center;

            &.small {
              font-size: 1.3rem;
            }
          }

          label {
            color: #8d8e92;
            font-size: 1.5rem;
            margin-left: 20px;
          }

          .form-control {
            background-color: #efeef2;
            height: 50px;
            border-radius: 25px;
            font-size: 1.8rem;
            font-family: "Open Sans", sans-serif;
          }

          .forgot-password {
            color: #8d8e92;
            font-size: 1.5rem;
            text-decoration: underline;
            margin-left: 20px;
          }

          .submit-form {
            margin: 50px 0 0 0;
            text-align: center;

            .btn {
              margin: 0 0 30px 0;
              padding: 0 50px;
              box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.3);

              &.disabled {
                cursor: default;
              }
            }
          }

          .new-here {
            font-size: 1.7rem;
            text-decoration: none;
            text-align: center;
            margin-bottom: 25px;
          }

          .invalid-feedback {
            font-size: 1.4rem;
            color: red;
          }

          .is-valid {
            border-color: #ced4da;
            box-shadow: none;
          }

          .is-invalid,
          .is-valid {
            background-image: none;
          }

          .password-wrap {
            position: relative;

            .eye {
              font-size: 1.3rem;
              position: absolute;
              right: 20px;
              top: 50%;
              transform: translateY(-50%);
              cursor: pointer;
            }
          }
        }
      }
    }
  }

  .dark-mode-container {
    .darkModeText {
      color: white !important;
    }
  }
}
</style>
