<template>
  <div id="footer">
    <div class="d-flex flex-column justify-content-center align-items-end px-4">
      <div>
        <p>© {{ year }} Spectrum.Life. {{ componentText.rights }}</p>
      </div>
      <div class="links-container">
        <a href="https://app.spectrum.life/content-sources" target="_blank" class="privacy">
          {{ componentText.sources }}
        </a>
        <span>|</span>
        <a href="/privacy-policy" target="_blank" class="privacy">
          {{ componentText.privacy }}
        </a>
        <span>|</span>
        <a href="/terms-and-conditions" target="_blank">
          {{ componentText.terms }}
        </a>
      </div>
      <div class="col-12 logout mt-2 pr-0" v-if="$store.state.accessCode">
        <a @click="logout">{{ componentText.logout }}</a>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import moment from "moment";
export default {
  name: "Footer",
  methods: {
    logout() {
      this.$store.dispatch("logout");
      this.$router.push("/login");
    }
  },
  computed: {
    componentText() {
      return this.getText.loggedIn.footer;
    },
    year() {
      return moment().format("YYYY");
    },
  }
};
</script>
<style lang="less">
#footer {
  padding: 30px 0;
  text-align: center;
  background-color: #00008F;
  font-size: 1.4rem;
  color: #fff;
  a {
    text-decoration: none;
    color: #fff;;

    &:hover {
      text-decoration: underline;
    }
  }
  .links-container {
    display: flex;
    gap: 8px;
  }
}
</style>
