var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"RandoxBookingScreen"}},[(_vm.areDatesLoading)?_c('div',[_c('b-spinner')],1):_c('div',{staticClass:"booking-container animate__animated animate__fadeIn"},[_vm._m(0),(_vm.dates)?_c('div',{staticClass:"d-flex flex-column justify-content-center align-items-center animate__animated animate__fadeIn"},[_c('h4',{staticClass:"booking-subtitle"},[_vm._v("Select a date")]),_c('div',{staticClass:"slick-container"},[_c('slick',{ref:"slickRef",staticClass:"slick-class",attrs:{"options":_vm.slickOptions}},_vm._l((_vm.dates),function(value,key){return _c('div',{key:key,staticClass:"d-flex align-items-center justify-content-center"},[_c('b-button',{staticClass:"fixed-width",attrs:{"variant":key === _vm.selectedDate ? 'option-selected' : 'option'},on:{"click":function($event){return _vm.selectDate(key)}}},[_vm._v(" "+_vm._s(_vm.getFormatedDate(key, "DD MMM YYYY"))+" ")])],1)}),0)],1),(_vm.selectedDate)?_c('div',{staticClass:"clinicians-container"},[_c('h4',{staticClass:"booking-subtitle"},[_vm._v(" Clinicians available ")]),_vm._l((_vm.dates[_vm.selectedDate]),function(clinician,i){return _c('button',{key:i,staticClass:"clinician-card",class:i === _vm.selectedClinicianIndex
          ? 'active btn-option-selected'
          : 'btn-clinician-option',on:{"click":function($event){return _vm.selectClinicianIndex(i, clinician.clinicianId)}}},[_c('div',[_c('span',{staticClass:"clinician-card-name"},[_vm._v(_vm._s(clinician.name))]),_c('p',{staticClass:"clinician-card-info mt-1"},[_vm._v(_vm._s(clinician.skills))])])])})],2):_vm._e(),(_vm.selectedDate && _vm.selectedClinicianId)?_c('div',{staticClass:"animate__animated animate__fadeIn"},[_c('div',{staticClass:"date-container"},[_c('h4',{staticClass:"booking-subtitle"},[_vm._v("Select a time")]),_c('div',{staticClass:"timeslots-container"},_vm._l((_vm.dates[_vm.selectedDate][
              _vm.selectedClinicianIndex
            ].timeslots),function(timeslot,i){return _c('div',{key:i,staticClass:"timeslot-item"},[_c('b-button',{attrs:{"variant":timeslot === _vm.selectedTimeslot
                ? 'option-selected'
                : 'option'},on:{"click":function($event){return _vm.selectTimeslot(timeslot)}}},[_vm._v(" "+_vm._s(timeslot)+" ")])],1)}),0)]),_c('div',{staticClass:"tcs-container"},[_c('p',{staticClass:"mb-0 text-center"},[_c('button',{staticClass:"tcs-button",on:{"click":function($event){return _vm.toggleTcAcceptence()}}},[_c('i',{staticClass:"clickable tcs-checkbox mr-1",class:[
                !_vm.tcsAccepted
                  ? 'fa-regular fa-square grey'
                  : 'fa fa-check-square filled'
              ]})]),_vm._v(" By booking an appointment, you agree to the following "),_c('RouterLink',{staticClass:"tcs-link pl-1",attrs:{"to":"/terms-and-conditions","target":"_blank"}},[_vm._v(" terms & conditions ")]),_vm._v(". ")],1)])]):_vm._e(),_c('div',{staticClass:"d-flex justify-content-center align-self-end pt-5 dmh-btn-container"},[_c('b-button',{staticClass:"btn-light mr-4",attrs:{"disabled":_vm.appointmentLoading},on:{"click":_vm.handleBackButtonClick}},[_vm._v("Back")]),_c('button',{staticClass:"btn-dark m-0",attrs:{"disabled":!_vm.selectedClinicianId || !_vm.tcsAccepted || _vm.appointmentLoading},on:{"click":_vm.handleNextButtonClick}},[_vm._v("Next")])],1)]):_vm._e()])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pb-3"},[_c('h2',{staticClass:"booking-title"},[_vm._v("Thank you! Let's book you in with your coach")])])
}]

export { render, staticRenderFns }