<template>
    <div class="row row-eq-height" id="TMHMeetingRoom">
<!--	    <div class="col-12">-->
<!--		    {{general_status}}-->
<!--	    </div>-->
      <div class="col-md-9" id="remote_media_box">
        <transition name="fade">
	        <div v-if="general_status === 'connected'" id="remote_media" class="animate__animated animate__fadeIn"></div>
	        <div v-else id="remote_media_placeholder" class="animate__animated animate__fadeIn">
		        <br/>
		        <br/>
		        <br/>
		        <br/>
		        <p>
			        {{status_message}}
		        </p>
	        </div>
        </transition>
	      
	      
        <transition name="fade">
	        <div v-if="general_status === 'connected'" id="remote_media_status_container" class="animate__animated animate__fadeIn">
		        <div key="a1" v-if="remote_audio_enabled" class="remote_vid_button mic  animate__animated animate__fadeIn">
			        <div  v-b-tooltip.hover title="Remote Audio is ON" class="bt_inner">
				        <i class="fa fa-microphone" aria-hidden="true"></i>
			        </div>
		        </div>
		        <div key="a2" v-else class="remote_vid_button mic off  animate__animated animate__fadeIn">
			        <div v-b-tooltip.hover title="Remote Audio is OFF" class="bt_inner off">
				        <i class="fa fa-microphone-slash" aria-hidden="true"></i>
			        </div>
		        </div>
		        <div key="v1" v-if="remote_video_enabled" class="remote_vid_button camera  animate__animated animate__fadeIn">
			        <div v-b-tooltip.hover title="Remote Video is ON" class="bt_inner">
				        <i class="fa fa-video" aria-hidden="true"></i>
			        </div>
		        </div>
		        <div key="v2" v-else class="remote_vid_button camera off animate__animated animate__fadeIn">
			        <div  v-b-tooltip.hover title="Remote Video is OFF" class="bt_inner off">
				        <i class="fa fa-video-slash" aria-hidden="true"></i>
			        </div>
		        </div>
	        </div>
	        <div v-else></div>
        </transition>
      </div>
	    
	    
        <div class="col-md-3 utilities_container">
	        <div id="remote_audio"></div>
            <div id="video_buttons">
                <div v-if="!video_processors_supported">
                    <div key="a1" v-if="audio_enabled" class="vid_button mic animate__animated animate__fadeIn">
                        <div class="bt_inner" @click="toggleAudio">
                            <i class="fas fa-microphone"></i>
                        </div>
                        <span>Your microphone is <b>ON</b></span>
                    </div>
                    <div key="a2" v-else class="vid_button mic animate__animated animate__fadeIn">
                        <div class="bt_inner off" @click="toggleAudio">
                            <i class="fas fa-microphone-slash"></i>
                        </div>
                        <span>Your microphone is <b>OFF</b></span>
                    </div>
                    <div key="v1" v-if="video_enabled" class="vid_button camera animate__animated animate__fadeIn">
                        <div class="bt_inner" @click="toggleVideo">
                            <i class="fas fa-video"></i>
                        </div>
                        <span>Your video is <b>ON</b></span>
                    </div>
                    <div key="v2" v-else class="vid_button camera animate__animated animate__fadeIn">
                        <div class="bt_inner off" @click="toggleVideo">
                            <i class="fas fa-video-slash"></i>
                        </div>
                        <span>Your video is <b>OFF</b></span>
                    </div>
                </div>
                <div v-else>
                    <div class="row text-center">
                        <div class="col-6">
                            <div key="a1" v-if="audio_enabled" class="vid_button mic animate__animated animate__fadeIn">
                                <div class="bt_inner" style="margin: auto" @click="toggleAudio">
                                    <i class="fas fa-microphone"></i>
                                </div>
                            </div>
                            <div key="a2" v-else class="vid_button mic animate__animated animate__fadeIn">
                                <div class="bt_inner off" style="margin: auto" @click="toggleAudio">
                                    <i class="fas fa-microphone-slash"></i>
                                </div>
                            </div>
                        </div>
                        <div class="col-6">
                            <div key="v1" v-if="video_enabled" class="vid_button camera animate__animated animate__fadeIn">
                                <div class="bt_inner" style="margin: auto" @click="toggleVideo">
                                    <i class="fas fa-video"></i>
                                </div>
                            </div>
                            <div key="v2" v-else class="vid_button camera animate__animated animate__fadeIn">
                                <div class="bt_inner off" style="margin: auto" @click="toggleVideo">
                                    <i class="fas fa-video-slash"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row bg_images mb-4">
                        <div class="col-4">
                            <img @click="imgBg('forest')" src="/img/video_backgrounds/forest.jpg" class="img-fluid" />
                        </div>
                        <div class="col-4">
                            <img @click="imgBg('flowers')" src="/img/video_backgrounds/flowers.jpg" class="img-fluid" />
                        </div>
                        <div class="col-4">
                          <div class="bg-blurred img-fluid" @click="blurBg"></div>
                        </div>
                    </div>
                    <div class="row">
                      <b-button class="mx-3" squared size="lg" @click="endSession" block variant="cta">
		                    End Meeting
	                    </b-button>
                    </div>
                </div>
                <div @click="removeBg" id="local_video_wrapper" class="animate__animated animate__fadeIn mt-auto">
                    <div id="local_video"></div>
                </div>
            </div>
	        <br/>
	        
        </div>
    </div>
</template>

<script>
const { connect, LocalVideoTrack } = require("twilio-video");

import * as VideoProcessors from "@twilio/video-processors";
export default {
  name: "VideoRoomMeeting",
  data() {
    return {
      video_processors_supported: false,
      participants: [],
      general_status: "waiting",
      currentBg: null,
      room_data: {
        id: false,
        sid: false,
        unique_name: false,
        friendly_name: false,
        token: false
      },
      status_message: "Meeting will start soon ...",
      connected: false,
      room_object: false,
      loopIntervalId: null,

      audio_enabled: true,
      video_enabled: true,
      remote_audio_enabled: false,
      remote_video_enabled: false,
      is_request_available: true
    };
  },
  mounted() {
    let self = this;
      navigator.permissions.query({ name: 'camera' })
      .then(function (result) {
      if (result.state == 'granted') {
        self.status_message = 'Establishing video connection...';
        self.permissionsStatus = true;
      } else if (result.state == 'prompt') {
        self.status_message = 'Please allow camera and microphone access...';
      } else {
        self.status_message = 'Please allow camera and microphone permissions and refresh the page...'
      }
    }).catch((error) => {
      self.status_message = 'Please allow camera and microphone access...'
      self.permissionsStatus = false;
    });
    this.video_processors_supported = VideoProcessors.isSupported
	  console.log('supported video processors : ', this.video_processors_supported)
		
	  // below unfortunetely has to stay for scenario where local track is passed from parent as device tests
	  // we could remove that passing media tracks bullshit all together and just destroy and create again in this component
	  // we need to think about it, and see how is that in terms of performance
	  if(this.local_video_track instanceof LocalVideoTrack){
			this.attachLocalVideo()
	  }
		// both just in case, can't use await here probably we will need to refactor this
		this.createLocalVideo()
		this.createLocalAudio()
		//

    this.requestBookingRoom();
  },
  async beforeDestroy() {
    try {
      if (this.loopIntervalId) {
        clearInterval(this.loopIntervalId);
        this.loopIntervalId = null;
      }
      await this.removeBg();
      this.$emit("terminateVideoAndAudio");
      await this.leaveRoom();
    } catch (error) {
      console.error("Error during beforeDestroy cleanup:", error);
      this.$router.push("/");
    }
  },
  props: ["booking", "coach", "local_video_track", "local_audio_track"],
  inject: [
    "createLocalAudio",
    "destroyLocalAudio",
    "createLocalVideo",
    "destroyLocalVideo"
  ],
  watch: {
    local_video_track(new_var) {
      if (new_var instanceof LocalVideoTrack) {
        this.attachLocalVideo();
      } else {
        this.detachLocalVideo();
      }
    }
  },
  methods: {
    async resetVideo() {
      this.is_request_available = true;
      this.general_status = "waiting";
      this.connected = false;
      await this.resetRemoteMediaDiv();
      this.loopForRequestingBookingRoom();
    },
    async attachLocalVideo() {
      const divContainer = document.getElementById("local_video");
      const videoElement = this.local_video_track.attach();
      divContainer.appendChild(videoElement);
    },
    async detachLocalVideo() {
      document.getElementById("local_video").innerHTML = "";
    },
    async toggleVideo() {
      if (this.local_video_track.isEnabled) {
        this.local_video_track.disable();
      } else {
        this.local_video_track.enable();
      }

      this.video_enabled = this.local_video_track.isEnabled;
      console.log("video track enabled : ", this.local_video_track.isEnabled);
      console.log("video track : ", this.local_video_track);
    },
    async toggleAudio() {
      if (this.local_audio_track.isEnabled) {
        this.local_audio_track.disable();
      } else {
        this.local_audio_track.enable();
      }
      this.audio_enabled = this.local_audio_track.isEnabled;
      console.log("audio track enabled : ", this.local_audio_track.isEnabled);
      console.log("audio track : ", this.local_audio_track);
    },
    async leaveRoom(redirectHome = false) {
      try {
        this.general_status = "leaving the room in 2 secs";
        if (this.general_status === 'connected' && document.getElementById("remote_media")) {
          document.getElementById("remote_media").innerHTML = "";
        }
        if (this.room_object) {
          this.room_object.disconnect();
          this.room_object = null;
        }
        //this is kind of hacky, if the user hits the end meeting button it overwrites the status message which is written by the room being destroyed which overwrite the initial assignement
        if (redirectHome) {
          this.status_message = "Ending the meeting...";
        }
        if (this.local_audio_track) {
          await this.destroyLocalAudio();
        }
        if (this.local_video_track) {
          await this.destroyLocalVideo();
        }
        if (redirectHome) {
          this.$router.push("/")
        }
      } catch (error) {
        console.error("Error during leaveRoom:", error);
        this.$router.push("/");
      }
    },
    async endSession() {
      try {
        if (this.loopIntervalId) {
          clearInterval(this.loopIntervalId);
          this.loopIntervalId = null;
        }
        await this.removeBg();
        this.general_status = "ending session";
        this.status_message = "Ending the meeting ...";

        await this.leaveRoom(true);
      } catch (error) {
        console.error("Error during endSession:", error);
        this.$router.push("/");
      }
    },
    async blurBg() {
      if (!this.local_video_track.isEnabled || this.currentBg === 'blur') {
        return;
      }
      await this.removeBg();
      const bg = new VideoProcessors.GaussianBlurBackgroundProcessor({
        assetsPath: "/assets/",
        maskBlurRadius: 7,
        blurFilterRadius: 15
      });
      await bg.loadModel();
      this.local_video_track.addProcessor(bg);
      this.currentBg = 'blur'
      console.log('change bg to', this.currentBg)
    },
    async imgBg(img_name = "flowers") {
      if (!this.local_video_track.isEnabled || this.currentBg === img_name) {
        return;
      }
      await this.removeBg();
      let img = new Image();
      if (img_name === "flowers") {
        img.src = "/img/video_backgrounds/flowers.jpg";
      } else {
        img.src = "/img/video_backgrounds/forest.jpg";
      }
      img.onload = async () => {
        const bg = new VideoProcessors.VirtualBackgroundProcessor({
          assetsPath: "/assets/",
          maskBlurRadius: 7,
          backgroundImage: img
        });
        await bg.loadModel();
        this.local_video_track.addProcessor(bg);
        this.currentBg = img_name
        console.log('change bg to', this.currentBg)
      };
    },

    async removeBg() {
      if (!this.local_video_track.isEnabled || !this.currentBg) {
        return;
      } else {
        await this.local_video_track.removeProcessor(this.local_video_track.processor);
        this.currentBg = null;
        console.log('change bg to', this.currentBg)
      }
    },

    async requestBookingRoom() {
      if (this.is_request_available) {
        this.is_request_available = false;
        let params = {
          path: "api/u/video/booking-video",
          booking_id: this.$route.params.booking_id
        };
        try {
          let res = await this.api(params);
          this.is_request_available = true;
          if (res.success === true) {
            clearInterval(this.loopIntervalId);
            this.loopIntervalId = null;
            this.room_data = { ...res.room };
            this.joinRoom();
          } else {
            if (!this.loopIntervalId) {
              console.log("Starting loop");
              this.loopForRequestingBookingRoom();
            }
          }
        } catch (e) {
          console.warn(e.toString());
          this.logVideoEvent(e.toString());
        }
      }
    },

    async resetRemoteMediaDiv() {
      let media_div = document.getElementById("remote_media");
      if (media_div) {
        media_div.innerHTML = "";
      }
    },

    async joinRoom() {
      console.log("joining room ", this.room_data);
      this.status_message = "Joining the meeting ...";
      let t = this;
      await this.resetRemoteMediaDiv();
      let tracks = [];

      tracks.push(this.local_audio_track);
      tracks.push(this.local_video_track);

      // console.log('local tracks')
      // console.log(tracks)

      this.room_object = await connect(this.room_data.token, {
        name: this.room_data.unique_name,
        tracks
      });

      // t.logVideoEvent('Joining room for booking: ' + this.$route.params.booking_id)
      t.general_status = "connected";

      this.room_object.participants.forEach(coach => {
        coach.on("trackSubscribed", track => {
          // do not attach dataTrack
          if (track.kind === "video") {
            // https://sdk.twilio.com/js/video/releases/2.19.1/docs/RemoteVideoTrack.html
            if (track.isEnabled) {
              this.remote_video_enabled = true;
            }
            t.resetRemoteMediaDiv();
            document.getElementById("remote_media").appendChild(track.attach());
            track.on("started", () => {
              console.log("remote video started");
            });

            track.on("enabled", () => {
              console.log("remote video enabled");
              t.remote_video_enabled = true;
            });

            track.on("disabled", () => {
              console.log("remote video disabled");
              t.remote_video_enabled = false;
            });

            track.on("switchedOff", () => {
              console.log("remote video switchedOff");
            });

            track.on("switchedOn", () => {
              console.log("remote video switchedOn");
            });

            track.on("dimensionsChanged", () => {
              console.log("remote video dimensionsChanged");
            });
          }
          if (track.kind === "audio") {
            // https://sdk.twilio.com/js/video/releases/2.19.1/docs/RemoteAudioTrack.html
            if (track.isEnabled) {
              t.remote_audio_enabled = true;
            }

            document.getElementById("remote_audio").appendChild(track.attach());
            track.on("started", () => {
              console.log("remote audio started");
            });
            track.on("enabled", () => {
              console.log("remote audio enabled");
              t.remote_audio_enabled = true;
            });

            track.on("disabled", () => {
              console.log("remote audio disabled");
              t.remote_audio_enabled = false;
            });

            track.on("switchedOff", () => {
              console.log("remote audio switchedOff");
            });

            track.on("switchedOn", () => {
              console.log("remote audio switchedOn");
            });
          }
        });
      }); // foreach coach end of loop
      this.room_object.once("participantDisconnected", (coach, error) => {
        this.resetVideo();
        this.status_message = "Coach disconnected";
        console.log("coach disconnected", error);
        if (this.room_object) {
          this.room_object.disconnect();
          this.room_object = false;
        }
      });
      this.room_object.once("disconnected", () => {
        this.status_message = "Coach disconnected";
        this.resetVideo();
      });
    },
    loopForRequestingBookingRoom() {
      const booking_id = this.$route.params.booking_id || null;
      if (this.loopIntervalId === null && booking_id) {
        this.loopIntervalId = setInterval(() => {
          this.requestBookingRoom();
        }, 2000);
      }
    },
    logVideoEvent(message) {
      let params = {
        path: "api/u/video/log-info",
        message: (message = JSON.stringify(message))
      };
      this.api(params);
    }
  },
};
</script>

<style lang="less">
#TMHMeetingRoom{
  #remote_media_box {
  position: relative;
  #remote_media_status_container {
    opacity: 0;
    position: absolute;
    top: 0px;
    left: 30px;
    width: 50px;
    text-align: center;
    height: 200px;
    .remote_vid_button {
			//text-align: center;
			//width: 40px;
			//height: 40px;
			//font-size: 25px;
			//border-radius: 50%;
			//background-color: white;
			//opacity: 0.7;
			
			z-index: 1000;
			text-align: center;
			font-size: 20px;
			border-radius: 50%;
			display: inline-block;
			background-color: rgba(0, 230, 184, 0.25);
			width: 50px;
			height: 50px;
			color: white;
			padding-top: 11px;
			margin-right: 1em;
			margin-top: 15px;
			&.off {
				background-color: rgba(0, 230, 184, 0.08);
			}
    }
  }
  #remote_media {
    min-height: 550px;
    position: relative;
      video {
        position: relative;
        z-index: 11;
        min-height: 550px;
          width: 100%;
          max-width: 100%;
        border-radius: 5px;
        //box-shadow: 0px 5px 15px rgba(0,0,0,0.25);
        overflow: hidden;
      }
  }
  #remote_media_placeholder {
    //background-color: black;
    border-radius: 5px;
    //box-shadow: 0px 5px 15px rgba(0,0,0,0.25);
    min-height: 550px;
    text-align: center;
    margin-bottom: 5px;
    h2, h4 {
      font-weight: bold;
      color: black;
    }
    p {
      color: black;
      font-size: 17px;
    }
  }
}

.bg-blurred {
  cursor: pointer;
  height: 100%;
  width: 100%;
  background-color: rgba(30, 30, 34, 0.1);
  backdrop-filter: blur(20px);
  border-radius: 4px;
}

#video_buttons {
  .vid_button {
    margin-bottom: 20px !important;
    position: relative;
    .bt_inner {
      text-align: center;
      font-size: 25px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #00e6b8;
      width: 60px;
      height: 60px;
      color: white;
      margin-right: 1em;
      box-shadow: 0px 5px 5px rgba(0,0,0,0.1);
      i {
        display: block;
      }
      &.off {
        background-color: #4d4d4f;
      }
      &:hover {
        cursor:pointer;
      }
    }
    span {
      position: relative;
      bottom: 5px;
    }
  }
}
  .utilities_container {
    position: relative;
    .bg_images {
      img {
        border-radius: 5px;
        box-shadow: 0px 5px 5px rgba(0,0,0,0.1);
        &:hover {
          cursor: pointer;
        }
      }
    }
      #local_video_wrapper {
        padding: 0px;
        position: absolute;
        bottom: 0px;
        #local_video {
          padding-right: 15px;
          video {
            width: 100%;
            max-width: 100%;
            border-radius: 5px;
            //box-shadow: 0px 5px 15px rgba(0,0,0,0.25);
          }
        }
      }
  }
  #local_media, .remote_media {
    background-color: whitesmoke;
  }
}

</style>
