<template>
    <div id="ConfirmDischargedCoacheeModal">
        <b-card hide-header class="discharged-card text-center">
            <b-card-text class="mt-3">
                <h4>Great to know that you’d like to re-start your coaching journey. 😊 If you’d like to continue, please note that your tasks and data from your previous coaching journeys will now be shared with your new coach. Simply click ‘yes’ to confirm and start your new coaching journey or click on ‘cancel’ if you don’t wish to proceed further at this time.</h4>
            </b-card-text>
            <b-button size="lg" class="mt-3 mb-3 pt-4" variant="danger" @click="hideModal">Cancel</b-button>
            <b-button size="lg" class="mt-3 mb-3 pt-4 ml-3 bg-brand-primary" variant="primary" @click="confirmReStart">Yes</b-button>
        </b-card>
    </div>
</template>
<script>
import axios from "axios";
import config from "../../config/constants";

export default {
    name: "ConfirmDischargedCoacheeModal",
    methods: {
        async confirmReStart() {
            const payload = {
                user_id: this.$store.getters.user_id
            };
            let res = await axios.post(
                config.api_env + "/application/api/u/user/redischarge-coachee",
                payload,
                config.options
            );
            window.location.reload();
        },
        hideModal() {
            this.$bvModal.hide('confirm_discharged_coachee_modal');
            this.$bvModal.hide('discharged_coachee_modal');
        },
    }
};
</script>
<style lang="less">
#ConfirmDischargedCoacheeModal {
    .card.discharged-card {
        margin: 0em -1em 0em -1em !important;
    }
    max-height: 85vh;
    overflow-y: auto;
    overflow-x: hidden;
}
</style>