<template>
  <div id="YourImpact">
    <div class="your-impact-header-container">
      <div class="your-impact-header">
        <img class="img-fluid" src="/img/forestry.jpg" />
        <div class="triangle-div"></div>
      </div>
    </div>
    <div class="your-impact-totals-container content-container">
      <div class="row mx-sm-5">
        <div class="your-impact-totals col-lg-7 col-md-9 col-sm-12">
          <div
            class="
              d-flex
              flex-wrap flex-wrap
              justify-content-between
              align-items-center
              pb-3
            "
          >
            <div class="header-container mb-4">
              <h2 class="your-impact-totals-header mb-0">Your Impact</h2>
              <div class="header-underline"></div>
            </div>
            <h4 class="your-impact-totals-since mb-0">
              SINCE <span v-html="getSinceDate(userImpact.since)"></span>
            </h4>
          </div>
          <div class="d-flex flex-row">
            <p class="your-impact-totals-intro col-8 px-0 pb-5 mx-sm-0">
              This is your very own dashboard to track the positive impact of
              your Wellbeing Journey. Follow along with updates to your impact
              as they happen on your timeline below.
            </p>
          </div>
          <div
            class="
              your-impact-totals-cards
              d-flex
              flex-row
              justify-content-between
              align-items-stretch
              mb-5
            "
          >
            <div class="your-impact-totals-card text-center">
              <div
                class="
                  your-impact-totals-card-icon
                  d-flex
                  justify-content-center
                  align-items-center
                "
              >
                <img
                  class="z-index-100"
                  src="/img/totalMentalHealth/water-bottle.png"
                />
                <div class="bg-brand-primary icon-backing"></div>
              </div>
              <div class="your-impact-totals-card-title">
                <h3>
                  {{ userImpact.items.bottles }} Bottle<span
                    v-if="userImpact.items.bottles !== 1"
                    >s</span
                  >
                </h3>
              </div>
              <div class="your-impact-totals-card-description">
                <p class="mb-3 mx-3">
                  Estimate 80 plastic bottles equals 1KG of plastic recovered
                  from the ocean
                </p>
              </div>
            </div>
            <div class="your-impact-totals-card text-center">
              <div
                class="
                  your-impact-totals-card-icon
                  d-flex
                  justify-content-center
                  align-items-center
                "
              >
                <i class="fa fa-tree text-white z-index-100"></i>
                <div class="bg-brand-primary icon-backing"></div>
              </div>
              <div class="your-impact-totals-card-title">
                <h3>
                  {{ userImpact.items.trees }} Tree<span
                    v-if="userImpact.items.trees !== 1"
                    >s</span
                  >
                </h3>
              </div>
              <div class="your-impact-totals-card-description">
                <p class="mb-3 mx-3">Planted because of your activities!</p>
              </div>
            </div>
          </div>
          <div>
            <p class="text-grey">
              *Your impact totals will udpate with future purchases.
            </p>
          </div>
          <div class="your-impact-button-container">
            <b-button class="your-impact-button cta-button shadow"
              ><span class="mr-5">Share Your Impact</span
              ><i class="fas fa-external-link"></i
            ></b-button>
          </div>
        </div>
      </div>
    </div>
    <div class="content-container">
      <div class="d-flex flex-row row justify-content-between mx-sm-5">
        <div class="col-md-7 col-sm-12">
          <div class="d-flex flex-row mb-4">
            <div class="header-container">
              <h2 class="your-impact-company-totals-header mb-0 mx-0">
                Total Impact across Organisations
              </h2>
              <div class="header-underline"></div>
            </div>
          </div>

          <div class="your-impact-company-container bg-brand-primary row">
            <div class="my-3">
              <p class="your-impact-company-description">
                Across all contributing organisations we’re making a big impact
                for sustainability projects across Africa and Asia. Via the Eden
                Reforestation Projects we’re helping to plant mangrove forests
                in Madagascar. The Ocean-Bound plastic cleanup projects via
                Empower AS operate in both Africa and Asia to recover
                ocean-bound plastic from our oceans.
              </p>
            </div>
            <div
              class="
                your-impact-company-stats-container
                d-flex
                flex-row
                justify-content-between
                flex-wrap
                text-white
                col-12
              "
            >
              <div
                class="
                  d-flex
                  flex-column
                  align-items-start
                  your-impact-company-stats
                "
              >
                <span class="your-impact-company-stats-figure">
                  2
                </span>
                <span class="your-impact-company-stats-value">Continents</span>
              </div>

              <div
                class="
                  d-flex
                  flex-column
                  align-items-start
                  your-impact-company-stats
                "
              >
                <span class="your-impact-company-stats-figure">{{
                  totalTrees
                }}</span>
                <span class="your-impact-company-stats-value"
                  >Mangrove Trees<br />planted in 2022</span
                >
              </div>
              <div
                class="
                  d-flex
                  flex-column
                  align-items-start
                  your-impact-company-stats
                "
              >
                <span class="your-impact-company-stats-figure"
                  >{{Math.round(totalBottles/80 * 10) / 10}}<span style="font-size: 16px">KGs</span></span
                >
                <span class="your-impact-company-stats-value"
                  >Plastic removed<br />from the ocean</span
                >
              </div>
            </div>
            <div class="your-impact-button-container">
              <b-button
                class="your-impact-button cta-button white-button shadow"
                ><span class="mr-5 brand-primary">Share Your Impact</span
                ><i class="brand-primary fas fa-external-link"></i
              ></b-button>
            </div>
          </div>
        </div>
        <div class="your-impact-items-container col-md-4 col-sm-12">
          <div class="d-flex flex-row mb-4">
            <div class="header-container">
              <h2 class="your-impact-items-header mb-0 mx-0">
                Your Contribution Activities
              </h2>
            </div>
          </div>
          <div class="your-impact-items">
            <div
              class="
                your-impact-item
                d-flex
                flex-row flex-nowrap
                align-items-center
                mb-3
              "
              v-for="(item, i) in userItems"
              :key="i"
            >
              <div class="your-impact-item-icon">
                <img
                  :src="
                    '/img/totalMentalHealth/achievements/Badges/' +
                    item.icon +
                    '.png'
                  "
                  class="img-fluid"
                />
              </div>
              <div
                class="your-impact-item-details d-flex flex-column flex-grow-1"
              >
                <div class="your-impact-item-title">{{ item.title }}</div>
                <div class="your-impact-item-reward">
                  <div class="your-impact-item-reward-icon bg-brand-primary">
                    <span v-if="item.type === 'trees'">
                      <i class="fa fa-tree"></i>
                    </span>
                    <span v-else>
                      <img
                        src="/img/totalMentalHealth/water-bottle.png"
                        class="img-fluid"
                        style="height: 12px"
                      />
                    </span>
                  </div>
                  <b class="brand-primary"
                    >{{ item.amount }}
                    <span v-if="item.type === 'trees'"
                      >tree<span v-if="item.type.bottles !== 1">s</span> seeded
                    </span>
                    <span v-if="item.type === 'bottles'"
                      >bottle<span v-if="item.type.bottles !== 1">s</span>
                      collected</span
                    >
                  </b>
                </div>
              </div>
              <div
                class="
                  your-impact-item-link
                  d-flex
                  flex-row
                  justify-content-center
                  align-content-center
                "
                v-if="false"
              >
                <i class="fas fa-external-link brand-primary"></i>
                <div class="bg-brand-primary icon-backing"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loader from "../components/Loader";
import { mapGetters } from "vuex";
import moment from "moment";

export default {
  name: "TotalMentalHealthYourImpact",
  components: { Loader },
  data() {
    return {
      loading: false,
      userImpact: {},
      userItems: {},
      companyImpact: {},
    };
  },
  mounted() {
    this.getImpact();
  },
  computed: {
    ...mapGetters(["hardcoded", "skin", "client"]),
    getLogo() {
      if (this.hardcoded && this.hardcoded.client_logo) {
        return this.hardcoded.client_logo;
      } else if (this.skin === 8) {
        return "/img/boi/boi-logo-alt.png";
      } else return this.client.client_logo;
    },
  },
  methods: {
    async getImpact() {
      let params = {
        path: "api/u/impact/get-impact-data",
      };
      let res = await this.api(params);
      if (res.success) {
        this.userImpact = res.top_box;
        this.userItems = res.your_items;
        this.totalTrees = res.total_trees;
        this.totalBottles = res.total_bottles;
        this.loading = false;
      }
    },
    getSinceDate(date) {
      let formatDate = date.split(" ")[0];
      formatDate = formatDate.split("-");
      console.log(formatDate);
      let day = new Date(formatDate[0], formatDate[1] - 1, formatDate[2]);
      console.log(day);
      return moment(day).locale(this.$store.state.lang).format("Do MMMM YYYY");
    },
  },
};
</script>
<style lang="less">
#YourImpact {
  background-color: #fdf5f5;
  position: relative;
  .your-impact-header-container {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    .your-impact-header {
      position: relative;
      height: 700px;
      overflow: hidden;
      z-index: 1;
      .triangle-div {
        z-index: 2;
        width: 0px;
        height: 0px;
        border-bottom: 450px solid #fdf5f5;
        border-right: 100vw solid transparent;
        bottom: 0;
        left: 0;
        position: absolute;
        color: #fdf5f5;
      }
      img {
        z-index: 1;
      }
    }
  }
  .your-impact-totals-container {
    position: relative;
    margin-top: 100px;
    margin-bottom: 100px;
    z-index: 2;
    .your-impact-totals {
      position: relative;
      padding: 25px;
      background-color: white;
      border-radius: 16px;
      .your-impact-totals-header {
        font-family: "PublicoHeadlineWebBold", sans-serif;
        position: relative;
        z-index: 1000;
      }
      .your-impact-totals-since {
        text-transform: uppercase;
      }
      .your-impact-totals-intro {
        font-size: 1.6rem;
      }
      .your-impact-totals-cards {
        margin: 0 -10px;
        .your-impact-totals-card {
          flex-basis: 50%;
          padding: 10px;
          margin: 0 10px;
          display: flex;
          flex-direction: column;
          align-items: center;
          flex-grow: 1;
          background-color: #fdf5f5;
          border-radius: 1.6rem;
          .your-impact-totals-card-icon {
            margin-top: 40px;
            margin-bottom: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            .icon-backing {
              position: absolute;
              width: 75px;
              height: 75px;
              border-radius: 1000px;
              z-index: 2;
            }
            img {
              height: 24px;
              position: relative;
              z-index: 3;
            }
            i {
              font-size: 24px;
              position: relative;
              z-index: 3;
            }
          }
          .your-impact-totals-card-title {
            h3 {
              margin: 0;
              padding: 15px 0;
            }
          }
          .your-impact-totals-card-description {
            font-size: 1.2rem;
          }
        }
      }
    }
  }
  .your-impact-company-totals-header {
    margin-left: 25px;
    margin-bottom: 25px;
    font-size: 2.8rem;
    font-family: "PublicoHeadlineWebBold", sans-serif;
    text-align: left;
    position: relative;
    z-index: 1000;
    text-transform: uppercase;
  }
  .your-impact-company-container {
    position: relative;
    border-radius: 1.6rem;
    margin-bottom: 100px;
    padding: 30px;
    .your-impact-company-logo-container {
      height: 40px;
      display: flex;
      flex-grow: 1;
    }
    .your-impact-company-description {
      color: white;
      font-size: 1.6rem;
      margin-right: 20%;
    }
  }
  .your-impact-company-socials-container {
    a {
      color: white;
      &:hover {
        color: #efefef;
      }
    }
  }
  .your-impact-company-stats-container {
    margin-bottom: 30px;
    .your-impact-company-stats {
      .your-impact-company-stats-figure {
        font-family: "PublicoHeadlineWebBold", sans-serif;
        font-size: 3.5rem;
      }
      .your-impact-company-stats-value {
        font-size: 1.4rem;
      }
    }
  }
  .your-impact-items-header {
    margin-bottom: 25px;
    font-size: 2.8rem;
    font-family: "PublicoHeadlineWebBold", sans-serif;
    text-align: left;
    position: relative;
    z-index: 1000;
    text-transform: uppercase;
  }
  .your-impact-items {
    .your-impact-item {
      background-color: white;
      border-radius: 1.6rem;
      padding: 10px;
      .your-impact-item-icon {
        width: 50px;
      }
      .your-impact-item-details {
        margin: 8px;
        .your-impact-item-title {
          font-family: "DMSans", sans-serif;
          font-weight: bold;
          font-size: 1.4rem;
          margin-bottom: 5px;
        }
        .your-impact-item-reward {
          height: 24px;
          border-radius: 1000px;
          color: white;
          background-color: #fdf5f5;
          display: flex;
          flex-direction: row;
          align-items: center;
          .your-impact-item-reward-icon {
            height: 24px;
            width: 24px;
            border-radius: 1000px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: white;
            margin-right: 5px;
          }
        }
      }
      .your-impact-item-link {
        position: relative;
        margin-left: 5px;
        .icon-backing {
          position: absolute;
          top: -6px;
          width: 30px;
          height: 30px;
          border-radius: 1000px;
          opacity: 0.5;
          z-index: 2;
        }
        i {
          font-size: 1.6em;
        }
      }
    }
  }
  .your-impact-button-container {
    display: none;
    position: absolute;
    bottom: -20px;
    right: -50px;
    .your-impact-button {
      padding: 20px 30px;
      display: flex;
      align-items: center;
      .fas {
        font-size: 1.8rem;
      }
    }
  }

  .header-container {
    position: relative;
    display: flex;
    flex-shrink: 1;
    .header-underline {
      background-color: #dcdcdc;
      height: 8px;
      width: 90%;
      position: absolute;
      bottom: -1px;
      left: -5px;
      border-radius: 10px;
      z-index: 2;
    }
  }
  .text-grey {
    color: #ccc;
  }
}
</style>
