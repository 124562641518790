<template>
  <div id="Home">
    <section id="HomeBanner" class="home-banner-container pb-5 brand-text">
      <div class="content-container">
        <div class="row home-banner align-items-center">
          <div class="home-banner-text col-md-7 col-sm-12">
            <h3 class="welcome brand-text">WELCOME</h3>
            <h1 class="home-banner-title brand-highlight-1 mb-3">Support for your wellbeing every day </h1>
            <p class="white-text mb-4 semibold">
              Discover the support available to you.  
            </p>
            <p class="white-text mb-4 semi-bold">
              Get help forming healthy habits for a healthier mind and body, and to manage life’s challenges. </p>
          </div>
          <div class="col-md-5 d-flex flex-row justify-content-center">
            <img src="/img/home-banner.svg" class="img-fluid" />
          </div>
        </div>
      </div>
    </section>
    <section class="content-container my-5 pb-5" v-if="content?.show_check_healthscore">
      <div class="row mx-0">
        <div class="healthscore-banner bg-white col-md-8 col-12 shadow d-flex flex-row align-items-center px-5 py-4">
          <div> <img src="/img/chart.svg" class="img-fluid" />
          </div>
          <div class="d-flex flex-column align-items-start px-5">
            <h3 class="pb-0">Check your Health Score</h3>
            <p class="p-text-sm mb-0 pb-0">Check your Health Score results, and take your next steps towards a healthier
              body and
              mind.</p>
          </div>
          <b-button @click="navigate('/healthscore')" variant="cta" squared
            class="d-flex flex-row align-items-center py-4">View&nbsp;Results<i
              class="ml-3 fas fa-chevron-right"></i></b-button>
        </div>
      </div>
    </section>
    <section class="content-container pt-5 my-5" v-if="content?.service_tiles?.length > 0">
      <h2 class="section-title">How can we help you today?</h2>
      <div class="home-card-container" :class="{ 'half': content.service_tiles.length === 2 }">
        <div class="home-card shadow" v-for="(tile, i) in content.service_tiles">
          <div class="home-card-image ">
            <img class="img-fluid" :src="tile.image_url" />
          </div>
          <div class="home-card-container">
            <h4 class="home-card-title">{{ tile.name }}</h4>
            <p class="home-card-text">
              {{ tile.description }}
            </p>
            <div class="mt-auto">
              <b-button variant="cta" @click="navigate(tile.link)" squared class="w-100">{{ tile.button_text }} <i
                  class="fas fa-chevron-right ml-3"></i></b-button>
            </div>
          </div>
        </div>
      </div>
    </section>
    <hr class="horizontal-divider content-container pb-5" v-if="content?.service_tiles?.length > 0" />
    <section class="home-section-container pb-5" v-for="(service, i) in content.coaching_services">
      <div class="content-container">
        <div class="row home-section mx-0 align-items-center">
          <div class="col-md-5 d-flex flex-row justify-content-center mb-5" :class="{ 'd-md-none': i % 2 !== 0 }">
            <img :src="service.image_url" class="img-fluid" />
          </div>
          <div class="home-section-text col-md-7 col-sm-12">
            <h3 class="p-text-lg mb-5">{{ service.name }}</h3>
            <p class="white-text mb-5 semi-bold">
              {{ service.description }}
            </p>
            <b-button variant="cta-outline" @click="navigate(service.link)" squared>{{ service.button_text }} <i
                class="fas fa-chevron-right ml-3"></i></b-button>
          </div>
          <div class="col-md-5 d-none  d-md-flex flex-row justify-content-center" v-if="i % 2 !== 0">
            <img :src="service.image_url" class="img-fluid" />
          </div>
        </div>
      </div>
    </section>
    <section class="content-container pt-5 my-5" v-if="content?.event_services?.length > 0">
      <h2 class="section-title">Health assessments</h2>
      <div class="home-card-container" :class="{ 'half': content.event_services.length === 2 }">
        <div class="home-card shadow" v-for="(tile, i) in content.event_services">
          <div class="home-card-image ">
            <img class="img-fluid" :src="tile.image_url" />
          </div>
          <div class="home-card-container">
            <h4 class="home-card-title">{{ tile.name }}</h4>
            <p class="home-card-text">
              {{ tile.description }}
            </p>
            <div class="mt-auto">
              <b-button variant="cta" @click="navigate(tile.link)" squared class="w-100">{{ tile.button_text }} <i
                  class="fas fa-chevron-right ml-3"></i></b-button>
            </div>
          </div>
        </div>
      </div>
    </section>

    <hr class="horizontal-divider content-container pb-5 mt-5 mb-5" v-if="content?.event_services?.length > 0" />
    <div class="pt-5 carousel-container">
      <HomeFilters />
      <HomeCardCarousel :title="'Events'" type="corporateEvent" cardType="corporateEvent" class="car" :mute="true">
      </HomeCardCarousel>
      <HomeCardCarousel title="Videos" class="car" type="standaloneArticles" additionalTag="Videos" :mute="true">
      </HomeCardCarousel>
      <HomeCardCarousel title="Articles" class="car" cardType="article" type="standaloneArticles" additionalTag="Articles" :mute="true">
      </HomeCardCarousel>

    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import axios from "axios";
import config from "../config/constants";
import Loader from "../components/Loader.vue";
import HomeCardCarousel from "@/components/Home/New/HomeCardCarousel.vue"
import HomeFilters from "@/components/Home/New/HomeFilters.vue"
import EventBus from "@/helpers/eventbus";


export default {
  name: "home",
  components: {
    Loader,
    HomeCardCarousel,
    HomeFilters,
  },
  data() {
    return {
      courses: {},
      tag: 'Show all',
      content: false,
    };
  },
  mounted() {
    let self = this;
    this.getHomeContent();
    EventBus.$on("setTag", function (tag) {
      self.tag = tag
    });
  },
  methods: {
    async getHomeContent() {
      const url = config.api_env + "/application/api/u/sapphire/home/web-landing";
      const payload = {
        user_id: this.$store.getters.user_id,
      };
      let res = await this.axios.post(
        url,
        payload,
        config.options
      );
      if (res.data.success) {
        this.content = res.data;
        this.loading = false;
      } else if (res.data.err && res.data.err.includes("3994")) {
        this.$store.dispatch("logout");
        this.$router.push("/login");
      }
    },
    navigate(route) {
      this.$router.push(route);
      this.scrollToRef(null, true);

    }
  },
  computed: {

  }
};
</script>
<style lang="less">
#Home {
  overflow-x: hidden;
  background-color: #FAFAFA !important;

  .welcome {
    font-size: 1.8rem;
    font-weight: 700;
    letter-spacing: 2.2px;
  }

  .content-container {
    padding-right: 16px;
    padding-left: 16px;
  }

  .home-banner-container {
    .home-banner {
      padding: 60px 16px 30px;
    }

    .home-banner-text {
      font-size: 1.6rem;
      z-index: 2;

      .home-banner-title {
        font-size: 6.4rem;
        font-weight: bold;
        padding-bottom: 25px;
      }

      p {
        font-size: 1.8rem;
      }
    }

    div {
      z-index: 2;
    }
  }

  .home-section-container {
    .home-section {
      padding-top: 30px;
    }

    .img-fluid {
      width: 100%;
      height: 246px;
      object-fit: cover;
      object-position: center;
    }

    .home-section-text {
      text-wrap-style: balance;
      font-size: 1.6rem;
      z-index: 2;

      .home-section-title {
        font-size: 6.4rem;
        font-weight: bold;
        padding-bottom: 25px;
      }

      p {
        font-size: 1.8rem;
      }
    }

    div {
      z-index: 2;
    }
  }

  .section-title {
    font-size: 2.8rem;
  }

  .healthscore-banner {
    border-radius: 6px;

    h3 {
      font-size: 2.4rem;
    }
  }

  @media only screen and (max-width: 768px) {
    .home-card-container {
      grid-template-columns: 1fr !important;
    }
  }

  @media only screen and (max-width: 768px) {
    .home-card-container {
      grid-template-columns: 1fr !important;
    }
  }

  .home-card-container.half {
    grid-template-columns: 1fr 1fr;
  }

  .home-card-container {
    padding-bottom: 30px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 30px;

    .home-card {
      background-color: white;
      border-radius: 8px;
      margin-top: 20px;
      margin-bottom: 20px;
      display: flex;
      flex-direction: column;
      position: relative;
      overflow: hidden;
      z-index: 2;
      flex-grow: 1;

      .home-card-container {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        padding: 15px;
      }

      h4 {
        font-size: 1.8rem;
        font-weight: bold;
        margin-bottom: 15px;
        z-index: 10;
      }

      p {
        font-size: 1.4rem;
        z-index: 10;
        margin-bottom: 20px;
      }

      .home-card-image {

        .img-fluid {
          width: 100%;
          height: 246px;
          object-fit: cover;
          object-position: center;
        }
      }

      .home-card-button {
        margin-left: -10px;
        padding: 10px;
        font-size: 1.4rem;
      }

    }
  }

  .home-section-container {
    .home-section {
      padding-top: 30px;
    }

    .img-fluid {
      width: 100%;
      height: 246px;
      object-fit: cover;
      object-position: center;
    }

    .home-section-text {
      font-size: 1.6rem;
      z-index: 2;

      .home-section-title {
        font-size: 6.4rem;
        font-weight: bold;
        padding-bottom: 25px;
      }

      p {
        font-size: 1.8rem;
      }
    }

    div {
      z-index: 2;
    }
  }

  .section-title {
    font-size: 2.8rem;
  }

  .healthscore-banner {
    border-radius: 6px;

    h3 {
      font-size: 2.4rem;
    }
  }

  @media only screen and (max-width: 768px) {
    .home-card-container {
      grid-template-columns: 1fr !important;
    }
  }

  @media only screen and (max-width: 768px) {
    .home-card-container {
      grid-template-columns: 1fr !important;
    }
  }

  .home-card-container.half {
    grid-template-columns: 1fr 1fr;
  }

  .home-card-container {
    padding-bottom: 30px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 30px;

    .home-card {
      background-color: white;
      border-radius: 8px;
      margin-top: 20px;
      margin-bottom: 20px;
      display: flex;
      flex-direction: column;
      position: relative;
      overflow: hidden;
      z-index: 2;
      flex-grow: 1;

      .home-card-container {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        padding: 15px;
      }

      h4 {
        font-size: 1.8rem;
        font-weight: bold;
        margin-bottom: 15px;
        z-index: 10;
      }

      p {
        font-size: 1.4rem;
        z-index: 10;
        margin-bottom: 20px;
      }

      .home-card-image {

        .img-fluid {
          width: 100%;
          height: 246px;
          object-fit: cover;
          object-position: center;
        }
      }

      .home-card-button {
        margin-left: -10px;
        padding: 10px;
        font-size: 1.4rem;
      }

    }
  }
}
</style>
