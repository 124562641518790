<template>
  <div id="BookingList" class="position-absolute">
    <div class="booking-container" v-click-outside="closeBookings">
      <div
        class="booking-top d-flex align-items-center justify-content-between"
      >
        <span class="booking-container-title">Bookings</span>
        <button @click="closeBookings" class="close-button">
          <i class="fa-regular fa-times fa-2x clickable"></i>
        </button>
      </div>
      <template v-if="isLoading">
        <Loader />
      </template>
      <template v-else>
        <div class="d-flex flex-nowrap booking-container-filter-row">
          <button
            class="booking-container-filter"
            :class="{ 'booking-selected': showUpcomingBookings }"
            @click="setShowUpcomingBookings(true)"
          >
            Upcoming
          </button>
          <button
            class="booking-container-filter"
            :class="{ 'booking-selected': !showUpcomingBookings }"
            @click="setShowUpcomingBookings(false)"
          >
            Past
          </button>
        </div>
        <div class="booking-container-bookings">
          <div
            v-if="
              showUpcomingBookings
                ? upcomingBookings.length > 0
                : pastBookings.length > 0
            "
          >
            <template v-if="showUpcomingBookings">
              <BookingCard
                v-for="(booking, i) in upcomingBookings"
                :key="i"
                :booking="booking"
                :isUpcomingBooking="showUpcomingBookings"
                @close-bookings="closeBookings"
              />
            </template>
            <template v-else>
              <BookingCard
                v-for="(booking, i) in pastBookings"
                :key="i"
                :booking="booking"
              />
            </template>
          </div>
          <div class="no-bookings-container" v-else>
            <p class="no-bookings-title brand-text">
              You don't have any upcoming sessions booked.
            </p>
            <p class="no-bookings-subtitle brand-text">
              Please note that only services booked through Spectrum.Life will
              be shown here
            </p>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import BookingCard from "./BookingCard.vue";
import axios from "axios";
import config from "../config/constants";
import moment from "moment";
import Loader from "@/components/Loader.vue";
import EventBus from "@/helpers/eventbus";

export default {
  name: "BookingList",
  components: {
    BookingCard,
    Loader
  },
  data() {
    return {
      showUpcomingBookings: true,
      bookings: [],
      upcomingBookings: [],
      pastBookings: [],
      isLoading: false
    };
  },
  mounted() {
    this.getBookings();
    EventBus.$on("getBookings", this.getBookings);
  },
  methods: {
    closeBookings() {
      this.$emit("close-bookings");
    },
    setShowUpcomingBookings(value) {
      this.showUpcomingBookings = value;
    },
    async getBookings() {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;
      const payload = {
        user_id: this.$store.getters.user_id
      };
      const res = await axios.post(
        config.api_env + "/application/api/u/sapphire/appointments",
        payload,
        config.options
      );
      this.isLoading = false;
      if (res.data.success) {
        this.bookings = res.data.appointments;
        let upcomingBookings = [];
        let pastBookings = [];
        res.data.appointments.forEach(app => {
          if (
            app.appointmentStatus !== "Cancelled" &&
            app.appointmentStatus !== "Cancellation Request"
          ) {
            if (!moment().isAfter(moment(app.endTime))) {
              upcomingBookings.push(app);
            } else {
              pastBookings.push(app);
            }
          }
        });
        this.pastBookings = pastBookings.sort((a, b) => {
          const dateA = new Date(a.startTime);
          const dateB = new Date(b.startTime);
          return dateB - dateA;
        });
        this.upcomingBookings = upcomingBookings.sort((a, b) => {
          const dateA = new Date(a.startTime);
          const dateB = new Date(b.startTime);
          return dateA - dateB;
        });
      } else if (res.data.err && res.data.err.includes("3994")) {
        this.$store.dispatch("logout");
        this.$router.push("/login");
      }
    }
  }
};
</script>

<style lang="less">
#BookingList {
  display: flex;
  justify-content: flex-end;
  z-index: 1000;
  height: 100vh;
  min-height: 100vh;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);

  .booking-container {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    max-width: 460px;
    background-color: white;
    border: 1px solid lightgrey;
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
    overflow: scroll;

    .booking-top {
      padding: 16px 20px;
    }

    .booking-container-title {
      width: 90%;
      text-align: center;
      color: #00008f;
      font-size: 1.8rem;
      font-family: "FSElliotBold", sans-serif;
      font-weight: bold;
    }

    .booking-container-filter-row {
      border-top: 1px solid #f0f0f0;
      font-size: 1.4rem;
      font-family: "FSElliotBook", sans-serif;
      font-weight: bold;
      cursor: pointer;

      .booking-container-filter {
        display: flex;
        border-width: 0;
        background-color: unset;
        align-items: center;
        justify-content: center;
        font-size: 1.6rem;
        width: 50%;
        height: 100%;
        padding: 18px 12px;
        font-weight: 600;
        color: #343c3d;
      }

      .booking-selected {
        color: #00008f;
        border-bottom: 2px solid #d24723;
        font-family: "FSElliotBlack", sans-serif;
      }
    }

    .booking-container-bookings {
      flex: 1 1 100%;
      border-top: 1px solid #cccccc;
      overflow: auto;
      padding: 24px;
      background-color: #f0f0f0;
    }

    .no-bookings-container {
      display: flex;
      height: 100%;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      font-size: 1.6rem;

      b {
        font-family: "FSElliotBold", sans-serif;
      }

      a {
        text-decoration: underline;
      }

      .no-bookings-title {
        font-size: 1.8rem;
        line-height: 2.2rem;
        margin-bottom: 20px;
      }

      .no-bookings-subtitle {
        font-family: "FSElliotBook", sans-serif;
        font-size: 1.6rem;
        line-height: 2.2rem;
      }
    }
  }

  .close-button {
    background-color: #00008f;
    border: 1.5px solid transparent;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    padding: 5px;
    font-size: 1.4rem;
    color: #fff;

    &:hover,
    &:focus {
      border: 1.5px solid #192550;
    }
  }
}
</style>
