<template>
  <div class="pulse-container white-text" id="coaching-profile-details">
    <div >
      <h2 class="question-text brand-highlight-1 pulse-form-header profileHeader white-text">Complete Your Profile</h2>
    </div>
      <b-row class="profile-row">
        <div class="col-12 ">
          <b-form-group
            id="fieldset-6"
            label="Address"
            label-for="input-10"
        >
          <b-form-input id="input-10" v-model="user_profile.address" trim></b-form-input>
        </b-form-group>
        </div>
        <div class="col-6 ">
          <b-form-group
              id="fieldset-7"
              label="City"
              label-for="input-1"
          >
            <b-form-input id="input-8" v-model="user_profile.city" trim></b-form-input>
          </b-form-group>
        </div>
        <div class="col-6 ">
          <b-form-group
            id="fieldset-8"
            label="County"
            label-for="input-1"
        >
          <b-form-input id="input-8" type="text" v-model="user_profile.county" trim></b-form-input>
        </b-form-group>
        </div>
        <div class="col-6 ">
          <b-form-group
            id="fieldset-7"
            label="Postal Code"
            label-for="input-1"
        >
          <b-form-input id="input-8" v-model="user_profile.postal_code" trim></b-form-input>
        </b-form-group>
        </div>
        <div class="col-6 ">
          <b-form-group
              label="Next of Kin Full Name"
              label-for="nextKin"
          >
            <b-form-input id="nextKin" v-model="user_profile.next_of_the_kin_full_name" trim></b-form-input>
          </b-form-group>
        </div>
        <div class="col-6 ">
          <b-form-group
              label="Next of Kin Phone Number"
              label-for="nextKinPhone"
          >
            <b-form-input id='nextKinPhone' type="number" v-model="user_profile.next_of_the_kin_phone" @keypress="phoneNumber($event)"
                          trim></b-form-input>
          </b-form-group>
        </div>
        <div class="col-6 d-flex justify-content-center align-items-end">
          <b-button :disabled="!valid_form" @click="updateUserDetails" variant="white" class="my-4 w-100" squared>Save</b-button>
        </div>
      </b-row>

  </div>
</template>

<script>
import axios from "axios";
import config from "@/config/constants";

export default {
  name: "CoachingProfileDetails",
  props: ['user_profile'],
  computed: {
    valid_form() {
      return (
          this.user_profile.address &&
          this.user_profile.city &&
          this.user_profile.county &&
          this.user_profile.postal_code &&
          this.user_profile.next_of_the_kin_full_name &&
          this.user_profile.next_of_the_kin_phone
      )
    },
  },
  methods: {
    phoneNumber(evt) {
       if(this.user_profile.next_of_the_kin_phone.length > 14) {
           evt.preventDefault();
       }
    },
    async updateUserDetails() {
      const payload = {
        user_id: this.$store.getters.user_id,
        user_profile: this.user_profile
      }
      await axios.post(config.api_env + '/application/api/u/user/update-user-profile', payload, config.options)
          .then((response) => {
            if(response.data.success) {
              this.$emit('updateSuccess');
            }
          });
    },
  }
}
</script>

<style lang="less">
#coaching-profile-details {
  .form-group {
    padding: 3px 5px !important;
  }
  .sub-header {
    padding: 15px;
    font-size: 1.8rem;
    font-weight: bold;
  }
  .pulse-form-header {
    color: white;
    font-size: 1.6rem;
    font-weight: bold;
    padding: 10px;
    text-align: center;
  }
  .pulse-container {
    background-color: #f8f8f8;
    min-height: 300px;
    border-radius: 300px;
  }
  .form-control {
    font-family: "DMSans", sans-serif;
    border: 0;
    color: white;
    background: transparent;
    font-size: 14px;
    max-width: 100% !important;
    line-height: 1.5rem;
    box-sizing: border-box;
  }
  .profile-row {
    margin-right: 0 !important;
    margin-left: 0 !important;
    padding: 10px;
  }
  .form-control:focus {
    outline: none !important;
    border: unset;
    box-shadow: unset;
  }

  .form-control:disabled {
    color: #d6d6d6;
  }
  .custom-select {
    font-family: "DMSans", sans-serif;
    border: 0;
    background: transparent;
    font-size: 14px;
  }
  label, .details-heading {
    padding: 0 15px;
    font-size: 1.4rem !important;
    font-family: "DMSans", sans-serif;
    font-weight: bold;
  }
  .custom-select:focus {
    outline: none !important;
    border: unset;
    box-shadow: unset;
  }
  .profileHeader {
    font-family: PublicoHeadlineWebBold;
    margin-bottom: 10px;
    padding: 3px;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type=number] {
    -moz-appearance: textfield;
  }
}

</style>