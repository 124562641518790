<template>
  <div class="AccordionParser w-100">
    <div class="accordion-parser-container w-100">
      <div class="accordion w-100">
        <div
          v-for="(item, y) in content"
          :key="item.id"
          class="accordion-item w-100"
        >
          <div
            v-b-toggle="'accordion-' + y"
            class="accordion-header shadow d-flex justify-content-between align-items-center"
          >
            <h3 class="accordion-header-title text-white">{{ item.title }}</h3>
            <i class="fas fa-chevron-down  fa-2x text-white"></i>
          </div>
          <b-collapse
            :id="'accordion-' + y"
            role="tabpanel"
            class="accordion-body"
            :class="{ 'bottom-radius': y === item.content.length - 1 }"
          >
            <div class="pb-4 px-4">
              <div v-for="(i, x) in item.content" :key="x">
                <TextParser
                  :content="i.content"
                  :options="i.options"
                  v-if="i.type === 'text'"
                />
                <ImageParser
                  :content="i.content"
                  :options="i.options"
                  v-if="i.type === 'image'"
                />
                <AudioParser
                  :content="i.content"
                  :options="i.options"
                  v-if="i.type === 'audio'"
                />
                <VideoParser
                  :content="i.content"
                  :options="i.options"
                  v-if="i.type === 'video'"
                />
              </div>
            </div>
          </b-collapse>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TextParser from "./TextParser.vue";
import ImageParser from "./ImageParser.vue";
import AudioParser from "./AudioParser.vue";
import VideoParser from "./VideoParser.vue";
import QuestionaireParser from "./QuestionaireParser.vue";

export default {
  components: {
    TextParser,
    ImageParser,
    AudioParser,
    VideoParser,
    QuestionaireParser
  },
  name: "AccordionParser",
  props: ["content"],
  data() {
    return {};
  },
  mounted() {},
  methods: {}
};
</script>
<style lang="less">
.AccordionParser {
  .hard-shadow {
  }

  .radius {
    border-radius: 1.6rem;
    overflow: hidden;
  }
  .accordion-header {
    background-color: #381c52;
    width: 100%;
    display: flex;
    flex-grow: 1;
    padding: 15px;
    border: 1px solid #eee;
    border-radius: 16px;
    margin-bottom: 20px;
    .accordion-header-title {
      display: flex;
      flex-grow: 1;
      width: 100%;
      font-weight: bold;
    }
  }
}
</style>
