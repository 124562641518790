<template>
  <div class="HomeCardCarousel py-3" v-if="!loaded || content.length > 0" @mouseover="showArrows = true"
    @mouseleave="showArrows = false" :class="showArrows ? null : 'hide-arrows'">
    <div v-if="!loaded" class="mb-3 container d-flex flex-row justify-content-between align-items-center">
      <HomeLoading />
    </div>
    <div v-else>
      <div class="mb-3 container d-flex flex-row justify-content-between align-items-center">
        <div class="d-flex flex-row align-items-center justify-content-between mb-2 w-100">
          <h3 class="home-title mb-0 mr-5">{{ title }}</h3>
          <!-- <div class="see-more-link brand-text" @click="seeMore">
            See More <i class="fa-solid fa-chevron-right"></i>
          </div> -->
        </div>
      </div>
      <div class="mb-3 container d-flex flex-row justify-content-between align-items-center" v-if="promoText">
        <div class="brand-text promoText">{{ promoText }}</div>
      </div>
      <div class="position-relative">
        <div class="pb-5 container" v-if="type === 'featured-event' && loaded === true"
          :class="type === 'featured-event' ? 'banner-card-container' : null">
          <slick id='slick-id' ref="slick" :options="eventSlickOptions" @afterChange="handleSwipe"
            @beforeChange="handleBeforeChange" class="justify-content-start">
            <HomeBannerEventCard :event="event" :key="i" v-for="(event, i) in content" :swiping="swiping"
              :reminders="reminders" />
          </slick>
        </div>
        <div class="pb-5 container" v-else-if="loaded === true">
          <slick id='slick-id' ref="slick" :options="slickOptions" @afterChange="handleSwipe"
            @beforeChange="handleBeforeChange" class="justify-content-start h-100"
            :class="cardType === 'event' ? 'event-slick' : ''">
            <!--                    <div :key="i" v-for="(item, i) in content" @click="cardLink(item)">-->
            <div class="h-100" :key="i" v-for="(item, i) in content">
              <HomeRecipeCard :item="item" :type="type" v-if="type.includes('recipe')" :swiping="swiping">
              </HomeRecipeCard>
              <ArticleCard :item="item" :type="type" v-else-if="cardType === 'article'" :swiping="swiping">
              </ArticleCard>
              <EventCard :item="item" :type="type" v-else-if="cardType === 'corporateEvent'" :swiping="swiping">
              </EventCard>
              <HomeClinicCard :item="item" v-else-if="type === 'clinic'" type="clinic" :swiping="swiping"
                :settings="clinicSettings"></HomeClinicCard>
              <VimeoCard :mute="mute" :video="item" :type="type" v-else-if="isVimeoVideo(item, type)" :swiping="swiping"
                :perma-tag="permaTag" :reminders="reminders" :unique_id="genRandonString(10)"></VimeoCard>
              <CourseCard :item="item" :type="type" v-else-if="item.source === 'ResCourses'" :swiping="swiping" />
              <VideoCard :video="item" :type="type" v-else-if="item.media === 'video'" :swiping="swiping" />
              <PodcastCard :podcast="item" :type="type" v-else-if="item.media === 'audio'" :swiping="swiping"
                :unique_id="genRandonString(10)" />
              <PodcastCard :podcast="item" :type="type" v-else-if="item.media === 'text'" :swiping="swiping"
                :unique_id="genRandonString(10)" />
              <PodcastCard :podcast="item" :type="type" v-else-if="item.media === 'podcasts'" :swiping="swiping"
                :unique_id="genRandonString(10)" />

            </div>
            <div class="d-flex justify-content-start align-items-center" :class="{ hideLoader: !loading_new_content }"
              v-if="page <= totalPages">
              <Loader v-if="loading_new_content && type !== 'recipes'" />
            </div>
          </slick>
        </div>
        <div class="d-flex flex-row flex-nowrap video-blur-container" v-if="!isAccessibilitySite()">
          <div class="left-blur"></div>
          <div class="container w-100"></div>
          <div class="right-blur"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios"
import slick from "vue-slick"
import VideoCard from "@/components/Home/New/VideoCard.vue"
import EventCard from "@/components/Home/New/EventCard.vue"
import BookingCard from "@/components/Home/New/BookingCard.vue"
import PodcastCard from "@/components/Home/New/PodcastCard.vue"
import HomeBannerEventCard from "@/components/Home/New/HomeBannerEventCard.vue";
import EventBus from "@/helpers/eventbus";
import HomeRecipeCard from "@/components/Home/New/HomeRecipeCard.vue";
import Loader from "@/components/Loader.vue";
import VimeoCard from "@/components/Home/New/VimeoCard.vue";
import CourseCard from "@/components/Home/New/CourseCard.vue";
import HomeLoading from "@/components/Home/New/HomeLoading.vue";
import HomeClinicCard from "@/components/Home/New/HomeClinicCard.vue";
import config from "@/config/constants";
import ArticleCard from "@/components/Home/New/ArticleCard.vue"

export default {
  name: "HomeCardCarousel",
  props: {
    title: String,
    type: String,
    cardType: String,
    permaTag: String,
    additionalTag: String,
    upcoming: {
      type: Boolean,
      default: false
    },
    promoText: {
      type: String,
      default: null
    },
    mute: {
      type: Boolean,
      default: false
    }
  },
  components: {
    HomeLoading,
    HomeClinicCard,
    HomeRecipeCard,
    VideoCard,
    EventCard,
    BookingCard,
    PodcastCard,
    HomeBannerEventCard,
    slick,
    Loader,
    VimeoCard,
    CourseCard,
    ArticleCard
  },
  data() {
    return {
      reminders: [],
      swiping: false,
      loaded: false,
      loading_new_content: false,
      content: [],
      events: [],
      page: 1,
      totalRecords: 0,
      totalPages: 0,
      optionalTag: [],
      showArrows: false,
      clinicSettings: null,
      searchTerm: '',
      slickOptions: {
        initialSlide: 0,
        arrows: true,
        slidesToScroll: 1,
        slidesToShow: 3,
        nextArrow:
          '<div class="slick-arrow-next std-arrow">' +
          '<a class="slick-arrow-container std-arrow-container"><i class="fas fa-chevron-right text-white fa-4xs"></i></a>' +
          '</div>',
        prevArrow:
          '<div class="slick-arrow-prev std-arrow">' +
          '<a class="slick-arrow-container std-arrow-container"><i class="fas fa-chevron-left text-white fa-4xs"></i></a>' +
          '</div>',
        adaptiveHeight: true,
        infinite: false,
        responsive: [
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 1,
            },
          },
          {
            breakpoint: 951,
            settings: {
              slidesToShow: 2,
            },
          },
          {
            breakpoint: 1066,
            settings: {
              slidesToShow: 3
            }
          },
          {
            breakpoint: 1315,
            settings: {
              slidesToShow: 4
            }
          }
        ],
      },
      eventSlickOptions: {
        initialSlide: 0,
        arrows: true,
        slidesToScroll: 1,
        slidesToShow: 1,
        nextArrow:
          '<div class="slick-arrow-next">' +
          '<a class="bg-brand-highlight-1 slick-arrow-container"><i class="fas fa-chevron-right text-white fa-4xs"></i></a>' +
          '</div>',
        prevArrow:
          '<div class="slick-arrow-prev">' +
          '<a class="bg-brand-highlight-1 slick-arrow-container"><i class="fas fa-chevron-left text-white fa-4xs"></i></a>' +
          '</div>',
        adaptiveHeight: true,
        infinite: false,
        responsive: [
          {
            breakpoint: 1300,
            settings: {
              slidesToShow: 1,
            },
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 1,
            },
          }
        ],
      },
    };
  },
  methods: {
    isVimeoVideo(video, type) {
      if (type === 'studio' || type === 'gym') return true;
      return (video.url && video.url.includes('vimeo')) || (video.embed_video && video.embed_video.includes('vimeo'));
    },
    seeMore() {
      switch (this.type.toLowerCase()) {
        case 'featured-event':
          this.$router.push("/wellbeing-studio");
          break;
        case 'studio':
          this.$router.push("/wellbeing-studio");
          break;
        case 'recipes':
          this.$router.push("/personal/nutrition");
          break;
        case 'gym':
          this.$router.push("/digital-gym");
          break;
        case 'classes':
          this.$router.push("/digital-gym");
          break;
        case 'exercise':
          this.$router.push("/digital-gym?selectedView='training_plans'");
          break;
        case 'courses':
          this.$router.push("/wellbeing");
          break;
        case 'surge':
          this.$router.push("/wellbeing");
          break;
        case 'be_calm':
          this.$router.push("/be-calm");
          break;
        case 'clinic':
          this.$router.push("/clinics/digital");
          break;
        case 'podcasts':
          this.$router.push("/sound-space/");
          break;
      }
    },
    handleBeforeChange() {
      //prevents reroute whilst tiles are in motion
      this.swiping = true;
      setTimeout(() => { this.swiping = false }, 1000)
    },
    async handleSwipe(event, slick, currentSlide) {
      //if the total records - the current slide is 4x
      if (this.content.length - currentSlide === 4 && this.page <= this.totalPages) {
        this.loading_new_content = true;
        await this.getContent();
      }
    },
    async getEvents() {
      const today = this.$date(new Date(), "YYYY-MM-DD");
      const payload = {
        user_id: this.$store.getters.user_id,
        from_date: today,
        days_limit: 60,
      };
      let res = await axios.post(
        config.api_env + "/application/api/u/events/get-events",
        payload,
        config.options
      );
      if (res.data.err && res.data.err.includes("3994")) {
        this.$store.dispatch("logout");
        this.$router.push("/login");
      } else {
        let self = this;
        res.data.data.forEach((date) => {
          date.events.forEach((e) => {
            self.content.push(e)
          })
        });
        this.loaded = true;
      }
    },
    async getContent(reInit = false, restart = false) {
      this.loading_new_content = true;
      let contentPath = '';
      let contentType;
      switch (this.type) {
        case 'corporateEvent':
          contentPath = "/application/api/u/sapphire/home/content";
          contentType = 'events'
          break;
        case 'standaloneArticles':
          contentPath = "/application/api/u/sapphire/home/content";
          contentType = 'articles'
          break;
        case 'featured-event':
          contentPath = "/application/api/u/studio/events/trending";
          contentType = 'events'
          break;
        case 'recipes':
          contentPath = "/application/api/u/content/nutrition";
          contentType = 'articles'

          break;
        case 'clinic':
          contentPath = "/application/api/u/home/get-dc-banner";
          contentType = 'courses'
          break;
        default:
          contentPath = "/application/api/u/sapphire/home/content";
          contentType = 'events'

      }
      if (restart) this.page = 1;
      let tags = [];
      if (this.optionalTag.length > 0) {
        console.log('in here?')
        tags.unshift(this.optionalTag[0])
      }
      if (this.additionalTag) {
        tags.unshift(this.additionalTag);
      }
      const payload = {
        user_id: this.$store.getters.user_id,
        tags: tags,
        search_term: this.searchTerm,
        type: contentType,
        page: this.page,
        pageSize: 6,
      };
      if (this.permaTag) {
        payload.tags.push(this.permaTag)
      }
      if (this.upcoming) {
        payload.upcoming = true;
      }
      if (this.type === "exercise") {
        payload.sort = "rank";
        payload.order = "asc";
      }
      let res = await this.axios.post(
        process.env.VUE_APP_API_PATH +
        contentPath,
        payload, config.options
      );
      this.loaded = true;

      if (res.data.success && !res.data.data) {
        res.data.data = []
      }
      if (res.data.success && !res.data.meta) {
        res.data.meta = {
          pagination: {
            current_page: 1,
            per_page: 6,
            total_pages: 0,
            total: 0,
            has_next_page: false,
            has_prev_page: false
          }
        }
      }
      if (res.data.success) {
        if (this.type === 'clinic') {
          this.content = res.data.categories;
          this.totalRecords = res.data.categories.length;
          this.totalPages = 0;
          this.clinicSettings = res.data.dc_setting;
        } else if (this.type === 'standaloneArticles' && res.data.content) {
          this.content = res.data.content;
          this.totalRecords = res.data.content.length;
          this.totalPages = 0;
        } else if (this.page === 1) {
          this.content = res.data.data;
          this.totalRecords = res.data.meta.pagination.total;
          this.totalPages = res.data.meta.pagination.total_pages;
          this.page++;
          if (reInit) this.reinitSlider(true);
        } else {
          this.loading_new_content = false;
          this.content = this.content.concat(res.data.data);
          this.page++;
          this.reinitSlider();
        }
      } else if (res.data.err && res.data.err.includes("3994")) {
        this.$store.dispatch("logout");
        this.$router.push("/login");
      } else {
        console.log('no data')
        this.loading_new_content = false;
        this.loaded = true;
      }

    },
    reinitSlider(backToStart = false) {
      let self = this;
      if (backToStart) this.slickOptions.initialSlide = 0;
      if (self.$refs.slick) {
        let currentSlide = backToStart ? 0 : self.$refs.slick.currentSlide();
        this.$refs.slick.destroy();
        this.$nextTick(() => {
          let options = this.type === 'featured-event' ? self.eventSlickOptions : self.slickOptions;
          options.initialSlide = currentSlide;
          self.$refs.slick.create(options);
        });
      }
      this.loading_new_content = false;
    },

  },
  mounted() {
    let self = this;
    EventBus.$on("setTag", function (tag) {
      if (['Life', 'Mind', 'Body'].includes(tag)) {
        self.optionalTag = [tag];
      } else {
        self.optionalTag = [];
      }
    });
    EventBus.$on("setSearch", function (search) {
      self.searchTerm = search
    });
    this.getContent(true);
    EventBus.$on('setBookingReminders', (data) => {
      this.reminders = data;
    })
    EventBus.$on('locationChange', () => {
      self.loaded = false;
      if (self.$refs.slick) {
        self.$refs.slick.goTo(0);
      }
      self.page = 1;
      self.getContent(true, true);
    })
  },
  watch: {
    optionalTag() {
      if (this.type !== 'featured-event') {
        this.loaded = false;
        if (this.$refs.slick) {
          this.$refs.slick.goTo(0);
        }
        this.page = 1;
        this.getContent(true, true);
      }
    },
    searchTerm() {
      if (this.type !== 'featured-event') {
        this.loaded = false;
        if (this.$refs.slick) {
          this.$refs.slick.goTo(0);
        }
        this.page = 1;
        this.getContent(true, true);
      }
    },
  },
};
</script>

<style lang="less">
.HomeCardCarousel {
  .slick-track {
    display: flex !important;
  }

  .slick-slide {
    height: inherit !important;
  }

  .slick-slide>div:first-child {
    height: 100% !important;
  }



  .slick-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-30%);
    z-index: 10;
    background-color: transparent;
    border: none;
    font-size: 1rem;

    &:focus {
      outline: none;
    }
  }

  &.hide-arrows {
    .std-arrow {
      display: none !important;
    }
  }

  .home-title {
    font-size: 2rem;
  }

  .home-title-line {
    height: 1px;
    width: 200px;
    max-width: 50%;
  }

  .promoText {
    font-family: DMSans, Arial, Helvetica, sans-serif;
    font-size: 1.75rem;
  }

  .hideLoader {
    width: 0px !important;
  }

  .slick-slide:has(.hideLoader) {
    width: 0px !important;
  }

  .see-more-link {
    font-size: 1.5em;

    &:hover {
      cursor: pointer;
    }
  }

  .slick-slider {
    .slick-slide {
      transform: scale(1);
      margin: 0 8px;
    }
  }

  .slick-list {
    margin: 0 -15px;
    overflow: visible;
  }

  .slick-arrow-container {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    line-height: 0;
    padding: 10px;
    height: 100%;
    display: none;
  }

  @media only screen and (max-width: 767px) {
    .home-title-line {
      display: none !important;
    }
  }

  @media only screen and (min-width: 980px) and (max-width: 992px) {
    .slick-arrow-container {
      display: flex;
    }
  }

  @media only screen and (min-width: 1130px) and (max-width: 1200px) {
    .slick-arrow-container {
      display: flex;
    }
  }

  @media only screen and (min-width: 1320px) {
    .slick-arrow-container {
      display: flex;
    }
  }

  .displayLoader {}

  .slick-arrow-next {
    right: -45px;
  }

  .slick-arrow-prev {
    left: -75px;
  }

  .std-arrow {
    top: 40% !important;

    &.slick-arrow-next {
      right: 0px;
    }

    &.slick-disabled {
      display: none !important;
    }

    &.slick-arrow-prev {
      left: -25px;
    }

    .std-arrow-container {
      background-color: rgba(225, 225, 225, 0.7);
    }
  }

  .slick-track {
    margin-left: 0;
  }

  .slick-slide.slick-active {
    pointer-events: auto;
    opacity: 1;
  }

  .slick-slide {
    pointer-events: none;
  }

  .slick-disabled {
    .bg-brand-highlight-1 {
      background-color: #eee;
      cursor: default;
    }
  }

  .video-blur-container {
    pointer-events: none;
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;

    .container {
      flex-grow: 1;

    }

    .left-blur {
      flex-grow: 1;
      background: rgb(247, 247, 247);
      background: linear-gradient(270deg, rgba(250, 250, 250, 0.0) 5%, rgba(250, 250, 250, 0.7) 15%, rgba(250, 250, 250, 1) 45%);
    }

    .right-blur {
      flex-grow: 1;
      background: rgb(247, 247, 247);
      background: linear-gradient(90deg, rgba(250, 250, 250, 0.0) 5%, rgba(250, 250, 250, 0.7) 15%, rgba(250, 250, 250, 1) 45%);
    }
  }
}
</style>
