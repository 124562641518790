<template>
  <div id="UserLogin">
	<div class="d-flex flex-row align-items-center justify-content-between" style="gap: 20px;">
		<b-button variant="cta" class="w-100" @click="login" squared>Login</b-button>
		<b-button variant="cta-outline" class="w-100" @click="login" squared>Register</b-button>
	</div>
  </div>
</template>

<script>
// import { validationMixin } from "vuelidate";
// import axios from "axios";
// import config from "../../config/constants";
// import { email, required } from "vuelidate/lib/validators";
// import { mapGetters } from "vuex";
// import EventBus from "../../helpers/eventbus";

export default {
  name: "UserLogin",
  props: [],
  // mixins: [validationMixin],
  data() {
    return {
	    email_id: "",
	    account : null,
	    access_token : null,
	    sso_id : null,
	    id_token : null,
    };
  },
  mounted() {},
  methods: {
	  async init(){
		  await this.$msal.handleRedirectPromise(); // getting weird errors if this is not here
		  await this.$msal.initialize()
	  },
	  async login() {
		  try {
			  await this.init()
			  const response = await this.$msal.loginPopup();
				console.log(response)
			  this.account = response.account;
			  this.email_id = this.account.name;
				this.sso_id = this.account.idTokenClaims.sub;
			  this.id_token = this.account.idToken;
				this.processLogin(response.account)
				// await this.processToken()
		  } catch (error) {
			  console.error("initialization or login failed:", error);
		  }
	  },
	  logout() {
		  this.account = null;
	  },
  },
	inject: ['processLogin']
};
</script>
<style lang="less">
#UserLogin {
	text-align: center;
	button{
		padding: 15px !important;
		font-size: 16px;
	}
}
</style>


