<template>
    <div id="BookCoach" class="row container brand-text">
        <div v-if="loading" class="col-12 coach-modal-loader">
            <Loader class="my-auto" />
        </div>
        <div class="w-100" v-else-if="selectedView === 'select'">
            <div class="d-flex flex-row align-items-center mt-5 w-100">
                <div class="col-sm-12 col-md-6">
                    <h3>Thank you!<br />Let's get you booked in with your coach</h3>
                </div>
                <div class="col-sm-12 col-md-6">
                    <div class="coach-card w-100 px-4 py-4 bg-white shadow brand-text">
                        <div class="d-flex flex-row align-items-center ">
                            <img v-if="coach.avatar_url" class="coach-image img-fluid mr-3" :src="coach.avatar_url" title="Coach Avatar"
                                :alt="coach.name">
                            <h3 class="coach-name" v-html="coach.name"></h3>
                        </div>
                        <div class="pt-3">
                            <p class="coach-description" v-html="coach.description"></p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="d-flex flex-row align-items-center mt-5 w-100">
                <div class="col-sm-12">
                    <h3 class="mb-4">What's the best date for you?</h3>
                    <div class="row">
                        <div class="col-md-3 col-sm-4 mb-4" v-for="(e, e_i) in events" :key="e_i">
                            <b-button class="w-100" @click="selectDate(e)" squared :variant="selected_date.date === e.date
                                ? 'cta'
                                : 'outline'
                                ">{{ getShortDate(e.date) }}</b-button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="d-flex flex-row align-items-center mt-5 w-100" v-if="selected_date">
                <div class="col-sm-12">
                    <h3 class="mb-4">What's the best time for you?</h3>
                    <div class="row">
                        <div class="col-md-3 col-sm-4 mb-4" v-for="(t, t_i) in selected_date.slots" :key="t_i">
                            <b-button class="w-100" @click="selectSlot(t)" squared :variant="t.id === selected_slot.id
                                ? 'cta'
                                : 'outline'
                                ">{{ getHoursMinutes(t.start, true) }}</b-button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="d-flex flex-column align-items-start mt-5 w-100" v-if="selected_slot">
                <div class="col-sm-12">
                    <p class="selected-text">You have selected
                        <b>{{ getFormatedDate(selected_slot.start, "DD/MM/YY") }}</b> at
                        <b>{{ getHoursMinutes(selected_slot.start, true) }}</b> with
                        <b>{{ coach.name }}</b>
                    </p>
                </div>
                <div class="col-md-8 col-sm-12 mt-5">
                    <h4 class="mb-5">What happens next?</h4>
                    <p class="p-text mb-4">
                        1. Look out for an email with a link. You can use this if you need to change your booking.
                    </p>
                    <p class="p-text mb-4">
                        2. We'll send a video call link 1 or 2 days before your appointment. If you don't get it, please
                        check your spam inbox, or email <b class="brand-primary"><a class="brand-primary">{{supportContacts.email.uk.length>0 ? supportContacts.email.uk : supportContacts.email.default}}</a></b>.
                    </p>
                    <p class="p-text mb-4">
                        3. Please log in using the link 5 minutes before your appointment, so you can check everything is
                        working correctly.
                    </p>

                </div>
                <div class="col-8 mt-5">
                    <h4 class="mb-5">Any questions?</h4>
                    <p class="p-text mb-5">
                        If you have any problems, email <b class="brand-primary"><a class="brand-primary">{{supportContacts.email.uk.length>0 ? supportContacts.email.uk : supportContacts.email.default}}</a></b> or send us a message using livechat.
                    </p>
                    <div class="d-flex flex-row align-items-center mb-4">
                        <input type="checkbox" id="agree-checkbox" v-model="checkbox"
                            aria-label="Agree to terms and conditions" class="checkbox" />
                        <label for="agree-checkbox" class="ml-2 mt-2 p-text">
                            <b>By booking an appointment, you agree to the <a href="/terms-and-conditions" target="_blank" class="brand-primary underlined">terms and conditions.</a></b>
                        </label>
                    </div>
                </div>
            </div>
            <div class="d-flex flex-row align-items-center justify-content-between col-12 mt-5 pt-5 pb-5 mb-5">
                <b-button variant="cta-outline" class="d-flex align-items center" size="lg" @click="$router.push('/cancer-coaching/select-coach')" squared><i
                        class="fa-md fa fa-chevron-left mr-5" ></i><span class="mr-5">Back</span></b-button>
                <b-button @click="bookSlot" variant="cta" size="lg" :disabled="!checkbox" squared><span class="ml-5">Book</span> <i
                        class="fa-md fa fa-chevron-right ml-5"></i></b-button>

            </div>
        </div>
        <div class="mx-auto confirm-container d-flex flex-column align-items-center justify-content-center mb-5 animate__animated animate__fadeIn pt-5" v-else-if="selectedView === 'booked'">
            <div class="border-brand-primar pb-5">
                <img :src="coach.avatar_url" class="clinician-image"/>
            </div>
            <h1 class="brand-highlight-1 mb-5">Your session</h1>
            <p class="p-text text-center">
                You've booked your session with<br /><br />
                <b>{{coach.name}}</b> for<br /><br />
                <b> {{ getFormatedDate(selected_slot.start, "DD/MM/YY") }} at {{ getHoursMinutes(selected_slot.start, true) }}</b><br /><br />
                Check your inbox for an <b>email reminder</b><br /> <br />
                <!-- You'll also see a <b>notification on the top right of the page.</b><br /><br /> -->
                If you have any questions around your session please feel free to reach out to your coach.
            </p>
            <b-button class="w-100 mt-5 mb-5" variant="cta" size="lg" squared @click="$router.push('/cancer-coaching/')">Done</b-button>
        </div>
    </div>
</template>
  
<script>
import Loader from "../components/Loader";
import slick from "vue-slick";
import EventBus from "@/helpers/eventbus";

export default {
    name: "EvolveSingleCoach",
    components: { Loader, slick },
    props: ["coach_id"],
    data() {
        return {
            loading: true,
            coach: {},
            events: [],
            selectedView: 'select',
            selected_date: false,
            selected_slot: false,
            selected_delivery_type: "video",
            booked: false,
            error: false,
            checkbox: false,

        };
    },
    mounted() {
        this.getCoach();
    },
    methods: {
        toggleCheckbox() {
            this.checkbox = !this.checkbox;
        },
        back() {
            history.back();
        },
        async bookSlot() {
            let self = this;
            let p = {
                path: "api/u/coaching-event/book-session",
                slot_id: this.selected_slot.id,
                delivery_type: this.selected_delivery_type,
                type: 'cancer'
            };
            let res = await this.api(p);
            if (res.success) {
                this.booked = true;
                this.selectedView = 'booked';
                this.$store.dispatch("setCoach", {
                    title: self.coach.name,
                    avatar: self.coach.avatar_url,
                });
            } else {
                this.$root.$emit("bookingFail");
                this.selectedView = 'select';
                this.error = true;
            }
        },
        selectSlot(t) {
            if (!this.booked) {
                this.selected_slot = t;
            }
        },
        selectDate(e) {
            this.selected_slot = false;
            this.checkbox = false;
            if (!this.booked) {
                this.selected_date = e;
            }
        },
        setDeliveryType(type) {
            if (!this.booked) {
                this.selected_delivery_type = type;
            }
        },
        async getCoach(id) {
            let p = {
                path: "api/u/coach/get-coach",
                coach_id: this.$route.params.id,
            };
            let res = await this.api(p);
            if (res.success) {
                this.coach = res.coach;
                this.events = res.events;
                this.loading = false;
            }
        },
        // async chooseCoach(id){
        //     let p = {
        //         path : 'api/u/coach/choose-coach',
        //         coach_id : id
        //     }
        //     let res = await this.api(p)
        //     if(res.success){
        //         this.$router.push("/coaching");
        //     }
        // }
    },
    computed: {
        supportContacts() {
            return this.$store.state.user.support_contacts;
        }
    },
};
</script>
<style lang="less">
#BookCoach {


    .coach-modal-loader {
        min-height: 800px;
        display: flex;
        align-items: center;
    }

    .coach-card {
        background-color: white;
        border-radius: 8px;

        .coach-image {
            max-width: 80px;
        }

        .coach-name {
            font-size: 1.8rem;
        }

        .coach-description {
            font-size: 1.4rem;
        }
    }

    .selected-text {
        font-size: 1.6rem;

        b {
            font-weight: 700;
        }
    }
    .checkbox{
        width: 20px;
        height: 20px;
    }
    .confirm-container{
        max-width: 400px;
    }
    .clinician-image{
        max-width: 150px;
    }
    .error{
        font-size: 1.6rem;
        font-weight: bold;
        color: #d9544d
    }
}
</style>
  