var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"notification-card",class:{
    clickable: [
      'SalesforceClinicianMessage',
      'SalesforceTooManyRejectedSuggestions',
      'SalesforceAppointmentOptions'
    ].includes(_vm.notification.type)
  },on:{"click":_vm.handleClick}},[_c('div',{staticClass:"title brand-text"},[_vm._v(_vm._s(_vm.notification.subject))]),_c('div',{staticClass:"body brand-text"},[_vm._v(_vm._s(_vm.notification.body))]),_c('div',{staticClass:"underline"})])
}
var staticRenderFns = []

export { render, staticRenderFns }