import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";
import NotLoggedIn from "../views/NotLoggedIn.vue";
import LoggedIn from "../views/LoggedIn.vue";
import NotFound from "../views/NotFound.vue";
import Faqs from "@/pages/Faqs";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import TCs from "../pages/TCs";
import ClientLogin from "../views/ClientLogin.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "NotLoggedIn",
    component: NotLoggedIn
  },
  {
    path: "/sso-redirect*",
    name: "axaSsoRedirect",
    component: NotLoggedIn
  },
  {
    path: "/",
    name: "home",
    component: LoggedIn
  },
  {
    path: "/mental-health-coaching",
    name: "mentalHealthCoachingHome",
    component: LoggedIn
  },
  {
    path: "/mental-health-coaching/start",
    name: "mentalHealthCoachingStart",
    component: LoggedIn
  },
  {
    path: "/mental-health-coaching/start/questionnaire",
    name: "mentalHealthCoachingQuestionnaire",
    component: LoggedIn
  },
  {
    path: "/mental-health-coaching/select-coach",
    name: "mentalHealthCoachingSelectCoach",
    component: LoggedIn
  },
  {
    path: "/mental-health-coaching/select-coach/:id",
    name: "mentalHealthCoachingBookCoach",
    component: LoggedIn
  },
  {
    path: "/health-coaching",
    name: "healthCoachingHome",
    component: LoggedIn
  },
  {
    path: "/health-coaching/start",
    name: "healthCoachingStart",
    component: LoggedIn
  },
  {
    path: "/health-coaching/select-coach",
    name: "healthCoachingSelectCoach",
    component: LoggedIn
  },
  {
    path: "/health-coaching/select-coach/:id",
    name: "healthCoachingBookCoach",
    component: LoggedIn
  },
    {
    path: "/cancer-coaching",
    name: "cancerCoachingHome",
    component: LoggedIn
  },
  {
    path: "/cancer-coaching/start",
    name: "cancerCoachingStart",
    component: LoggedIn
  },
  {
    path: "/cancer-coaching/select-coach",
    name: "cancerCoachingSelectCoach",
    component: LoggedIn
  },
  {
    path: "/cancer-coaching/select-coach/:id",
    name: "cancerCoachingBookCoach",
    component: LoggedIn
  },
  {
    path: "/total-mental-health/your-impact",
    name: "totalMentalHealthYourImpact",
    component: LoggedIn
  },
  {
    path: "/events/view-event/:id",
    name: "viewEvent",
    // meta: { title: navItems['events'] ? navItems['events'].label : "" },
    component: LoggedIn,
  },
  {
    path: "/events/:page?/:webinar?",
    name: "events",
    // meta: { title: navItems['events'] ? navItems['events'].label : "" },
    component: LoggedIn,
  },
  {
    path: "/faqs",
    name: "dmhFaqs",
    component: LoggedIn
  },
  {
    path: "/articles",
    name: "dmhArticles",
    component: LoggedIn
  },
  {
    path: "/stand-alone-article/:id",
    name: "standaloneArticle",
    // meta: { title: navItems['events'] ? navItems['events'].label : "" },
    component: LoggedIn,
  },
  {
    path: "/settings",
    name: "settings",
    component: LoggedIn
  },
  {
    path: "/dmh/booking",
    name: "dmh",
    component: LoggedIn
  },
  {
    path: "/dmh",
    name: "dmhLanding",
    component: LoggedIn
  },
  {
    path: "/blood-test",
    name: "randoxKit",
    component: LoggedIn
  },
  {
    path: "*",
    name: "not-found",
    component: NotFound
  },
  {
    path: "/privacy-policy",
    name: "privacy",
    component: PrivacyPolicy
  },
  {
    path: "/terms-and-conditions",
    name: "TCs",
    component: TCs
  },
  {
    path: "/password-reset",
    name: "reset",
    component: LoggedIn
  },
  {
    path: "/booking-video-room/:booking_id/:tests?",
    name: "bookingVideoRoom",
    component: LoggedIn
  },
  {
    path: "/healthscore",
    name: "healthScore",
    component: LoggedIn,
  },
  {
    path: "/healthscore/data-protection/:assessment_id",
    name: "healthScoreDataProtection",
    component: LoggedIn,
  },
  {
    path: "/healthscore/assessment/:assessment_id",
    name: "healthScoreAssessment",
    component: LoggedIn,
  },
  {
    path: "/healthscore/results/:assessment_id",
    name: "healthScoreResults",
    component: LoggedIn,
  },
  {
    path: "/emergency-contacts",
    name: "emergencyContacts",
    component: LoggedIn,
  },
  {
    path: "/efwewfsdcxzcviomgfdmfsdewdcdcdjfd",
    name: "efwewfsdcxzcviomgfdmfsdewdcdcdjfd",
    component: ClientLogin,
  }
];

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err);
};

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

// if (store.getters.hardcoded && store.getters.hardcoded.permittedRoutes) {
//   router.beforeEach((to, from, next) => {
//     if (!store.getters.hardcoded.permittedRoutes.includes(to.name)) {
//       try {
//         next(store.getters.homeRoute);
//       } catch (e) {
//         console.log(e);
//         next("/login");
//       }
//     }
//     next();
//   });
// }

  // router.beforeEach((to, from, next) => {
  //   console.log(window.location)
  //   if (!store.getters.hardcoded.permittedRoutes.includes(to.name)) {
  //     try {
  //       next(store.getters.homeRoute);
  //     } catch (e) {
  //       console.log(e);
  //       next("/login");
  //     }
  //   }
  //   next();
  // });


// router.afterEach(() => {
//   console.log(window.location)
//   if (window.location.hash.includes("state=") || window.location.hash.includes("id_token")) {
//     console.log(window.location)
//     throw new Error(window.location)
//     window.history.replaceState(null, "", window.location.pathname);
//   }
// });


export default router;
