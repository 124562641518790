<template>
  <div id="DmhConfirm" class="border-brand-primary ">
    <div class="dmh-confirmation-container w-100">
      <label class="dmh-confirm-field brand-text">Date</label>
      <input
        id="datePicker"
        v-model="selectedDate"
        type="date"
        class="dmh-confirm-input"
        :min="getMinimumAllowedDate()"
      />
    </div>
    <div class="dmh-confirmation-container w-100 ">
      <label class="dmh-confirm-field brand-text">Time</label>
      <input
        id="timePicker"
        v-model="selectedTime"
        type="time"
        class="dmh-confirm-input"
        :min="getMinimumAllowedTime()"
        v-if="selectedDate === getMinimumAllowedDate()"
      />
      <input
        id="timePicker"
        v-model="selectedTime"
        type="time"
        class="dmh-confirm-input"
        v-else
      />
    </div>

    <div
      class="d-flex flex-row align-items-center justify-content-center my-4 pt-3"
      v-if="selectedDateTime"
    >
      <p v-if="isSameDateTime" class="p-text-lg pb-0 mb-0">
        <i class="fa fa-times-circle mr-3" aria-hidden="true"></i> The time for
        the second preference must be different from the first preference.
      </p>
      <p v-else class="p-text-lg pb-0 mb-0">
        <i class="fa-solid fa-circle-check mr-3"></i> You have selected your
        preference as
        <b>{{ getFormattedDate(selectedDateTime, "EEEE do MMM") }}</b> at
        <b>{{ getFormattedDate(selectedDateTime, "h:mm a") }}</b>
      </p>
    </div>
  </div>
</template>

<script>
import EventBus from "@/helpers/eventbus";
import { format } from "date-fns";

export default {
  name: "DmhStart",
  props: ["question", "answer", "setAnswer", "isSameDateTime"],
  data() {
    return {
      selectedDateTime: null,
      selectedDate: null,
      selectedTime: null
    };
  },
  mounted() {
    let self = this;
    EventBus.$on("questionChanged", function() {
      self.selectedDateTime = null;
      self.selectedDate = null;
      self.selectedTime = null;
    });
  },
  methods: {
    getMinimumAllowedDate() {
      const today = new Date();
      return today.toISOString().split("T")[0];
    },
    getMinimumAllowedTime() {
      if (this.selectedDate === this.getMinimumAllowedDate()) {
        const now = new Date();
        const hours = now
          .getHours()
          .toString()
          .padStart(2, "0");
        const minutes = now
          .getMinutes()
          .toString()
          .padStart(2, "0");
        return `${hours}:${minutes}`;
      }
      return "00:00";
    },
    getFormattedDate(dateTime, formatStr) {
      return format(new Date(dateTime), formatStr);
    }
  },
  watch: {
    selectedDateTime(val) {
      this.setAnswer(val);
    },
    selectedDate(val) {
      if (this.selectedTime === null) return (this.selectedDateTime = null);
      this.selectedDateTime = val + "T" + this.selectedTime;
    },
    selectedTime(val) {
      if (this.selectedDate === null) return (this.selectedDateTime = null);
      this.selectedDateTime = this.selectedDate + "T" + val;
    }
  }
};
</script>
<style lang="less">
#DmhConfirm {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #343c3d;

  .fa-circle-check,
  .fa-times-circle {
    font-size: 2rem;
  }

  .dmh-confirmation-container {
    display: flex;
    flex-direction: column;
    font-size: 1.8rem;
    border-radius: 8px;
    padding: 15px;
    label {
      font-family: "DMSans", serif;
      font-weight: bold;
    }
    .dmh-confirm-input {
      padding: 15px;
      border: 2px solid;
      border-radius: 8px;
      font-size: 1.8rem;
    }
    .read-only-field {
      opacity: 0.5;
      cursor: not-allowed;
    }
    input {
      border: none;
      width: 100%;
    }

    input:focus {
      outline-width: 0;
    }
    select {
      background-color: white;
      cursor: pointer;
      border: none;
      width: 100%;
    }

    select:focus {
      outline-width: 0;
    }
    &.invalid {
      input {
        border-color: #c04943 !important;
      }
    }
  }
}
</style>
