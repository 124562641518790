export default {
  bind(el, binding) {
    el.clickOutsideEvent = function (event) {
      const bookingWrapper = document.querySelector('#BookingList');
      const notificationWrapper = document.querySelector('#NotificationList');

      // Trigger the provided function if the click is directly on the booking-wrapper or notification-wrapper element
      if (bookingWrapper && event.target === bookingWrapper || notificationWrapper && event.target === notificationWrapper) {
        binding.value(event);
      }
    };

    el.escKeyEvent = function (event) {
      if (event.key === "Escape") {
        binding.value(event);
      }
    };

    document.addEventListener("click", el.clickOutsideEvent);
    document.addEventListener("keydown", el.escKeyEvent);
  },
  unbind(el) {
    document.removeEventListener("click", el.clickOutsideEvent);
    document.removeEventListener("keydown", el.escKeyEvent);
  },
};
