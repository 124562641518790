<template>
    <div id="NotLoggedIn">
        <div class="bg-white">
            <div id="Nav" class="d-flex container flex-row align-items-center position-relative px-2 px-lg-0">
                <div
                    class="content-container d-flex flex-row flex-wrap justify-content-between align-items-center w-100">
                    <div>
                        <img class="nav-logo ml-2 ml-sm-0" src="/img/axa-health.svg" />
                    </div>
                </div>
            </div>
        </div>
        <div class="container min-height">
            <div class="row mt-5 d-flex justify-content-center align-items-center">
                <div class="col-12">
                    <div class="login-container">
                        <div class="normal-container ">
                            <div class="row d-flex align-items-center">
                                <div class="col-12 col-md-6">
                                    <label class="dmh-confirm-field semibold"></label>
                                    <b-input class="dmh-confirm-input" aria-placeholder="Enter" v-model="email" />
                                    <label class="dmh-confirm-field semibold"></label>
                                    <b-input class="dmh-confirm-input" aria-placeholder="Enter" v-model="client_id" />

                                    <div class="button-wrapper mt-5 w-100">
                                        <b-button class="btn-height" variant="cta" size="lg" block @click="login"
                                            squared><span v-if="!loading">Button</span><b-spinner
                                                v-else></b-spinner></b-button>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer></Footer>
    </div>
</template>

<script>
// @ is an alias to /src
import axios from "axios";
import config from "../config/constants";
import Loader from "../components/Loader";
import Footer from "../components/Footer";

export default {
    name: "Login",
    components: {
        Loader,
        Footer
    },
    data() {
        return {
            email: null,
            client_id: null,
            accessScreen: false,
            termsAgreement: false,
            showWarning: true,
            loading: false,
            warning: "",
            account: null,
            f_name: '',
            l_name: '',
            access_code: ''
        };
    },
    beforeCreate() { },
    mounted() {
        this.$store.dispatch("logout");
    },
    methods: {
        openTerms() {
            window.open('/terms-and-conditions', "_blank");

        },
        toggleAccessScreen() {
            this.accessScreen = !this.accessScreen;
        },
        toggleTermsAgreement() {
            this.termsAgreement = !this.termsAgreement;
        },
        hideWarning() {
            this.showWarning = false;
        },
        async login() {
            this.loading = true;
            let payload = {
                email: this.email,
                client_id: this.client_id
            };
            let res = await axios.post(
                config.api_env + "/application/api/u/sapphire/alternative/alternative-login",
                payload,
                config.options
            );
            if (res.data.success) {
                this.$store.dispatch("loginAs", res.data);
                this.$router.push('/');
            }
            this.loading = false
        },
    },

    watch: {
        warning(newWarning) {
            if (newWarning) {
                this.showWarning = true;
            } else this.showWarning = false;
        }
    },
    provide: function () {
        return {
            processLogin: this.processLogin
        }
    },
};
</script>
<style lang="less">
#NotLoggedIn {
    display: flex;
    flex-direction: column;
    background-color: #fafafa;
    min-height: 100vh;

    .btn-height {
        min-height: 50px;
    }

    .header {
        background: white;
    }

    .page-title {
        font-size: 4rem;
    }

    .min-height {
        min-height: 70vh;
    }

    .login-container {
        .warning-container {
            padding: 30px 35px;
            background: #e5f7fa;
            border: 1px solid #00ADC6;
            border-radius: 15px;
            margin-bottom: 30px;
        }

        .normal-container {
            background: white;
            padding: 55px 45px;

            h3 {
                font-weight: normal;
                font-size: 16px;
            }
        }
    }

    .terms-container {
        border-radius: 12px;
    }

    .warning {
        position: absolute;
        height: 100vh;
        width: 100vw;
        background-color: rgba(0, 0, 0, 0.4);
        top: 0;
        left: 0;
    }

    .dropdown-toggle {
        color: inherit;
        font-family: "DMSans", sans-serif;
        letter-spacing: 0;
        background: transparent;

        &:active {
            background: transparent;
        }
    }

    .dropdown-menu {
        font-size: 1.4rem;
    }

    label {
        font-family: "DMSans", serif;
        font-size: 1.6rem;
        margin-bottom: 10px;
    }

    .dmh-confirm-input {
        padding: 15px;
        border: 1px solid #757575;
        border-radius: 8px;
        margin-bottom: 30px;
        font-size: 1.8rem;
        min-height: 50px;
    }

    .container {
        flex: 1 1 100%;

        .page-content {
            background-color: #fff;
            border-radius: 30px;
            overflow: hidden;
            min-height: 400px;
            padding: 0px !important;

            @media screen and (max-width: 768px) {
                border-radius: 0px;
            }

            .img-part {
                padding-left: 0px;

                .login-bg {
                    -webkit-background-size: cover;
                    -moz-background-size: cover;
                    -o-background-size: cover;
                    background-size: cover;
                    position: relative;
                    height: 100%;
                    width: 100%;

                    .heart-placeholder {
                        position: absolute;
                        top: 10%;
                        left: 10%;
                        height: 10%;
                        width: 10%;

                        .heart-img {
                            height: 65px;
                        }
                    }

                    .login-image {
                        height: 60%;
                        width: 80%;
                        background-image: url("/public/img/auth/_sign-in.svg");
                        background-repeat: no-repeat;
                        background-position: center;
                        background-size: contain;
                    }

                    .verify-image {
                        position: absolute;
                        top: 20%;
                        left: 20%;
                        height: 60%;
                        width: 60%;
                        background-image: url("/public/img/auth/_verify-email.svg");
                        background-repeat: no-repeat;
                        background-position: center;
                        background-size: contain;
                    }

                    .sign-up-image {
                        position: absolute;
                        top: 10%;
                        left: 10%;
                        height: 80%;
                        width: 80%;
                        background-image: url("/public/img/auth/_sign-up.svg");
                        background-repeat: no-repeat;
                        background-position: center;
                        background-size: contain;
                    }

                    .forgot-pass-image {
                        position: absolute;
                        top: 20%;
                        left: 20%;
                        height: 60%;
                        width: 60%;
                        background-image: url("/public/img/auth/_forgot-password.svg");
                        background-repeat: no-repeat;
                        background-position: center;
                        background-size: contain;
                    }
                }
            }

            .logos {
                flex-wrap: nowrap;
                justify-content: center;

                .logo {
                    flex-grow: 1;
                    justify-content: center;
                    align-items: center;

                    //height: 25px;
                    img {
                        max-height: 100%;
                        max-width: 100%;
                    }
                }
            }

            .form {
                background: #ffffff;

                .form-content {
                    padding: 25px;
                    min-height: 795px;

                    .form-logo {
                        margin: 30px auto 30px auto;

                        img {
                            width: 100%;
                        }
                    }

                    p {
                        font-size: 1.7rem;
                        font-family: "DMSans", sans-serif;
                        text-align: center;

                        &.small {
                            font-size: 1.3rem;
                        }
                    }

                    label {
                        color: #8d8e92;
                        font-size: 1.5rem;
                        margin-left: 20px;
                    }

                    .form-control {
                        background-color: #efeef2;
                        height: 50px;
                        border-radius: 25px;
                        font-size: 1.8rem;
                        font-family: "Open Sans", sans-serif;
                    }

                    .forgot-password {
                        color: #8d8e92;
                        font-size: 1.5rem;
                        text-decoration: underline;
                        margin-left: 20px;
                    }

                    .submit-form {
                        margin: 50px 0 0 0;
                        text-align: center;

                        .btn {
                            margin: 0 0 30px 0;
                            padding: 0 50px;
                            box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.3);

                            &.disabled {
                                cursor: default;
                            }
                        }
                    }

                    .new-here {
                        font-size: 1.7rem;
                        text-decoration: none;
                        text-align: center;
                        margin-bottom: 25px;
                    }

                    .invalid-feedback {
                        font-size: 1.4rem;
                        color: red;
                    }

                    .is-valid {
                        border-color: #ced4da;
                        box-shadow: none;
                    }

                    .is-invalid,
                    .is-valid {
                        background-image: none;
                    }

                    .password-wrap {
                        position: relative;

                        .eye {
                            font-size: 1.3rem;
                            position: absolute;
                            right: 20px;
                            top: 50%;
                            transform: translateY(-50%);
                            cursor: pointer;
                        }
                    }
                }
            }
        }
    }

    .dark-mode-container {
        .darkModeText {
            color: white !important;
        }
    }
}
</style>