<template>
  <div class="my-bookings content-container row">
    <div class="bookings-search col-12 pb-5">
      <b-form-input
        v-model="searchQuery"
        type="text"
        :placeholder="componentText.search"
      ></b-form-input>
    </div>
    <div class="booking-filter col-12 mb-5">
      <b-button-group>
        <b-button
          class="mx-3 px-4 py-3"
          :class="{ active: filter === 'upcoming' }"
          @click="filterSelect('upcoming')"
          >{{ componentText.upcoming }}</b-button
        >
        <b-button
          class="mx-3 px-4 py-3"
          :class="{ active: filter === 'past' }"
          @click="filterSelect('past')"
          >{{ componentText.past }}</b-button
        >
        <b-button
          class="mx-3 px-4 py-3"
          :class="{ active: filter === 'cancelled' }"
          @click="filterSelect('cancelled')"
          >{{ componentText.cancelled }}</b-button
        >
      </b-button-group>
    </div>
    <div v-if="loading" class="loading w-100">
    </div>
    <div v-else class="col-md-6 booking-results my-5 pr-5">
      <div>
        <div class="booking-results-wrap">
          <div
            v-for="(booking, i) in filteredBookings"
            :key="i"
            :class="{ active: booking.booking_id === activeItem }"
            class="booked-event row mb-3 border-brand-primary brand-secondary"
            @click="viewBooking(booking, i)"
          >
            <div class="col-2 start-date">
              <div class="start-day">
                {{ formatDate(booking.date_start, "DD") }}
              </div>
              <div class="start-month">
                {{ formatDate(booking.date_start, "MMM") }}
              </div>
            </div>
            <div class="col-8 booking-title">
              {{ booking.title }}
            </div>
            <div class="col-2">
              <div class="marker"></div>
            </div>
          </div>
          <div v-if="bookingCount > 0" class="booking-line"></div>
          <div
            class="no-events brand-secondary"
            v-else
          >
            <div class="no-events-heading before-secondary">
              {{ filter === 'upcoming' ? componentText.noBookings : filter === 'past' ? componentText.noPast : componentText.noCancelled }}
            </div>
            <a @click="$emit('return')">{{ componentText.return }}</a>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-6" v-if="!loading && selectedBooking">
      <div
        class="view-booking my-5 pl-5 pr-4 pb-5"
        :class="{ disabled: selectedBooking.time_status !== 'upcoming' }"
        v-if="bookingCount > 0"
      >
        <div class="booking-head row pt-5">
          <div class="booking-title pt-3 col-8">
            <h3>{{ selectedBooking.title }}</h3>
          </div>
          <div class="booking-date brand-secondary col-4">
            <div class="start-day">
              {{ formatDate(selectedBooking.date_start, "DD") }}
            </div>
            <div class="start-month">
              {{ formatDate(selectedBooking.date_start, "MMM") }}
            </div>
          </div>
          <div class="booking-status col-12">
            <span :class="{'booking-warning': selectedBooking.time_status === 'past' || selectedBooking.time_status === 'cancelled' }"
            >
              {{ selectedBooking.status === 'registered' &&
                  selectedBooking.time_status === 'upcoming' ? componentText.confirmed : selectedBooking.time_status === 'past' ? componentText.eventPassed : componentText.bookingCancelled }}
            </span>
          </div>
          <div class="booking-description col-12">
            <p v-html="selectedBooking.description"></p>
          </div>
          <div
            class="booking-reference col-12 mb-5"
            v-if="selectedBooking.booking_ref"
          >
            <p>Booking reference</p>
            <p>{{ selectedBooking.booking_ref }}</p>
          </div>
          <div class="booking-info col-12 py-3">
            <div class="booking-info-item-wrap my-3">
              <div class="booking-info-icon">
                <font-awesome-icon icon="clock" size="2x" />
              </div>
              <div class=" booking-info-item">
                {{ getFormatedDate(selectedBooking.date_start, "ddd DD MMM") }},
                {{ getFormatedDate(selectedBooking.time_start, "HH.mm ") }}

                -
                {{ getFormatedDate(selectedBooking.time_end, "HH.mm ") }}
                {{ componentText.oclock }}
              </div>
            </div>
            <div class="booking-info-item-wrap my-3 mb-5">
              <div class="booking-info-icon">
                <font-awesome-icon icon="map-marker-alt" size="2x" />
              </div>
              <div class=" booking-info-item">
                {{ selectedBooking.locations }}
                <div v-if="selectedBooking.e_room_info">
                  {{ selectedBooking.e_room_info }}
                </div>
              </div>
            </div>
            <div class="booking-info-item-wrap my-3">
              <div class="booking-info-icon">
                <font-awesome-icon icon="user" size="2x" />
              </div>
              <div class=" booking-info-item">
                {{ componentText.provider }}
                {{ selectedBooking.service_provider_name }}
              </div>
            </div>
            <div
              class="booking-info-item-wrap my-3"
              v-if="selectedBooking.e_employee_pay_flag"
            >
              <div class="booking-info-icon"></div>
              <div class=" booking-info-item">
                Price:
                {{
                  this.currencyString(
                    selectedBooking.e_employee_slot_cost,
                    selectedBooking.b_t_currency
                  )
                }}
              </div>
            </div>
          </div>
          <div
            class="cancel-attendance col-12 my-4"
            v-if="
              selectedBooking.status === 'registered' &&
                selectedBooking.time_status === 'upcoming'
            "
          >
            <b-button
              class="py-3 px-4 cta-button"
              @click="cancelBookingByTimeslot(selectedBooking)"
              >{{ componentText.cancel }}</b-button
            >
          </div>
        </div>
      </div>
      <div class="no-bookings" v-else>
        <img src="/img/placeholder-bookings-generic.svg" />
      </div>
    </div>
    <div v-if="bookingResult !== ''" class="event-pop-up">
      <div class="pop-up-background" @click="hidePopup"></div>
      <div class="pop-up-content row">
        <div class="booking-info col-12">
          <div v-html="bookingResult"></div>
          <b-button @click="hidePopup" class="cta-button py-3 px-5">{{
            componentText.ok
          }}</b-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// @ is an alias to /src
import axios from "axios";
import config from "../../config/constants";
import { dateFilter } from "vue-date-fns";
import moment from "moment";
export default {
  name: "MyBookings",
  components: {},
  filters: {
    date: dateFilter
  },
  data() {
    return {
      loading: true,
      searchQuery: "",
      filter: "upcoming",
      bookings: {
        items: []
      },
      selectedBooking: {},
      activeItem: null,
      bookingResult: ""
    };
  },
  mounted() {
    this.getBookings();
    if (this.$route.params.booking_id) {
      this.selectedBooking = this.$route.params;
      this.parseBookingDates([this.selectedBooking]);
      this.activeItem = this.selectedBooking.booking_id;
    }
  },
  methods: {
    filterSelect(key) {
      this.filter = key;
      this.getBookings();
    },
    async getBookings() {
      this.loading = true
      const payload = {
        user_id: this.$store.getters.user_id,
        status: this.filter,
        limit: -1
      };
      let res = await axios.post(
        config.api_env + "/application/api/u/events/get-bookings",
        payload,
        config.options
      );
      this.bookings = res.data;
      this.parseBookingDates(this.bookings.items);
      if (!this.selectedBooking) {
        this.selectedBooking = this.bookings.items[0];
        this.activeItem = this.selectedBooking.booking_id;
      }
      this.loading = false;
    },
    parseBookingDates(items) {
      let length = Object.keys(items).length;
      for (let i = 0; i < length; i++) {
        items[i].date_start = items[i].timeslot_start.split(" ")[0];
        items[i].date_end = items[i].timeslot_end.split(" ")[0];
        items[i].time_start = new Date(
          items[i].timeslot_start.replace(" ", "T")
        );
        items[i].time_end = new Date(items[i].timeslot_end.replace(" ", "T"));
      }
      this.bookings.items.sort((a, b) =>
        a.timeslot_start > b.timeslot_start ? 1 : -1
      );
    },
    viewBooking(booking, i) {
      this.selectedBooking = booking;
      this.activeItem = booking.booking_id;
    },
    async cancelBookingByTimeslot(booking) {
      if (!booking.timeslot_id) {
        return this.cancelBookingByDateID(booking.event_date_id);
      }
      const payload = {
        timeslot_id: booking.timeslot_id,
        user_id: this.$store.getters.user_id,
        all_series: false
      };
      let res = await axios.post(
        config.api_env + "/application/api/u/events/cancel-timeslot-booking",
        payload,
        config.options
      );
      if (res.data.success) {
        // let the user know
        this.bookingResult = booking.e_employee_pay_flag
          ? "<h4>" +
            this.componentText.cancellationSuccess +
            "</h4>" +
            this.componentText.cancellationPaid +
            this.currencyString(
              booking.e_employee_slot_cost,
              booking.b_t_currency
            ) +
            ".</p>"
          : "<h4>" +
            this.componentText.cancellationSuccess +
            "</h4><p>" +
            this.componentText.cancellationFree +
            "</p>";
      } else {
        this.bookingResult =
          "<h4>Oops, something went wrong. Please try again later.</h4>";
      }
    },
    async cancelBookingByDateID(booking) {
      const payload = {
        booking_date_id: booking.booking,
        user_id: this.$store.getters.user_id
      };
      let res = await axios.post(
        config.api_env + "/application/api/u/cancel-date-booking",
        payload,
        config.options
      );
      if (res.data.success) {
        // let the user know
        this.bookingResult = res.data.message;
      } else {
        this.bookingResult = this.componentText.error;
      }
    },
    hidePopup() {
      this.bookingResult = "";
      this.getBookings();
    },
    formatDate(date, format) {
      return moment(date)
        .locale(this.$store.state.lang)
        .format(format);
    }
  },
  computed: {
    filteredBookings() {
      return this.bookings.items.filter(booking => {
        return booking.title
          .toLowerCase()
          .includes(this.searchQuery.toLowerCase());
      });
    },
    bookingCount() {
      if (this.filteredBookings) {
        return Object.keys(this.filteredBookings).length;
      }
    },
    componentText() {
      return this.getText.loggedIn.events.myBookings;
    }
  },
  watch: {
    filteredBookings(newVal) {
      if (this.$route.params.booking_id && this.filter === "upcoming") {
        this.selectedBooking = this.$route.params;
        this.activeItem = this.selectedBooking.booking_id;
      } else if (newVal[0] && newVal[0].booking_id) {
        this.selectedBooking = newVal[0];
        this.activeItem = newVal[0].booking_id;
      }
    }
  }
};
</script>
<style lang="less">
#events {
  .content-container {
    position: relative;
  }
  .header-background {
    z-index: 0;
    position: absolute;
    width: 30%;
    right: 0;
    top: 50%;
    transform: translateY(-40%);
  }

  .bookings-search {
    text-align: center;
    input {
      margin: auto;
      border-radius: 25px;
      height: 50px;
      font-size: 2.4rem;
      font-family: "DMSans", sans-serif;
      padding: 0 30px 5px 30px;
      max-width: 670px;
    }
  }
  .booking-filter {
    text-align: center;
    .btn {
      font-family: "FilsonProLight", sans-serif;
      font-weight: 800;
      &.active {
        color: #fff;
        border-width: 0;
      }
    }
  }
  .loading {
    display: flex;
    flex-grow: 1;
  }
  .booking-results {
    position: relative;
    .booking-results-title {
      margin: 0 0 24px 4px;
      font-family: "FilsonProLight", sans-serif;
      font-size: 19px;
      line-height: 1;
      text-transform: uppercase;
      color: #394961;
      opacity: 0.6;
    }
    .booking-results-wrap {
      position: relative;
    }
    .see-all-button {
      background-color: transparent;
      border-width: 1px;
    }
    .booked-event {
      border-style: solid;
      border-width: 0 0 0 10px;
      border-radius: 0 10px 10px 0;
      box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.3);
      width: 100%;
      min-height: 75px;
      font-family: "FilsonProBold", sans-serif;
      display: flex;
      align-items: center;
      text-transform: uppercase;
      cursor: pointer;
      & > div {
        @media screen and (-ms-high-contrast: active),
          (-ms-high-contrast: none) {
          top: 13px;
        }
      }
      &.active {
        box-shadow: none;
        background-color: #f6f6f6;
      }
      .start-date {
        text-align: center;
        .start-day {
          font-size: 2.6rem;
          line-height: 1;
        }
        .start-month {
          font-size: 2rem;
          line-height: 1;
          font-family: "DMSans", sans-serif;
        }
      }
      .booking-title {
        font-size: 1.8rem;
      }
      .marker {
        content: "";
        position: absolute;
        width: 12px;
        height: 12px;
        border: 1px solid #7e4578;
        border-radius: 50%;
        background-color: white;
        transform: translateY(-50%);
        z-index: 1;
        right: 30px;
      }
    }
    .booking-line {
      width: 1px;
      position: absolute;
      height: 100%;
      top: 0;
      right: 50px;
      background-color: #929497;
    }
    .no-bookings {
      font-size: 2.4rem;
      font-family: "DMSans";
    }
  }
  .view-booking {
    background-color: #f6f6f6;
    border-radius: 0 0 25px 25px;
    .booking-title {
      h3 {
        padding-top: 0;
      }
    }
    .booking-date {
      text-align: center;
      .start-day {
        font-size: 6rem;
        font-family: "FilsonProBold";
        line-height: 1;
      }
      .start-month {
        font-size: 4rem;
        font-family: "FilsonProLight";
        line-height: 1;
        text-transform: uppercase;
      }
    }
    .booking-status {
      font-family: "DMSans", sans-serif;
      font-size: 2.4rem;
      color: #394961;
    }
    .booking-reference {
      font-family: "FilsonProBold", sans-serif;
      font-size: 2.2rem;
      line-height: 1;
      p {
        margin: 0;
      }
    }
    .booking-description {
      font-family: "DMSans", sans-serif;
      font-size: 1.7rem;
      line-height: 1.2;
      color: #394961;
      p {
        padding: 24px 10px 24px 0;
      }
    }
    .booking-info {
      &:before,
      &:after {
        position: absolute;
        left: 15px;
        content: "";
        width: 78px;
        height: 1px;
        background-color: #929497;
      }
      &:before {
        top: 0;
      }
      &:after {
        bottom: 0;
      }
      .booking-info-item-wrap {
        display: flex;
        align-items: flex-start;
        .booking-info-icon {
          width: 26px;
          display: inline-flex;
          justify-content: center;
          & > i {
            font-size: 22px;
            color: #7e4377;
          }
        }
        .booking-info-item {
          margin-left: 10px;
          font-family: "FilsonProBold", sans-serif;
          font-size: 17px;
          color: #394961;
          line-height: 1;
        }
      }
    }
    .cancel-attendance {
      .btn {
        font-size: 1.2rem;
        font-family: "FilsonProBold";
        border-width: 0;
        box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.3);
      }
    }
    &.disabled {
      .booking-title,
      .booking-date,
      .booking-description,
      .booking-reference,
      .booking-info {
        opacity: 0.3;
      }
      .booking-warning {
        opacity: 1;
        z-index: 10;
      }
    }
  }
  .event-pop-up {
    display: flex;
    align-items: center;
    z-index: 500;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    text-align: center;
    .pop-up-content {
      background: #fff;
      max-width: 400px;
      width: 100%;
      margin: auto;
      border-radius: 25px;
      padding: 30px 20px;
      font-size: 4rem;
      text-align: center;
      z-index: 10;
      .booking-info {
        h4 {
          font-size: 3rem;
        }
        p {
          font-size: 2rem;
        }
      }
    }
    .pop-up-background {
      width: 100vw;
      height: 100vh;
      position: absolute;
      left: 0;
      right: 0;
      z-index: 5;
      background: transparent;
    }
  }
  .fc-state-default {
    border-width: 0;
    background: transparent;
    color: #00067b;
    font-size: 2.5rem;
    box-shadow: none;
    &:focus {
      outline: none;
    }
  }
  .no-bookings {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    img {
      max-width: 300px;
      margin-left: auto;
    }
  }
}
</style>
