<template>
    <div id="view-event">
        <div class="row" v-if="loading">
            <b-spinner class="mx-auto my-5"></b-spinner>
        </div>
        <div v-else>
                <div class="content-container row">
                    <div class="col-md-8">
                        <div class="pt-5">
                            <h1 class="title">{{ event.e_title }}</h1>
                            <div>
                              <p class="title-tag">{{event.e_is_virtual_event === 1 ? 'Online' : 'Onsite'}}</p>
                            </div>
                            <div v-if="event.embed_code" v-html="decodedEmbed"></div>
                            <img class="img-fluid mb-5"
                             :src="event.e_featured_img" v-else-if="event.e_featured_img">
                            <div class="event-description" v-html="event.e_description"></div>
                            <EventInfo :event="event"></EventInfo>
                        </div>
                    </div>
                    <div v-if="events && events.length" class="col-md-4 pt-5">
                        <h3 class="subtitle mb-5">Available Events</h3>
                        <div class="corporate-event-card shadow mb-4 clickable" @click="refresh(e.events[0].event_date_id)"
                            v-for="(e, i) in events">
                            <div class="d-flex flex-row align-items-stretch">
                                <div
                                    class="date-container white-text bg-brand-highlight-1 d-flex flex-column align-items-center justify-content-center">
                                    <div class="month">{{ getFormatedDate(e.date, 'dddd') }}</div>
                                    <div class="day">{{ getFormatedDate(e.date, 'DD') }}</div>
                                    <div class="month">{{ getFormatedDate(e.date, 'MMM') }}</div>
                                </div>
                                <div class="d-flex w-100 justify-content-between">
                                  <div class="details-container px-3 py-4">
                                      <h4 class="details-title mb-0">{{ e.events[0].title }}</h4>
                                      <span class="tag">{{ e.events[0].is_virtual_event ? 'Online' : 'Onsite' }}</span>
                                  </div>
                                  <div class="time-container">
                                      <div class="date">{{ getFormatedDate(e.date, 'DD/MM/YY') }}</div>
                                      <div class="time">{{ getFormatedDate(e.events[0].start_date, 'HH.mm') }} -
                                          {{ getFormatedDate(e.events[0].end_date, 'HH.mm') }}</div>
                                  </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12">
                        <LargeBookingButton :event="event"></LargeBookingButton>
                    </div>
                    <div class="col-12">
                        <b-button variant="cta-outline" class="px-5" squared
                            @click="navigate('/')">Back</b-button>
                    </div>
                </div>


        </div>
    </div>
</template>

<script>
// @ is an alias to /src
import axios from "axios";
import config from "../config/constants";
import LargeBookingButton from "../components/Event/LargeBookingButton";
import EventInfo from "../components/Event/EventInfo";
export default {
    name: "viewEvent",
    components: {
        LargeBookingButton,
        EventInfo
    },
    data() {
        return {
            event: {},
            events: [],
            loading: true
        };
    },
    mounted() {
        this.getEventDate();
        if (this.$route.query.session_id) {
            if (!this.$route.query.session_id.includes('cancelled')) {
                this.showMsg();
            }
            //delete the session ID query, in case user goes in and out of stripe
            let query = Object.assign({}, this.$route.query);
            delete query.session_id;
            this.$router.replace({ query });
        }
        this.getEvents();
    },
    methods: {
        async showMsg() {
            if (await this.showMsgModal('Booking Successful')) {
                this.$router.push({ name: 'events', query: { page: 'bookings' }, params: { booking_id: this.event.e_id } })
            }
        },
        async getEvents() {
            const today = this.$date(new Date(), "YYYY-MM-DD");
            const payload = {
                user_id: this.$store.getters.user_id,
                from_date: today,
                days_limit: 60,
            };
            let res = await axios.post(
                config.api_env + "/application/api/u/events/get-events",
                payload,
                config.options
            );
            if (res.data.err && res.data.err.includes("3994")) {
                this.$store.dispatch("logout");
                this.$router.push("/login");
            }
            this.events = [...res.data.data].slice(0, 4);
            this.loading = false
        },
        getEventDate() {
            const url = config.api_env + "/application/api/u/events/get-date";
            const payload = {
                user_id: this.$store.getters.user_id,
                event_date_id: this.$route.params.id
            };
            const opts = {
                'method': 'post',
                'url': url,
                'data': payload
            }
            const _this = this;
            this.axios(opts)
                .then(function (res) {
                    _this.event = res.data.event_date;
                    _this.loading = false
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        refresh(id) {
            this.loading = true;
            this.$router.push('/events/view-event/' + id);
            this.getEventDate();
        },
    },
    computed: {
        decodedEmbed() {
            return this.event.embed_code ? atob(this.event.embed_code) : null;
        },
        componentText() {
            return this.getText.loggedIn.event;
        },
    }
};
</script>
<style lang="less">
#view-event {
  background-color: #FAFAFA;
  .title {
    color: #00008F;
    font-size: 3.2rem;
    margin-bottom: 8px;
  }
  .subtitle {
    color: #36383A;
    font-size: 2.8rem;
  }
  .details-title {
    color: #36383A;
    font-size: 1.6rem;
  }
    .title-tag {
        display: inline-block;
        background-color: #1F1F9C;
        padding: 8px;
        border-radius: 40px;
        font-size: 1.6rem;
        color: #FFFFFF;
        margin-bottom: 32px;
    }
    .corporate-event-card {
        border-radius: 8px;
        overflow: hidden;

        .date-container {
          max-width: 104px;
            flex-grow: 1;
            min-height: 100%;
            padding: 10px 20px;
            width: 100%;

            .day {
                font-size: 4rem;
                font-weight: bold;
            }

            .month {
                font-size: 1.6rem;
            }
        }

        .details-container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: start;
            padding: 20px 10px;
        }

        .time-container {
            align-self: center;
            font-size: 1.2rem;
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            padding-right: 10px;

            .date {
                text-wrap: nowrap;
                font-size: 1.4rem;
                margin-bottom: 8px;
            }

            .time {
              font-size: 1.4rem;
                text-wrap: nowrap;
                font-weight: bold;
            }
        }

        .tag {
            margin-top: 10px;
            background-color: #E5F7F9;
            padding: 8px;
            border-radius: 40px;
            font-size: 1.4rem;
            color: #343C3D;
        }
    }

    .event-description {
        font-size: 2rem;
        color: #343C3D;
        margin-bottom: 24px;
    }

    .white-text {
        color: white !important
    }

    .img-fluid {
        border-radius: 16px;
    }

    .page-header {
        padding: 60px 0;
    }

    .page-title {
        text-align: left;

        h2 {
            text-align: left;
            font-size: 3.8rem;
            letter-spacing: 0.7rem;
            font-family: "FilsonProBold", sans-serif;
            color: #fff;
        }

        .return {
            background-color: transparent;
            position: absolute;
            bottom: -70px;
            left: 10px;

            svg {
                width: 35px;

                .cls-1 {
                    fill: #ea3975;
                }

                .cls-2 {
                    fill: #fff;
                }
            }

            &:hover {
                .cls-1 {
                    fill: darken(#ea3975, 10%);
                }
            }
        }
    }

    .content-container {
        padding: 20px;
    }

    .event-card {
        position: relative;
        border-left: 1px solid #ccc;
        border-top: 1px solid #ccc;
        border-bottom: 1px solid #ccc;
        margin-right: 50px;
        background-color: #f4f4f4;
        padding: 45px 0 55px 30px;
        box-shadow: 6px -2px 8px 0 rgba(0, 0, 0, 0.25);

        &:before {
            content: "";
            position: absolute;
            top: -1px;
            right: 0;
            height: 60px;
            width: 50px;
            display: inline-block;
            border-right: 1px solid #ccc;
            border-top: 1px solid #ccc;
            box-shadow: 9px 0 5px -4px rgba(0, 0, 0, 0.25);
            transform: skew(40deg) translateX(50%);
            background-color: #f4f4f4;
            z-index: 1;
        }

        &:after {
            content: "";
            position: absolute;
            right: 0;
            bottom: -1px;
            height: calc(100% - 58px);
            width: 50px;
            border-right: 1px solid #ccc;
            border-bottom: 1px solid #ccc;
            background-color: #f4f4f4;
            transform: translateX(100%);
            box-shadow: 4px -4px 5px 0 rgba(0, 0, 0, 0.25);
        }

        h3 {
            font-size: 3.1rem;
            font-family: FilsonProBold;
            width: 70%;
            text-transform: capitalize;
            margin-bottom: 25px;
            letter-spacing: 0;
            padding-top: 0;
        }
    }

    .waiting-list {
        font-size: 1.7rem;
        color: #394961;
    }

    .event-info {
        font-size: 1.6rem;

        .past-event {
            background-color: #989898;
            margin: 0 -38px;
            padding-left: 25px;
            padding-right: 23px;

            &:first-child {
                margin: -38px -38px 0 -38px;
                border-radius: 20px 20px 0 0;
            }

            .disabled {
                &:hover {
                    background-color: #fff !important;
                }
            }
        }

        .event-info-item-wrap {
            font-size: 1.8rem;
            display: flex;
            align-items: center;

            .event-info-icon {
                width: 26px;
                display: inline-flex;
                justify-content: center;

                &>i {
                    font-size: 22px;
                }
            }

            .event-info-item {
              color: #343C3D;
                margin-left: 16px;
            }
        }
    }


    .event-register {
        font-family: "DMSans", sans-serif;
        font-size: 1.6rem;

        a {
            color: #212529;
            text-decoration: underline;
        }
    }

    .no-slots {
        font-size: 1.7rem;
        border: 1px solid #CED1D2;
        border-radius: 20px;
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;

        h4 {
            color: red;
            margin-bottom: 0;
        }
    }

    .event-pop-up {
        display: flex;
        align-items: center;
        z-index: 500;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        text-align: center;

        .pop-up-content {
            background: #fff;
            max-width: 400px;
            width: 100%;
            margin: auto;
            border-radius: 25px;
            padding: 30px 20px;
            font-size: 4rem;
            text-align: center;
            z-index: 10;

            .event-details {
                border-top-width: 1px !important;
                border-color: #dee2e6;
                position: relative;

                h4,
                a {
                    font-size: 2.5rem;
                    font-family: 'FilsonProBold';
                    line-height: 0;
                    white-space: nowrap;
                }

                .event-title {
                    height: 40px;
                    text-align: left;
                    position: relative;
                    transform: translateY(-50%);
                }

                .event-info {
                    font-size: 1.2rem;
                    margin: auto;
                }
            }
        }

        .pop-up-background {
            width: 100vw;
            height: 100vh;
            position: absolute;
            left: 0;
            right: 0;
            z-index: 5;
            background: transparent;
        }
    }

    .btn.btn-cta-outline {      
      padding: 12px 20px !important;
      font-size: 1.6rem;
    }
}
</style>
