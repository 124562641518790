<template>
  <div id="dmhFaqs">
    <div class="back-container mx-auto">
      <span class="mr-auto" @click="back()"><i class="fa-solid fa-chevron-left fa-3x brand-text clickable"></i></span>
    </div>
    <div class="faq-header content-container">
      <h2 class="faq-heading brand-text">Got a question for us?</h2>
        <div id="input-container">
          <input id="input" v-model="search" placeholder="Type something..." type="text">
          <i v-if="!search" class="fa-solid fa-magnifying-glass brand-text"></i>
          <i v-else class="fa-solid fa-xmark brand-text clickable pl-1" @click="resetSearch()"></i>
        </div>
    </div>
    <div class="content-container d-flex flex-column justify-content-center align-items-center">
      <div class="faq-no-content-message" v-if="content.length === 0">
        <p v-if="debouncedSearchQuery.length === 0">Please enter a search query.</p>
        <p v-else>Sorry, no results for that search query.</p>
      </div>
      <div v-for="(item, i) in content" :id="'acc' + item.id" :key="'acc' + item.id" class="w-100" v-else>
        <DmhFaqItem class="w-100" :item="item" :type="'faq'"></DmhFaqItem>
      </div>  
    </div>
  </div>
</template>

<script>
import axios from "axios";
import config from "../config/constants";
import { debounce } from "../helpers/debounce"
import DmhFaqItem from "../components/DMH/DmhFaqItem.vue";
import EventBus from "@/helpers/eventbus"

export default {
  name: "faqs",
  data() {
    return {
      content: [],
      search: '',
      debouncedSearchQuery: '',
    }
  },
  components: {
    DmhFaqItem,
},
  watch: {
    search: debounce(function (newQuery) {
      this.debouncedSearchQuery = newQuery;
    }, 500),
    debouncedSearchQuery() {
      this.getFaqs();
    },
  },
  computed: {

  },
  methods: {
    back(){
      history.back();
    },
    async getFaqs() {
      const payload = {
        user_id: this.$store.getters.user_id,
        search_string: this.debouncedSearchQuery,
      }
      // if (this.debouncedSearchQuery.length > 0) {
      //   payload.search_string = this.debouncedSearchQuery;
      // }
      let res = await axios.post(config.api_env + '/application/api/u/dmh/get-faqs', payload, config.options)
      if (res.data.success) {
        this.content = res.data.data
      } else if (res.data.error ) {
          this.content = [];
      } else if (res.data.err && res.data.err.includes("3994")) {
        this.$store.dispatch("logout");
        this.$router.push("/login").catch((err) => { });
      }
    },
    async getArticles() {
      const payload = {
        user_id: this.$store.getters.user_id,
        from: 0,
        size: 6,
        search_string: this.debouncedSearchQuery,
      }
      let res = await axios.post(config.api_env + '/application/api/u/dmh/articles-search', payload, config.options)
      if (res.data.success) {
        this.content = res.data.data
      } else if (res.data.err && res.data.err.includes("3994")) {
        this.$store.dispatch("logout");
        this.$router.push("/login").catch((err) => { });
      }
    },
    resetSearch() {
      this.search = '';
    },
  },
  mounted() {
    EventBus.$emit('updateNav', 'faq');
  },

}
</script>

<style lang="less">
#dmhFaqs {
  background-color: #fbfafa;
  .faq-no-content-message{
    font-size: 1.8rem;
    p{
      color: lightgrey !important;
    }
  }
  .content-container{
    max-width: 600px;
  }
  .back-container{
    padding-top: 60px;
    max-width: 900px;
  }


  .not-collapsed .fa-circle-plus {
    display: none !important;
  }

  .collapsed .fa-circle-minus {
    display: none !important;
  }

  .accordion {
    max-width: 600px !important;
    margin: auto !important;
    margin-top: 20px !important;
    margin-bottom: 20px !important;

    .collapsed:hover {
      background-color: darken(#f7f7f7, 10%) !important;
    }

    .collapsed {
      background-color: #f7f7f7 !important;
      color: #403d56 !important;
    }

    button {
      background-color: #403d56 !important;
      border-radius: 0 !important;
      color: white;
      text-align: left !important;
      padding: 18px;
      display: flex;
      justify-content: space-between;
      font-family: "FilsonProBold", sans-serif !important;
      font-size: 1.4rem;
    }


    .card,
    .card-body,
    .card-header {
      border: none !important;
      background-color: white !important;

    }

    .card-header {
      padding: 0 !important;
    }

    .card {
      border: 1px lightgrey solid !important;
      margin-bottom: 10px !important;
    }
  }

  .category-container {
    padding: 20px;
  }

  .question-container {
    margin-bottom: 30px;

    .question {
      font-family: "FilsonProBold", sans-serif;
      font-size: 1.4rem;
      margin-bottom: 10px;
      color: #403d56;
    }

    .greyQuestion {
      color: darken(#f7f7f7, 20%) !important;
    }

    .greyQuestion * {
      color: darken(#f7f7f7, 20%) !important;
    }

    .answer {
      color: #403d56;
      font-size: 1.3rem;
    }

    img {
      margin-top: 8px;
      margin-bottom: 8px;
    }
  }

  .faq-header {
    padding: 0 60px 60px 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    #input-container {
      position: relative;
      padding: 0;
      margin: 0;
      display: flex;
      justify-content: center;
      align-content: center;
      width: 100%;

      input {
        width: 100%;
        height: 80px;
        margin: 0;
        padding: 30px;
        padding-left: 60px;
        font-size: 1.6rem;
        background-color: #E0E5F5;
        border-radius: 12px;
        border: none;
      }

      input:focus {
        outline: none;
      }

      i {
        position: absolute;
        left: 20px;
        bottom: 50%;
        width: 10px;
        height: 10px;
        font-size: 2rem;
      }
      i.fa-xmark{
        font-size: 2.2rem;
      }
    }

    .faq-heading {
      text-align: center;
      font-size: 3.8rem;
      letter-spacing: 0;
      margin-bottom: 60px !important;
      font-family: "FSElliotBold", sans-serif;
    }
  }

  .faq-footer {

    background-color: #f7f7f7;
    padding: 50px;

    .footer-question {
      color: #394961;
      font-family: "FilsonProBold", sans-serif;
      text-align: center;
      margin-bottom: 10px;
    }

    .footer-paragraph {
      color: #394961;
      font-size: 1.5rem;
      text-align: center;

      .support-email {
        font-family: "FilsonProBold", sans-serif;
      }
    }
  }
}</style>