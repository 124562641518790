<template>
  <div id="ConversationWrapper">
    <div v-if="loading" class="text-center">
      <Loader />
    </div>
    <div v-else>
      <div class="row">
        <div class="conversation-wrapper-container d-flex flex-column mx-3 shadow">
          <div class="conversation-wrapper-header bg-brand-highlight-1">
            <h2 class="mb-0 pb-0">Chat with your coach {{coachName}} </h2>
          </div>
          <div
            id="messages"
            class="conversation-messages"
            v-on:mousemove="$emit('update-read-status', $event)"
          >
            <div v-if="chat_loading" class="text-center">
              <Loader />
            </div>
            <div class="message-scroll" v-else>
              <div
                v-for="message in messages"
                class="outer_message"
                :key="message.state.index"
              >
                <ChatMessage
                  :message="message"
                  :is_chat_bot_conversation="is_chat_bot_conversation"
                  :author="chat_clinician_title"
                  :total-messages="messages.length"
                  :getUsernameBySha1Identity="getUsernameBySha1Identity"
                />
              </div>
            </div>
          </div>
          <div class="new-message-container mt-auto">
            <form name="messageForm" id="messageForm" @submit="sendMessage">
              <div class="d-flex flex-row align-items-center">
                <b-button
                  class="cta-button file-upload-button"
                  @click="clickFileInput()"
                  :disabled="discharged"
                  >Attach</b-button
                >
                <div class="ml-3" v-if="file !== ''">
                  <b>File Selected</b>: {{ file.name }}
                </div>
                <div class="typing-status ml-auto">
                  <span v-if="typing_participant"
                    >
                    <span>{{getUsernameBySha1Identity(typing_participant.identity)}} is typing</span>
                    <b-spinner class="brand-primary"></b-spinner>
                  </span>
                  <span v-if="warningMessage"
                  >
                    <span class="warning-message">{{warningMessage}}</span>
                  </span>
                </div>
              </div>
              <div class="row mx-0">
                <div
                  class="
                    message-input-container
                    col-12
                    d-flex
                    flex-row flex-nowrap
                    px-0
                  "
                >
                  <textarea
                    v-model="new_message"
                    placeholder="Enter message here.."
                    class="message-input"
                    size="sm"
                    :disabled="discharged"
                    @keydown="
                      (e) => {
                        conversation.typing();
                        var code = e.keyCode ? e.keyCode : e.which;
                        if (code == 13 && !e.shiftKey) {
                          e.preventDefault();

                          submitForm(e);
                        }
                      }
                    "
                  />
                  <b-button
                    class="message-input-button"
                    id="sendButton"
                    type="submit"
                    :disabled="discharged"
                    ><i class="fas fa-paper-plane fa-2x brand-highlight-1"></i
                  ></b-button>
                </div>
              </div>
              <div class="send-file-form">
                <input
                  type="file"
                  id="fileInput"
                  ref="file"
                  style="display: none"
                  v-on:change="handleFileUpload()"
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import config from "../../config/constants";
import { Client } from "@twilio/conversations";
import ChatMessage from "./ChatMessage";
import DischargedCoacheeModal from "./DischargedCoacheeModal.vue";
import Loader from "../Loader";


export default {
  name: "ConversationWrapper",
  components: { ChatMessage, DischargedCoacheeModal, Loader },
  props: ['type'],
  data() {
    return {
      loading: true,
      chat_loading: true,
      is_chat_bot_conversation: false,
      token: false,
      conversation: null,
      discharged: false,
      c_id: false,
      messages: [],
      coachName: 'Coach',
      friendly_name: "",
      friendly_name_spaceless: "",
      new_message: "",
      user: {},
      chat_clinician_title: "",
      conversation_sid: null,
      typing_participant: null,
      file: "",
      remote_participant_last_read_message_index: null,
      warningMessage: false,
      participants_mapping: [],
    };
  },
  watch: {
    remote_participant_last_read_message_index(new_index) {
      localStorage[this.friendly_name_spaceless] = new_index;
    },
  },
  mounted() {
    this.joinConversation();
    this.$on("send-message-via-btn", async (event) => {
      this.new_message = event.target.outerText;
      await this.sendMessage(event);
    });
    this.$on("update-read-status", async () => await this.markMessageAsRead());
    this.$on("participant-updated", (participant) => {
      if (this.isRemoteParticipant(participant)) {
        this.remote_participant_last_read_message_index =
          participant.lastReadMessageIndex;
        const messages = this.messages.concat();
        this.setMessages(messages);
      }
    });
    this.$on("typing-started", (participant, conversation) => {
      this.typing_participant = participant;
    });
    this.$on("typing-ended", (participant, conversation) => {
      this.typing_participant = null;
    });
  },
  beforeDestroy() {
    this.leaveConversation();
  },
  methods: {
    setMessages(messages) {
      this.messages = messages.map((message) => {
        return {
          ...message,
          is_marked: this.messageCanBeMarkedAsRead(message),
        };
      });
    },
    getUsernameBySha1Identity(sha1Identity){
      const filtered = this.participants_mapping.filter(item => item.identity === sha1Identity)
      return filtered.length === 1 &&
        typeof filtered[0].email !== "undefined" ? filtered[0].email : sha1Identity;
    },
    getSha1IdentityByUsername(email){
      const filtered = this.participants_mapping.filter(item => item.email === email);
      return filtered.length === 1 &&
        typeof filtered[0].identity !== "undefined" ? filtered[0].identity : email;
    },
    isRemoteParticipant(participant) {
      return participant.identity !== this.getSha1IdentityByUsername(this.$store.getters.user.username);
    },
    isRemoteParticipantMessage(message) {
      return message.state.author !== this.getSha1IdentityByUsername(this.$store.getters.user.username);
    },
    isMessageIndexCorrectToBeMarked(message) {
      return (
        message.state.index <= this.remote_participant_last_read_message_index
      );
    },
    hasRemoteParticipantReadAnyMessages() {
      return this.remote_participant_last_read_message_index !== null;
    },
    messageCanBeMarkedAsRead(message) {
      return (
        this.hasRemoteParticipantReadAnyMessages() &&
        this.isMessageIndexCorrectToBeMarked(message) &&
        !this.isRemoteParticipantMessage(message)
      );
    },
    getIsMarkedProperty(index) {
      const message = this.messages.filter(
        (message) => message.state.index === index
      );
      if (message.length) {
        return message[0].is_marked;
      }
      return false;
    },
    async submitFile() {
      try {
        const formData = new FormData();
        formData.append("UploadFileModel[files]", this.file);
        formData.append("conversation_sid", this.conversation.sid);
        formData.append("token", this.$store.getters.user_id);
        const request = new XMLHttpRequest();
        request.open(
          "POST",
          `${config.api_env}/application/uploader/file/upload`,
          false
        );
        request.onload = () => {
          if (request.status >= 200 && request.status < 300) {
            let response = null
            let message = null
            if (request.responseText) {
              response = JSON.parse(request.responseText);
            }
            if (response.message) {
              message = JSON.parse(response.message);
            }
            if (message && message.files && message.files[0].includes("Max file size")) {
              this.warningMessage = "Max file size exceeded"; 
            }
          }
        };
        request.send(formData);
      } catch(err) {
        this.warningMessage = "Something went wrong"
      } finally {
        setTimeout(()=> {
          this.warningMessage = false;
        }, 3000);
        this.file = ''
      }
    },
    handleFileUpload() {
      this.file = this.$refs.file.files[0];
    },
    clickFileInput() {
      document.getElementById("fileInput").click();
    },
    submitForm() {
      document.getElementById("sendButton").click();
    },
    async joinConversation() {
      const payload = {
        user_id: this.$store.getters.user_id,
        type: this.type
      };
      let res = await axios.post(
        config.api_env + "/application/api/u/chat/join",
        payload,
        config.options
      );
      if (res.data.success === true) {
        this.token = res.data.token;
        this.coachName = res.data.user_coach_name;
        this.participants_mapping = res.data.participants_mapping;
        this.friendly_name = res.data.friendly_name;
        this.friendly_name_spaceless = res.data.friendly_name.replace(
          /\s+/g,
          ""
        );
        this.user = { ...res.data.user };
        this.discharged = res.data.status === "discharged" ? true : false;
        if (this.discharged) {
          const content = this.$createElement(DischargedCoacheeModal)
          const skin = this.$store.getters.skin;
          let opts = {
            title: "My Coaching Session",
            hideHeader: false,
            hideFooter: true,
            modalClass: "coach_modal theme-" + skin,
            headerClass: "coach_modal_header bg-brand-secondary d-flex justify-content-center",
            size: "lg",
            buttonSize: "sm",
            centered: true,
            contentClass: "coach_modal",
            id: 'discharged_coachee_modal'
          }
          this.$bvModal.msgBoxOk(content, opts);
        }
        this.chat_clinician_title = res.data.chat_user_title;
        this.conversation_sid = res.data.conversation_sid;
        //set message read index before load messages
        if (typeof localStorage[this.friendly_name_spaceless] !== "undefined") {
          this.remote_participant_last_read_message_index =
            localStorage[this.friendly_name_spaceless];
        }
        this.initializeConversation();
        if (res.data.init_conversation) {
          // this.is_chat_bot_conversation = true;
        }
        this.loading = false;
      } else {
        console.log(res);
      }
    },
    async leaveConversation() {
      const payload = {
        user_id: this.$store.getters.user_id,
        conversation_id: this.conversation_id,
      };
      let res = await axios.post(
        config.api_env + "/application/api/u/chat/leave-conversation",
        payload,
        config.options
      );

      if (res.data.success == true) {
        console.log("conversation left");
      } else {
        console.log(res);
      }
    },
    async markMessageAsRead() {
      if (this.conversation) {
        await this.conversation.setAllMessagesRead();
      }
    },
    async initializeConversation() {
      const client = new Client(this.token);

      client.on("conversationJoined", async (conversation) => {
        window.conv = conversation;
        this.conversation = await client.getConversationBySid(
          this.conversation_sid
        );
        //read-horizon
        this.conversation.on("participantUpdated", (event) => {
          this.$emit("participant-updated", event.participant);
        });
        this.loadMessages();
        this.conversation.on("messageAdded", async (message) => {
          this.messages.push(message);
          const messages = this.messages.concat();
          this.setMessages(messages);
          this.bottomScroll();
        });
        //typing status
        this.conversation.on("typingEnded", (participant) => {
          this.$emit("typing-ended", participant, conversation);
        });
        this.conversation.on("typingStarted", (participant) => {
          this.$emit("typing-started", participant, conversation);
        });
      });

      client.on("conversationLeft", (conversation) => {
        this.conversation = conversation;
        console.log("left conversation Client", conversation);
      });
    },
    async sendMessage(event) {
      /**
       * It makes sure user isn't able to send message when
       * discharged
       */
      if (this.discharged === true) {
        return;
      }
      event.preventDefault();
      if (this.file !== "") {
        if(this.file?.type.includes('video')) {
          this.warningMessage = "You cannot submit a video file.";
          this.file = "";
        } else if (this.file.size > 5242880) { // 5MB
          this.warningMessage = "Max file size exceeded"
        } else {
          await this.submitFile();
        }
        setTimeout(()=> {
          this.warningMessage = false;
        }, 3000);
        this.file = "";
        this.touchMessage();
      }
      if (this.new_message !== "") {
        const message = this.new_message;
        this.new_message = "";
        await this.conversation.sendMessage(message);
        this.touchMessage();
      }
    },
    async flagMessage(event) {
      event.preventDefault();
      const message = this.new_message;
      this.new_message = "";
      await this.conversation.update(message);
      this.loadMessages();
    },
    async touchMessage() {
      const payload = {
        user_id: this.$store.getters.user_id,
        conversation_sid: this.conversation_sid,
      };
      let res = await axios.post(
        config.api_env + "/application/api/u/chat/touch-conversation",
        payload,
        config.options
      );

      if (res.data.success == true) {
        console.log("conversation touched");
      } else {
        console.log(res);
      }
    },
    async loadMessages() {
      let self = this;
      this.conversation
        .getMessages()
        .then((messagePaginator) => {
          let receivedMessages = messagePaginator.items.filter(function (item) {
            return item.state.author !== self.chat_clinician_title;
          });
          const lastReceivedMessage =
            receivedMessages[receivedMessages.length - 1];
          if (
            lastReceivedMessage !== undefined &&
            lastReceivedMessage.state.attributes.options !== undefined
          ) {
            // this.is_chat_bot_conversation = true;
          }

          this.setMessages(messagePaginator.items);
          this.chat_loading = false;
          this.bottomScroll();
        })
        .catch((err) => {
          console.error("Couldn't fetch messages IMPLEMENT RETRY", err);
          this.$store.commit("setAlert", {
            visible: true,
            text: "Conversation not loaded ..",
            color: "success",
          });
        });
    },
    bottomScroll() {
      setTimeout(function () {
        const element = document.getElementById("messages");
        if (element) {
          element.scrollTo({
            top: element.scrollHeight,
            behavior: "smooth",
          });
        }
      }, 100);
    },
  },
};
</script>
<style lang="less">
#ConversationWrapper {
  width: 100%;
  .warning-message {
    font-size: 1.3em;
    color: red;
  }
  .typing-status {
    text-align: right;
    font-size: 12px;
    line-height: normal;
    position: relative;
    padding: 10px 0;
    span {
      display: flex;
      align-items: center;
      position: relative;
      .spinner-border {
        margin-left: 3px;
        height: 12px;
        width: 12px;
      }
    }
  }
  .conversation-wrapper-container {
    height: 600px;
    
    width: 100%;
    margin-top: 40px;
    background-color: #f3f3f3;
    border-radius: 30px 30px 0px 0px;
    overflow: hidden;
    .conversation-wrapper-header {
      color: white;
      text-align: center;
      font-size: 16px;
      line-height: normal;
      position: relative;
      padding: 20px;
    }
    .conversation-messages {
      padding: 0 10px;
      flex-grow: 1 1 auto;
      white-space: pre-line;
      overflow-y: auto;
      &::-webkit-scrollbar {
        width: 20px;
        height: 20px;
      }
      &::-webkit-scrollbar-thumb {
        background: #ebebeb;
        border: 5px solid #f3f3f3;
        border-radius: 10px;
      }
      &::-webkit-scrollbar-thumb:hover {
        background: #dedede;
      }
      &::-webkit-scrollbar-track {
        background: #f3f3f3;
        border-radius: 0px 16px 16px 0px;
      }
      .message {
        .ui_icons {
          overflow: visible;
        }
      }
      .outer_message {
        margin-top: 10px;
        text-align: left;
        background-color: inherit;
        .message {
          background-color: inherit;
          .text_message {
            background-color: inherit;
            label {
              background-color: inherit;
            }
          }
        }
      }
      .outer_message:first-child {
        padding-top: 0px;
      }
    }
    .new-message-container {
      
      padding: 0 10px 10px 10px;
      .file-upload-button {
        min-height: 40px;
        min-width: 40px;
        border-radius: 4px;
      }
      .message-input-container {
        flex-grow: 1;
        margin-right: 10px;

        .message-input {
          background-color: #e5e5e5;
          padding: 10px;
          width: 100%;
          height: 60px;
          font-size: 1.4rem;
          outline: none;
          border: none;
          border-right: 1px solid grey;
          resize: none;
        }
        .message-input-button {
          background-color: #e5e5e5;
          border-radius: 0;
          width: 60px;
        }
      }
      .message-send-btn {
        border-radius: 8px !important;
        max-width: 200px;
      }
    }
  }
}
</style>
