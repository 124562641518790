<template>
  <div id="DmhBookingCard">
    <div
      class="booking_inner"
      :class="isNewStyling ? 'booking-inner-row' : 'booking-inner-column'"
    >
      <div class="booking-inner-top">
        <div class="d-flex flex-row align-items-start justify-content-between">
          <div class="d-flex flex-row align-items-start justify-content-start">
            <div v-if="booking.clinician.image">
              <img class="image" :src="booking.clinician.image" alt="" />
            </div>
            <div class="mr-3">
              <p class="coach pb-1">
                {{ getTitle(booking.appointmentType) }} Appointment
              </p>
              <p v-if="booking.clinician.name" class="day pb-1 brand-label">
                {{ booking.clinician.name }}
              </p>
              <p class="hour">
                {{ getHoursMinutes(booking.startTime) }} -
                {{ getHoursMinutes(booking.endTime) }}
              </p>
            </div>
          </div>
          <div class="d-flex flex-column align-items-center">
            <p class="date">{{ formatDate(booking.startTime)[1] }}</p>
            <p class="month">{{ formatDate(booking.startTime)[0] }}</p>
          </div>
        </div>
      </div>
      <div
        v-if="isUpcomingBooking"
        class="btn-wrapper"
        :class="isNewStyling ? 'btn-wrapper-contain' : 'btn-wrapper-full'"
      >
        <b-button
          v-if="shouldShowLink || true"
          @click="handleClick(booking, serviceProvider)"
          class="btn-join"
          :class="{'w-100': !isCancelButtonShown}"
        >
          <span
            v-if="isLocationShown"
          >
            <span>Location</span>
          </span>
          <span v-else>Join</span>
        </b-button>
        <b-button
          v-if="isCancelButtonShown"
          @click="handleCancelClick(booking)"
          class="btn-cancel"
        >
          Cancel
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import MeetingRoomModal from "./DMH/Booking/MeetingRoomModal.vue";
import EarlyModal from "./DMH/Booking/EarlyModal.vue";
import CancelModal from "./DMH/Booking/CancelModal.vue";
import F2FDetailsModal from "./DMH/Booking/F2FDetailsModal.vue";

export default {
  name: "DigitalClinicsBookingCard",
  props: {
    booking: Object,
    serviceProvider: Object,
    isEarliest: Boolean,
    isUpcomingBooking: Boolean,
    isNewStyling: Boolean
  },
  data() {
    return {
      shouldShowLink: true
    };
  },
  computed: {
    isCancelButtonShown() {
      return !this.hasBookingStarted(this.booking.startTime);
    },
    corporateEventTitle() {
      return this.booking?.details?.event_title || ''
    },
    isLocationShown() {
      return this.booking.appointmentType === 'face_to_face' 
      || this.booking?.details?.locations.length && !this.booking?.details?.is_virtual
    }
  },
  mounted() {
    let self = this;
    let now = moment();
    let bookingStart = moment(this.booking.startTime);
    if (this.isEarliest) {
      if (now.isSameOrAfter(bookingStart.subtract(10, "minute"))) {
        this.shouldShowLink = true;
      } else {
        let offset = bookingStart.diff(now);
        if (offset < 2147483647) {
          setTimeout(() => {
            self.shouldShowLink = true;
          }, offset);
        }
      }
    }
  },
  methods: {
    hasBookingStarted(startTime) {
      const now = new Date();
      const givenDate = new Date(startTime);
      const differenceInMilliseconds = givenDate - now;

      return differenceInMilliseconds <= 0;
    },
    handleClick(booking, sp) {
      if (this.isLocationShown) {
        this.openDetailsModal(booking);
      } else if (['healthcoach', 'mental_health', 'cancer'].includes(booking.appointmentType.toLowerCase())) {
        this.$emit("close-bookings");
        this.$router.push(
          "/booking-video-room/" + booking.appointmentId + "/device-tests"
        )
      } else {
        this.openModal(booking, sp);
      }
    },
    handleCancelClick(booking) {
      if (booking.appointmentType === "face_to_face") {
        this.$router.push("/message/" + booking.appointmentId + "/cancel");
      } else {
        this.openCancelModal(booking);
      }
    },
    openModal(booking) {
      if (moment(booking.start).diff(moment(), "days") >= 1) {
        // this.openEarlyModal(booking, sp);
        this.openBookingModal(booking);
      } else {
        this.openBookingModal(booking);
      }
    },
    openCancelModal(booking) {
      const content = this.$createElement(CancelModal, { props: { booking } });
      const skin = this.$store.getters.skin;
      let opts = {
        title: "My Coaching Session",
        hideHeader: true,
        hideFooter: true,
        modalClass: "coach_modal theme-" + skin,
        headerClass:
          "coach_modal_header bg-brand-secondary d-flex justify-content-center",
        size: "md",
        buttonSize: "sm",
        centered: true,
        contentClass: "coach_modal",
        id: "cancel_modal"
      };
      return this.$bvModal.msgBoxOk(content, opts);
    },
    openBookingModal(booking) {
      const content = this.$createElement(MeetingRoomModal, {
        props: { booking: booking }
      });
      const skin = this.$store.getters.skin;
      let opts = {
        title: "My Coaching Session",
        hideHeader: true,
        hideFooter: true,
        modalClass: "coach_modal theme-" + skin,
        headerClass:
          "coach_modal_header bg-brand-secondary d-flex justify-content-center",
        size: "lg",
        buttonSize: "sm",
        centered: true,
        contentClass: "coach_modal",
        id: "digital_clinics_modal"
      };
      return this.$bvModal.msgBoxOk(content, opts);
    },
    openDetailsModal(booking) {
      const content = this.$createElement(F2FDetailsModal, {
        props: { booking: booking }
      });
      const skin = this.$store.getters.skin;
      let opts = {
        title: "My Upcoming Session",
        hideHeader: true,
        hideFooter: true,
        modalClass: "coach_modal theme-" + skin,
        headerClass:
          "coach_modal_header bg-brand-secondary d-flex justify-content-center",
        size: "lg",
        buttonSize: "sm",
        centered: true,
        contentClass: "coach_modal",
        id: "digital_clinics_modal"
      };
      return this.$bvModal.msgBoxOk(content, opts);
    },
    openEarlyModal(booking) {
      const content = this.$createElement(EarlyModal, {
        props: { booking: booking }
      });
      const skin = this.$store.getters.skin;
      let opts = {
        title: "My Session",
        hideHeader: true,
        hideFooter: true,
        modalClass: "coach_modal theme-" + skin,
        headerClass:
          "coach_modal_header bg-brand-secondary d-flex justify-content-center",
        size: "md",
        buttonSize: "sm",
        centered: true,
        contentClass: "coach_modal",
        id: "digital_clinics_early_modal"
      };
      return this.$bvModal.msgBoxOk(content, opts);
    },
    getTitle(type) {
      if (type === "Video") {
        return "Video Call";
      } else if (type === "Audio") {
        return "Audio Call";
      } else if (type === "face_to_face") {
        return "Face to face";
      } else if (type === "Coaching") {
        return "Coaching";
      } else if (type === "corporateEvent") {
        return `${this.corporateEventTitle} Corporate Event`;
      } else {
        return type.split("_").join(" ");
      }
    },
    formatDate(dateString) {
      if (!dateString) return "";
      const date = new Date(dateString);
      return new Intl.DateTimeFormat("en-US", {
        day: "numeric",
        month: "short"
      })
        .format(date)
        .split(" ");
    }
  }
};
</script>

<style lang="less">
#DmhBookingCard {
  background-color: #fff;
  border-radius: 8px;
  .booking_inner {
    display: flex;
    color: #333333;
    border: 1px solid #e0e5f5;
    padding: 20px;
    margin-bottom: 15px;
    border-radius: 8px;

    &.booking-inner-row {
      flex-direction: row;
      gap: 28px;
    }

    &.booking-inner-column {
      flex-direction: column;
    }

    .booking-inner-top {
      width: 100%;
    }

    .underline {
      height: 1px;
      background-color: #e6e8f2;
      margin: 16px 0;
    }

    .img {
      max-width: 60px;
      max-height: 60px;
      border-radius: 8px;
      padding: 0 0 0 0 !important;
      margin-right: 10px;
    }

    .btn-wrapper {
      display: flex;
      flex-direction: row;
      gap: 12px;
      align-items: center;
      justify-content: space-between;
    }

    .btn {
      font-family: "FSElliotBold";
    }

    .btn-wrapper-full {
      margin-top: 16px;
    }

    .btn-wrapper-contain {
      max-width: 200px;
      width: 100%;

      .btn {
        min-width: 40%;
      }
    }

    .image {
      width: 60px;
      height: 60px;
      border-radius: 50%;
      object-fit: cover;
      margin-right: 12px;
    }

    .coach {
      color: #333333;
      font-weight: 700;
      font-family: "FSElliotBold", sans-serif;
      font-size: 1.8rem;
      line-height: 2.4rem;
      text-transform: capitalize;
    }

    .title {
      font-size: 1.6rem;
    }

    .day {
      font-family: "FSElliotBook", sans-serif;
      font-size: 1.6rem;
    }

    .hour {
      font-size: 1.6rem;
    }

    .date {
      font-family: "FSElliotBold", sans-serif;
      font-size: 5rem;
      line-height: 5rem;
      font-weight: 600;
    }

    .month {
      font-family: "FSElliotBold", sans-serif;
      font-size: 1.8rem;
    }

    p {
      margin-bottom: 0;
    }
  }

  .btn-join {
    font-family: "FSElliotBook", sans-serif !important;
    background-color: #00008f;
    border-radius: 6px;
    width: 50%;
    min-height: 44px;
    padding: 10px 20px;
    font-size: 1.8rem;
    line-height: 2.7rem;
    font-weight: 400;
    margin-bottom: 0;
  }

  .btn-cancel {
    font-family: "FSElliotBook", sans-serif !important;
    min-height: 44px;
    width: 50%;
    border-radius: 6px;
    padding: 10px 20px;
    font-size: 1.8rem;
    font-weight: 400;
    line-height: 2.7rem;
    background-color: #d24723;
  }

  .btn.video {
    margin: 0;
  }

  @media screen and (max-width: 470px) {
    .booking_inner {
      &.booking-inner-row {
        flex-direction: column;
      }

      .btn-wrapper-contain {
        max-width: unset;

        .btn {
          min-width: unset;
          width: 100%;
          flex: 1 1 100%;
        }
      }
    }
  }

  @media screen and (max-width: 380px) {
    .btn-continue {
      min-width: 60%;
    }
  }
}
</style>
