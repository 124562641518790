<template>
  <div id="RandoxBookingScreen">
    <div v-if="areDatesLoading">
      <b-spinner></b-spinner>
    </div>
    <div v-else class="booking-container animate__animated animate__fadeIn">
      <div class="pb-3">
        <h2 class="booking-title">Thank you! Let's book you in with your coach</h2>
      </div>
      <div v-if="dates"
        class="d-flex flex-column justify-content-center align-items-center animate__animated animate__fadeIn">
        <h4 class="booking-subtitle">Select a date</h4>
        <div class="slick-container">
          <slick ref="slickRef" class="slick-class" :options="slickOptions">
            <div v-for="(value, key) in dates" :key="key" class="d-flex align-items-center justify-content-center">
              <b-button :variant="key === selectedDate ? 'option-selected' : 'option'
                " class="fixed-width" @click="selectDate(key)">
                {{ getFormatedDate(key, "DD MMM YYYY") }}
              </b-button>
            </div>
          </slick>
        </div>
        <div class="clinicians-container" v-if="selectedDate">
          <h4 class="booking-subtitle">
            Clinicians available
          </h4>
          <button class="clinician-card" :class="i === selectedClinicianIndex
            ? 'active btn-option-selected'
            : 'btn-clinician-option'
            " v-for="(clinician, i) in dates[selectedDate]" :key="i"
            @click="selectClinicianIndex(i, clinician.clinicianId)">
            <div>
              <span class="clinician-card-name">{{ clinician.name }}</span>
              <p class="clinician-card-info mt-1">{{ clinician.skills }}</p>
            </div>
          </button>
        </div>
        <div
          class="animate__animated animate__fadeIn"
          v-if="selectedDate && selectedClinicianId">
          <div class="date-container">
            <h4 class="booking-subtitle">Select a time</h4>
            <div class="timeslots-container">
              <div class="timeslot-item" v-for="(timeslot, i) in dates[selectedDate][
                selectedClinicianIndex
              ].timeslots" :key="i">
                <b-button :variant="timeslot === selectedTimeslot
                  ? 'option-selected'
                  : 'option'
                  " @click="selectTimeslot(timeslot)">
                  {{ timeslot }}
                </b-button>
              </div>
            </div>
          </div>
          <div class="tcs-container">
            <p class="mb-0 text-center">
              <button @click="toggleTcAcceptence()" class="tcs-button">
                <i class="clickable tcs-checkbox mr-1" :class="[
                  !tcsAccepted
                    ? 'fa-regular fa-square grey'
                    : 'fa fa-check-square filled'
                ]"></i>
              </button>
              By booking an appointment, you agree to the following
              <RouterLink to="/terms-and-conditions" target="_blank" class="tcs-link pl-1"> terms & conditions
              </RouterLink>.
            </p>
          </div>
        </div>
        <div class="d-flex justify-content-center align-self-end pt-5 dmh-btn-container">
          <b-button class="btn-light mr-4" @click="handleBackButtonClick" :disabled="appointmentLoading">Back</b-button>
          <button class="btn-dark m-0" :disabled="!selectedClinicianId || !tcsAccepted || appointmentLoading" @click="handleNextButtonClick">Next</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import config from "../../config/constants";
import slick from "vue-slick";
import ErrorModal from "../GP/ErrorModal.vue";

export default {
  name: "GpSelect",
  props: ["reset", "previous", "questionHistory"],
  components: {
    slick,
  },
  data() {
    return {
      areDatesLoading: true,
      selectedDate: null,
      selectedClinicianIndex: null,
      selectedClinicianId: null,
      selectedTimeslot: null,
      dates: false,
      clinicians: [],
      timeslots: [],
      appointmentLoading: false,
      tcsAccepted: false,
      slickOptions: {
        infinite: false,
        nextArrow: `<button type="button" class="slick-next"><i class="fa fa-caret-right" aria-hidden="true"></i></button>`,
        prevArrow: `<button type="button" class="slick-prev"><i class="fa fa-caret-left" aria-hidden="true"></i></button>`,
        slidesToScroll: 4,
        slidesToShow: 4,
        responsive: [
          {
            breakpoint: 700,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3
            }
          },
          {
            breakpoint: 520,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2
            }
          }
        ]
      }
    };
  },
  mounted() {
    this.getDates();
  },
  methods: {
    handleBackButtonClick() {
      this.$emit("changeCurrentView", "book")
    },
    handleNextButtonClick() {
      this.createAppointment()
    },
    selectDate(date) {
      this.selectedDate = date;
      this.selectedClinicianIndex = null;
      this.selectedTimeslot = null;
      this.selectedClinicianId = null;
    },
    toggleTcAcceptence() {
      this.tcsAccepted = !this.tcsAccepted;
    },
    selectTimeslot(time) {
      this.selectedTimeslot = time;
    },
    selectClinicianIndex(i, id) {
      this.selectedClinicianId = id;
      this.selectedTimeslot = null;
      this.selectedClinicianIndex = i;
    },
    async getDates() {
      try {
        const payload = {
          user_id: this.$store.getters.user_id,
          gender_preference: "No Preference",
        };
        let res = await axios.post(
          config.api_env + "/application/api/u/randox/get-booking-dates",
          payload,
          config.options
        );
        if (res.data.success) {
          this.dates = res.data.dates;
        } else {
          this.$emit("changeCurrentView", "error")
        }
      } catch(err) {
        this.$emit("changeCurrentView", "error")
      } finally {
        this.areDatesLoading = false;
      }
    },
    async createAppointment() {
      if (this.appointmentLoading) {
        return;
      }
      this.appointmentLoading = true;
      try {
        const payload = {
          user_id: this.$store.getters.user_id,
          clinician_id: this.selectedClinicianId,
          start_date: this.selectedDate,
          start_time: this.selectedTimeslot,
        };
        const res = await axios.post(
          config.api_env + "/application/api/u/randox/book-appointment",
          payload,
          config.options
        );
        if (res.data.success) {
          if (res.data.appointmentStartTime) {
            this.$emit("changeCurrentView", "bookingConfirmed")
          } else if (
            res.data.apiStatus ===
            "Appointment has been already booked for the same slot. Please choose different clinician or slot."
          ) {
            this.openErrorModal(
              "Sorry, this appointment is no longer available",
              "Please choose another appointment"
            );
          }
        } else {
          this.openErrorModal(
            "Sorry, something went wrong",
            "Please try again"
          );
        }
      } catch (err) {
        this.$emit("changeCurrentView", "error")
      } finally {
        this.appointmentLoading = false;
      }
    },
    openErrorModal(title, error, redirect, goBack) {
      const content = this.$createElement(ErrorModal, {
        props: { title, error, redirect, goBack }
      });
      const skin = this.$store.getters.skin;
      let opts = {
        hideHeader: true,
        hideFooter: true,
        modalClass: "coach_modal theme-" + skin,
        size: "md",
        buttonSize: "sm",
        centered: true,
        contentClass: "coach_modal",
        id: "error-modal"
      };
      return this.$bvModal.msgBoxOk(content, opts);
    }
  },
};
</script>
<style lang="less">
#RandoxBookingScreen {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #1E1E22;
  padding: 40px 20px;

  .booking-container {
    max-width: 650px;
    width: 100%;
    margin: 0 auto;
  }

  .booking-title {
    color: #343C3D;
    text-align: center;
    font-size: 2.4rem;
    letter-spacing: 0;
    font-family: "DMSans", sans-serif;
    font-weight: bold;
    margin-bottom: 24px;
  }

  .booking-subtitle {
    width: 100%;
    color: #1E1E22;
    text-align: left;
    margin-bottom: 20px;
    font-size: 2.2rem;
    line-height: 2.8rem;
    letter-spacing: 0;
    font-family: "DMSans", sans-serif;
    margin-bottom: 20px;
  }

  .btn-clinician-option {
    background-color: #fff;
    border: 2px solid #fff;
    box-shadow: 0px 2px 2px 0px #33333326;
  }

  .btn-option {
    color: #1E1E22;
    font-size: 1.6rem;
    line-height: 2.2rem;
    padding: 12px 16px;
    border-radius: 16px;
    border: 2px solid #AEB0BD;
  }

  .btn-option-selected {
    background-color: #00008F;
    border: 2px solid #00008F;
    border-radius: 16px;
    color: #fff;
    font-size: 1.6rem;
    line-height: 2.2rem;
    padding: 12px 16px;
  }

  .clinician-card {
    width: 100%;
    padding: 16px;
    margin-bottom: 20px;
    text-align: left;
    border-radius: 6px;
    color: #333333;

    .clinician-card-name {
      font-weight: 700;
      font-size: 2.2rem;
      line-height: 2.8rem;
      margin-bottom: 16px;
    }

    .clinician-card-info {
      font-weight: 400;
      font-size: 1.6rem;
      line-height: 2rem;
    }

    &.active {
      color: #fff;
    }
  }

  .slick-container {
    width: 100%;
    max-width: 616px;
    margin-bottom: 32px;
  }

  .slick-slider {
    position: relative;
  }

  .slick-next,
  .slick-prev {
    position: absolute;
    z-index: 10;
    top: 50%;
    background-color: transparent;
    border: none;
    padding: 0 6px;
    transform: translateY(-50%);

    .fa {
      font-size: 3rem;
      color: #01018F;
    }
  }

  .slick-next {
    right: -30px;
  }

  .slick-prev {
    left: -30px;
  }

  .slick-slide {
    margin: 0 6px;
  }

  .slick-disabled {
    display: none !important;
  }

  .clinicians-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
  }

  .timeslots-container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
  }

  .timeslot-item {
    flex: 1 1 30%;
    width: auto;

    .btn-option,
    .btn-option-selected {
      width: 100%;
    }
  }

  .date-container {
    margin-bottom: 20px;
  }

  .tcs-container {
    color: #1E1E22;
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 2rem;
  }

  .tcs-button {
    border: 0;
    background-color: unset;

  }

  .tcs-checkbox {
    font-size: 2rem;
  }
}
</style>
