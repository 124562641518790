<template>
    <div id="DmhSlider" class="border-brand-secondary">
        <div class="slider-text px-2">{{ question.slider_text }}</div>
        <div class="slider-border border-brand-secondary">
            <div class="slider-row border-brand-tertiary position-relative">
                <div class="slider-button" :class="[option == selectedOption ? 'bg-brand-primary' : 'bg-white']"
                    v-for="option in question.options" @click="selectOption(option)"></div>
                <div class="line bg-brand-secondary"></div>
            </div>

        </div>

        <div class="slider-answer">{{ selectedOption }}</div>
    </div>
</template>
<script>

export default {
    components: {

    },
    name: "Slider",
    data() {
        return {
            selectedOption: null,
        };
    },
    mounted() {
        this.selectedOption = this.question.options[2];
    },
    beforeDestroy() {

    },
    methods: {
        selectOption(option) {
            this.selectedOption = option;
            this.updateAnswer(this.question, option);
        },
    },
    props: ['question', 'updateAnswer', 'index'],
    inject: [],
    provide: function () {
        return {
            // alert : this.a
        }
    },
};
</script>
<style lang="less">
#DmhSlider {
    max-width: 100vw;
    text-align: center;
    font-size: 1.8rem;
    margin-bottom: 70px;
    display: flex;
    flex: 1 1 0px;
    flex-direction: column;
    align-items: center;

    .slider-text {
        font-size: 1.8rem;
        padding-bottom: 20px;
        max-width: 350px;
    }

    .slider-border {
        width: 550px;
        max-width: 100vw;
        display: flex;
        flex: 1 1 0px;
        border: 2px solid;
        border-color: inherit;
        border-radius: 1000px;
    }

    .slider-row {
        display: flex;
        flex: 1;
        flex-grow: 1;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: space-between;
        gap: 50px;
        margin: 20px;
        border-radius: 16px;
        overflow: hidden;

        .line {
            height: 5px;
            width: calc(100% - 3px);
            position: absolute;
            top: calc(50% - 2px);
            z-index: 3;
        }
    }

    .slider-button {
        cursor: pointer;
        width: 40px;
        height: 40px;
        border: 4px solid;
        border-color: inherit;
        border-radius: 100px;
        z-index: 5;
    }

    .slider-button.bg-brand-primary {
        border: none;
    }

    .slider-answer {
        background: #DBDBDB;
        margin-top: 20px;
        padding: 20px;
        border-radius: 50px;
        min-width: 250px;
    }

    .slider-answer::before {
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-bottom: 5px solid #DBDBDB;
    }

    @media only screen and (max-width: 992px) {
        .slider-row{
            gap: 20px;
        }
        .slider-button {
            width: 30px;
            height: 30px;
        }
    }
}</style>