<template>
    <div id="CoachingHome">
        <section id="CoachingBanner" class="coaching-home-banner-container pb-5">
            <div class="content-container">
                <div class="row coaching-home-banner mx-0 align-items-center">
                    <div class="coaching-home-banner-text col-md-7 col-sm-12">
                        <h1 class="coaching-home-banner-title mb-3">Mental wellbeing coaching</h1>
                        <p class="white-text mb-4">
                            You'll have sessions with a mental wellbeing coach, who'll work with you to identify your
                            goals, and help you work on practical steps towards them.
                        </p>

                        <b-button variant="cta" size="lg" squared class="py-3 px-5 mt-5"
                            @click="$router.push('/mental-health-coaching/start/questionnaire')">Book a coaching
                            session</b-button>
                    </div>
                    <div class="col-md-5">
                        <img src="/img/banner.png" class="img-fluid" />
                    </div>
                </div>
            </div>
        </section>
        <section class="content-container">
            <div class="row">
                <div class="col-sm-12 col-md-6 mx-3 pb-5">
                    <h3 class="brand-highlight-1 pb-2">How it works</h3>
                    <h3 class="brand-text pb-3">Here's what happens</h3>

                    <ol>
                        <li>
                            <p class="p-text">Book an initial consultation by phone or online. </p>
                        </li>
                        <li>
                            <p class="p-text">Create your personalised action plan – your coach will work with you to
                                create this.</p>
                        </li>
                        <li>
                            <p class="p-text">Get ongoing support via app, online or by phone - you'll have regular
                                follow-ups and your coach will help you track your progress.</p>
                        </li>
                        <li>
                            <p class="p-text">Everyone’s coaching needs are different. Support can last up to 12 weeks,
                                but your mental wellbeing coach will work with you to determine the support you need.
                            </p>
                        </li>
                    </ol>

                </div>
                <div class="col-sm-12 col-md-6 mx-3 ">
                    <h3 class="brand-highlight-1 pb-3">FAQs</h3>
                    <div class="brand-text pb-3">
                        <h4 class="p-text">Q: What can I expect in a coaching session? </h4>
                        <p class="p-text">A: Your coach will help you focus on setting goals, developing strategies and
                            overcoming challenges.</p>
                    </div>
                    <div class="brand-text pb-3">
                        <h4 class="p-text">Q: How do I book a session?</h4>
                        <p class="p-text">A: Just select 'Book a coaching session’ and you can choose a time for your
                            session.</p>
                    </div>
                    <div class="brand-text pb-3">
                        <h4 class="p-text">Q: Are the sessions confidential?</h4>
                        <p class="p-text">A: Yes, and we handle your personal data in accordance with our <a
                                href="/privacy-policy" target="_blank"
                                class="underlined brand-primary"><b>privacy&nbsp;policy</b></a>.</p>
                    </div>
                </div>
                <div class="emergency-box">
                    <h4 class="subtitle pb-3">Feeling like you're at risk?</h4>
                    <p class="p-text">The Samaritans are a charity that have someone on hand, 24/7. If you feel like you
                        could be a
                        risk to yourself or others, you can call them on <a href="tel:116123"
                            class="semibold brand-highlight-1 underlined">116 123</a></p>
                    <p class="p-text pb-0">You could also contact <a href="tel:111"
                            class="semibold brand-highlight-1 underlined">111</a>
                        or <a href="tel:999" class="semibold brand-highlight-1 underlined">999</a> for the NHS emergency
                        services.</p>
                </div>

            </div>

        </section>
        <section id="CoachingPathways" class="mt-5">
            <div class="content-container py-5" id="coachingPathways">
                <h2 class="section-title text-left">Explore Coaching <b>Pathways</b></h2>
                <div class="coaching-pathways-container">
                    <div class="coaching-pathway shadow">
                        <div class="coaching-pathway-image ">
                            <img class="img-fluid" src="/img/axa/1.png" />
                        </div>
                        <div class="coaching-pathway-container">
                            <h4 class="coaching-pathway-title">Improving Sleep</h4>
                            <p class="coaching-pathway-text">
                                Dreaming of a better life? Join us on this journey to discovery how you can sleep your
                                way
                                to wellbeing.
                            </p>
                            <div class="mt-auto">
                                <b-button variant="white" class="pathway-card-button"
                                    @click="showPathwayModal('/img/axa/1.png', 'Improve Sleep', componentText.improveSleepText)">Find
                                    out more ></b-button>
                            </div>
                        </div>


                    </div>
                    <div class="coaching-pathway shadow">
                        <div class="coaching-pathway-image">
                            <img class="img-fluid" src="/img/axa/2.png" />
                        </div>
                        <div class="coaching-pathway-container">
                            <h4 class="coaching-pathway-title">Improving Mood</h4>
                            <p class="coaching-pathway-text">
                                Feeling low? Our emotions tell us about what’s missing in our lives and what we truly
                                need
                                for our
                                wellbeing. Find out more here.
                            </p>
                            <div class="mt-auto">
                                <b-button variant="white" class="pathway-card-button"
                                    @click="showPathwayModal('/img/axa/2.png', 'Improve Mood', componentText.improveYourMoodText)">Find
                                    out more ></b-button>
                            </div>
                        </div>

                    </div>
                    <div class="coaching-pathway shadow">
                        <div class="coaching-pathway-image">
                            <img class="img-fluid" src="/img/axa/3.png" />
                        </div>
                        <div class="coaching-pathway-container">
                            <h4 class="coaching-pathway-title">Reducing Anxiety</h4>
                            <p class="coaching-pathway-text">
                                Anxiety can help us prepare for future challenges, but it can also rob us of our present
                                joys. Come aboard
                                to sail through the waves of anxiety.
                            </p>
                            <div class="mt-auto">
                                <b-button variant="white" class="pathway-card-button"
                                    @click="showPathwayModal('/img/axa/3.png', 'Reducing Anxiety', componentText.transformAnxietyText)">Find
                                    out more ></b-button>
                            </div>
                        </div>
                    </div>
                    <div class="coaching-pathway shadow">
                        <div class="coaching-pathway-image">
                            <img class="img-fluid" src="/img/axa/4.png" />
                        </div>
                        <div class="coaching-pathway-container">
                            <h4 class="coaching-pathway-title">Stress Management</h4>
                            <p class="coaching-pathway-text">
                                Did you know ‘stressed’ spelt backwards is desserts? Join this challenge to transform
                                bitter
                                stress into
                                something a bit sweeter.
                            </p>
                            <div class="mt-auto">
                                <b-button variant="white" class="pathway-card-button"
                                    @click="showPathwayModal('/img/axa/4.png', 'Stress Management', componentText.stressManagementText)">Find
                                    out more ></b-button>
                            </div>
                        </div>
                    </div>
                    <div class="coaching-pathway shadow">
                        <div class="coaching-pathway-image">
                            <img class="img-fluid" src="/img/axa/5.png" />
                        </div>
                        <div class="coaching-pathway-container">
                            <h4 class="coaching-pathway-title">Communication Skills</h4>
                            <p class="coaching-pathway-text">
                                Communication is so much more than just words. Join here to discover how to better
                                express
                                yourself and
                                nurture meaningful connections with others.
                            </p>
                            <div class="mt-auto">
                                <b-button variant="white" class="pathway-card-button"
                                    @click="showPathwayModal('/img/axa/5.png', 'Communication Skills', componentText.integrativeCommunicationText)">Find
                                    out more ></b-button>
                            </div>
                        </div>
                    </div>
                    <div class="coaching-pathway shadow">
                        <div class="coaching-pathway-image">
                            <img class="img-fluid" src="/img/axa/6.png" />
                        </div>
                        <div class="coaching-pathway-container">
                            <h4 class="coaching-pathway-title">Mindfulness</h4>
                            <p class="coaching-pathway-text">
                                Each moment is a gift. That is why it is called present. Take this moment to let go what
                                was
                                or what will be
                                and awaken to the power of now.
                            </p>
                            <div class="mt-auto">
                                <b-button variant="white" class="pathway-card-button"
                                    @click="showPathwayModal('/img/axa/6.png', 'Mindfulness', componentText.mindfulnessText)">Find
                                    out more ></b-button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="content-container my-5">
            <h2 class="section-title mb-5">Here's how to get in touch with us</h2>
            <div class="d-flex flex-row contact-card-container justify-content-between align-items-stretch">
                <div class="contact-card">
                    <div class="contact-card-icon">
                        <i class="fas fa-comment-dots"></i>
                    </div>
                    <h3 class="contact-card-title">Live chat</h3>
                    <div class="contact-card-247">Available 24/7</div>
                    <p>Our support teams are online and ready to help you</p>
                    <b-button variant="cta" squared class="w-100" @click="initChat()">Start a live chat</b-button>
                </div>
                <div class="contact-card">
                    <div class="contact-card-icon">
                        <i class="fas fa-phone"></i>
                    </div>
                    <h3 class="contact-card-title">Call us</h3>
                    <div class="contact-card-247">Available 24/7</div>
                    <p>Our support teams are online and ready to help you</p>
                    <b-button variant="cta" squared class="w-100 unclickable">Call
                        {{ supportContacts?.phone.ie }}</b-button>
                </div>
            </div>
        </section>
        <b-modal id="pathway-modal" v-bind:modal-class="'pathway-modal ' + getTheme()" hide-footer hide-header>
            <div class="pathway-modal-inner">
                <span class="close-button clickable" @click="$bvModal.hide('pathway-modal')"><i
                        class="fas fa-times brand-highlight-1 fa-3x ml-4 mt-4"></i></span>
                <div class="d-flex flex-row justify-content-center">
                    <img class="img-fluid mt-3 mx-auto rounded" :src="pathwayModalImageUrl" />
                </div>
                <div class="pathway-content-container px-4 py-5">
                    <h2 class="text-center mb-3">
                        Start the <br /><b>{{ pathwayModalTitle }} Pathway</b>
                    </h2>
                    <div class="pathway-text" v-html="pathwayModalText"></div>
                    <div class="text-center">
                        <b-button variant="cta" size="lg" squared class=" px-5 mt-3 w-100"
                            @click="closePathwayModal()">Start Journey</b-button>
                    </div>
                </div>
            </div>
        </b-modal>
    </div>
</template>

<script>
import axios from "axios";
import config from "../config/constants";
import slick from "vue-slick";

export default {
    name: "CoachingHome",
    components: {
        slick,
    },
    data() {
        return {
            startColour: '#381B51',
            stopColour: '#9A5683',
            courses: {},

            pathwayModalTitle: "",
            pathwayModalImageUrl: "",
            pathwayModalText: "",
            windowWidth: window.innerWidth,
            selectPathwayBtnText: 'More Info',
            slickOptions: {
                infinite: true,
                autoplay: false,
                dots: false,
                nextArrow:
                    '<button class="slick-next  py-1" type="button" style=""><i class="fas fa-chevron-right"></i></button>',
                prevArrow:
                    '<button class="slick-prev  py-1" type="button" style=""><i class="fas fa-chevron-left"></button>',
                speed: 300,
                initialSlide: 1,
                slidesToScroll: 0,
                slidesToShow: 3,
                centerMode: true,
                centerPadding: "0",
                variableWidth: false,
                adaptiveHeight: true,
                responsive: [
                    {
                        breakpoint: 768,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 0,
                        },
                    },
                ],
            },
        };
    },
    methods: {
        initChat() {
            let sf = document.getElementById('live-chat-widget');
            if (sf) {
                sf.style.display = "initial";
            } else {
                let script = document.createElement('script');
                script.src = "/assets/salesforce_Script.js";
                script.type = "text/javascript";
                script.charset = "UFT-8";
                script.setAttribute("id", "sf-script")
                document.head.appendChild(script);
            }
        },
        closePathwayModal() {
            this.$bvModal.hide("pathway-modal");
            this.$router.push('/mental-health-coaching/start/questionnaire')
        },
        showPathwayModal(image, title, text) {
            this.pathwayModalImageUrl = image;
            this.pathwayModalText = text;
            this.pathwayModalTitle = title;
            this.$bvModal.show("pathway-modal");
            const pathwayName = title.replace(' ', '-').toLowerCase();
            const payload = {
                user_id: this.$store.getters.user_id,
                pathway: pathwayName
            };
            axios.post(config.api_env + "/application/api/u/pathways/set-pathway", payload, config.options);
        },
    },
    computed: {
        componentText() {
            return this.getText.loggedIn.home.homeWellbeing;
        },
        supportContacts() {
            return this.$store.state.user.support_contacts;
        }
    },
};
</script>
<style lang="less">
#CoachingHome {
    background-color: #FAFAFA;

    .emergency-box {
        margin-top: 25px;
        text-align: left;
        background-color: #ecf1f8;
        border: 1px solid #4976ba;
        border-radius: 6px;
        padding: 16px;
    }

    .emergency-box-title {
        font-family: "SourceSansProBold";
        color: #343c3d;
        font-size: 1.8rem;
        line-height: 2.4rem;
        margin-bottom: 8px;
    }

    .subtitle {
        font-family: "SourceSansProBold";
        color: #343c3d;
        font-size: 2.2rem;
        line-height: 2.8rem;

        &.dark {
            color: #111b1d;
        }
    }

    .paragraph {
        font-family: "SourceSansProRegular";
        text-align: left !important;
        color: #343c3d;
        font-size: 1.8rem;
        line-height: 2.4rem;

        &.semibold {
            font-family: "SourceSansProSemibold";
            color: #00008f;
        }

        .unclickable {
            cursor: default !important;
        }
    }

    .section-title {
        font-size: 2.2rem;
        font-weight: bold;
        letter-spacing: normal;
        font-weight: bold;
    }

    .contact-card-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 50px;

        .contact-card {
            background-color: white;
            border: 1px solid lightgrey;
            display: flex;
            flex-grow: 1;
            min-width: 400px;
            flex-direction: column;
            align-items: center;
            padding: 25px;

            .contact-card-icon {
                margin-bottom: 20px;
                background-color: lightblue;
                width: 64px;
                height: 64px;
                border-radius: 100px;
                display: flex;
                align-items: center;
                justify-content: center;

                i {
                    color: white;
                    font-size: 2.5rem;
                }
            }

            .contact-card-title {
                font-size: 2rem;
                font-weight: bold;
                letter-spacing: normal;
                font-weight: bold;
                margin: 0;
                padding: 0;
            }

            .contact-card-247 {
                font-size: 1.4rem;
                font-weight: bold;
                letter-spacing: normal;
                font-weight: bold;
                margin-bottom: 10px;
                padding: 0;
                color: #138636;
            }

            p {
                font-size: 1.6rem;
            }
        }
    }

    .coaching-home-banner-container {
        .coaching-home-banner {
            padding-top: 60px;
        }

        .coaching-home-banner-text {
            font-size: 1.6rem;
            z-index: 2;

            .coaching-home-banner-title {
                font-size: 6.4rem;
                font-weight: bold;
                padding-bottom: 25px;
            }

        }

        div {
            z-index: 2;
        }
    }

    ol {
        padding-left: 15px;

        li {
            font-size: 1.6rem;
        }
    }

    @media only screen and (max-width: 768px) {
        .coaching-pathways-container {
            grid-template-columns: 1fr 1fr !important;
        }
    }

    .coaching-pathways-container {
        padding-bottom: 30px;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        column-gap: 30px;

        .coaching-pathway {
            background-color: white;
            border-radius: 8px;
            margin-top: 20px;
            margin-bottom: 20px;
            display: flex;
            flex-direction: column;
            position: relative;
            overflow: hidden;
            z-index: 2;
            flex-grow: 1;

            .coaching-pathway-container {
                flex-grow: 1;
                display: flex;
                flex-direction: column;
                padding: 15px;
            }

            h4 {
                font-size: 1.8rem;
                font-weight: bold;
                margin-bottom: 15px;
                z-index: 10;
            }

            p {
                font-size: 1.4rem;
                z-index: 10;
            }

            .coaching-pathway-image {
                display: flex;
                align-items: center;
                justify-content: center;

                img {
                    height: auto;
                    width: 100%;
                }
            }

            .pathway-card-button {
                margin-left: -10px;
                padding: 10px;
                font-size: 1.4rem;
            }

        }
    }

    .horizontal-divider {
        flex-grow: 1;
        margin-left: 20px;
        border: 2px solid;
    }

    .banner-overlay {
        position: absolute;
        width: 100%;
        bottom: 100%;
        z-index: 1;

        img {
            width: 100%;
        }
    }

    .pathway-overlay {
        position: absolute;
        width: 100%;
        bottom: 0;
        z-index: 1;

        img {
            width: 100%;
        }
    }

    .pathway-blob {
        position: absolute;
        width: 100%;
        bottom: 0;
        right: 0;
        z-index: 1;

        div {}
    }
}

#pathway-modal {
    .modal-content {
        border-radius: 24px;
    }

    .pathway-modal-inner {
        max-height: 90vh;
        overflow-y: auto;
    }

    .close-button {
        cursor: pointer;
    }

    .pathway-text {
        font-size: 1.4rem;
    }

    img {
        width: 80%;
        border-radius: 18px !important;
    }
}
</style>