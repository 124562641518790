import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
import VueAxios from "vue-axios";
import BootstrapVue from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "fullcalendar/dist/fullcalendar.css";
import config from "./config/constants";
import "animate.css";
import { library } from "@fortawesome/fontawesome-svg-core";

import ClickOutside from "./directives/v-click-outside";
Vue.directive("click-outside", ClickOutside);

import VueConfetti from "vue-confetti";
Vue.use(VueConfetti);

import {
  faTimesCircle,
  faTimes,
  faClock,
  faUserClock,
  faMapMarkerAlt,
  faUser,
  faEuroSign,
  faChevronLeft,
  faChevronRight,
  faCalendar,
  faBars,
  faEllipsisH,
  faPlay,
  faCheck,
  faLock,
  faEye,
  faEyeSlash
} from "@fortawesome/free-solid-svg-icons";
import "@fortawesome/fontawesome-pro/css/all.css";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import "slick-carousel/slick/slick.css";
import VueDateFns from "vue-date-fns";
import IdleVue from "idle-vue";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
// import VueGtag from "vue-gtag";
// import VuePapaParse from "vue-papa-parse";
// import * as Sentry from "@sentry/vue";
// import { BrowserTracing } from "@sentry/tracing";

// Vue.use(VuePapaParse);
// Sentry.init({
//   Vue,
//   dsn: "https://077f159660354a2894daf246ce39de83@sentry.spectrum.life/12",
//   environment: process.env.NODE_ENV,
//   integrations: [
//     new BrowserTracing({
//       routingInstrumentation: Sentry.vueRouterInstrumentation(router),
//       tracingOrigins: ["localhost", "spectrum.life", /^\//]
//     })
//   ],
//   tracesSampleRate: 1.0
// });

// Vue.use(VueGtag, {
//   config: { id: process.env.VUE_APP_GA_TRACKING_ID },
//   appName: 'UP-SPA',
// }, router);

Vue.component("v-select", vSelect);

const eventsHub = new Vue();
const options = { eventEmitter: eventsHub, idleTime: 7200000 };

Vue.use(IdleVue, options);

Vue.use(VueDateFns);
//TODO remove this fontwesome below, just use html tags instead
library.add(
  faTimesCircle,
  faTimes,
  faClock,
  faUserClock,
  faMapMarkerAlt,
  faUser,
  faEuroSign,
  faChevronLeft,
  faChevronRight,
  faCalendar,
  faBars,
  faEllipsisH,
  faPlay,
  faCheck,
  faLock,
  faEye,
  faEyeSlash
);
Vue.component("font-awesome-icon", FontAwesomeIcon);
Vue.use(BootstrapVue);

axios.defaults.headers.common["Content-type"] =
  "application/x-www-form-urlencoded; charset=utf-8";

Vue.use(VueAxios, axios);
Vue.use(config);

Vue.config.productionTip = false;

import "./mixins/generalMixin";

import { PublicClientApplication } from "@azure/msal-browser";
const msalConfig = {
  auth: {
    clientId: process.env.VUE_APP_AXA_SSO_CLIENT_ID,
    authority: process.env.VUE_APP_AXA_SSO_AUTHORITY,
    redirectUri: process.env.VUE_APP_AXA_SSO_REDIRECT_URI,
    knownAuthorities: [process.env.VUE_APP_AXA_SSO_KNOWN_AUTHORITIES],
    navigateToLoginRequestUrl: false,
    // protocolMode: "OIDC",
  },
  cache: {
    cacheLocation: "localStorage", // or 'sessionStorage'
    storeAuthStateInCookie: false, // Set to true if experiencing issues with certain browsers
  },
  system: {
    allowRedirectInIframe: true,
  }
};

// const msalInstance = new PublicClientApplication(msalConfig);
async function createMsalInstance() {
  return await PublicClientApplication.createPublicClientApplication(msalConfig);
}

const Msal = {
  async install(Vue) {
    const msalInstance = await createMsalInstance();
    Vue.prototype.$msal = msalInstance;
  },
};

Vue.use(Msal);

new Vue({
  router,
  store,
  axios,
  render: h => h(App)
}).$mount("#app");
