<template>
  <div id="events">
    <div class="page-header">
      <div class="content-container row">
        <div class="toolbar col-12 px-0 px-sm-4 d-none d-sm-block">
          <b-button-group>
            <b-button class="px-1 px-sm-5 py-4" variant="cta-outline" :class="{ active: page === 'events' }" @click="selectPage('events')">{{
                componentText.events
            }}</b-button>
            <b-button variant="cta-outline" class="px-1 px-sm-5 py-4" :class="{ active: page === 'bookings' }"
              @click="selectPage('bookings')">{{ componentText.bookings }}</b-button>
          </b-button-group>
        </div>
        <div class="col-12 d-block d-sm-none order-3 py-5">
          <div class="dropdown-wrap">
            <b-dropdown :text="componentText[page]" class="dropdown cta-button py-2">
              <b-dropdown-item @click="selectPage('events')">{{ componentText.events }}
              </b-dropdown-item>
              <b-dropdown-item @click="selectPage('bookings')">{{ componentText.bookings }}
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </div>
        <div class="header-background">
          <img v-if="hardcoded.illustration_events" :src="hardcoded.illustration_events">
          <img src="/img/event/placeholder-top.svg" v-else />
        </div>
      </div>
    </div>
    <div class="page-content bg-white px-md-5">
      <transition name="slide-fade">
        <MyBookings v-if="page === 'bookings'" @return="selectPage('events')" />
        <Webinars v-else-if="page === 'webinars' && !webinarId" />
        <WebinarView v-else-if="page === 'webinars' && webinarId" />
        <AvailableEvents v-else @event-popup="eventPopUp" />
      </transition>
    </div>
    <div v-if="eventSelected" class="event-pop-up">
      <div class="pop-up-background" @click="hidePopUp"></div>
      <div class="pop-up-content row">
        <div class="col-12">
          <h4>{{ this.eventDate }}</h4>
        </div>
        <div class="event-details-container">
          <div class="event-details d-flex flex-row justify-space-between border-top py-2"
            v-for="(event, i) in this.events" :key="i">
            <div @click="eventNav(event)" class="col-7 event-title brand-primary">
              {{ event.title }}
            </div>
            <div class="event-info col-2 px-0">
              <font-awesome-icon :icon="['fas', 'clock']" class="mr-2"></font-awesome-icon>
              {{ new Date(event.start_date) | date("HH.mm") }}-{{
                  new Date(event.end_date) | date("HH.mm")
              }}
            </div>
            <div class="event-info col-2 px-0">
              <font-awesome-icon icon="map-marker-alt" class="mr-2"></font-awesome-icon>
              {{ event.locations[0].location_label }}
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import MyBookings from "@/components/Events/MyBookings.vue";
import AvailableEvents from "@/components/Events/AvailableEvents.vue";
import { dateFilter } from "vue-date-fns";
import { mapGetters } from "vuex";
import Webinars from "../components/Events/Webinars";
import WebinarView from "../components/Events/WebinarView";
export default {
  name: "events",
  components: {
    Webinars,
    MyBookings,
    AvailableEvents,
    WebinarView
  },
  filters: {
    date: dateFilter
  },
  data() {
    return {
      eventSelected: false,
      eventDate: "",
      events: "",
    };
  },
  mounted() {
  },
  beforeRouteUpdate(to, from, next) {
    if (to.params.page !== from.params.page) {
      next()
    }
  },
  methods: {
    selectPage(page) {
      if (this.page !== page) {
        this.$router.push({ name: 'events', params: { page: page } })
      }
    },
    eventPopUp(date, events) {
      this.eventSelected = true;
      (this.eventDate = date), (this.events = events);
      for (let i = 0; i < events.length; i++) {
        if (events[i].start_date) {
          events[i].start_date = new Date(
            events[i].start_date.replace(" ", "T")
          );
          events[i].end_date = new Date(events[i].end_date.replace(" ", "T"));
        }
      }
    },
    hidePopUp() {
      this.eventSelected = false;
    },
    eventNav(event) {
      this.$router.push({
        name: "viewEvent",
        params: { id: event.event_date_id }
      });
    },
  },
  computed: {
    ...mapGetters(["hardcoded"]),
    componentText() {
      return this.getText.loggedIn.events;
    },
    page() {
      return this.$route.params.page ? this.$route.params.page : 'events'
    },
    webinarId() {
      return this.$route.params.webinar ? this.$route.params.webinar : null
    }
  }
};
</script>
<style lang="less">
#events {
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  .page-header {
    padding: 60px 0;
  }

  .page-title {
    text-align: left;
    padding-bottom: 80px;

    h2 {
      text-align: left;
      font-size: 3.8rem;
      letter-spacing: 0.7rem;
      font-family: "FilsonProBold", sans-serif;
      color: #fff;
    }
  }

  .toolbar {
    text-align: center;
    z-index: 10;
    max-width: 700px;
    margin: auto;

    .btn-group {
      display: flex;

      .btn {
        font-family: "DMSans", sans-serif;
        font-size: 1.6rem;
        border-width: 1px;
        letter-spacing: 0;
        white-space: nowrap;
        border-radius: 0;
        flex-basis: 0;

        &:first-child {
          border-radius: 60px 0 0 60px;
        }

        &:last-child {
          border-radius: 0 60px 60px 0;
        }

        &.active {}
      }
    }
  }

  .dropdown-wrap {
    text-align: center;
    font-size: 1.6rem;
    width: 300px;
    margin: auto;
    position: relative;

    .dropdown {
      position: relative;
      border-width: 0;
      border-radius: 10px;
      font-size: 1.6rem;
      width: 300px;
      text-align: center;
      margin: auto;
      box-shadow: none;

      &:focus {
        border-radius: 10px 10px 0 0;
        box-shadow: none;
      }

      &.show {
        border-radius: 10px 10px 0 0;

        .dropdown-toggle {
          background: none;
        }
      }

      .dropdown-toggle {
        &:active {
          background: none;
        }
      }

      .dropdown-menu {
        margin-top: 0;
        width: 100%;
        font-size: 1.6rem;
        border-radius: 0 0 10px 10px;
      }
    }
  }

  .page-content {
    padding: 60px 0;
    height: 100%;
  }

  .event-pop-up {
    display: flex;
    align-items: center;
    z-index: 500;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    text-align: center;

    .pop-up-content {
      background: #fff;
      max-width: 700px;
      width: 100%;
      margin: auto;
      border-radius: 25px;
      padding: 30px 20px;
      font-size: 4rem;
      text-align: center;
      z-index: 10;

      /* Firefox */
      * {
        scrollbar-width: thin;
        scrollbar-color: #dcdcdc #ffffff;
      }

      /* Chrome, Edge, and Safari */
      *::-webkit-scrollbar {
        width: 16px;
      }

      *::-webkit-scrollbar-track {
        background: #ffffff;
      }

      *::-webkit-scrollbar-thumb {
        background-color: #dcdcdc;
        border-radius: 10px;
        border: 3px solid #ffffff;
      }

      .event-details-container {
        display: flex;
        flex-grow: 1;
        flex-direction: column;
        justify-content: start;
        max-height: 600px;
        overflow: auto;

        .event-details {
          border-top-width: 1px !important;
          border-color: #dee2e6;
          position: relative;
          flex-grow: 1;

          h4 {
            font-size: 2rem;
            font-family: "FilsonProBold";
          }

          .event-title {
            cursor: pointer;
            font-size: 2rem;
            font-family: "FilsonProBold";
            text-align: left;

          }

          .event-info {
            font-size: 1.2rem;
            margin: auto;
            line-height: 2rem;
            display: flex;
            align-items: center;
            align-content: center;

            svg {
              transform: translateY(1.5px);
            }
          }
        }
      }

    }

    .pop-up-background {
      width: 100vw;
      height: 100vh;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      z-index: 5;
      background: transparent;
    }
  }

  .no-events {
    font-family: "FilsonProBold", sans-serif;
    font-size: 4rem;

    .no-events-heading {
      position: relative;
    }

    a {
      font-size: 2rem;
      text-decoration: underline;
    }
  }

  @media only screen and (max-width: 576px) {
    .toolbar {
      .btn-group {
        max-width: 400px;
        width: 100%;

        .btn {
          letter-spacing: 0;
        }
      }
    }
  }
}
</style>
