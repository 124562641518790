<template>
  <div id="BeforeResultsScreen">
    <LoadingAnimation v-if="isLoadingAnimationShown" />
    <div v-else class="before-results-inner">
      <div class="image-container">
        <img
          class="image"
          :src="`/img/health-score/${questionnaireId}_results.png`"
          alt=""
        />
      </div>
      <h2 class="before-results-title">
        You've now completed the {{ questionnaireName }} assessment
      </h2>
      <h3 class="before-results-subtitle">
        A few important things to know before you get your results
      </h3>
      <ol class="before-results-list">
        <li class="list-item">
          Your Health Score is not medical advice. If you’re concerned about
          your health or any medical condition, please see your doctor.
        </li>
        <li class="list-item">
          Never ignore any medical advice because of something you’ve read on
          your Health Score.
        </li>
        <li class="list-item">
          Please check with your doctor before making any decisions on
          healthcare.
        </li>
        <li class="list-item">
          Your Health Score gives you information and ideas for changes you can
          make that are likely to improve your health. If you’ve had heart
          disease or cancer, the Health Score doesn’t take these into account
          when working out your score or the recommendations.
        </li>
      </ol>
      <div class="before-results-btn-container">
        <button
          class="button-dark"
          @click="viewResults"
          :disabled="isRestartLoading || areButtonsDisabled"
        >
          View results
          <i class="fa fa-angle-right ml-3" aria-hidden="true"></i>
        </button>
        <button
          class="button-light"
          @click="handleRestartAssessment"
          :disabled="isRestartLoading || areButtonsDisabled"
        >
          <i class="fa fa-refresh mr-3" aria-hidden="true"></i>
          Restart {{ questionnaireName }} assessment
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import config from "@/config/constants";
import LoadingAnimation from "./LoadingAnimation.vue";

export default {
  name: "BeforeResultsScreen",
  props: ["questionnaireId"],
  components: {
    LoadingAnimation
  },
  data() {
    return {
      isLoadingAnimationShown: false,
      isRestartLoading: false,
      isRestartSuccessful: false,
      areButtonsDisabled: false,
      timeoutId: null
    };
  },
  computed: {
    questionnaireName() {
      return this.questionnaireId?.split("_").join(" ");
    }
  },
  beforeDestroy() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
  },
  methods: {
    viewResults() {
      this.isLoadingAnimationShown = true;
      this.timeoutId = setTimeout(() => {
        this.$router.push(`/healthscore/results/${this.questionnaireId}`);
      }, 4000);
    },
    async handleRestartAssessment() {
      this.areButtonsDisabled = true;
      await this.restartAssessment();

      if (this.isRestartSuccessful) {
        window.location.reload();
      } else {
        this.$router.push("/healthscore");
      }
    },
    async restartAssessment() {
      if (this.isRestartLoading) {
        return;
      }
      try {
        this.isRestartLoading = true;
        const payload = {
          user_id: this.$store.getters.user_id,
          questionnaire_name: this.questionnaireId
        };
        const res = await axios.post(
          config.api_env +
            "/application/api/u/sapphire/healthscore/restart-questionnaire",
          payload,
          config.options
        );
        if (res.data.success) {
          this.isRestartSuccessful = true;
        }
      } catch (err) {
        console.error(err);
      } finally {
        this.isRestartLoading = false;
      }
    }
  }
};
</script>

<style lang="less">
@padding-x: 28px;
@max-width: calc(544px + @padding-x * 2);

.centered {
  max-width: @max-width;
  margin: 0 auto;
}

#BeforeResultsScreen {
  background-color: #fafafa;

  .before-results-inner {
    padding: 48px @padding-x 38px;

    .centered();
  }

  .image-container {
    display: flex;
    justify-content: center;
    margin-bottom: 40px;

    .image {
      max-width: 300px;
      object-fit: contain;
    }
  }

  .before-results-title {
    font-family: "PublicoHeadlineWebBold";
    color: #00008f;
    font-size: 3.6rem;
    line-height: 4.5rem;
    margin-bottom: 16px;
  }

  .before-results-subtitle {
    font-family: "SourceSansProBold";
    font-size: 2.2rem;
    line-height: 2.8rem;
    color: #343c3d;
    margin-bottom: 16px;
  }

  .before-results-list {
    font-family: "SourceSansProRegular";
    font-size: 1.8rem;
    line-height: 2.4rem;
    margin-bottom: 32px;

    .list-item {
      margin-bottom: 12px;
    }
  }

  .before-results-btn-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .button-dark {
    width: 100%;
    text-align: center;
    font-family: "SourceSansProSemibold";
    background-color: #00008f;
    border-radius: 6px;
    padding: 12px 20px;
    color: #ffffff;
    font-size: 1.8rem;
    line-height: 2.7rem;
    transition: background-color 0.2s;
    border: 0;

    &:hover {
      background-color: #00006d;
    }

    &:focus {
      outline: 4px solid #ffbc11;
    }
  }

  .button-light {
    width: 100%;
    text-align: center;
    font-family: "SourceSansProSemibold";
    background-color: #fff;
    border-radius: 6px;
    padding: 12px 20px;
    color: #00008f;
    font-size: 1.8rem;
    line-height: 2.7rem;
    border: 1px solid #00008f;
    transition: background-color 0.2s;

    &:hover {
      border: 1px solid #00006d;
      color: #00006d;
    }

    &:focus {
      border: 1px solid #00006d;
      outline: 4px solid #ffbc11;
    }
  }
}
</style>
