<template>
  <div id="EvolveTask">
    <div
      class="task mt-3"
      :class="[
        task.disabled || task.completed
          ? 'bg-brand-highlight-1 white-text'
          : 'bg-white',
        task.disabled ? 'disabled-task' : '',
        task.completed ? 'completed-task' : '',
      ]"
      @click="openTaskModal(task.id, task.title)"
    >
      <div class="fa-stack fa-1x mr-3 ">
        <i
          class="fas fa-circle brand-primary fa-stack-2x"
          :class="getIconClass()"
        ></i>
        <i class="fa-stack-1x fa-inverse " :class="[activeClass]"></i>
      </div>
      <div class="task-text brand-primary" v-if="!task.completed">{{ task.title }}</div>
      <div v-else><div class="task-text strikethrough">{{ task.title }}</div></div>
      <div
        class="task-time ml-auto"
        v-if="!task.disabled"
        v-html="friendlyTaskDueDate(task)"
      ></div>
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  components: {},
  name: "EvolveTask",
  props: ["task"],

  mounted() {
    this.iconClass = this.task.icon
      ? this.task.icon.split('"')[1]
      : "fas fa-heart";
  },
  methods: {
    getIconClass() {
      if(this.task.completed) return 'grad-icon-completed brand-highlight-1';
      if(!this.task.disabled) return 'brand-primary';
      return 'brand-primary';
    },
    openTaskModal(id) {
      this.$emit("clicked", id, moment());
    },
    friendlyTaskDueDate(task) {
      if (task.completed) {
        return "<b class='white-text'>Done</b>";
      }
      if(task.recurring_type) {
        return "Repeats <span class='recurrance'>" + task.recurring_type.charAt(0).toUpperCase() + task.recurring_type.slice(1) +"</span>";
      }
      if (task.target_date !== false) {
        const testDate = moment().add(6,'days');
        const taskDate = moment(task.target_date, "YYYY-MM-DD");
        const format = testDate.isAfter(taskDate) && taskDate.isAfter(moment()) ? 'dddd' : 'Do MMM';
        let output = taskDate.format(format);
        if(moment().isSame(taskDate,'day')) output = 'Today';
        if(moment().add(1,'days').isSame(taskDate,'day')) output = 'Tomorrow';
        return (
          "To complete by <span class='recurrance'>" +
            output +
          "</span>"
        );
      }
      if (task.recurring_type !== false && task.recurring_type !== undefined) {
        return "Repeats <span class='recurrance'>" + task.recurring_type + "</span>";
      }
      return "";
    },
  },
  computed: {
    activeClass() {
      if (this.task.completed) {
        return "fas fa-check";
      } else if (this.task.disabled) {
        return "fas fa-lock";
      } else if (this.task.icon !== null) {
        return this.task.icon.split('"')[1];
      } else {
        return "fas fa-heart";
      }
    },
  },
};
</script>
<style lang="less">
#EvolveTask {
  .recurrance {
    font-family: PublicoHeadlineWebBold;
  }
  .task {
    font-size: 1.4rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: nowrap;
    width: 100%;
    padding: 10px 15px;
    border-radius: 10px;
    font-weight: bold;
    z-index: 100;
    cursor: pointer;
    .task-time {
      font-family: "DMSans", serif;
      align-self: flex-end;
      font-weight: 300;
      color: grey;
    }
    &:hover {
      .task-text {
        text-decoration: underline;
      }
    }
  }
  .task-text {
    font-family: "DMSans", serif;
    font-weight: bold;
  }
  .task.disabled-task {
    cursor: default;
    .task-text {
      font-family: "PublicoHeadlineWebBold", serif;
      background: #ddd;
    }
    .fa-circle {
      color: grey;
    }
    .fa-lock {
      color: white;
    }
  }
  .task.completed-task {
    .task-text {
      font-family: "PublicoHeadlineWebBold", serif;
      color: white !important;
      text-decoration: line-through;
    }
  }
  .white-text{
    color: white !important;
  }
  .fa-stack {
    flex-shrink: 0;
  }
}
</style>
