<template>
	<div id="who_modal">
		<div v-if="loading"></div>
		<div v-else>
			<h1 class="text-center">Your Progress</h1>
			<div id="top_graph_wrapper">
				<div id="top_graph">
					<Who5Graph :graph="graph" />
				</div>
			</div>
			<div v-if="finished" id="finished">
				<h4>Questionnaire completed !</h4>
				<p>Thanks, please come back another time ...</p>
				<hr/>
				<div class="text-right mt-5">
					<b-button variant="purple" @click="closeModal()">Exit</b-button>
				</div>
			</div>
			<div v-else id="questions" class="mt-5">
				<h4>Who 5 Progress</h4>
				<p>Please Answer all five questions truthfully<br/> to track your progress</p>
				<hr/>
				<div id="who_checkin_question" :class="question_wrapper_class">
					<div class="question_counter text-center p-3">
						<span>(Question {{current_question}}/{{total_questions}})</span>
					</div>
					<Question :question="survey_section.questions[(current_question-1)]" />
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import Question from "./Question"
import Who5Graph from "./Who5Graph"
export default {
	components: {
		Question,
		Who5Graph
	},
	name: "Who5Modal",
	data() {
		return {
			survey_section : [],
			total_questions : 0,
			current_question : 1,
			question_state : "show", // [hide, show]
			loading : true,
			finished : false,
			graph : {}
		};
	},
	mounted() {
		this.getSurvey()
	},
	beforeDestroy() {
	
	},
	computed : {
		question_wrapper_class(){
			let classes = ["animate__animated"]
			let anim = this.question_state === "show" ? "animate__fadeIn" : "animate__fadeOut"
			classes.push(anim)
			return classes.join(" ")
		}
	},
	methods: {
		async getSurvey() {
			let res = await this.api({
				path : "api/u/surveys/get-fixed-survey",
				name : "WHO-5"
			});
			if (res.success) {
				this.survey_section = res.survey.sections[0]
				this.total_questions = this.survey_section.questions.length
				this.graph = res.survey.graph
				this.loading = false
			}
		},
		flipQuestion(){
			// document.getElementById("who_checkin_question")
			console.log('flip parent')
			let t = this
			t.question_state = "hide"
			setTimeout(() => {
				if(t.current_question < t.total_questions){
					t.current_question += 1
					t.question_state = "show"
				} else {
					t.finished = true
					t.getSurvey()
				}
			}, 250)
		},
		closeModal(){
			this.$bvModal.hide('who_checkin_modal')
		}
	},
	props : [],
	provide: function () {
		return {
			flipQuestion : this.flipQuestion
		}
	},
};
</script>
<style lang="less">
.who_checkin_modal_content {
	border-radius: 15px !important;
	overflow: hidden;
	.modal-body {
		padding: 0px;
	}
	.modal-content {
		padding: 0px;
	}
	.modal-footer {
		display: none;
	}
}
#who_modal {
	min-height: 300px;
	padding: 15px;
	//background: url('/public/img/totalMentalHealth/achievements/Wave.png') no-repeat center bottom;
	background-size: 100% 92%;
	#top_graph_wrapper {
		padding: 15px;
		#top_graph {
			background-color: #403d56;
			border-radius: 15px;
			padding: 15px;
			#line-chart {
				max-height: 200px;
			}
		}
	}

}
</style>