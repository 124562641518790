<template>
  <div id="EmergencyContacts">
    <div class="container">
      <h1 class="title">Emergency Service Contacts</h1>
      <p class="paragraph">
        If you feel like you’re a risk to yourself or others right now please contact emergency services in your local area via the list below:
      </p>
      <div class="grid-table">
        <div
          class="grid-header"
          v-for="(header, index) in headers"
          :key="'header-' + index"
        >
          {{ header }}
        </div>
        <div
          class="grid-cell"
          v-for="(value, index) in table"
          :key="'cell-' + index"
        >
          {{ value }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "EmergencyContacts",
  data() {
    return {
      headers: [
        "Country", "Emergency Number", "Mental Health Crisis Support Service", "Support Offered", "Crisis Support Number(s)"
      ],
      table: [
        "United States", "911", "National Suicide Prevention Lifeline", "24/7 suicide prevention and crisis support", "Call or text 988",
        "Canada", "911", "Talk Suicide Canada", "24/7 suicide prevention and crisis support", "1-833-456-4566",
        "Australia", "000", "Lifeline Australia", "24/7 crisis support and suicide prevention", "13 11 14",
        "Austria", "112", "Telefonseelsorge Österreich", "24/7 crisis counselling", "142",
        "Belgium", "112", "Centre de Prévention du Suicide", "24/7 suicide prevention", "0800 32 123",
        "Bulgaria", "112", "Bulgarian Red Cross / National Sofia Suicide Hotline", "Psychological support / Suicide prevention", "02 81 64 877, 0035 9249 17 223",
        "Croatia", "112", "Blue Phone Helpline","Emotional support (8am-7pm, Mon-Fri)","00385 1 4833 888",
        "Cyprus","112","Cyprus Samaritans","Confidential emotional support","8000 7773",
        "Czech Republic","112","Linka bezpečí / Linka důvěry Ostrava","Youth support / Adult crisis line (24/7)","116 111, 737 267 939",
        "Denmark","112","Livslinien","24/7 suicide prevention","70 201 201",
        "Estonia","112","Eluliin (Estonian and Russian)","24/7 crisis counselling","655 8088, 3726555688",
        "Finland","112","MIELI Mental Health Finland","24/7 crisis helpline","09 2525 0116",
        "France","112","SOS Suicide Phénix","Suicide prevention and emotional support","01 45 39 40 00",
        "Germany","112","Telefonseelsorge Deutschland","24/7 crisis counselling","0800 111 0111",
        "Greece","112","Klimaka Suicide Prevention Line","24/7 suicide prevention","1018",
        "Hungary","112","Ifjúsági Lelki Elsősegély Szolgálat / Telefonos Lelki Elsősegély-szolgálat","Youth and adult crisis support (24/7)","137-00, 116123",
        "Ireland","112 or 999","Samaritans Ireland","24/7 emotional support","116 123",
        "Italy","112","Telefono Amico Italia","24/7 emotional support","199 284 284",
        "Latvia","112","Skalbes Crisis Hotline","24/7 psychological support","6722 2922",
        "Lithuania","112","Jaunimo Linija","24/7 emotional support for youth","8 800 28888",
        "Luxembourg","112","SOS Détresse","24/7 emotional support","454545",
        "Malta","112","Supportline 179","24/7 emotional support and counselling","179",
        "Netherlands","112","113 Suicide Prevention","24/7 suicide prevention","113, 900 0113",
        "Poland","112","116 SOS","24/7 emotional support and crisis helpline",        "116 123",
        "Portugal","112","Vozes Amigas de Esperanca","24/7 crisis hotline", "222 030 707",
        "Romania","112","Alianța Română de Prevenție a Suicidului","24/7 suicide prevention","0800 801 200",
        "Slovakia","112","Linka dôvery Nezábudka","24/7 crisis helpline","0800 800 566",
        "Slovenia","112","Zaupni telefon Samarijan","24/7 crisis helpline","116 123",
        "Spain","112","Teléfono de la Esperanza","24/7 emotional support","717 003 717",
        "Sweden","112","Mind Självmordslinjen","24/7 suicide prevention hotline","90101"
      ]
    }
  }
}
</script>

<style lang="less">
#EmergencyContacts {
  background-color: #FAFAFA;

  .container {
    overflow-x: scroll;
    max-width: 720px;
    padding: 40px 20px;
    margin: 0 auto;
  }

  .title {
    font-family: "SourceSansProBold";
    text-align: center;
    color: #343C3D;
    font-size: 2.2rem;
    line-height: 2.8rem;
    margin-bottom: 32px;
  }

  .paragraph {
    font-family: "SourceSansProRegular";
    text-align: center;
    font-size: 1.8rem;
    line-height: 2.7rem;
    margin-bottom: 44px;
  }

  .grid-table {
    min-width: 440px;
    font-size: 1.6rem;
    display: grid;
    grid-template-columns: repeat(5, minmax(10px, 1fr));
    width: 100%;
    margin: auto;
    border: 1px solid #B9B9B9;
    border-radius: 4px;
  }

  .grid-header {
    font-family: "SourceSansProBold";
    font-size: 1.4rem;
    border: 1px solid #ddd;
    background: #f4f4f4;
    padding: 10px;
    text-align: left;
  }

  .grid-cell {
    font-family: "SourceSansProRegular";
    font-size: 1.4rem;
    padding: 10px;
    text-align: left;
    border: 1px solid #ddd;
  }
}
</style>