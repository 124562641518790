<template>
  <div id="ServiceCard">
    <img class="card-img" :src="service.image_url" :alt="service.name" />
    <div class="card-info">
      <h3 class="card-title">{{ service.name }}</h3>
      <p class="card-paragraph">{{ service.description }}</p>
      <RouterLink class="button-dark" :to="service.link">
        {{ service.button_text }}
        <i class="fa fa-chevron-right" aria-hidden="true"></i>
      </RouterLink>
    </div>
  </div>
</template>

<script>
export default {
  name: "ServiceCard",
  props: ["service"]
};
</script>

<style lang="less">
#ServiceCard {
  flex: 1 1 auto;
  min-width: 280px;
  max-width: 352px;
  border-radius: 6px;
  overflow: hidden;
  box-shadow: 0px 4px 8px 0px #00000033;

  .card-img {
    width: 100%;
    max-height: 196px;
    object-fit: cover;
    object-position: center;
  }

  .card-info {
    padding: 16px;
  }

  .card-title {
    font-family: "SourceSansProBold";
    font-size: 2.2rem;
    line-height: 2.8rem;
    margin-bottom: 8px;
  }

  .card-paragraph {
    font-family: "SourceSansProRegular";
    font-size: 1.8rem;
    line-height: 2.7rem;
    margin-bottom: 16px;
  }

  .button-dark {
    display: block;
    width: 100%;

    .fa {
      font-size: 1.6rem;
      margin-left: 10px;
    }
  }
}
</style>
