<template>
  <div id="QuestionTypeFloat">
    <div class="options-container">
      <button
        v-for="(option, index) in optionsArray"
        :key="index"
        @click="selectUnit(option)"
        class="select-button"
        :class="{ active: option.includes(selectedUnit) }"
      >
        <i
          v-if="option.includes(selectedUnit)"
          class="fa fa-circle-o"
          aria-hidden="true"
        ></i>
        <span class="select-button-text">{{ option }}</span>
      </button>
    </div>
    <div v-if="isInputShown" :class="{ 'input-center': !isQuestionWithUnits }">
      <label for="unit-input" class="input-label">{{ selectedUnit }}</label>
      <div class="input-container">
        <input
          class="input"
          :class="{ error: errorMessage }"
          type="text"
          id="unit-input"
          v-model="inputValue"
          :placeholder="placeholderTextArray[0]"
          @input="validateInput"
        />
        <input
          v-if="isSecondInputShown"
          id="unit-input-2"
          class="input"
          type="text"
          :placeholder="placeholderTextArray[1]"
          v-model="inputValue2"
          @input="validateInput"
        />
      </div>
      <p v-if="errorMessage" class="error-message mb-0">
        {{ errorMessage }}
      </p>
    </div>
  </div>
</template>

<script>
import EventBus from "@/helpers/eventbus";

export default {
  name: "QuestionTypeFloat",
  props: ["questionData", "previousUnit", "previousAnswer"],
  data() {
    return {
      selectedUnit: this.questionData.units
        ? this.previousUnit || this.questionData.units[0]
        : null,
      inputValue: "",
      inputValue2: "",
      isInputTouched: false,
      errorMessage: ""
    };
  },
  computed: {
    optionsArray() {
      const options = [
        `I don't know my ${this.questionData.slug.split("_").join(" ")}`
      ];
      if (this.isQuestionWithUnits) {
        options.unshift(...this.questionData.units);
      }

      return this.isQuestionWithUnits ? options : [];
    },
    isInputShown() {
      return this.selectedUnit
        ? !this.selectedUnit.includes("I don't know")
        : true;
    },
    isQuestionWithUnits() {
      return this.questionData.units;
    },
    isDecimalNumber() {
      return ["total_cholesterol", "hdl_cholesterol", "triglycerides", "glucose", "hba1c"].includes(
        this.questionData?.slug
      );
    },
    isSecondInputShown() {
      return (
        this.selectedUnit &&
        ["Feet and inches", "Stones and pounds"].includes(this.selectedUnit)
      );
    },
    dividedUnitArray() {
      return this.isSecondInputShown
        ? this.selectedUnit.split(" and ")
        : [this.selectedUnit];
    },
    placeholderTextArray() {
      if (!Array.isArray(this.dividedUnitArray)) return [];

      return this.dividedUnitArray.map(unit => this.setPlaceholderText(unit));
    },
    finalValue() {
      if (this.isSecondInputShown && this.selectedUnit === "Feet and inches") {
        return this.convertFeetInchesToCm(
          Number(this.inputValue),
          Number(this.inputValue2)
        );
      } else if (
        this.isSecondInputShown &&
        this.selectedUnit === "Stones and pounds"
      ) {
        return this.convertStonesPoundsToKg(
          Number(this.inputValue),
          Number(this.inputValue2)
        );
      } else {
        return this.inputValue;
      }
    }
  },
  mounted() {
    if (this.previousAnswer) {
      this.inputValue = `${this.previousValue()[0]}`;
      this.inputValue2 = `${this.previousValue()[1]}`;
    }
  },
  methods: {
    selectUnit(option) {
      this.selectedUnit = option;
      this.inputValue = "";
      this.inputValue2 = "";

      if (option.includes("I don't know")) {
        EventBus.$emit("setAnswer", option, this.selectedUnit);
      } else {
        EventBus.$emit("setAnswer", null, this.selectedUnit);
      }
    },
    previousValue() {
      if (this.previousAnswer) {
        if (
          this.isSecondInputShown &&
          this.selectedUnit === "Feet and inches"
        ) {
          return this.convertCmToFeetInches(Number(this.previousAnswer));
        } else if (
          this.isSecondInputShown &&
          this.selectedUnit === "Stones and pounds"
        ) {
          return this.convertKgToStonesPounds(Number(this.previousAnswer));
        } else {
          return [this.previousAnswer, ""];
        }
      } else {
        return ["", ""];
      }
    },
    convertFeetInchesToCm(feet, inches) {
      feet = Number(feet) || 0;
      inches = Number(inches) || 0;
      const totalInches = feet * 12 + inches;
      const centimetres = totalInches * 2.54;
      return centimetres;
    },
    convertCmToFeetInches(cm) {
      const totalInches = Number(cm) / 2.54;
      const feet = Math.floor(totalInches / 12);
      const inches = Math.round(totalInches % 12) || "";
      return [feet, inches];
    },
    convertStonesPoundsToKg(stones, pounds) {
      stones = Number(stones) || 0;
      pounds = Number(pounds) || 0;
      const totalPounds = stones * 14 + pounds;
      const kilograms = totalPounds * 0.453592;
      return kilograms;
    },
    convertKgToStonesPounds(kg) {
      const totalPounds = kg / 0.453592;
      const stones = Math.floor(totalPounds / 14);
      const pounds = Math.round(totalPounds % 14) || "";
      return [stones, pounds];
    },
    setPlaceholderText(unit) {
      if (!unit) return "000";

      switch (unit.toLowerCase()) {
        case "metres":
          return "000 (m)";
        case "centimetres":
          return "000 (cm)";
        case "metres and centimetres":
          return "000 (cm)";
        case "feet":
          return "00 (ft)";
        case "inches":
          return "00 (in)";
        case "kilograms":
          return "000 (kg)";
        case "stones":
          return "00 (st)";
        case "pounds":
          return "00 (lb)";
        case "systolic / diastolic (mmhg)":
          return "000 / 00 (mmHg)";
        default:
          return "000";
      }
    },
    isValidBloodPressure(bp) {
      const regex = /^\d{2,3}\/\d{2,3}$/;
      return regex.test(bp);
    },
    validateInput() {
      const isBloodPressure = this.selectedUnit
        ?.toLowerCase()
        .includes("systolic / diastolic");
      this.isInputTouched = true;

      if (isBloodPressure) {
        this.inputValue = this.inputValue.replace(/[^0-9/]/g, "");
        this.inputValue2 = this.inputValue2.replace(/[^0-9/]/g, "");
      } else if (this.isDecimalNumber) {
        this.inputValue = this.inputValue.replace(/[^0-9.]/g, "");
        this.inputValue2 = this.inputValue2.replace(/[^0-9.]/g, "");
      } else if (this.isSecondInputShown) {
        this.inputValue = this.inputValue.replace(/[^0-9]/g, "").slice(0, 2);
        this.inputValue2 = this.inputValue2.replace(/[^0-9]/g, "").slice(0, 2);
      } else {
        this.inputValue = this.inputValue.replace(/[^0-9]/g, "");
        this.inputValue2 = this.inputValue2.replace(/[^0-9]/g, "");
      }

      if (
        isBloodPressure &&
        this.isInputTouched &&
        !this.isValidBloodPressure(this.inputValue)
      ) {
        this.errorMessage =
          "Please provide your blood pressure level following a 'Systolic / Diastolic (mmHg)' format, e. g. 120/60";
        EventBus.$emit("setAnswer", null);
      } else if (this.inputValue.length < 1 && this.isInputTouched) {
        this.errorMessage = "Please provide the required information above";
        EventBus.$emit("setAnswer", null, this.selectedUnit);
      } else {
        this.errorMessage = "";
        EventBus.$emit("setAnswer", this.finalValue, this.selectedUnit);
      }
    },
    resetData() {
      this.isInputTouched = false;
      this.errorMessage = "";
    }
  },
  watch: {
    questionData() {
      this.resetData();
    },
    previousUnit(newAnswer) {
      return this.isQuestionWithUnits
        ? (this.selectedUnit = newAnswer || this.questionData.units[0])
        : null;
    },
    previousAnswer() {
      this.inputValue = `${this.previousValue()[0]}`;
      this.inputValue2 = `${this.previousValue()[1]}`;
    }
  }
};
</script>

<style lang="less">
#QuestionTypeFloat {
  .options-container {
    display: flex;
    flex-direction: column;
    gap: 32px;
    margin-bottom: 32px;
  }

  .select-button {
    font-family: "SourceSansProSemibold";
    border: 2px solid #757575;
    background-color: #ffffff;
    border-radius: 6px;
    box-shadow: 0px 2px 2px 0px #33333326;
    padding: 12px 32px;
    color: #343c3d;
    text-align: left;
    font-size: 1.8rem;
    line-height: 2.8rem;

    &:hover {
      border-color: #343c3d;
    }

    &:focus {
      outline: 4px solid #ffbc11;
    }

    &.active {
      border: 2px solid #00008f;

      .select-button-text::before {
        display: none;
      }
    }
  }

  .select-button-text::before {
    content: "";
    position: relative;
    top: 3px;
    display: inline-block;
    width: 20px;
    height: 20px;
    border: 3px solid #757575;
    border-radius: 50%;
    margin-right: 8px;
  }

  .fa-circle-o {
    position: relative;
    top: 2px;
    width: 20px;
    height: 20px;
    font-size: 2rem;
    color: #00008f;
    margin-right: 8px;
  }

  .input-label {
    font-family: "SourceSansProSemibold";
    display: block;
    color: #343c3d;
    font-size: 1.8rem;
    line-height: 2.7rem;
    margin-bottom: 8px;
  }

  .input-container {
    display: flex;
    gap: 24px;
  }

  .input {
    width: 100%;
    font-family: "SourceSansProRegular";
    display: block;
    border: 1px solid #757575;
    border-radius: 6px;
    padding: 12px;
    color: #343c3d;
    font-size: 1.8rem;
    line-height: 2.7rem;

    &.error {
      border: 1px solid #c91432;
      padding: 13px 17px;
    }
  }

  .input-center {
    display: flex;
    justify-content: center;
  }

  .error-message {
    text-align: left;
    font-family: "SourceSansProSemibold";
    font-size: 1.8rem;
    line-height: 2.7rem;
    color: #c91432;
    margin-top: 8px;
  }
}
</style>
