<template>
    <div id="DmhLanding">
        <section id="CoachingBanner" class="coaching-home-banner-container pb-5">
            <div class="content-container">
                <div class="row coaching-home-banner mx-0 align-items-start pb-5">
                    <div class="coaching-home-banner-text col-md-7 col-sm-12">
                        <h1 class="coaching-home-banner-title mb-3 brand-highlight-1">Your Employee Assistance Programme
                            (EAP)</h1>
                        <h3 class="subtitle pb-5">Your EAP can support you with:</h3>
                        <ul class="pb-5">
                            <li>
                                <p class="p-text">A mental health worry – anxiety, stress, family or relationship
                                    matters.</p>
                            </li>
                            <li>
                                <p class="p-text">A financial or legal worry – if you’re experiencing low mood and need
                                    financial or legal support, our Life Management service gives you practical,
                                    impartial guidance on concerns including home and family issues, such as buying a
                                    house, debt management, or tenants’ rights.</p>
                            </li>
                        </ul>
                        <h3 class="coaching-home-banner-subtitle pb-3">How can we help you today?</h3>
                        <p>You can access support in the way that suits you best. Please note to use the EAP service
                            anonymously, please call our phone line instead of accessing it through the online journey.
                        </p>
                    </div>
                    <div class="col-md-5">
                        <img src="/img/dmhhero.jpeg" class="img-fluid" />
                    </div>
                </div>
            </div>
        </section>
        <section class="content-container pb-5">
            <div class="row px-3 pb-5">
                <div class="col-12 col-md-4 pb-4">
                    <img class="img-fluid pb-4" style="max-width: 150px;" src="/img/OnlineHealth.svg">
                    <hr class="divider pb-4" />
                    <h4 class="subtitle pb-4">Online</h4>
                    <p class="p-text pb-4">
                        Answer some questions and you'll be put in touch with the right support.<br /><br />You can also
                        find out more about the service, or learn more about mental health and wellbeing.
                    </p>
                    <b-button @click="$router.push('/dmh/booking')" variant="cta" size="lg" class="w-100" squared>Use
                        online services <i class="fa-solid fa-chevron-right fa-sm"></i></b-button>
                </div>
                <div class="col-12 col-md-4 pb-4">
                    <img class="img-fluid pb-4" style="max-width: 150px;" src="/img/ChatBubble.svg">
                    <hr class="divider pb-4" />
                    <div class="d-flex flex-row align-items-center justify-content-start pb-4">
                        <h4 class="subtitle">Messaging</h4>
                        <h4 class="available ml-3">Available 24/7</h4>
                    </div>
                    <p class="p-text pb-4">
                        You’ll get answers to your messages straight away.
                    </p>
                    <div class="pb-5">
                        <a class="livechat-button p-text brand-highlight-1" @click="initChat()">Use live chat <i
                                class="fa-solid fa-chevron-right"></i></a>
                    </div>
                    <p class="p-text semibold pb-2">Message via Whatsapp:</p>
                    <p class="p-text semibold pb-2">UK: {{ supportContacts?.whatsapp.uk }}</p>
                    <!-- <p class="p-text semibold pb-4">IE: {{ supportContacts?.whatsapp.ie }}</p> -->
                    <p class="p-text semibold pb-2">Send a text messages:</p>
                    <p class="p-text semibold ">UK: {{ supportContacts?.sms.uk }}</p>
                    <!-- <p class="p-text semibold ">IE: {{ supportContacts?.sms.ie }}</p> -->

                </div>
                <div class="col-12 col-md-4 pb-4">
                    <img class="img-fluid pb-4" style="max-width: 150px;" src="/img/Phone.svg">
                    <hr class="divider pb-4" />
                    <div class="d-flex flex-row align-items-center justify-content-start pb-4">
                        <h4 class="subtitle">Phone</h4>
                        <h4 class="available ml-3">Available 24/7</h4>
                    </div>
                    <p class="p-text pb-4">
                        A qualified mental health practitioner or counsellor will talk with you about your problem and
                        connect you with the right person to help.<br /><br />If you’re a manager, you can also call
                        this number for support with any management concerns.
                    </p>
                    <p v-if="supportContacts.phone.uk" class="p-text semibold pb-2"><i class="fa-solid fa-phone mr-3"></i>UK: <a class="link" :href="`tel:${supportContacts?.phone.uk}`" target="_blank" rel="noopener noreferrer">{{
                        supportContacts?.phone.uk }}</a></p>
                    <!-- <p class="p-text semibold pb-2">IE: {{ supportContacts?.phone.ie }}</p> -->
                </div>
            </div>
        </section>
        <section class="content-container pb-5">
            <div class="row px-3 pb-5">
                <div class="col-12 warning-container px-4 py-4">
                    <h4 class="subtitle pb-3">Feeling like you're at risk?</h4>
                    <p class="p-text">The Samaritans are a charity that have someone on hand, 24/7. If you feel like you
                        could be a risk to yourself or others, you can call them on <a href="tel:116123"
                            class="bold brand-highlight-1 underlined">116 123</a></p>
                    <p class="p-text pb-0">You could also contact <a href="tel:111"
                            class="bold brand-highlight-1 underlined">111</a> or <a href="tel:999"
                            class="bold brand-highlight-1 underlined">999</a> for the NHS emergency services.</p>
                </div>
            </div>
        </section>


    </div>
</template>

<script>
import slick from "vue-slick";

export default {
    name: "CoachingHome",
    components: {
        slick,
    },
    methods: {
        initChat() {
            let sf = document.getElementById('live-chat-widget');
            if (sf) {
                sf.style.display = "initial";
            } else {
                let script = document.createElement('script');
                script.src = "/assets/salesforce_Script.js";
                script.type = "text/javascript";
                script.charset = "UFT-8";
                script.setAttribute("id", "sf-script")
                document.head.appendChild(script);
            }
        },
    },
    computed: {
        supportContacts() {
            return this.$store.state.user.support_contacts;
        }
    }
};
</script>
<style lang="less">
#DmhLanding {
    background-color: #FAFAFA;

    .warning-container {
        border-radius: 16px;
        padding: 15px;
        background-color: #ECF1F8;
        border: 2px solid #4976BA;
    }

    .subtitle {
        font-size: 1.8rem;
        font-weight: bold;
        margin-bottom: 0;
    }

    .available {
        font-size: 1.4rem;
        font-weight: bold;
        color: #138636;
        margin-bottom: 0;
    }

    .livechat-button {
        padding-bottom: 20px;
        font-weight: 600;

        &:hover {
            text-decoration: underline;
        }
    }

    .section-title {
        font-size: 2.8rem;
        font-weight: bold;
        letter-spacing: normal;
        font-weight: bold;
    }

    .link {
      color: #00008F;
    }

    .contact-card-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 50px;

        .contact-card {
            background-color: white;
            border: 1px solid lightgrey;
            display: flex;
            flex-grow: 1;
            min-width: 400px;
            flex-direction: column;
            align-items: center;
            padding: 25px;

            .contact-card-icon {
                margin-bottom: 20px;
                background-color: lightblue;
                width: 64px;
                height: 64px;
                border-radius: 100px;
                display: flex;
                align-items: center;
                justify-content: center;

                i {
                    color: white;
                    font-size: 2.5rem;
                }
            }

            .contact-card-title {
                font-size: 2rem;
                font-weight: bold;
                letter-spacing: normal;
                font-weight: bold;
                margin: 0;
                padding: 0;
            }

            .contact-card-247 {
                font-size: 1.4rem;
                font-weight: bold;
                letter-spacing: normal;
                font-weight: bold;
                margin-bottom: 10px;
                padding: 0;
                color: #138636;
            }

            p {
                font-size: 1.6rem;
            }
        }
    }

    .coaching-home-banner-container {
        .coaching-home-banner {
            padding-top: 60px;
        }

        .coaching-home-banner-text {
            font-size: 1.6rem;
            z-index: 2;

            .coaching-home-banner-title {
                font-size: 4.2rem;
                font-weight: bold;
                padding-bottom: 25px;
            }


        }

        .img-fluid {
            border-radius: 8px;
        }

        div {
            z-index: 2;
        }
    }

    ol {
        padding-left: 15px;

        li {
            font-size: 1.6rem;
        }
    }

    ul {
        padding-left: 15px;
    }

    @media only screen and (max-width: 768px) {
        .coaching-pathways-container {
            grid-template-columns: 1fr 1fr !important;
        }
    }

    .coaching-pathways-container {
        padding-bottom: 30px;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        column-gap: 30px;

        .coaching-pathway {
            background-color: white;
            border-radius: 8px;
            margin-top: 20px;
            margin-bottom: 20px;
            display: flex;
            flex-direction: column;
            position: relative;
            overflow: hidden;
            z-index: 2;
            flex-grow: 1;

            .coaching-pathway-container {
                flex-grow: 1;
                display: flex;
                flex-direction: column;
                padding: 15px;
            }

            h4 {
                font-size: 1.8rem;
                font-weight: bold;
                margin-bottom: 15px;
                z-index: 10;
            }

            p {
                font-size: 1.4rem;
                z-index: 10;
            }

            .coaching-pathway-image {
                display: flex;
                align-items: center;
                justify-content: center;

                img {
                    height: auto;
                    width: 100%;
                }
            }

            .pathway-card-button {
                margin-left: -10px;
                padding: 10px;
                font-size: 1.4rem;
            }

        }
    }

    .horizontal-divider {
        flex-grow: 1;
        margin-left: 20px;
        border: 2px solid;
    }

    .banner-overlay {
        position: absolute;
        width: 100%;
        bottom: 100%;
        z-index: 1;

        img {
            width: 100%;
        }
    }

    .pathway-overlay {
        position: absolute;
        width: 100%;
        bottom: 0;
        z-index: 1;

        img {
            width: 100%;
        }
    }

    .pathway-blob {
        position: absolute;
        width: 100%;
        bottom: 0;
        right: 0;
        z-index: 1;

        div {}
    }
}

#pathway-modal {
    .modal-content {
        border-radius: 24px;
    }

    .pathway-modal-inner {
        max-height: 90vh;
        overflow-y: auto;
    }

    .close-button {
        cursor: pointer;
    }

    .pathway-text {
        font-size: 1.4rem;
    }

    img {
        width: 80%;
        border-radius: 18px !important;
    }
}
</style>