<template>
  <div id="EmergencyBox">
    <div v-if="isEAPNumberShown" class="contact-us">
      <h3 class="subtitle dark mb-3">Contact us via phone</h3>
      <img class="phone-img" src="/img/health-score/phone.png" alt="Phone" />
      <p class="available-label">Available 24/7</p>
      <p class="paragraph dark mb-4">
        We'll talk to you about your problem, and connect you to the right
        person to help.
      </p>
      <div class="call-us-container">
        <i class="fa fa-phone" aria-hidden="true"></i>
        <span class="paragraph semibold">Call us on UK: <a class="link" :href="`tel:${supportContacts?.phone.uk}`" target="_blank" rel="noopener noreferrer">{{ supportContacts?.phone.uk }}</a></span>
      </div>
    </div>
    <div class="emergency-box">
      <h4 class="subtitle pb-3">Feeling like you're at risk?</h4>
      <p class="p-text">The Samaritans are a charity that have someone on hand, 24/7. If you feel like you could be a
        risk to yourself or others, you can call them on <a href="tel:116123"
          class="bold brand-highlight-1 underlined">116 123</a></p>
      <p class="p-text pb-0">You could also contact <a href="tel:111" class="bold brand-highlight-1 underlined">111</a>
        or <a href="tel:999" class="bold brand-highlight-1 underlined">999</a> for the NHS emergency services.</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "EmergencyBox",
  computed: {
    supportContacts() {
      return this.$store.state.user?.support_contacts;
    },
    isEAPNumberShown() {
      return this.$store.state.user?.eligibilities?.ShouldHaveEapSupportLine || null;
    }
  }
};
</script>

<style lang="less">
#EmergencyBox {
  color: #111b1d;

  .contact-us {
    margin-bottom: 40px;

    .phone-img {
      width: 70px;
      object-fit: contain;
      margin-bottom: 20px;
    }
  }

  .available-label {
    font-family: "SourceSansProSemibold";
    color: #138636;
    font-size: 1.6rem;
    line-height: 2.4rem;
    margin-bottom: 8px;
  }

  .call-us-container {
    display: inline-block;
    border-bottom: 2px solid #00008f;

    .fa-phone {
      font-size: 1.6rem;
      color: #00008f;
      margin-right: 8px;
    }
  }

  .emergency-box {
    text-align: left;
    background-color: #ecf1f8;
    border: 1px solid #4976ba;
    border-radius: 6px;
    padding: 16px;
  }

  .emergency-box-title {
    font-family: "SourceSansProBold";
    color: #343c3d;
    font-size: 1.8rem;
    line-height: 2.4rem;
    margin-bottom: 8px;
  }

  .subtitle {
    font-family: "SourceSansProBold";
    color: #343c3d;
    font-size: 2.2rem;
    line-height: 2.8rem;

    &.dark {
      color: #111b1d;
    }
  }

  .paragraph {
    font-family: "SourceSansProRegular";
    text-align: left !important;
    color: #343c3d;
    font-size: 1.8rem;
    line-height: 2.4rem;

    &.semibold, .link {
      font-family: "SourceSansProSemibold";
      color: #00008f;

      &:hover {
        text-decoration: none;
      }
    }

    &.dark {
      color: #111b1d;
    }
  }

  .link {
    font-family: "SourceSansProRegular";
    color: #01018f;
    font-size: 1.8rem;
    line-height: 2.4rem;

    &:hover {
      text-decoration: underline;
    }

    &.tel {
      font-family: "SourceSansProBold";
      color: #01018f;
      text-decoration: underline;
    }
  }
}
</style>
