<template>
	<div id="achievements-wrapper" class="bg-white brand-text mb-5">
		<div class="achievements-content row no-gutters row-eq-heigh">
			<div :class="[showCheckIn ? 'col-md-3' : 'd-none']">
				<MyProgressColumn />
			</div>
			<div :class="[showCheckIn ? 'col-md-5' : 'col-md-8 borders']">
				<AchievementsColumn :badges="badges" :day_streaks="day_streak" />
			</div>
			<div class="col-md-4">
				<ImpactColumn :bottles="bottles" :trees="trees" />
			</div>
		</div>
	</div>
</template>
<script>
import MyProgressColumn from "./MyProgressColumn.vue";
import AchievementsColumn from "./AchievementsColumn.vue";
import ImpactColumn from "./ImpactColumn.vue";
import AchievementCelebration from "./AchievementCelebration";
import EventBus from "../../../helpers/eventbus";

export default {
	components: {
		MyProgressColumn,
		AchievementsColumn,
		ImpactColumn
	},
	name: "AchievementsWrapper",
	data() {
		return {
			bottles: 0,
			trees: 0,
			badges: [],
			day_streak: 0
		}
	},
	mounted() {
		console.log(this.$route.path);
		this.getData()
		let t = this
		EventBus.$on("getCoachingData", function () {
			t.getData();
		});
	},
	methods: {
		async getData() {
			let params = {
				path: "api/u/coaching/get-achievements-data",
			};
			let res = await this.api(params);
			if (res.success) {
				this.badges = res.achievements
				this.bottles = res.bottles
				this.trees = res.trees
				this.day_streak = res.streak_days

				let to_celebrate = this.badges.filter(b => {
					return !b.seen_by_user
				})

				for (let i = 0; i < to_celebrate.length; i++) {
					this.startConfetti()
					await this.celebrateModal(to_celebrate[i])
					this.stopConfetti()
					await this.markSeen(to_celebrate[i])
				}
			}
		},
		async celebrateModal(achievement) {
			const content = this.$createElement(AchievementCelebration, { props: { achievement: achievement } })
			const skin = this.$store.getters.skin;
			let opts = {
				hideHeader: true,
				hideFooter: true,
				modalClass: "achievement_celebration_modal theme_" + skin,
				headerClass: "achievement_celebration_modal_header",
				footerClass: "achievement_celebration_modal_footer",
				okTitle: 'Done',
				size: "sm",
				static: true,
				buttonSize: "sm",
				centered: true,
				contentClass: "achievement_celebration_modal",
				id: 'achievement_celebration_modal'
			}
			return this.$bvModal.msgBoxOk(content, opts);
		},
		startConfetti() {
			this.$confetti.start({
				windSpeedMax: 0,
				particles: [
					{
						type: 'rect'
					},
					{
						type: 'heart',
						size: 7,
						dropRate: 7
					}
				]
			});
		},
		stopConfetti() {
			this.$confetti.stop();
		},
		async markSeen(achievement) {
			let params = {
				path: "api/u/coaching/mark-achievement-seen",
				achievement_id: achievement.id
			};
			return await this.api(params);
		}
	},
	computed: {
		showCheckIn(){
			return this.$route.path !== '/health-coaching'
		},
	}
};
</script>
<style lang="less">
#achievements-wrapper {
	border-radius: 15px;
	border: 2px solid #3a4a61;

	.borders {
		border-left: 2px solid rgba(255, 255, 255, 0.07);
		border-right: 2px solid rgba(255, 255, 255, 0.07);
	}

	.bottom_button {
		position: absolute;
		width: 100%;
		text-align: center;
		bottom: 30px;
	}
}
</style>