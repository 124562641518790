<template>
  <div id="AssessmentCard">
    <div
      class="d-flex justify-content-center"
      v-if="isRestartLoading || isGetScoreLoading"
    >
      <b-spinner class="spinner"></b-spinner>
    </div>
    <template v-else>
      <div
        v-if="areProgressBarsShown && progressText"
        class="progress-container"
      >
        <div class="progress-bar">
          <div
            class="progress-bar-inner"
            :style="{
              width: progressWidth + '%',
              backgroundColor: isAssessmentCompleted ? '#138636' : '#00ADC6'
            }"
          ></div>
        </div>
        <p class="progress-text mb-0">
          {{ progressNumber }}% {{ progressText }}
        </p>
      </div>
      <div class="card-top">
        <img class="img" :src="cardData.imgSrc" :alt="cardData.alt" />
        <div>
          <h3 class="title card-top-title">{{ cardData.title }}</h3>
          <p class="card-top-paragraph" v-if="score && score.score">
            Score: {{ score.score }} {{ score.outcome }}
          </p>
        </div>
      </div>
      <div class="card-score-bar">
        <ScoreBar v-if="score && (score.score || score.score === 0)" :score="score" />
      </div>
      <div class="card-main">
        <h4 class="title card-main-title">{{ cardData.subtitle }}</h4>
        <p class="card-main-paragraph mb-0">{{ cardParagraph }}</p>
      </div>
      <button class="card-button-next" @click="handleNextButtonClick">
        {{ buttonText }}
      </button>
      <button
        v-if="isAssessmentCompleted"
        @click="restartAssessment"
        :disabled="isRestartLoading"
        class="card-button card-button-restart"
      >
        <b-spinner v-if="isRestartLoading"></b-spinner>
        <span v-else>
          <i class="fa fa-refresh" aria-hidden="true"></i>
          Restart assessment
        </span>
      </button>
    </template>
  </div>
</template>

<script>
import axios from "axios";
import config from "@/config/constants";
import EventBus from "@/helpers/eventbus";
import ScoreBar from "./ScoreBar.vue";

export default {
  name: "AssessmentCard",
  components: {
    ScoreBar
  },
  props: ["cardData", "healthScoreProgress", "areProgressBarsShown"],
  data() {
    return {
      score: null,
      isGetScoreLoading: false,
      isRestartLoading: false
    };
  },
  computed: {
    questionnaireName() {
      return this.cardData?.id;
    },
    demographicsProgress() {
      return this.healthScoreProgress.demographics || 0;
    },
    progressNumber() {
      const progress =
        this.healthScoreProgress &&
        this.healthScoreProgress[this.questionnaireName];
      return progress;
    },
    progressWidth() {
      const defaultProgress = 0;
      return this.progressNumber || defaultProgress;
    },
    progressText() {
      return this.progressNumber >= 100
        ? "complete"
        : this.progressNumber > 0
        ? "progress"
        : "complete";
    },
    isAssessmentCompleted() {
      return this.progressNumber >= 100;
    },
    buttonText() {
      return this.isAssessmentCompleted
        ? "View results"
        : this.progressNumber > 0
        ? "Continue assessment"
        : this.cardData?.buttonText;
    },
    cardParagraph() {
      const completedAssessmentText = `Thank you for completing the ${this.questionnaireName
        .split("_")
        .join(
          " "
        )} assessment. You can see your results, or take the assessment again.`;
      return this.isAssessmentCompleted
        ? completedAssessmentText
        : this.cardData.paragraph;
    }
  },
  mounted() {
    if (this.isAssessmentCompleted) {
      this.getScore();
    }
  },
  methods: {
    startAssessment() {
      const route =
        this.demographicsProgress === 0 ? "data-protection" : "assessment";
      this.$router.push({
        path: `/healthscore/${route}/${this.questionnaireName}`
      });
    },
    handleNextButtonClick() {
      if (this.isAssessmentCompleted) {
        this.$router.push(`/healthscore/results/${this.questionnaireName}`);
      } else if (this.progressNumber >= 0) {
        this.startAssessment();
      }
    },
    async getScore() {
      if (this.isGetScoreLoading) {
        return;
      }
      try {
        this.isGetScoreLoading = true;
        const payload = {
          user_id: this.$store.getters.user_id,
          questionnaire_name: this.questionnaireName
        };
        const res = await axios.post(
          config.api_env + "/application/api/u/sapphire/healthscore/score",
          payload,
          config.options
        );
        if (res.data.success) {
          this.score = res.data;
        }
      } catch (err) {
        console.error(err);
      } finally {
        this.isGetScoreLoading = false;
      }
    },
    async restartAssessment() {
      if (this.isRestartLoading) {
        return;
      }
      try {
        this.isRestartLoading = true;
        const payload = {
          user_id: this.$store.getters.user_id,
          questionnaire_name: this.questionnaireName
        };
        const res = await axios.post(
          config.api_env +
            "/application/api/u/sapphire/healthscore/restart-questionnaire",
          payload,
          config.options
        );
        if (res.data.success) {
          this.startAssessment()
        }
      } catch (err) {
        console.error(err);
      } finally {
        this.isRestartLoading = false;
      }
    }
  }
};
</script>

<style lang="less">
#AssessmentCard {
  position: relative;
  flex: 1 1 33%;
  display: flex;
  flex-direction: column;
  max-width: 358px;
  border-radius: 12px;
  overflow: hidden;
  background-color: #ffffff;
  box-shadow: 0px 2px 2px 0px #33333326;
  padding: 24px;

  .spinner {
    border-color: #00008f;
    border-right-color: transparent;
  }

  .progress-container {
    margin-bottom: 32px;
  }

  .progress-text {
    font-family: "SourceSansProSemibold";
    font-size: 1.4rem;
    line-height: 1.8rem;
    text-transform: uppercase;
    text-align: right;
    color: #00006d;
  }

  .progress-bar {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 8px;
    background-color: #e5e5e5;
  }

  .progress-bar-inner {
    height: 100%;
  }

  .card-top {
    display: flex;
    gap: 24px;
    align-items: center;
    margin-bottom: 8px;

    .img {
      width: 78px;
      height: 78px;
      object-fit: contain;
      color: #111b1d;
      font-family: "SourceSansProRegular";
      font-size: 1.8rem;
    }
  }

  .card-top-paragraph {
    font-family: "SourceSansProBold";
    color: #343c3d;
    font-size: 1.8rem;
    line-height: 2.7rem;
    margin-bottom: 0;
  }

  .title {
    font-family: "SourceSansProBold";
    font-size: 2.2rem;
    line-height: 2.8rem;
  }

  .card-top-title {
    color: #00006d;
  }

  .card-main {
    flex: 1 1 100%;
    margin-bottom: 40px;
  }

  .card-main-title {
    color: #343c3d;
    margin-bottom: 4px;
  }

  .card-main-paragraph {
    font-family: "SourceSansProRegular";
    color: #111b1d;
    font-size: 1.8rem;
    line-height: 2.7rem;
  }

  .card-button-next {
    width: 100%;
    text-align: center;
    font-family: "SourceSansProSemibold";
    background-color: #00008f;
    border-radius: 6px;
    padding: 8px;
    color: #ffffff;
    font-size: 1.6rem;
    line-height: 2.7rem;
    transition: background-color 0.2s;
    border: 0;

    &:hover {
      background-color: #00006d;
    }

    &:focus {
      outline: 4px solid #ffbc11;
    }
  }

  .card-button-restart {
    width: 100%;
    text-align: center;
    font-family: "SourceSansProSemibold";
    background-color: #fff;
    border-radius: 6px;
    padding: 8px;
    color: #00008f;
    font-size: 1.6rem;
    line-height: 2.7rem;
    border: 1px solid #00008f;
    margin-top: 8px;
    transition: background-color 0.2s;

    &:hover {
      border: 1px solid #00006d;
      color: #00006d;
    }

    &:focus {
      border: 1px solid #00006d;
      outline: 4px solid #ffbc11;
    }
  }

  .fa-refresh {
    margin-right: 8px;
  }

  .card-score-bar {
    margin-bottom: 12px;
  }
}

@media only screen and (max-width: 1024px) {
  #AssessmentCard {
    max-width: 440px;
  }
}
</style>
