<template>
  <div id="HealthScoreDataProtection">
    <LoadingAnimation v-if="isLoadingAnimationShown" title="nearlyThere" />
    <div v-else class="data-protection-inner">
      <div class="hero">
        <div class="text-center">
          <img class="hero-image" :src="pageData.imgSrc" alt="" />
        </div>
        <h2 class="title">{{ pageData.title }}</h2>
        <p class="paragraph mb-4">
          {{ pageData.paragraph[questionnaireName] }}
        </p>
      </div>
      <button class="btn-continue" @click="nextStep">
        Continue
        <i class="fa fa-angle-right ml-4" aria-hidden="true"></i>
      </button>
      <EmergencyBox />
      <p class="bottom-paragraph">
        About your data -
        <a class="bottom-link" href="/privacy-policy" target="_blank"
          >privacy policy</a
        >
      </p>
    </div>
  </div>
</template>

<script>
import EventBus from "@/helpers/eventbus";
import EmergencyBox from "../components/HealthScore/EmergencyBox.vue";
import LoadingAnimation from "../components/HealthScore/LoadingAnimation.vue";

export default {
  name: "HealthScoreDataProtection",
  components: {
    EmergencyBox,
    LoadingAnimation
  },
  data() {
    return {
      isLoadingAnimationShown: false,
      timeoutId: null,
      existingAssessments: ["well_in_life", "well_in_body", "well_in_mind"]
    };
  },
  computed: {
    questionnaireName() {
      return this.$route.params.assessment_id;
    },
    pageData() {
      const data = {
        title: `${this.questionnaireName.split("_").join(" ")} assessment`,
        paragraph: {
          well_in_body:
            "Please answer the following questions honestly - your answers are confidential, and we use them to help us understand how to support you with better physical health.",
          well_in_life:
            "Please answer the following questions honestly - your answers are confidential, and we use them to help us understand how to support you with life's everyday challenges.",
          well_in_mind:
            "Please answer the following questions honestly - your answers are confidential, and we use them to help us understand how to support you with better mental health."
        },
        imgSrc: `/img/health-score/${this.questionnaireName}.png`,
        alt: {
          well_in_body: "Girl riding a bicycle",
          well_in_life: "Man and woman smiling while playing football",
          well_in_mind: "Woman meditating"
        }
      };

      return data;
    }
  },
  async mounted() {
    this.scrollToTop();
    EventBus.$emit("startHealthScoreAssessment");
    if (!this.existingAssessments.includes(this.questionnaireName)) {
      this.$router.push("/healthscore");
    }
  },
  beforeDestroy() {
    EventBus.$emit("finishHealthScoreAssessment");
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
  },
  methods: {
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    },
    nextStep(event) {
      event.target.blur();
      this.scrollToTop();
      this.isLoadingAnimationShown = true;
      this.timeoutId = setTimeout(() => {
        this.$router.push({
          path: `/healthscore/assessment/${this.questionnaireName}`
        });
      }, 4000);
    }
  }
};
</script>

<style lang="less">
@padding-x: 28px;
@max-width: calc(1110px + @padding-x * 2);

.centered {
  max-width: @max-width;
  margin: 0 auto;
}

#HealthScoreDataProtection {
  background-color: #fafafa;

  .data-protection-inner {
    padding: 80px @padding-x;
    .centered();
  }

  .hero {
    margin-bottom: 16px;
  }

  .hero-image {
    width: 100%;
    max-height: 200px;
    object-fit: contain;
    margin-bottom: 40px;
    color: #111b1d;
    font-family: "SourceSansProRegular";
    font-size: 1.8rem;
  }

  .title {
    font-family: "PublicoHeadlineWebBold";
    color: #00008f;
    font-size: 3.6rem;
    line-height: 4.5rem;
    margin-bottom: 16px;

    &::first-letter {
      text-transform: capitalize;
    }
  }

  .subtitle {
    font-family: "SourceSansProBold";
    color: #343c3d;
    font-size: 2.2rem;
    line-height: 2.8rem;

    &.dark {
      color: #111b1d;
    }
  }

  .paragraph {
    font-family: "SourceSansProRegular";
    color: #343c3d;
    font-size: 1.8rem;
    line-height: 2.4rem;

    &.semibold {
      font-family: "SourceSansProSemibold";
      color: #00008f;
    }

    &.dark {
      color: #111b1d;
    }
  }

  .link {
    font-family: "SourceSansProRegular";
    color: #01018f;
    font-size: 1.8rem;
    line-height: 2.4rem;

    &:hover {
      text-decoration: underline;
    }

    &.tel {
      font-family: "SourceSansProBold";
      color: #01018f;
      text-decoration: underline;
    }
  }

  .btn-continue {
    font-family: "SourceSansProSemibold";
    color: #343c3d;
    width: 100%;
    background-color: #00008f;
    padding: 12px;
    color: #ffffff;
    font-size: 1.8rem;
    line-height: 2.7rem;
    border: 0;
    border-radius: 6px;
    margin-bottom: 32px;

    &:hover {
      background-color: #00006d;
    }

    &:focus {
      outline: 4px solid #ffbc11;
    }

    &:disabled {
      background-color: #e5e5e5;
      border: 1px solid #e5e5e5;
      color: #757575;
    }
  }

  .bottom-paragraph {
    font-family: "SourceSansProRegular";
    color: rgba(17, 27, 29, 1);
    font-size: 1.8rem;
    line-height: 2.7rem;
    margin-top: 24px;
  }

  .bottom-link {
    font-family: "SourceSansProBold";
    color: rgba(0, 0, 143, 1);
    font-size: 1.8rem;
    line-height: 2.7rem;
    text-decoration: underline;

    &:hover {
      text-decoration: underline;
    }
  }
}
</style>
