<template>
    <div id="DmhSliderQuestion" class="border-brand-primary">
            <!-- <div class="dmh-confirm-container w-100 shadow" >
                <div class="dmh-confirm-field brand-tertiary">{{ question.slider_text }}</div>
                <select class="dmh-confirm-input" v-model="answer[question.slider_text]">
                    <option v-for="option in question.options" :value="option">{{option}}</option>
                </select>
            </div> -->
            <Slider :question="question" :updateAnswer="updateAnswer" :index="i" v-for="(question, i) in question.sliders"></Slider>

    </div>
</template>
  
<script>
import { mapGetters } from "vuex";
import Slider from "./Slider.vue"

export default {
    name: "DmhStart",
    props: ['question', 'answer', 'setAnswer'],
    components: {
        Slider
    },
    mounted() { },
    data() {
        return {
        };
    },
    mounted() {
    },
    methods: {
        updateAnswer(question, answer){
            this.answer[question.slider_text] = answer;
        }
    },
    computed: {
        ...mapGetters(["user"]),
    },
};
</script>
<style lang="less">
#DmhSliderQuestion {
    display: flex;
    flex: 1 1 0px;
    flex-direction: column;
    align-items: center;
    text-align: center;

    .dmh-confirm-container {
        display: flex;
        background-color: white;
        border: 1px solid ;
        border-color: inherit;
        border-radius: 8px;
        flex-direction: column;
        font-size: 1.8rem;
        padding: 15px;
        margin-bottom: 20px;
        .dmh-confirm-input{
            margin-top: 15px;
            font-size: 2rem;
        }

        select {
            cursor: pointer;
            border: none;
            width: 100%;
        }

        select:focus {
            outline-width: 0;
        }
    }

}
</style>
  