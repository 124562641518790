<template>
  <div id="RandoxKit">
    <div v-if="currentView === 'loading'" class="spinner-container">
      <b-spinner></b-spinner>
    </div>
    <div v-else-if="currentView === 'order'">
      <RandoxKitOrder @changeView="changeCurrentView" />
    </div>
    <div v-else-if="currentView === 'submitted'" class="submitted">
      <div class="container">
        <i class="fa fa-check-circle" aria-hidden="true"></i>
        <h2 class="title">
          Order Confirmed
        </h2>
        <button class="btn-dark" @click="goHome">Back to home</button>
      </div>
    </div>
    <div v-else-if="currentView === 'book'" class="book">
      <div class="container">
        <h2 class="title">Remote Bloods</h2>
        <p class="paragraph">We've received your blood kit results. You can now book in for a session with our team to discuss your results.
          AXA Health collects your information, and shares it with Randox Health, on the understanding that it is private
          and confidential. AXA Health and Randox Health will use your information in accordance with their respective
          privacy policies, which can be accessed via the following links:
        </p>
        <a class="link" href="https://randoxhealth.com/privacy-policy-axa/" target="_blank">https://randoxhealth.com/privacy-policy-axa/</a>
        <button class="btn-dark" @click="showBookingScreen">Book Slot</button>
      </div>
    </div>
    <div v-else-if="currentView === 'bookingScreen'">
      <RandoxBookingScreen @changeCurrentView="changeCurrentView"/>
    </div>
    <div v-else-if="currentView === 'bookingConfirmed'" class="booking-confirmed">
      <div class="container">
        <i class="fa fa-check-circle" aria-hidden="true"></i>
        <h2 class="title">
          Booking confirmed!
        </h2>
        <p class="paragraph">You should shortly receive an email for this in your email inbox.</p>
        <button class="btn-dark" @click="goHome">Back to home</button>
      </div>
    </div>
    <div v-else-if="['error', 'not_eligible', 'waiting'].includes(currentView)" class="info p-5 text-center flex-column">
      <h2 class="title">{{ infoScreenTitle }}</h2>
      <button class="btn-dark" @click="$router.push('/')">Return to Home</button>
    </div>
  </div>
</template>

<script>
import RandoxBookingScreen from '../components/RandoxKit/RandoxBookingScreen.vue';
import RandoxKitOrder from '../components/RandoxKit/RandoxKitOrder.vue';
import axios from "axios";
import config from "@/config/constants";

export default {
  name: "RandoxKit",
  components: {
    RandoxKitOrder,
    RandoxBookingScreen
  },
  data() {
    return {
      currentView: "loading",
    }
  },
  computed: {
    infoScreenTitle() {
      return this.currentView === "not_eligible" ? "You are not eligible for this service." : this.currentView === "waiting" ? "We're processing your order." : "Something went wrong..."
    }
  },
  mounted() {
    this.getRandoxKit()
  },
  methods: {
    async getRandoxKit() {
      try {
        const payload = {
          user_id: this.$store.getters.user_id
        };
        const res = await axios.post(
          config.api_env +
            "/application/api/u/randox/landing",
          payload,
          config.options
        );
        if (res.data.success) {
          this.currentView = res.data.status // "order", "book", "waiting", "not_eligible" // res.data.status
        } else {
          this.currentView = 'error'
        }
      } catch(err) {
        this.currentView = 'error'
        console.error(err);
    }
    },
    goHome() {
      this.$router.push("/")
    },
    changeCurrentView(view) {
      this.currentView = view
    },
    showBookingScreen() {
      this.currentView = 'bookingScreen'
    }
  }
}
</script>

<style lang="less">
#RandoxKit {
  background-color: #FAFAFA;

  .spinner-container {
    text-align: center;
    padding: 20px;
    color: #343C3D;
  }

  .container {
    padding: 20px;
    max-width: 920px;
    margin: 0 auto;
  }

  .submitted, .booking-confirmed, .info {
    height: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;

    .title {
      color: #192550;
      font-weight: 700;
      font-size: 2.4rem;
      line-height: 3rem;
      margin-bottom: 20px;
    }

    .paragraph {
      color: #343C3D;
      font-size: 1.6rem;
      line-height: 2.4rem;
    }

    .fa {
      color: #03B57C;
      font-size: 10rem;
      margin-bottom: 32px;
    }
  }

  .btn-dark {
    color: #FFFFFF;
    background-color: #00008F;
    border-radius: 6px;
    border: 0;
    font-size: 1.8rem;
    line-height: 2.7rem;
    padding: 12px 24px;
    margin-top: 40px;

    &:hover {
      background-color: #00006D;
    }

    &:focus {
      outline: 4px solid #FFBC11;
    }

    &:disabled {
      background-color: #E5E5E5;
      color: #757575;
    }
  }

  .btn-light {
    border: 1px solid #00008F;
    background-color: #fff;
    padding: 12px 24px;
    border-radius: 6px;
    font-size: 1.8rem;
    line-height: 2.7rem;
    color: #00008F;
    font-weight: 400;
  }

  .book {
    background-color: #FAFAFA;
    height: 100%;

    .title {
      color: #36383A;
      font-size: 3.2rem;
      line-height: 3.8rem;
      margin-bottom: 40px;
    }

    .paragraph {
      color: #343C3D;
      font-size: 1.6rem;
      line-height: 2.4rem;
    }

    .btn-dark {
      width: 100%;
      max-width: 260px;
    }

    .link {
      display: block;
      color: #3A5E94;
      display: block;
      font-size: 1.6rem;
      line-height: 2.4rem;
    }
  }
}
</style>