<template>
	<div id="achievements" class="h-100 brand-text">
		<div id="streak_info">
			<div id="streak_info_top">
				<h3>Current Streak</h3>
				<h2>{{ getDaysString() }}</h2>
			</div>
			<div id="border_div"></div>
		</div>
		<div class="ml-4 pl-2 d-flex justify-content-start align-items-center">
      <div class="hexagon-container">
        <span class="hexagon-text">{{badges.length}}</span>
        <span class="hexagon"></span>
      </div>
			<div class="badges-text bg-brand-highlight-1 white-text">
        Total Badges
			</div>
		</div>
		<div id="badges">
			<div class="col-12" id="badge-holder">
				<Badge v-for="(b, i) in badges" :badge="b" :key="i" style="margin-right: 20px" />
			</div>
		</div>
		<div class="bottom_button">
			<b-button @click="showAllAchievements()" variant="cta" size="lg" squared class=" shadow">View All</b-button>
		</div>
	</div>
</template>
<script>
import Badge from "./Badge.vue";
import AllAchievementsModal from "./AllAchievementsModal";
export default {
	components: {
		Badge
	},
	name: "AchievementsColumn",
	props: ['badges', 'day_streaks'],
	mounted() {
		// this.showAllAchievements()
	},
	methods: {
		getDaysString() {
			return this.day_streaks + ' Day' + (this.day_streaks === 1 ? '' : 's')
		},
		showAllAchievements() {
			const content = this.$createElement(AllAchievementsModal, {})
			const skin = this.$store.getters.skin;
			let opts = {
				hideHeader: true,
				hideFooter: true,
				modalClass: "all_achievements_modal theme_" + skin,
				okTitle: 'Done',
				okOnly: true,
				size: "lg",
				static: true,
				buttonSize: "sm",
				centered: true,
				contentClass: "all_achievements_modal_content",
				id: 'all_achievements_modal'
			}
			return this.$bvModal.msgBoxOk(content, opts);
		}
	},
};
</script>
<style lang="less">
#achievements {
	.badge-icon{
		transform: rotate(-0.25turn)
	}
	.badge-text{
		font-size: 4rem;
		font-family: PublicoHeadlineWebBold
	}
	.white-text{
		color: white !important;
	}
  #streak_info_top {
    h3, h2 {
      color: #343C3D;
    }
  }
	.badges-text{
		padding: 5px 10px;
		margin-left: -4px;
		font-size: 1.6rem;
		font-weight: bold;
	}
  .hexagon-container {
    position: relative;
    width: 58px;
    height: 66px;
    margin-right: 6px;
  }
  .hexagon-text {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -45%);
    display: inline-block;
    font-size: 3.5rem;
    position: absolute;
    z-index: 100;
    color: #fff;
  }
  .hexagon {
    position: absolute;
    z-index: 99;
    top: 0;
    left: 0;
    display: inline-block;
    color: #fff;
    width: 58px;
    height: 66px;
    background-color: #00008F;
    clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
  }

	@media only screen and (min-width: 769px) and (max-width: 970px) {
		#badges {
			padding: 10px !important;
		}

		#badge-holder {
			padding: 5px;
			width: 100%;
		}

		.achievement-badge {
			width: 100%;
			margin-right: 5px !important;
			margin-left: -5px !important;

			.collected-column {
				padding: 5px !important;
				padding-top: 15px !important;
			}

			.img_wrapper {
				justify-content: flex-start;
				align-items: flex-start;
				padding-top: 15px;
			}
		}
	}

	@media only screen and (max-width: 768px) {
		#badge-holder {
			display: flex;
			overflow: scroll;
			overflow-y: hidden;

			.achievement-badge {
				min-width: 260px;
				min-height: 120px;

				.title {
					font-size: 1.8em;
				}

				.collected-column {
					padding: 5px !important;
					padding-top: 15px !important;
				}

				.img_wrapper {
					justify-content: flex-start;
					align-items: flex-start;
					padding-top: 15px;
				}
			}
		}
	}

	background: url('/public/img/totalMentalHealth/achievements/Blob.png') no-repeat top center;
	background-size: 70%;
	padding-bottom: 80px;
	position: relative;

	.btn{
    width: 100%;
    max-width: 200px;
    border-radius: 15px !important;
    padding: 14px;
		margin-right: 20px;
    box-shadow: 0px 4px 4px 0px #00000040;
	}

	#streak_info {
		padding: 15px;
		margin-top: 15px;
		min-height: 250px;
		position: relative;

		h2 {
			font-size: 40px;
			text-align: left;
			font-weight: 600;
		}

		background: url('/public/img/totalMentalHealth/achievements/Rocket.png') no-repeat center top;
		background-size: contain;

		#border_div {
			position: absolute;
			bottom: 17px;
			width: 90%;
			right: 15px;
			border-bottom: 2px solid rgba(255, 255, 255, 0.07);
		}

		#streak_info_bottom {

			#total_digit {
				display: flex;
				align-items: center;
				justify-content: center;
				width: 45px;
				height: 48px;
				font-size: 20px;
			}

		}
	}

	#badges {
		padding: 15px 30px;
	}
}</style>
