<template>
  <div
    class="notification-card"
    :class="{
      clickable: [
        'SalesforceClinicianMessage',
        'SalesforceTooManyRejectedSuggestions',
        'SalesforceAppointmentOptions'
      ].includes(notification.type)
    }"
    @click="handleClick"
  >
    <div class="title brand-text">{{ notification.subject }}</div>
    <div class="body brand-text">{{ notification.body }}</div>
    <div class="underline"></div>
  </div>
</template>

<script>
export default {
  name: "Notification",
  props: ["notification"],
  methods: {
    handleClick() {
      if (this.notification.type === "SalesforceAppointmentOptions") {
        this.$router.push(
          "/f2f/" +
            this.notification.event?.salesforce_appointment_options?.sessionId +
            "/" +
            this.notification.id
        );
      } else if (
        this.notification.type === "SalesforceTooManyRejectedSuggestions"
      ) {
        this.$router.push(
          "/message/" +
            this.notification.event?.salesforce_session_suggestions_rejected
              ?.sessionId +
            "/reject"
        );
      } else if (this.notification.type === "SalesforceClinicianMessage") {
        this.$router.push(
          "/message/" +
            this.notification.event?.salesforce_clinician_message
              ?.sf_appointment_id +
            "/reply"
        );
      }
      // else if(this.notification.event?.actionUrl?.href){
      //   this.$router.push(this.notification.event?.actionUrl?.href);
      // }
    }
  }
};
</script>

<style lang="less">
.notification-card {
  margin-bottom: 16px;

  .icon-holder {
    height: 100%;
    width: 44px;
  }

  .icon {
    width: 41px;
    height: 41px;
    border-radius: 10px;
    font-size: 1.6rem;
    font-family: "FSElliotBold", sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .title {
    font-size: 1.6rem;
    font-family: "FSElliotBold", sans-serif;
    margin-bottom: 10px;
  }

  .body {
    font-size: 1.4rem;
    font-family: "FSElliotBook", sans-serif;
  }

  .underline {
    height: 1px;
    background-color: #e0e5f5;
    margin-top: 16px;
  }
}
</style>
