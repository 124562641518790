<template>
	<div id="my-progress" class="h-100">
		<h2 class="brand-text">My Progress</h2>
		<img src="/img/totalMentalHealth/achievements/progress-illustration.png" class="img-fluid mb-5" title="" />
		<p class="mb-5 text-center p-text-sm">
			As part of your coaching plan, you'll be asked to <b>check-in</b> regularly on how you're feeling. Over time, you'll be able to see how participating in the coaching programme is impacting your overall wellbeing
		</p>
		<br/>
		<br/>
		
		<div class="bottom_button_not text-center">
			<b-button @click="openWhoModal()" variant="cta w-100" squared  size="lg" class=" shadow">Check in now!</b-button>
		</div>
		<div class="bottom_button">
		
		</div>
	</div>
</template>
<script>

import Who5Modal from "./Who5Modal";

export default {
	components: {
	
	},
	name: "MyProgressColumn",
	data() {
		return {
		
		};
	},
	mounted() {
		// this.openWhoModal()
	},
	beforeDestroy() {
	
	},
	methods: {
		openWhoModal(){
			const content = this.$createElement( Who5Modal, {} )
			const skin = this.$store.getters.skin;
			let opts = {
				hideHeader: true,
				hideFooter: true,
				modalClass: "who_checkin_modal theme-" + skin,
				okTitle : 'Done',
				okOnly : true,
				size: "lg",
				static : true,
				buttonSize: "sm",
				centered: true,
				contentClass: "who_checkin_modal_content",
				id : 'who_checkin_modal'
			}
			return this.$bvModal.msgBoxOk(content, opts);
		},
	},
	props : [],
	inject : [],
	provide: function () {
		return {

		}
	},
};

// "roman_who@mailinator.com"
</script>
<style lang="less">
#my-progress {
	padding: 30px 15px 80px;
	position: relative;
	h2 {
		font-size: 2.4rem;
		text-align: center;
	}
  .cta-button {
    background: linear-gradient(to right, #985582, #d50870);
    width: 80%;
    max-width: 200px;
    border-radius: 15px;
  }
}
</style>