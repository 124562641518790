<template>
  <div id="F2FDetails">
    <div class="dmh-f2f-container animate__animated animate__fadeIn">
      <h2 class="dmh-start-title mb-3">Your session is located at</h2>
      <div class="border-brand-primar pb-3">
        <img :src="booking.clinician.image" class="clinician-image" />
      </div>
      <p>
        <b>{{
          booking.clinician.address || booking.details.locations.join(", ")
        }}</b>
        <!-- with <b>{{ booking.clinician.name }}</b> is scheduled
        for <b>{{ getFormatedDate(booking.startTime, 'dddd MMM Do [at] h:mma') }}</b> -->
      </p>

      <!-- <LeafletMap v-if="showMap" class="py-5 mb-5 map-container" :lng="booking.clinician.addressCoordinates.lng"
        :lat="booking.clinician.addressCoordinates.lat" :draggable="false" />
      <div v-else class="w-100">
        <Loader></Loader>
      </div> -->
    </div>
  </div>
</template>
<script>
import Loader from "@/components/Loader";
import LeafletMap from "@/components/LeafletMap";

export default {
  props: ["booking"],
  components: {
    LeafletMap,
    Loader
  },
  name: "F2FDetailsModal",
  data() {
    return {
      showMap: false
    };
  }
  // mounted() {
  // setTimeout(() => { this.showMap = true }, 500)
  // },
};
</script>
<style lang="less">
#digital_clinics_modal___BV_modal_outer_ {
  z-index: 1000000 !important;

  * {
    scrollbar-width: thin;
    scrollbar-color: #aaa;
  }

  /* Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 8px;
  }

  *::-webkit-scrollbar-track {
    background: rbga(0, 0, 0, 0);
    margin: 10px;
  }

  *::-webkit-scrollbar-thumb {
    background-color: #aaa;
    border-radius: 10px;
    border: 5px none #ffffff;
    opacity: 0;
  }

  .hidden-link {
    opacity: 0;
    cursor: pointer;
  }
}

#F2FDetails {
  max-height: 93vh;
  overflow-y: auto;
  overflow-x: hidden;

  .dmh-f2f-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 800px;
    padding: 30px;

    .prompt-container {
      margin-top: 20px;
      padding: 25px;
      max-width: 650px;
      border: 2px solid #e0e5f5;
      border-radius: 16px;
      background-color: white;
    }

    p {
      font-size: 1.6rem;
      text-align: center;

      b {
        font-family: "FSElliotBold", sans-serif;
      }
    }

    .map-container {
      border-radius: 16px;
    }

    .dmh-start-title {
      text-align: center;
      font-size: 1.8rem;
      letter-spacing: 0;
      font-family: "FSElliotBold", sans-serif;
    }

    .dmh-prompt-title {
      padding: 0;
      margin-top: 0;
      margin-bottom: 30px;
      text-align: center;
      font-size: 2.4rem;
      letter-spacing: 0;

      font-family: "FSElliotBold", sans-serif;
    }

    .phone-item {
      font-size: 1.8rem;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: "FSElliotBook", sans-serif;

      .phone-link {
        border-bottom: 1.5px solid;
        font-family: "FSElliotBold", sans-serif;
      }
    }

    .dmh-options-container {
      margin: 20px;
      max-width: 650px;
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      width: 100%;
    }

    .dmh-confirm-container {
      display: flex;
      flex-direction: column;
      font-size: 1.6rem;
      background: #dedede;
      border-radius: 8px;
      padding: 15px;
      margin-bottom: 20px;

      .dmh-confirm-input {
        font-family: "PlusJakartaSansRegular";
        font-size: 2rem;
      }

      textarea {
        background: #dedede;
        border: none;
        width: 100%;
      }

      textarea:focus {
        outline-width: 0;
      }
    }

    .clinician-image {
      max-width: 80px;
      border-radius: 1000px;
      border: 4px solid inherit;
    }
  }
}
</style>
