<template>
    <div class="home-event-card brand-text shadow bg-white h-100">
        <div class="d-flex flex-column mx-0 event-card-content">
            <div class="d-flex flex-row align-items-center justify-content-start mb-3">
                <p class="p-text-lg mb-0 pb-0">Event</p><i
                    class="fa-regular fa-calendar-day ml-3 brand-primary fa-2x"></i>
            </div>
            <hr class="horizontal-divider mb-5" />
            <h3 class="truncate">{{ item.title }}</h3>
            <p v-if="item.description" class="truncate-3 p-text-sm pt-3">{{ item.description }}</p>
            <div class="mt-auto d-flex flex-row align-items-center justify-content-between justify-self-end">
                <b-button @click="navigate('/events/view-event/' + item.id.event_date_id)" variant="white" size="lg"
                    style="margin-left: -15px;">View event <i class="fa-regular ml-2 fa-chevron-right"></i></b-button>
                <div class="date p-text semibold" v-if="item.start_date"><i
                        class="fa-regular brand-primary fa-clock fa-lg mr-2"></i>{{ getFormatedDate(item.start_date,
                    'DD/MM/YY')}}</div>
            </div>
        </div>
    </div>
</template>
<script>

export default {
    components: {
    },
    name: "HomeEventCard",
    props: {
        item: Object,
    },
    data() {
        return {
        };
    },
    mounted() {
			console.log(this.item)
    },
    beforeDestroy() {

    },
    methods: {
        navigate(route) {
            this.$router.push(route);
            this.scrollToRef(null, true);

        },
        bookNow(event) {
            this.$router.push('/wellbeing-studio/' + event.id)
        },
    }
}
</script>
<style lang="less">
.home-event-card {
    border-radius: 6px;

    .event-card-content {
        padding: 25px;
        height: 100%;
    }

    hr {
        height: 1px;
        width: 100%;
    }

    .truncate {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        /* number of lines to show */
        line-clamp: 1;
        -webkit-box-orient: vertical;
    }

    .truncate-3 {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        /* number of lines to show */
        line-clamp: 3;
        -webkit-box-orient: vertical;
    }
}
</style>
