<template>
  <div id="ReportAccordion">
    <button class="btn" @click="toggle">
      <h2 class="title">{{ title }}</h2>
      <i
        class="fa"
        :class="isOpen ? 'fa-chevron-up' : 'fa-chevron-right'"
        aria-hidden="true"
      ></i>
    </button>
    <div v-if="isOpen">
      <p
        class="paragraph"
        v-for="(paragraph, key) in content"
        :key="key"
        v-html="paragraph.report_paragraphs"
      ></p>
    </div>
  </div>
</template>

<script>
export default {
  name: "ReportAccordion",
  props: ["title", "content"],
  data() {
    return {
      isOpen: true
    };
  },
  methods: {
    toggle() {
      this.isOpen = !this.isOpen;
    }
  }
};
</script>

<style lang="less">
#ReportAccordion {
  .btn {
    width: 100%;
    padding: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 32px;

    .fa {
      font-size: 2rem;
    }
  }
}
</style>
