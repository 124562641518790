<template>
  <div id="CancelModal">
    <div
      class="d-flex flex-column justify-content-center align-items-center w-100"
      v-if="booking"
    >
      <div
        class="px-5 py-5 d-flex flex-column justify-content-center align-items-center text-center"
        v-if="!bookingCanceled"
      >
        <h3 class="section-title mt-3 text-center">
          Are you sure you want to cancel your appointment?
        </h3>
        <p class="section-paragraph">
          Once you cancel your appointment you will no longer be able to speak
          to a specialist
        </p>
        <!-- <div class="pb-2"><b class="sentence-case">Clinician: </b>{{ booking.clinician.name }}</div>
                <div class="pb-2"><b>Date: </b>{{ getFormatedDate(booking.startTime, 'DD MMMM YYYY') }}
                </div>
                <div class="pb-2"><b>Time: </b>{{ getFormatedDate(booking.startTime, 'h.mma') }}</div> -->
        <b-button
          :id="'confirm-cancel-' + booking.appointmentType + '-appt-button'"
          class="btn-dark mt-5"
          squared
          @click="cancelBooking(booking)"
          :disabled="bookingCancelLoading"
          ><span v-if="!bookingCancelLoading">Yes, cancel my appointment</span>
          <b-spinner style="width: 1.4rem; height: 1.4rem;" v-else></b-spinner>
        </b-button>
        <button :disabled="bookingCancelLoading" class="btn-underline" @click="closeModal">
          I want to keep my appointment
        </button>
      </div>
      <div
        class="px-5 py-5 d-flex flex-column align-items-center justify-content-center"
        v-else
      >
        <h3 class="section-title mt-3 text-center">Appointment cancelled!</h3>
        <div>
          <i class="fa-3x fa-solid fa-check-circle brand-primary mt-4"></i>
        </div>
        <b-button class="btn-dark mt-5" squared @click="closeModal"
          >Close
        </b-button>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import config from "@/config/constants";
import EventBus from "@/helpers/eventbus";

export default {
  props: ["booking"],
  name: "CancelModal",
  data() {
    return {
      bookingCancelLoading: false,
      bookingCanceled: false
    };
  },
  mounted() {},
  beforeDestroy() {},
  methods: {
    closeModal() {
      this.$bvModal.hide("cancel_modal");
    },
    async cancelBooking() {
      if (this.bookingCancelLoading) {
        return;
      }
      this.bookingCancelLoading = true;
      let requestString = "";
      let payload = {
        user_id: this.$store.getters.user_id
      };
      if (this.booking.appointmentType === "corporateEvent") {
        requestString = "/application/api/u/events/cancel-timeslot-booking";
        payload["all_series"] = false;
        payload["timeslot_id"] = this.booking?.details?.timeslot_id;
      } else {
        const appointmentType = (this.booking?.appointmentType?.toLowerCase().includes('health') || this.booking?.appointmentType?.toLowerCase().includes('cancer')) ? 'coaching' : this.booking.appointmentType?.toLowerCase()
        requestString = "/application/api/u/dmh/cancel-appointment";
        payload["appointment_id"] = this.booking.appointmentId;
        payload[
          "appointment_type"
        ] = appointmentType;
        payload["message"] = "User cancelled the session";
      }
      const res = await axios.post(
        config.api_env + requestString,
        payload,
        config.options
      );
      this.bookingCancelLoading = false;
      this.closeModal();
      if (res.data.success) {
        EventBus.$emit("getBookings");
      }
    }
  },
  computed: {}
};
</script>
<style lang="less">
#digital_clinics_modal___BV_modal_outer_ {
  z-index: 1000000 !important;

  * {
    scrollbar-width: thin;
    scrollbar-color: #aaa;
  }

  /* Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 8px;
  }

  *::-webkit-scrollbar-track {
    background: rbga(0, 0, 0, 0);
    margin: 10px;
  }

  *::-webkit-scrollbar-thumb {
    background-color: #aaa;
    border-radius: 10px;
    border: 5px none #ffffff;
    opacity: 0;
  }

  .hidden-link {
    opacity: 0;
    cursor: pointer;
  }
}

#cancel_modal {
  .section-title {
    color: #000000;
    text-align: center;
    font-size: 2.8rem;
    letter-spacing: 0;
    padding-bottom: 15px;
    font-weight: 700;
    font-family: "DMSans", sans-serif;
  }

  .section-paragraph {
    color: #000000;
    font-size: 1.8rem;
    font-weight: 600;
    font-family: "DMSans", sans-serif;
  }

  div {
    font-size: 1.6rem;

    b {
      font-family: "DMSans", sans-serif;
    }
  }

  button {
    letter-spacing: normal !important;
    background-color: #192550;
    border-color: #192550;
    color: white;
    border-radius: 4px;
  }

  button.rounded-0 {
    border-radius: 4px !important;
  }

  .btn-dark {
    min-width: 200px;
    font-size: 1.6rem;
    line-height: 2rem;
    padding: 12px 20px;
    border-radius: 6px;
    background-color: #00008f;
  }

  .btn-underline {
    background-color: unset;
    border: 0;
    color: #00008f;
    font-size: 1.6rem;
    line-height: 2rem;
    text-decoration: underline;
    font-weight: 600;
    margin-top: 12px;
  }
}
</style>
