export default {
  //TODO use process.env.VUE_APP_API_PATH to get environment variables from .env files
  //TODO move all env related stuff to .env files
  env: "dev",
  //    env : 'live',

  /** Dev Settings */

  api_env: process.env.VUE_APP_API_PATH,
  return_domain: "http://localhost:8080",
  esp_domain: "dev-esp.spectrumwellness.ie",
  stripe_key: process.env.VUE_APP_STRIPE_KEY,
  zen_desk_key: "0c6ea862-cbb8-419a-9bfa-4a3903d971c5",
  // set by default to qa for jenkins auto deployment, change it when clone to local

  //TODO so this
  /** QA Settings */
  //    api_env : 'https://qa.healthcoach-api.com',
  //    esp_domain : 'esp.surge-qa.com',

  //TODO and this
  /** Live Settings */
  //api_env : 'https://api.aluna.app',
  //esp_domain : 'admin.spectrum.life',
  //    zen_desk_key : '3okxX0ixGGb6EMgCdyN36VEHZseWQFwl',
  //    stripe_key : 'pk_live_BI5krTXE17asvo1ZEeEBEnjF',

  /** Other*/
  options: {
    headers: {
      "Content-type": "application/json"
    }
  },
  encrypt_level: "high",
  adminEmail: "admin@example.com"
};
