<template>
  <div id="DmhConfirm" class="border-brand-dark">
      <div class="dmh-confirm-list w-100" 
           v-for="question in question.options"
           :class="{ invalid: touched_fields.includes(question.field_key) && !validations[question.field_key] }">
           
          <div class="dmh-confirm-container w-100 mb-0">
              <label class="dmh-confirm-field">{{ question.field_label }}</label>

              <select @focus="markAsTouched(question.field_key)" 
                      class="dmh-confirm-input" 
                      :class="{'empty': answer[question.field_key] === ''}" 
                      v-model="answer[question.field_key]"
                      v-if="question.field_type === 'select'">
                  <option disabled value="">Please select one</option>
                  <option v-for="option in question.options" :value="option">{{ option }}</option>
              </select>

              <select @focus="markAsTouched(question.field_key)" 
                      class="dmh-confirm-input" 
                      v-model="answer[question.field_key]"
                      v-else-if="question.field_label === 'Gender'">
                  <option v-for="option in genderOptions" :value="option">{{ option }}</option>
              </select>

              <input @input="markAsTouched(question.field_key)" 
                     :value="question.data ? question.data : ''"
                     v-model="answer[question.field_key]" 
                     :type="question.field_type"
                     :readonly="question.attributes && question.attributes.includes('readonly')"
                     class="dmh-confirm-input"
                     :max="question.field_type === 'date' ? '2026-12-01' : null"
                     :class="{ 'read-only-field': question.attributes && question.attributes.includes('readonly') }"
                     v-else />
          </div>

          <div v-if="touched_fields.includes(question.field_key) && getErrorMessages(question.field_key).length">
              <div 
                  v-for="error in getErrorMessages(question.field_key)" 
                  class="error-text d-flex flex-row align-items-center">
                  <i class="fa-exclamation-circle fas mr-3"></i>
                  <div>{{ error }}</div>
              </div>
          </div>
      </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "DmhStart",
  props: ['question', 'answer', 'setAnswer'],
  data() {
      return {
          touched_fields: [],
          genderOptions: ['Male', 'Female', 'Prefer not to say'],
      };
  },
  methods: {
      markAsTouched(fieldKey) {
          if (!this.touched_fields.includes(fieldKey)) {
              this.touched_fields.push(fieldKey);
          }
      },
      getErrorMessages(fieldKey) {
          const errors = [];
          if (!this.validations[fieldKey]) {
              if (fieldKey.includes("email"))
                  errors.push("Must be a valid email address");
              else if (fieldKey.includes("phone_number"))
                  errors.push("Must be a valid phone number with at least 8 digits");
              else if (fieldKey.includes("dob"))
                  errors.push(this.DOBValidationMessage(fieldKey));
              else if (fieldKey.includes("select") || fieldKey.includes("gender"))
                  errors.push("Must not be empty");
              else
                  errors.push("Must be at least 2 characters long");
          }
          return errors;
      },
      DOBValidationMessage(fieldKey) {
          if (fieldKey === "child_dob") {
              return "Must be between 6 and 16 years old";
          } else {
              return "Must be at least 16 years old";
          }
      },
  },
  computed: {
      ...mapGetters(["user"]),
      validations() {
          return this.validateDmhAnswers(this.answer);
      },
  },
};
</script>

<style lang="less">
#DmhConfirm {
    display: flex;
    flex-direction: column;
    align-items: center;

    .dmh-confirm-list:not(:first-child) {
        margin-top: 20px;
    }

    .dmh-confirm-field {
      color: #343C3D;
      font-size: 1.8rem;
      font-family: "SourceSansProSemibold";
      margin-bottom: 8px;
    }

    .dmh-confirm-container {
        display: flex;
        flex-direction: column;
        font-size: 1.8rem;

        .dmh-confirm-input {
          font-family: "SourceSansProRegular";
            font-size: 1.8rem;
            border: 1px solid #757575;
            color: #343C3D;
            border-radius: 6px;
            background-color: #fff;
            padding: 12px;
        }

        .read-only-field {
          background-color: #F4F6FC;
          color: #797B89;
          cursor: not-allowed;
        }

        input {
            border: none;
            width: 100%;
        }

        input:focus {
            outline-width: 0;
        }

        select {
            background-color: white;
            cursor: pointer;
            border: none;
            width: 100%;
        }

        select:focus {
            outline-width: 0;
            
        }

        &.invalid {
            border-color: #992F0C !important;
        }
    }

    select.empty{
        color: #8e8e90 !important
    }

    .dmh-confirm-container:focus-within {
        border-color: #1E1E22;
    }
    .error-text {
        border-radius: 8px;
        border: 1px solid #F40B0B;
        color: #F40B0B;
        background-color: #EABABA;
        padding: 10px;
        margin-top: 10px;
        font-size: 1.6rem;
    }
    a {
      color: #3C16F9;
      text-decoration: underline;
      font-family: "SourceSansProBold";
    }
}</style>
  