<template>
  <div id="questionnaire-parser">
    <div v-for="(contentItem, i) in content" v-bind:key="contentItem.id">
      <Question
        :selectedDate="selectedDate"
        :key="contentItem.id"
        :question="contentItem"
        :options="options"
        v-on:selected-date="selectDate"
        class="mb-5"
      ></Question>
      <div v-if="showDivider(i)" class="divider"></div>
    </div>
  </div>
</template>

<script>
import Question from "./Question.vue";
import moment from "moment";

export default {
  components: {
    Question
  },
  name: "TextParser",
  props: ["content", "options", "selectedDate"],
  data() {
    return {
      answerArray: []
    };
  },
  mounted() {
    let self = this;
    this.content.forEach(function(question) {
      self.answerArray.push({ question_id: question.id, text: "" });
    });
  },
  methods: {
    showDivider(i) {
      if (this.options.hasOwnProperty("journal_type")) {
        if (this.options.journal_type === "worksheet") return false;
      }
      if (i < this.content.length - 1) return true;
      return false;
    },
    setAnswer(id, answer) {
      let index = this.answerArray.findIndex(x => x.question_id === id);
      this.answerArray[index].text = answer;
    },
    selectDate(timestamp) {
      this.$emit("selected-date", timestamp);

      this.content.forEach(question => {
        question.last_answers.forEach(answer => {
          answer.active = false;
          if (moment(answer.timestamp).isSame(timestamp, "day")) {
            answer.active = true;
          }
        });
      });
    },
    decodeData(data) {
      try {
        return atob(data);
      } catch (error) {
        return data;
      }
    }
  },
  watch: {
    answerArray(newArray) {
      this.$parent.setAnswerArray(newArray);
    }
  }
};
</script>
<style lang="less">
#questionnaire-parser {
  .divider {
    border-top: 2px solid lightblue;
    margin-bottom: 20px;
  }
}
</style>
