<template>
  <div id="CoachingHome" v-if="true">
    <section id="EvolveBanner" class="evolve-home-banner-container pb-5">
      <div class="content-container">
        <div class="row evolve-home-banner mx-0 align-items-center">
          <div class="evolve-home-banner-text col-md-7 col-sm-12">
            <h1 class="evolve-home-banner-title">Mental Health Coaching</h1>
            <p class="white-text">
              Our Team of Mental Health Coaches are on hand to help you
              make the changes you want or need to make in your life. Whether it
              be emotional wellbeing, motivation, goal setting or a feeling that
              things are just a little off, our qualified and experience coaches
              are here to help.
            </p>
            <p><b>IE:</b> {{ supportContacts?.phone.ie }}</p>
            <p><b>UK:</b> {{ supportContacts?.phone.uk }}</p>
            <p><b>International:</b> {{ supportContacts?.phone.international }}</p>
            <b-button variant="cta" size="lg" squared class="py-3 px-5"
              @click="$router.push('/mhcoach/select-coach')">Start Journey</b-button>
          </div>
          <div class="col-md-5">
            <img src="/img/banner.png" class="img-fluid" />
          </div>
        </div>
      </div>
    </section>
    <section id="EvolvePathways" class="bg-white mt-5 py-5" style="position: relative">
      <div class="content-container py-5" id="coachingPathways">
        <h3>Explore Coaching <b>Pathways</b></h3>
        <p class="pathways-subheader">Our Total Mental Health Coaches are eager to support you in achieving your goals.
          Read below to find out more about some of the wellbeing pathways that you can work on along with your coach.</p>
        <p class="pathways-subheader">These pathways are just an initial guide for conversation with your coach but you
          are free to set your own goals on anything you like from resilience to burnout, our coaches are here to help.
        </p>
        <div class="evolve-pathways-container">
          <div class="evolve-pathway shadow">
            <img class="evolve-pathway-image bg-brand-primary" src="/img/evolve/improve-sleep.png" />
            <div class="py-4 px-4">
              <h4 class="evolve-pathway-title">Improving Sleep</h4>
              <p class="evolve-pathway-text">
                Dreaming of a better life? Join us on this journey to discovery how you can sleep your way to wellbeing.
              </p>
              <div>
                <b-button variant="white" class="pathway-card-button"
                  @click="showPathwayModal('/img/evolve/centered/improve-sleep.png', 'Improve Sleep', componentText.improveSleepText)">Find
                  out more ></b-button>
              </div>
            </div>
            

          </div>
          <div class="evolve-pathway shadow">
            <img class="evolve-pathway-image bg-brand-primary" src="/img/evolve/improve-mood.png" />
            <div class="px-4 py-4">
              <h4 class="evolve-pathway-title">Improving Mood</h4>
              <p class="evolve-pathway-text">
                Feeling low? Our emotions tell us about what’s missing in our lives and what we truly need for our
                wellbeing. Find out more here.
              </p>
              <div>
                <b-button variant="white" class="pathway-card-button"
                  @click="showPathwayModal('/img/evolve/centered/improve-mood.png', 'Improve Mood', componentText.improveYourMoodText)">Find
                  out more ></b-button>
              </div>
            </div>

          </div>
          <div class="evolve-pathway shadow">
            <img class="evolve-pathway-image bg-brand-primary" src="/img/evolve/reducing-anxiety.png" />
            <div class="px-4 py-4">
              <h4 class="evolve-pathway-title">Reducing Anxiety</h4>
              <p class="evolve-pathway-text">
                Anxiety can help us prepare for future challenges, but it can also rob us of our present joys. Come aboard
                to sail through the waves of anxiety.
              </p>
              <div>
                <b-button variant="white" class="pathway-card-button"
                  @click="showPathwayModal('/img/evolve/centered/reducing-anxiety.png', 'Reducing Anxiety', componentText.transformAnxietyText)">Find
                  out more ></b-button>
              </div>
            </div>
          </div>
          <div class="evolve-pathway shadow">
            <img class="evolve-pathway-image bg-brand-primary" src="/img/evolve/stress-management.png" />
            <div class="px-4 py-4">
              <h4 class="evolve-pathway-title">Stress Management</h4>
              <p class="evolve-pathway-text">
                Did you know ‘stressed’ spelt backwards is desserts? Join this challenge to transform bitter stress into
                something a bit sweeter.
              </p>
              <div>
                <b-button variant="white" class="pathway-card-button"
                  @click="showPathwayModal('/img/evolve/centered/stress-management.png', 'Stress Management', componentText.stressManagementText)">Find
                  out more ></b-button>
              </div>
            </div>
          </div>
          <div class="evolve-pathway shadow">
            <img class="evolve-pathway-image bg-brand-primary" src="/img/evolve/communication-skills.png" />
            <div class="px-4 py-4">
              <p class="evolve-pathway-text">
                <h4 class="evolve-pathway-title">Communication Skills</h4>
                Communication is so much more than just words. Join here to discover how to better express yourself and
                nurture meaningful connections with others.
              </p>
              <div>
                <b-button variant="white" class="pathway-card-button"
                  @click="showPathwayModal('/img/evolve/centered/communication-skills.png', 'Communication Skills', componentText.integrativeCommunicationText)">Find
                  out more ></b-button>
              </div>
            </div>
          </div>
          <div class="evolve-pathway shadow">
            <img class="evolve-pathway-image bg-brand-primary" src="/img/evolve/mindfulness.png" />
            <div class="px-4 py-4">
              <h4 class="evolve-pathway-title">Mindfulness</h4>
              <p class="evolve-pathway-text">
                Each moment is a gift. That is why it is called present. Take this moment to let go what was or what will be
                and awaken to the power of now.
              </p>
              <div>
                <b-button variant="white" class="pathway-card-button"
                  @click="showPathwayModal('/img/evolve/centered/mindfulness.png', 'Mindfulness', componentText.mindfulnessText)">Find
                  out more ></b-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import axios from "axios";
import config from "../config/constants";
import slick from "vue-slick";

export default {
  name: "CoachingHome",
  components: {
    slick,
  },
  mounted() { },
  data() {
    return {
      startColour: '#381B51',
      stopColour: '#9A5683',
      courses: {},

      slickOptions: {
        infinite: true,
        autoplay: false,
        dots: false,
        nextArrow:
          '<button class="slick-next  py-1" type="button" style=""><i class="fas fa-chevron-right"></i></button>',
        prevArrow:
          '<button class="slick-prev  py-1" type="button" style=""><i class="fas fa-chevron-left"></button>',
        speed: 300,
        initialSlide: 1,
        slidesToScroll: 0,
        slidesToShow: 3,
        centerMode: true,
        centerPadding: "0",
        variableWidth: false,
        adaptiveHeight: true,
        responsive: [
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 0,
            },
          },
        ],
      },
    };
  },
  methods: {
    closePathwayModal() {
      this.$bvModal.hide("pathway-modal");
    },
    showPathwayModal(image, title, text) {
      this.pathwayModalImageUrl = image;
      this.pathwayModalText = text;
      this.pathwayModalTitle = title;
      this.$bvModal.show("pathway-modal");
      const pathwayName = title.replace(' ','-').toLowerCase();
      const payload = {
        user_id: this.$store.getters.user_id,
        pathway: pathwayName
      };
      axios.post(config.api_env + "/application/api/u/pathways/set-pathway", payload, config.options);
    },
  },
  computed: {
    componentText() {
      return this.getText.loggedIn.home.homeWellbeing;
    },
    supportContacts() {
      return this.$store.state.user.support_contacts;
    }
  },
};
</script>
<style lang="less">
#CoachingHome {
  background-color: #FAFAFA;

  .evolve-home-banner-container {
    .evolve-home-banner {
      padding-top: 60px;
    }

    .evolve-home-banner-text {
      font-size: 1.6rem;
      z-index: 2;

      .evolve-home-banner-title {
        font-size: 2.8rem;
        font-weight: bold;
        padding-bottom: 25px;
      }
    }

    div {
      z-index: 2;
    }
  }

  .evolve-reviews-container {
    padding-bottom: 30px;

    .evolve-review {
      padding: 20px;

      .evolve-review-content {
        font-size: 1.6rem;
      }

      .evolve-review-name {
        font-size: 1.6rem;
        font-weight: bold;
        text-transform: uppercase;
      }

      .evolve-review-location {
        font-size: 1.4rem;
      }
    }
  }

  @media only screen and (max-width: 768px) {
    .evolve-pathways-container {
      grid-template-columns: 1fr !important;
    }
  }

  .evolve-pathways-container {
    padding-bottom: 30px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 30px;

    .evolve-pathway {
      background-color: white;
      border-radius: 6px;
      margin-top: 20px;
      margin-bottom: 20px;
      display: flex;
      flex-direction: column;
      position: relative;
      overflow: hidden;
      z-index: 2;
      flex-grow: 1;
      justify-content: space-around;

      h4 {
        font-size: 1.8rem;
        font-weight: bold;
        margin-bottom: 15px;
        z-index: 10;
      }

      p {
        font-size: 1.4rem;
        z-index: 10;
      }

      img {
        height: auto;
      }
    }
  }

  .horizontal-divider {
    flex-grow: 1;
    margin-left: 20px;
    border: 2px solid;
  }

  .banner-overlay {
    position: absolute;
    width: 100%;
    bottom: 100%;
    z-index: 1;

    img {
      width: 100%;
    }
  }

  .pathway-overlay {
    position: absolute;
    width: 100%;
    bottom: 0;
    z-index: 1;

    img {
      width: 100%;
    }
  }

  .pathway-blob {
    position: absolute;
    width: 100%;
    bottom: 0;
    right: 0;
    z-index: 1;
  }
}
</style>
