<template>
    <div id="SideMenu" class="position-absolute">
        <div class="booking-container" v-click-outside="closeSideMenu">
            <div class="booking-top d-flex align-items-center justify-content-between">
                <span class="booking-container-title">Menu</span>
                <button @click="closeSideMenu" class="close-button">
                    <i class="fa-regular fa-times fa-2x clickable"></i>
                </button>
            </div>
            <div class="locations border-top">
                <div class="side-menu-title brand-highlight-1">Set Office Location <i
                        class="fa-solid fa-location-dot fa-lg ml-3"></i></div>
                <b-form-select v-model="selectedLocation" class="select-location mt-4 mb-5 confirm-input">
                    <option v-for="location in locations.locations" :value="location.location_id" :class="{
                        selected: locations.current_loc == location.location_id
                    }">{{ location.location_label }}</option>
                </b-form-select>
            </div>

            <div class="d-flex justify-content-center align-items-start ">
                <b-button variant="white" class="side-menu-btn" @click="logout()">Logout<i
                        class="fa-regular fa-times fa-lg ml-3"></i></b-button>
            </div>
        </div>
    </div>
</template>

<script>
import BookingCard from "./BookingCard.vue";
import axios from "axios";
import config from "../config/constants";
import moment from "moment";
import Loader from "@/components/Loader.vue";
import EventBus from "@/helpers/eventbus";

export default {
    name: "BookingList",
    components: {
        BookingCard,
        Loader
    },
    data() {
        return {
            showUpcomingBookings: true,
            bookings: [],
            upcomingBookings: [],
            pastBookings: [],
            isLoading: false,
            selectedLocation: "",
            locations: {},
            locationPopupContent: {
                header: "",
                message: ""
            },
            showLocationPopup: false,
        };
    },
    mounted() {
        this.getLocations();
    },
    methods: {
        closeSideMenu() {
            this.$emit("close-side-menu");
        },
        logout() {
            this.$store.dispatch("logout");
            this.$router.push("/login");
        },
        async getLocations() {
            const payload = {
                user_id: this.$store.getters.user_id
            };
            let res = await axios.post(
                config.api_env + "/application/api-hc/get-locations",
                payload,
                config.options
            );
            if (res.data.success) {
                this.locations = res.data;
            } else if (res.data.err && res.data.err.includes("3994")) {
                this.$store.dispatch("logout");
                this.$router.push("/login");
            }
            this.selectedLocation = this.locations.current_loc !== -1 ? this.locations.current_loc : { location_id: null, location_label: 'Not set' };
        },
        async setLocation() {
            const payload = {
                user_id: this.$store.getters.user_id,
                location_id: this.selectedLocation,
            };
            let res = await axios.post(
                config.api_env + "/application/api-hc/set-location",
                payload,
                config.options
            );
            if (res.data.success) {
                setTimeout(() => {
                    EventBus.$emit('locationChange');
                }, 1000)
            }
        },
    },
    watch: {
        selectedLocation() {
            if(this.selectedLocation !== this.locations.current_loc){
                this.setLocation();
            }
        },
    },
};
</script>

<style lang="less">
#SideMenu {
    display: flex;
    justify-content: flex-end;
    z-index: 1000;
    height: 100vh;
    min-height: 100vh;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);

    .booking-container {
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
        max-width: 460px;
        background-color: white;
        border: 1px solid lightgrey;
        border-top-left-radius: 12px;
        border-bottom-left-radius: 12px;
        overflow: scroll;

        .confirm-input {
            font-family: "SourceSansProRegular";
            font-size: 1.6rem;
            border: 1px solid #757575;
            border-radius: 6px;
        }

        .border-top {
            border-top: 1px solid lightblue;
        }

        .locations {
            border-bottom: 1px solid lightblue;
        }

        .select-location {
            margin-left: 20px;
            width: 70%;
        }

        .side-menu-btn {
            text-align: left;
            background-color: white;
            border-radius: 0;
            font-size: 1.6rem;
            width: 100%;
            font-weight: bold;
            padding: 20px;
            border-bottom: 1px solid lightblue;

            &:hover {
                background-color: #eee;
            }
        }

        .side-menu-title {
            text-align: left;
            background-color: white;
            border-radius: 0;
            font-size: 1.6rem;
            width: 100%;
            font-weight: bold;
            padding: 20px;
            padding-bottom: 0px;
        }

        .booking-top {
            padding: 16px 20px;
        }

        .booking-container-title {
            width: 90%;
            text-align: center;
            color: #00008f;
            font-size: 1.8rem;
            font-family: "FSElliotBold", sans-serif;
            font-weight: bold;
        }
    }


    .close-button {
        background-color: #00008f;
        border: 1.5px solid transparent;
        border-radius: 50%;
        width: 40px;
        height: 40px;
        padding: 5px;
        font-size: 1.4rem;
        color: #fff;

        &:hover,
        &:focus {
            border: 1.5px solid #192550;
        }
    }
}
</style>