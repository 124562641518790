<template>
  <div id="loader" class="text-center">
    <br />
    <br />
    <div class="fa-3x spinner">
      <i class="fa fa-spinner fa-spin"></i>
    </div>
    <br />
  </div>
</template>

<script>
export default {
  name: "Loader"
};
</script>
<style scoped lang="less">
#loader {
  max-width: 50%;
  margin: auto;
  img {
    max-width: 250px;
    margin: auto;
  }
  .spinner {
    color: #87d2dc;
    font-size: 50px;
  }
}
</style>
