<template>
  <div id="Journal">
    <div class="d-flex flex-row justify-content-between flex-wrap journal-container shadow">
      <div class="calendar-container">
        <Calendar :entryDates="entryDates"></Calendar>
      </div>
      <div class="button-container d-flex flex-column align-items-center px-3 justify-content-around">
        <h2 class="brand-text mt-5 py-0">{{ title }}</h2>
        <b-button variant="cta" class="mt-5" squared size="lg" @click="openTaskModal()"
          >Add Entry</b-button
        >
<!--         this button should be hidden when selectedDate has an entry-->
        <b-button
        variant="cta"
          class=" mt-5 view-entry-btn"
          size="lg"
          @click="showJournalEntryModal()"
          v-if="false"
          >View Entry</b-button
        >
      </div>

    </div>
  </div>
</template>

<script>
import moment from "moment";
import Calendar from "./Calendar.vue";
import JournalEntry from "./JournalEntry.vue";
import EventBus from "../../helpers/eventbus";
import config from "@/config/constants";

export default {
  components: {},
  name: "Journal",
  props: ["id"],
  components: {
    Calendar,
    JournalEntry,
  },
  data() {
    return {
      selectedMonth: "",
      selectedDate: "",
      entryDates: [],
      title: "",
      journalContent: null,
    };
  },
  mounted() {
    let self = this;
    EventBus.$on("getCalendar", function (updatedId) {
      if (updatedId === self.id) {
        self.getCalendar(self.id, self.selectedMonth);
      }
    });
  },
  methods: {
    showJournalEntryModal() {
      this.$bvModal.show("journal-modal-" + this.journalId);
    },
    openTaskModal() {
      this.$parent.showTaskModal(
        this.id,
        this.selectedDate ? this.selectedDate : moment(),
          true
      );
    },
    selectDate(date) {
      this.selectedDate = date.format("YYYY-MM-DD");
      this.openTaskModal();
    },
    selectMonth(month) {
      this.selectedMonth = month;
    },
    async getCalendar(id, month = null) {
      let reqMonth = month === null ? moment().format("YYYY-MM") : month;
      const payload = {
        user_id: this.$store.getters.user_id,
        task_id: id,
        month: reqMonth,
      };
      let res = await this.axios.post(
        process.env.VUE_APP_API_PATH +
          "/application/api/u/coaching/get-calendar",
        payload, config.options
      );
      if (res.data.success) {
        this.entryDates = res.data.dates;
        this.title = res.data.title;
      } else {
        console.log("didnt get calendar");
      }
    },
  },
  computed: {},
  watch: {
    selectedMonth(month) {
      this.getCalendar(this.id, month);
    },
  },
};
</script>
<style lang="less">
  .view-entry-btn {
    border-radius: 0px !important;
  }
.journal-modal {

  .modal-content {
    border-radius: 24px;
    overflow: hidden;
  }
  .modal-body {
    max-height: 800px;
    overflow-x: auto;
    padding: 15px;
  }
  .close-button {
    cursor: pointer;
  }
  .journal-content {
    font-size: 1.6rem;
  }
  .journal-modal-title {
    font-size: 3rem;
    font-weight: bold;
    text-align: center;
    padding: 30px 30px 0px 30px;
  }
  ::-webkit-scrollbar {
    padding-right: 10px;
    width: 13px;
    height: 13px;
  }
  ::-webkit-scrollbar-thumb {
    background: linear-gradient(40deg, #87d3dc 50%, #6b8d9d 100%);
    border-radius: 0px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: linear-gradient(40deg, #87d3dc 50%, #6b8d9d 100%);
  }
  ::-webkit-scrollbar-track {
    background: #fafafa;
    border-radius: 10px;
    box-shadow: inset 0px 0px 0px 0px #f0f0f0;
  }
}
#Journal {
  .journal-container {
    border: 2px solid #3a4a61;
    background-color: white;
    margin-top: 20px;
    width: 100%;
    border-radius: 16px;
    .button-container {
      width: 250px;
      text-align: center;
      h3 {
        color: white;
      }
    }
    .calendar-container {
      flex-grow: 1;
      background-color: #eee;
      border-radius: 10px;
      margin-top: 30px;
      margin-bottom: 30px;
      margin-right: 15px;
      margin-left: 15px;
    }
  }
}
</style>
