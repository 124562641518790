<template>
  <div id="NotificationList" class="position-absolute">
    <div class="notification-container" v-click-outside="closeNotifications">
      <div
        class="notification-top d-flex align-items-center justify-content-between"
      >
        <div class="notification-title">Your notifications</div>
        <button class="close-button" @click="closeNotifications">
          <i class="fa-regular fa-times fa-2x clickable"></i>
        </button>
      </div>
      <template v-if="!syncing">
        <div class="d-flex flex-nowrap notification-container-filter-row">
          <button
            class="notification-container-filter"
            :class="{ 'notification-selected': showUnreadNotifications }"
            @click="setShowUnreadNotifications(true)"
          >
            Unread
          </button>
          <button
            class="notification-container-filter"
            :class="{ 'notification-selected': !showUnreadNotifications }"
            @click="setShowUnreadNotifications(false)"
          >
            Read
          </button>
        </div>
        <div class="notification-container-notifications">
          <div
            v-if="
              showUnreadNotifications
                ? unreadNotifications.length > 0
                : readNotifications.length > 0
            "
          >
            <template v-if="showUnreadNotifications">
              <Notification
                v-for="notification in unreadNotifications"
                :notification="notification"
                :key="notification.id"
              />
            </template>
            <template v-else>
              <Notification
                v-for="notification in readNotifications"
                :notification="notification"
                :key="notification.id"
              />
            </template>
          </div>
          <div class="no-notifications-container" v-else>
            <p class="no-notifications-title">
              You don't have any new notifications.
            </p>
            <p class="no-notifications-subtitle">
              Please note that only services provided by Spectrum.Life will send
              notifications
            </p>
          </div>
        </div>
      </template>
      <template v-else>
        <Loader />
      </template>
    </div>
  </div>
</template>

<script>
import Notification from "@/components/Notification.vue";
import Loader from "@/components/Loader.vue";

export default {
  name: "NotificationList",
  components: { Loader, Notification },
  data() {
    return {
      notifications: [],
      syncing: false,
      view: "new",
      showUnreadNotifications: true
    };
  },
  mounted() {
    this.getNotifications();
  },
  computed: {
    unreadNotifications() {
      return this.notifications.filter(item => {
        return item.readAt === null;
      });
    },
    readNotifications() {
      return this.notifications.filter(item => {
        return item.readAt !== null;
      });
    }
  },
  methods: {
    closeNotifications() {
      this.$emit("close-notifications");
    },
    setShowUnreadNotifications(value) {
      this.showUnreadNotifications = value;
    },
    async getNotifications() {
      if (this.syncing) return;
      this.syncing = true;
      const res = await this.api({
        path: "/api/u/notifications"
      });
      this.notifications = res.data;
      this.syncing = false;
    }
  }
};
</script>

<style lang="less">
#NotificationList {
  display: flex;
  justify-content: flex-end;
  z-index: 1000;
  height: 100vh;
  min-height: 100vh;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);

  .notification-top {
    padding: 16px 20px;
  }

  .notification-container {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    max-width: 460px;
    background-color: white;
    border: 1px solid lightgrey;
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
    overflow: scroll;
  }

  .notification-title {
    font-size: 2.4rem;
    font-family: "FSElliotBold", sans-serif;
    font-weight: bold;
  }

  .notification-heading {
    font-family: "FSElliotBold", sans-serif;
    font-size: 1.6rem;
    margin: 16px 24px;
    border-bottom: 1px solid #e0e5f5;
  }

  .notification-container-filter-row {
    border-top: 1px solid #f0f0f0;
    font-size: 1.4rem;
    font-family: "FSElliotBook", sans-serif;
    font-weight: bold;
    cursor: pointer;

    .notification-container-filter {
      background-color: unset;
      border-width: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 50%;
      height: 100%;
      font-size: 1.6rem;
      padding: 18px 12px;
      font-weight: 600;
      color: #343c3d;
    }

    .notification-selected {
      color: #00008f;
      border-bottom: 2px solid #d24723;
      font-family: "FSElliotBlack", sans-serif;
    }
  }

  .notification-container-notifications {
    flex: 1 1 100%;
    border-top: 1px solid #cccccc;
    overflow: auto;
    padding: 24px;
  }

  .no-notifications-container {
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 1.6rem;

    b {
      font-family: "FSElliotBold", sans-serif;
    }

    a {
      text-decoration: underline;
    }

    .no-notifications-title {
      font-size: 1.8rem;
      line-height: 2.2rem;
      margin-bottom: 20px;
    }

    .no-notifications-subtitle {
      font-family: "FSElliotBook", sans-serif;
      font-size: 1.6rem;
      line-height: 2.2rem;
    }
  }

  .close-button {
    background-color: #00008f;
    border: 1.5px solid transparent;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    padding: 5px;
    font-size: 1.4rem;
    color: #fff;

    &:hover,
    &:focus {
      border: 1.5px solid #192550;
    }
  }
}
</style>
