<template>
  <div id="content" class="bg-white">
    <transition name="fade" mode="out-in">
      <component @scroll-to="scrollTo" :is="currentContent" />
    </transition>
  </div>
</template>

<script>
import Home from "../pages/Home.vue";
import Settings from "../pages/Settings.vue";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import DigitalMentalHealth from "../pages/DigitalMentalHealth";
import TCs from "../pages/TCs";
import Events from "../pages/Events.vue";
import Event from "../pages/Event.vue";
import BookingVideoRoom from "../pages/BookingVideoRoom";
import MHCoachingHome from "../pages/MHCoachingHome.vue";
import MHCoachingStart from "../pages/MHCoachingStart";
import MHCoachingSelectCoach from "../pages/MHCoachingSelectCoach";
import MHCoachingBookCoach from "../pages/MHCoachingBookCoach";
import HealthCoachingHome from "../pages/HealthCoachingHome.vue";
import HealthCoachingStart from "../pages/HealthCoachingStart";
import HealthCoachingSelectCoach from "../pages/HealthCoachingSelectCoach";
import HealthCoachingBookCoach from "../pages/HealthCoachingBookCoach";
import CancerCoachingHome from "../pages/CancerCoachingHome.vue";
import CancerCoachingStart from "../pages/CancerCoachingStart";
import CancerCoachingSelectCoach from "../pages/CancerCoachingSelectCoach";
import CancerCoachingBookCoach from "../pages/CancerCoachingBookCoach";
import TotalMentalHealthStart from "../pages/TotalMentalHealthStart";
import TotalMentalHealthSelectCoach from "../pages/TotalMentalHealthSelectCoach";
import TotalMentalHealthYourImpact from "../pages/TotalMentalHealthYourImpact";
import MHCoachingQuestionnaire from "../pages/MHCoachingQuestionnaire.vue";
import DigitalMentalHealthLanding from "../pages/DigitalMentalHealthLanding.vue";
import DmhFaqs from "../pages/DmhFaqs.vue";
import DmhArticles from "../pages/DmhArticles.vue"
import RandoxKit from "../pages/RandoxKit.vue";
import StandaloneArticle from "../pages/StandaloneArticle.vue"
import HealthScore from "../pages/HealthScore.vue"
import HealthScoreAssessment from "../pages/HealthScoreAssessment.vue"
import HealthScoreResults from "../pages/HealthScoreResults.vue"
import HealthScoreDataProtection from "../pages/HealthScoreDataProtection.vue"
import EmergencyContacts from "../pages/EmergencyContacts.vue";

const mappings = {
  home: Home,
  settings: Settings,
  privacy: PrivacyPolicy,
  TCs: TCs,
  dmhFaqs: DmhFaqs,
  dmhArticles: DmhArticles,
	dmh: DigitalMentalHealth,
  dmhLanding: DigitalMentalHealthLanding,
  mentalHealthCoachingHome: MHCoachingHome,
  mentalHealthCoachingStart: MHCoachingStart,
  mentalHealthCoachingSelectCoach: MHCoachingSelectCoach,
  mentalHealthCoachingBookCoach: MHCoachingBookCoach,
  mentalHealthCoachingQuestionnaire: MHCoachingQuestionnaire,
  healthCoachingHome: HealthCoachingHome,
  healthCoachingStart: HealthCoachingStart,
  healthCoachingSelectCoach: HealthCoachingSelectCoach,
  healthCoachingBookCoach: HealthCoachingBookCoach,
  cancerCoachingHome: CancerCoachingHome,
  cancerCoachingStart: CancerCoachingStart,
  cancerCoachingSelectCoach: CancerCoachingSelectCoach,
  cancerCoachingBookCoach: CancerCoachingBookCoach,
  totalMentalHealthStart: TotalMentalHealthStart,
  totalMentalHealthSelectCoach: TotalMentalHealthSelectCoach,
  totalMentalHealthYourImpact: TotalMentalHealthYourImpact,
  bookingVideoRoom: BookingVideoRoom,
  events: Events,
  viewEvent: Event,
  randoxKit: RandoxKit,
  standaloneArticle: StandaloneArticle,
  healthScore: HealthScore,
  healthScoreAssessment: HealthScoreAssessment,
  healthScoreResults: HealthScoreResults,
  healthScoreDataProtection: HealthScoreDataProtection,
  emergencyContacts: EmergencyContacts,
};
// the mappings below will overwrite the above mapping where NavigationV2 is being used as the nav
const navigationV2Mappings = {};

export default {
  name: "ContentRouter",
  updated() {},
  methods: {
    scrollTo(coordinates) {
      this.$emit("scroll-to", coordinates);
    }
  },
  computed: {
    navType() {
      return this.$store.getters.navType;
    },
    currentContent() {
      if (this.navType === "versionTwo") {
        if (navigationV2Mappings.hasOwnProperty(this.$route.name)) {
          return navigationV2Mappings[this.$route.name];
        }
      }
      return mappings[this.$route.name];
    }
  }
};
</script>
<style lang="less">
#content {
  min-height: calc(100vh - 200px);
  display: flex;
  justify-content: center;
  & > div {
    width: 100%;
  }
}
.fade-enter-active {
  transition-duration: 0.5s;
  transition-property: opacity;
  transition-timing-function: ease-in;
}
.fade-leave-active {
  transition-duration: 0s;
  transition-property: opacity;
  transition-timing-function: ease-in;
}
.fade-enter,
.fade-leave-active {
  opacity: 0;
}
.slide-fade-enter-active {
  transition: all 1s ease;
}
.slide-fade-leave-active {
  transition: all 0s ease;
}
.slide-fade-enter
  /* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.slide-fade-leave-to {
  transform: translateX(-10px);
  opacity: 0;
}
</style>
