<template>
  <div id="QuestionTypeSelect">
    <button
      v-for="(option, index) in optionsArray"
      :key="index"
      @click="selectOption(option.option_text)"
      class="select-button"
      :class="{ 'active': option.option_text === selectedOption }"
    >
      <i
        v-if="option.option_text === selectedOption"
        class="fa fa-circle-o"
        aria-hidden="true"
      ></i>
      <span class="select-button-text">{{ option.option_text }}</span>
    </button>
  </div>
</template>

<script>
import EventBus from "@/helpers/eventbus";

export default {
  name: "QuestionTypeSelect",
  props: ["optionsArray", "previousAnswer"],
  data() {
    return {
      selectedOption: this.previousAnswer || null,
    };
  },
  watch: {
    previousAnswer(newAnswer) {
      this.selectedOption = newAnswer;
    },
  },
  methods: {
    selectOption(option) {
      this.selectedOption = option;
      EventBus.$emit("setAnswer", this.selectedOption);
    }
  }
};
</script>

<style lang="less">
#QuestionTypeSelect {
  display: flex;
  flex-direction: column;
  gap: 32px;

  .select-button {
    font-family: "SourceSansProSemibold";
    border: 2px solid #757575;
    background-color: #ffffff;
    border-radius: 6px;
    box-shadow: 0px 2px 2px 0px #33333326;
    padding: 12px 32px;
    color: #343c3d;
    text-align: left;
    font-size: 1.8rem;
    line-height: 2.8rem;

    &:hover {
      border-color: #343c3d;
    }

    &:focus {
      outline: 4px solid #ffbc11;
    }

    &.active {
      border: 2px solid #00008f;

      .select-button-text::before {
        display: none;
      }
    }
  }

  .select-button-text::before {
    content: "";
    position: relative;
    top: 3px;
    display: inline-block;
    width: 20px;
    height: 20px;
    border: 3px solid #757575;
    border-radius: 50%;
    margin-right: 8px;
  }

  .fa-circle-o {
    position: relative;
    top: 2px;
    width: 20px;
    height: 20px;
    font-size: 2rem;
    color: #00008f;
    margin-right: 8px;
  }
}
</style>
