<template>
    <div id="DmhStart">
        <div class="dmh-start-container content-container" v-if="selectedView === 'journey'">
            <div class="progress-bar-outer w-100">
                <div class="progress-bar-inner " :style="{ width: progressWidth + '%' }"></div>
            </div>
            <h1 class="text-center brand-text">{{ currentQuestion.question_text ? currentQuestion.question_text :
                currentQuestion.sub_heading }}</h1>
            <div class="dmh-options-container">
                <SingleSelectQuestion :question="currentQuestion" :answer="currentAnswer" :setAnswer="setAnswer"
                    :answerQuestion="answerQuestion" v-if="currentQuestion.question_type === 'select'">
                </SingleSelectQuestion>
                <DateTimeQuestion :question="currentQuestion" :answer="currentAnswer" :setAnswer="setAnswer"
                    v-if="currentQuestion.question_type === 'date_time'" :isSameDateTime="isSameDateTime"></DateTimeQuestion>
                <ConfirmQuestion :question="currentQuestion" :setAnswer="setAnswer"
                    v-if="currentQuestion.question_type === 'text'"></ConfirmQuestion>
                <DmhLocation question="currentQuestion" :setAnswer="setAnswer"
                    v-if="currentQuestion.question_type === 'location_detection'" @skip="answerQuestion" />
                <TextQuestion :question="currentQuestion" :answer="currentAnswer" :setAnswer="setAnswer"
                    v-if="currentQuestion.question_type === 'confirm'"></TextQuestion>
                <TextAreaQuestion :question="currentQuestion" :answer="currentAnswer" :setAnswer="setAnswer"
                    v-if="currentQuestion.question_type === 'text_input'"></TextAreaQuestion>
                <SliderQuestion :question="currentQuestion" :answer="currentAnswer" :setAnswer="setAnswer"
                    v-if="currentQuestion.question_type === 'slider'"></SliderQuestion>
                <DateReview :question="currentQuestion" :setAnswer="setAnswer"
                    v-if="currentQuestion.question_type === 'date_review'"></DateReview>
                <DmhHardcodedOne :question="currentQuestion" :answer="currentAnswer" :setAnswer="setAnswer"
                    v-if="currentQuestion.question_type === 'hardcoded_page_1'"></DmhHardcodedOne>
                <div class="py-5 p-text-sm" v-if="currentQuestion.question_id === 5">
                    <p class="semibold pb-3">We will use the email and may use the phone number below to contact you about your mental health
                        appointment.
                    </p>
                    <ul class="semibold">
                        <li class="pb-3">If you prefer to be contacted about your mental health appointment using a different email or phone number, please update your details before clicking "Next."</li>
                        <li>By clicking "Next" and booking your appointment without updating your contact details, you are consenting to be contacted about your mental health appointment using the existing email and/or phone number.</li>
                    </ul>
                </div>
                <div class="d-flex justify-content-between align-items-center pt-5 mt-3 w-100">
                    <b-button
                        @click="questionHistory.length !== 0 ? getQuestion(questionHistory) : $router.push('/dmh')"
                        class="mr-5 py-4" variant="cta-outline" size="lg" squared>Back</b-button>
                    <b-button :disabled="currentAnswer === null || !allAnswersValid || isSameDateTime" variant="cta" class="py-4 ml-auto"
                        size="lg" squared @click="answerQuestion()">Next</b-button>
                </div>
            </div>

        </div>
        <div class="dmh-options-container content-container" v-else-if="selectedView === 'booking'">
            <DmhBooking :reset="reset" :previous="getQuestion" :questionHistory="questionHistory"></DmhBooking>
        </div>
        <div v-else-if="selectedView === 'financeSuccess'">
            <div class="d-flex flex-column align-items-center justify-content-center dmh-complete-container">
                <h3 class="brand-highlight-1">Thank you!</h3>
                <p>Our Team will be in touch within 48 hours to confirm your appointment.</p>
                <b-button variant="cta" squared size="lg" @click="$router.push('/')">Return to Home</b-button>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import config from "../config/constants";
import moment from "moment";
import SingleSelectQuestion from "@/components/DMH/SingleSelectQuestion"
import TextQuestion from "@/components/DMH/TextQuestion"
import TextAreaQuestion from "@/components/DMH/TextAreaQuestion"
import ConfirmQuestion from "@/components/DMH/ConfirmQuestion"
import SliderQuestion from "@/components/DMH/SliderQuestion"
import DateTimeQuestion from "@/components/DMH/DateTimeQuestion"
import DmhBooking from "@/components/DMH/DmhBooking"
import DateReview from "@/components/DMH/DateReview"
import DmhHardcodedOne from "@/components/DMH/DmhHardcodedOne.vue";
import EventBus from "@/helpers/eventbus"


import { mapGetters } from "vuex";
import DmhLocation from "@/components/DMH/Location.vue";

export default {
    name: "DmhStart",
    components: {
        DmhLocation,
        SingleSelectQuestion,
        TextQuestion,
        ConfirmQuestion,
        TextAreaQuestion,
        SliderQuestion,
        DateTimeQuestion,
        DmhBooking,
        DmhHardcodedOne,
        DateReview
    },
    data() {
        return {
            selectedView: 'start',
            selectedOptionQId: null,
            selectedOptionIndex: null,
            currentQuestion: null,
            currentAnswer: null,
            questionHistory: [],
            progressWidth: 0,
            financial: false,
            totalNumberOfQuestions: null,
            numberOfQuestionsToComplete: null,
            bookingStepsToComplete: null,
            firstDateTimeAnswer: null
        };
    },
    mounted() {
        this.getQuestion(null, 1);
    },
    methods: {
        reset() {
            this.selectedOptionIndex = null;
            this.selectedOptionQId = null;
            this.currentQuestion = null;
            this.questionHistory = [];
            this.currentAnswer = null;
            this.selectedView = 'start';
            this.progressWidth = 0;
            this.scrollToRef();
        },

        async getQuestion(questionHistory, questionId) {
            let question_id;
            if (questionHistory?.length === 0) {
                this.selectedOptionQId = null;
                this.selectedOptionIndex = null;
                this.selectedView = 'start';
            } else if (questionHistory?.length > 0) {
                this.selectedView = 'journey'
            }
            if (questionHistory) {
                question_id = questionHistory.pop();
            }
            if (questionId) {
                if (questionId === 'faq') {
                    return this.$router.push('/faqs')
                }
                if (questionId === 'articles') {
                    return this.$router.push('/articles')
                }
                question_id = questionId;
            }
            const payload = {
                user_id: this.$store.getters.user_id,
                question_id,
                service_type: 'axa_dmh'
            }
            let res = await axios.post(config.api_env + '/application/api/u/dmh/get-question', payload, config.options)
            if (res.data.success) {
                if (questionId) {
                    this.selectedView = 'journey'
                }
                this.currentQuestion = res.data.question
                EventBus.$emit("questionChanged");

                this.totalNumberOfQuestions = res.data.questionnaire_stage.total_number_of_questions
                this.numberOfQuestionsToComplete = res.data.questionnaire_stage.number_of_questions_to_complete
                if (['location_detection', 'text', 'hardcoded_page_1', 'date_review'].includes(res.data.question?.question_type)) {
                    this.currentAnswer = true
                } else if (res.data.question?.question_type === 'confirm') {
                    let answer = {};
                    res.data.question.options.forEach((o) => {
                        answer[o.field_key] = o.data
                    });
                    this.currentAnswer = answer;
                } else if (res.data.question?.question_type === 'slider') {
                    let answer = {};
                    res.data.question.sliders.forEach((s) => {
                        answer[s.slider_text] = s.options[2];
                    });
                    this.currentAnswer = answer;
                } else {
                    this.currentAnswer = null;
                }
            } else if (res.data.err && res.data.err.includes("3994")) {
                this.$store.dispatch("logout");
                this.$router.push("/login").catch((err) => { });
            }
        },
        async answerQuestion() {
            if (this.questionHistory.length === 0) {
                if (this.currentAnswer === 1) {
                    this.financial = true;
                } else {
                    this.financial = false;
                }
                if (this.currentAnswer === 2) {
                    return this.$router.push('/faqs');
                }
                if (this.currentAnswer === 3) {
                    return this.$router.push('/articles');
                }
            }
            const payload = {
                user_id: this.$store.getters.user_id,
                answered_question_id: this.currentQuestion.question_id,
                answer: this.currentAnswer,
                service_type: 'axa_dmh'
            }
            if (this.currentQuestion?.question_type === 'date_time' && this.currentQuestion?.sub_heading.includes('first')) {
              this.firstDateTimeAnswer = this.currentAnswer
            }
            let res = await axios.post(config.api_env + '/application/api/u/dmh/answer-question', payload, config.options)
            if (res.data.success) {
                this.questionHistory.push(this.currentQuestion.question_id);
                this.totalNumberOfQuestions = res.data.questionnaire_stage.total_number_of_questions
                this.numberOfQuestionsToComplete = res.data.questionnaire_stage.number_of_questions_to_complete
                if (res.data.question === null) {
                    if (this.financial) {
                        this.selectedView = 'financeSuccess'
                    } else {
                        this.selectedView = 'booking';
                    }
                } else {
                    this.currentQuestion = res.data.question;
                    EventBus.$emit("questionChanged");
                    this.numOfQuestions = res.data.questionnaire_stage.number_of_questions_to_complete;
                }
                if (['location_detection', 'text', 'hardcoded_page_1', 'date_review'].includes(res.data.question?.question_type)) {
                    this.currentAnswer = true
                } else if (res.data.question?.question_type === 'confirm') {
                    let answer = {};
                    res.data.question.options.forEach((o) => {
                        answer[o.field_key] = o.data
                    });
                    this.currentAnswer = answer;
                } else if (res.data.question?.question_type === 'slider') {
                    let answer = {};
                    res.data.question.sliders.forEach((s) => {
                        answer[s.slider_text] = s.options[2];
                    });
                    this.currentAnswer = answer;
                } else {
                    this.currentAnswer = null;
                }
                this.scrollToRef();
            } else if (res.data.err && res.data.err.includes("3994")) {
                this.$store.dispatch("logout");
                this.$router.push("/login").catch((err) => { });
            }
        },
        setAnswer(answer, type) {
            this.currentAnswer = answer;
        },
        selectOptionIndex(i, id) {
            this.selectedOptionIndex = i;
        },
        getClass(text) {
            switch (text) {
                case "I'd like to talk to someone about my mental health.":
                    return "fa-solid fa-head-side-virus";
                case "I'd like to learn more about the service.":
                    return "fa-solid fa-book-open-reader";
                case "I'd like to learn more about mental health and wellbeing.":
                    return "fa-solid fa-heart-pulse";
                case "I'd like to get access another service like financial or legal.":
                    return "fa-solid fa-coins";
                case "Video":
                    return "fa-solid fa-video";
                case "Voice Only":
                    return "fa-solid fa-microphone";
                default:
                    return ""
            }
        }
    },
    computed: {
        isTextInputQuestion() {
            return this.currentQuestion?.question_type === 'text_input' || this.currentQuestion?.question_type === 'text_area'
        },
        allAnswersValid() {
            if (this.currentQuestion?.question_type === 'location_detection') return true;
            if (this.isTextInputQuestion) {
                return this.currentAnswer?.trim().length > 4;
            }
            const validationResults = this.validateDmhAnswers(this.currentAnswer);
            return validationResults ? Object.values(validationResults).every(value => value === true) : true;
        },
        isSameDateTime() {
          if (this.currentQuestion?.question_type === 'date_time' && this.currentQuestion?.sub_heading.includes('second')) {
            return this.currentAnswer === this.firstDateTimeAnswer
          }
        },
        ...mapGetters(["user"]),
    },
    watch: {
        numberOfQuestionsToComplete(value) {
            const bookClinicianStep = 1
            this.progressWidth = ((this.totalNumberOfQuestions - value) / (this.totalNumberOfQuestions + bookClinicianStep)) * 100;
        },
    },
};
</script>
<style lang="less">
#DmhStart {
    padding: 70px 0;
    min-height: 100%;
    background-color: #fbfafa;

    .dmh-complete-container {
        font-size: 1.8rem;

        h3 {
            text-align: center;
            font-size: 3.8rem;
            letter-spacing: 0;
            font-family: "FSElliotBold", sans-serif;
        }

        p {
            padding-bottom: 30px;
            max-width: 450px;
            text-align: center;
        }
    }

    h1 {
        font-family: 'DMSans', sans-serif;
        font-weight: bold;
    }

    .progress-bar-outer {
        max-width: 650px;
        background-color: lightgrey;
        height: 6px;
        border-radius: 5px;
        margin-bottom: 60px;

        .progress-bar-inner {
            border-radius: 6px;
            height: 100%;
            max-width: 100% !important;
            background-color: #00ADC6;
        }
    }

    .dmh-start-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 800px;

        .prompt-container {
            margin-top: 20px;
            padding: 25px;
            max-width: 650px;
            border: 2px solid #e0e5f5;
            border-radius: 16px;
            background-color: white;
        }

        .dmh-start-title {
            text-align: center;
            font-size: 3.8rem;
            letter-spacing: 0;
            font-family: "DMSans", sans-serif;
        }

        .dmh-prompt-title {
            padding: 0;
            margin-top: 0;
            margin-bottom: 30px;
            text-align: center;
            font-size: 2.4rem;
            letter-spacing: 0;

            font-family: "DMSans", sans-serif;
        }

        .phone-item {
            font-size: 1.8rem;
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;
            font-family: "FSElliotBook", sans-serif;

            .phone-link {
                border-bottom: 1.5px solid;
                font-family: "DMSans", sans-serif;

            }
        }

        .dmh-options-container {
            margin: 20px;
            max-width: 650px;
            display: flex;
            flex-direction: column;
            flex-grow: 1;
            width: 100%;


        }

    }

    .dmh-complete-container {
        font-size: 1.8rem;

        h3 {
            text-align: center;
            font-size: 3.8rem;
            letter-spacing: 0;
            font-family: "DMSans", sans-serif;
        }

        p {
            padding-bottom: 30px;
            max-width: 450px;
            text-align: center;
        }
    }
}
</style>