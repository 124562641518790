var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"custom-calendar container",attrs:{"id":"Calendar"}},[_c('div',{staticClass:"calendar-nav pb-3 d-flex flex-row brand-text mt-2"},[_c('div',{staticClass:"month"},[_vm._v(_vm._s(_vm.currentMonth))]),_c('div',{staticClass:"prev"},[_c('div',{staticClass:"nav-button px-3",on:{"click":_vm.prevMonth}},[_c('i',{staticClass:"fa fa-arrow-left brand-highlight-1"})])]),_c('div',{staticClass:"next"},[_c('div',{staticClass:"nav-button px-3",on:{"click":_vm.nextMonth}},[_c('i',{staticClass:"fa fa-arrow-right brand-highlight-1"})])])]),(_vm.loading)?_c('div',[_c('b-spinner')],1):(true)?_c('div',[_c('div',{staticClass:"row flex-nowrap pb-3"},_vm._l((_vm.days),function(day,index){return _c('div',{key:index,staticClass:"col day"},[_vm._v(" "+_vm._s(day)+" ")])}),0),_vm._l((_vm.calendar),function(row,i){return _c('div',{key:i,staticClass:"row flex-nowrap pb-3"},_vm._l((row),function(date,index){return _c('div',{key:index,staticClass:"col date-wrap p-2",class:{
          'has-entry': _vm.entryDates.includes(
            _vm.getFormatedDate(date.fullDate, 'YYYY-MM-DD')
          ),
        }},[_c('div',{ref:'ref-' + date.displayDate,refInFor:true,staticClass:"date",class:{
            'not-current': !date.currentMonth,
          },on:{"click":function($event){return _vm.selectDate(date)}}},[_vm._v(" "+_vm._s(date.displayDate)+" ")]),(
            _vm.entryDates.includes(_vm.getFormatedDate(date.fullDate, 'YYYY-MM-DD'))
          )?_c('div',{staticClass:"has-entry-sticker bg-brand-highlight-1"}):_vm._e(),(_vm.selectedDate === _vm.formatDate(date.fullDate))?_c('div',{staticClass:"active bg-brand-secondary"}):_vm._e()])}),0)})],2):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }