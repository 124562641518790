<template>
    <div id="HealthCoachingHome">
        <section id="CoachingBanner" class="coaching-home-banner-container pb-5">
            <div class="content-container">
                <div class="row coaching-home-banner mx-0 align-items-center">
                    <div class="coaching-home-banner-text col-md-6 col-sm-10 mx-auto">
                        <div class="col-12 mb-5">
                            <img src="/img/healthcoachBanner.png" class="img-fluid" />
                        </div>
                        <h1 class="coaching-home-banner-title mb-3">Cancer Coaching</h1>
                        <p class="white-text mb-5">
                            Cancer Coaching is a personalised, action-oriented service designed to help you improve your overall wellbeing, create healthier habits, and achieve your fitness and lifestyle goals. 
                        </p>
                        <div class="d-flex flex-row align-items-center text-left clickable w-100 mb-5" v-b-toggle.collapse-1-inner>
                            <h3 class=" brand-highlight-1 semibold mb-0 pb-0" >How it works</h3>
                            <i class="fa-solid fa-caret-down ml-auto fa-lg brand-highlight-1"></i>
                        </div>
                        <b-collapse id="collapse-1-inner">
                            <ol class="pb-5 pt-3">
                                <li class="pb-3">
                                    Initial Consultation: Start with a session where you’ll discuss your health and wellbeing goals with a certified health coach.
                                </li>
                                <li class="pb-3">
                                    Tailored Plan: Your coach will develop a customised plan to help you achieve your desired outcomes, whether it's improving nutrition, increasing physical activity, or adopting healthier habits.                                </li>
                                <li class="pb-3">
                                    Ongoing Support: Regular follow-up sessions will keep you motivated and on track, with expert advice and practical strategies.
                                </li>
                                <li class="pb-3">
                                    Convenient Access: Coaching sessions are available virtually in-app, and over live chat ensuring support is accessible wherever you are.
                                </li>
                            </ol>
                        </b-collapse>
                        <div class="d-flex flex-row align-items-center text-left clickable w-100 mb-5" v-b-toggle.collapse-2-inner>
                            <h3 class=" brand-highlight-1 semibold mb-0 pb-0" >Our commitment</h3>
                            <i class="fa-solid fa-caret-down ml-auto fa-lg brand-highlight-1"></i>
                        </div>
                        <b-collapse id="collapse-2-inner">
                            <p class="pb-5 py-3">At Spectrum.Life, we are dedicated to:</p>
                            <ul class="pb-5 pl-4">
                                <li class="pb-3">
                                    Initial Consultation: Start with a session where you’ll discuss your health and wellbeing goals with a certified health coach.
                                </li>
                                <li class="pb-3">
                                    Tailored Plan: Your coach will develop a customised plan to help you achieve your desired outcomes, whether it's improving nutrition, increasing physical activity, or adopting healthier habits.                                </li>
                                <li class="pb-3">
                                    Ongoing Support: Regular follow-up sessions will keep you motivated and on track, with expert advice and practical strategies.
                                </li>
                                <li class="pb-3">
                                    Convenient Access: Coaching sessions are available virtually in-app, and over live chat ensuring support is accessible wherever you are.
                                </li>
                            </ul>
                        </b-collapse>
                        <div class="d-flex flex-row align-items-center text-left clickable w-100 mb-5" v-b-toggle.collapse-3-inner>
                            <h3 class=" brand-highlight-1 semibold mb-0 pb-0" >FAQs</h3>
                            <i class="fa-solid fa-caret-down ml-auto fa-lg brand-highlight-1"></i>
                        </div>
                        <b-collapse id="collapse-3-inner">
                            <div class="brand-text py-3 ">
                                <h4 class="p-text">Q: Who can access mental health coaching?</h4>
                                <p class="p-text">A: You'll work with a qualified health coach with expertise in helping people to meet their own goals.</p>
                            </div>
                            <div class="brand-text pb-3">
                                <h4 class="p-text">Q: What can I expect in a coaching session?</h4>
                                <p class="p-text">A: Just select 'Book a coaching session' and you can choose a time for your session</p>
                            </div>
                            <div class="brand-text pb-3">
                                <h4 class="p-text">Q: Are the sessions confidential?</h4>
                                <p class="p-text">A: Yes, and we handle your personal data in accordance with our <a href="/privacy-policy" target="_blank" class="underlined brand-primary"><b>privacy&nbsp;policy</b></a>.</p>
                            </div>
                            <div class="brand-text pb-5">
                                <h4 class="p-text">Q: How many sessions can I have?</h4>
                                <p class="p-text">A: This depends on how your membership is set up. Please check your membership information, ask your HR team, or contact AXA Health for more details.</p>
                            </div>
                        </b-collapse>
                        <b-button variant="cta" size="lg" squared class="py-3 px-5 mt-5 w-100"
                            @click="$router.push('/cancer-coaching/select-coach')">Continue to booking</b-button>
                    </div>
                    
                </div>
            </div>
        </section>
    </div>
</template>
  
<script>
import axios from "axios";
import config from "../config/constants";
import slick from "vue-slick";

export default {
    name: "HealthCoachingStart",
    components: {
        slick,
    },
    mounted() { },
    data() {
        return {
            startColour: '#381B51',
            stopColour: '#9A5683',
            courses: {},

            pathwayModalTitle: "",
            pathwayModalImageUrl: "",
            pathwayModalText: "",
            windowWidth: window.innerWidth,
            selectPathwayBtnText: 'More Info',
            slickOptions: {
                infinite: true,
                autoplay: false,
                dots: false,
                nextArrow:
                    '<button class="slick-next  py-1" type="button" style=""><i class="fas fa-chevron-right"></i></button>',
                prevArrow:
                    '<button class="slick-prev  py-1" type="button" style=""><i class="fas fa-chevron-left"></button>',
                speed: 300,
                initialSlide: 1,
                slidesToScroll: 0,
                slidesToShow: 3,
                centerMode: true,
                centerPadding: "0",
                variableWidth: false,
                adaptiveHeight: true,
                responsive: [
                    {
                        breakpoint: 768,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 0,
                        },
                    },
                ],
            },
        };
    },
    mounted() {
    },
    methods: {

        closePathwayModal() {
            this.$bvModal.hide("pathway-modal");
            this.$router.push('/health-coaching/start/')
        },
        showPathwayModal(image, title, text) {
            this.pathwayModalImageUrl = image;
            this.pathwayModalText = text;
            this.pathwayModalTitle = title;
            this.$bvModal.show("pathway-modal");
            const pathwayName = title.replace(' ', '-').toLowerCase();
            const payload = {
                user_id: this.$store.getters.user_id,
                pathway: pathwayName
            };
            axios.post(config.api_env + "/application/api/u/pathways/set-pathway", payload, config.options);
        },
    },
    computed: {
        componentText() {
            return this.getText.loggedIn.home.homeWellbeing;
        },
    },
};
</script>
<style lang="less">
#HealthCoachingHome {
    background-color: white;
    .semibold{
        font-size: 2rem;
    }
    .section-title {
        font-size: 2.8rem;
        font-weight: bold;
        letter-spacing: normal;
        font-weight: bold;
    }

    .contact-card-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 50px;

        .contact-card {
            background-color: white;
            border: 1px solid lightgrey;
            display: flex;
            flex-grow: 1;
            min-width: 400px;
            flex-direction: column;
            align-items: center;
            padding: 25px;

            .contact-card-icon {
                margin-bottom: 20px;
                background-color: lightblue;
                width: 64px;
                height: 64px;
                border-radius: 100px;
                display: flex;
                align-items: center;
                justify-content: center;

                i {
                    color: white;
                    font-size: 2.5rem;
                }
            }

            .contact-card-title {
                font-size: 2rem;
                font-weight: bold;
                letter-spacing: normal;
                font-weight: bold;
                margin: 0;
                padding: 0;
            }

            .contact-card-247 {
                font-size: 1.4rem;
                font-weight: bold;
                letter-spacing: normal;
                font-weight: bold;
                margin-bottom: 10px;
                padding: 0;
                color: #138636;
            }

            p {
                font-size: 1.6rem;
            }
        }
    }

    .coaching-home-banner-container {
        .coaching-home-banner {
            padding-top: 60px;
        }

        .coaching-home-banner-text {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            font-size: 1.6rem;
            z-index: 2;

            .coaching-home-banner-title {
                text-align: center;
                font-size: 4.8rem;
                font-weight: bold;
                padding-bottom: 25px;
            }

        }

        div {
            z-index: 2;
        }
    }
    ol{
        padding-left: 15px;
        li{
            font-size: 1.6rem;
        }
    }

    @media only screen and (max-width: 768px) {
        .coaching-pathways-container {
            grid-template-columns: 1fr 1fr !important;
        }
    }

    .coaching-pathways-container {
        padding-bottom: 30px;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        column-gap: 30px;

        .coaching-pathway {
            background-color: white;
            border-radius: 8px;
            margin-top: 20px;
            margin-bottom: 20px;
            display: flex;
            flex-direction: column;
            position: relative;
            overflow: hidden;
            z-index: 2;
            flex-grow: 1;

            .coaching-pathway-container {
                flex-grow: 1;
                display: flex;
                flex-direction: column;
                padding: 15px;
            }

            h4 {
                font-size: 1.8rem;
                font-weight: bold;
                margin-bottom: 15px;
                z-index: 10;
            }

            p {
                font-size: 1.4rem;
                z-index: 10;
            }

            .coaching-pathway-image {
                display: flex;
                align-items: center;
                justify-content: center;

                img {
                    height: auto;
                    width: 100%;
                }
            }

            .pathway-card-button {
                margin-left: -10px;
                padding: 10px;
                font-size: 1.4rem;
            }

        }
    }

    .horizontal-divider {
        flex-grow: 1;
        margin-left: 20px;
        border: 2px solid;
    }

    .banner-overlay {
        position: absolute;
        width: 100%;
        bottom: 100%;
        z-index: 1;

        img {
            width: 100%;
        }
    }

    .pathway-overlay {
        position: absolute;
        width: 100%;
        bottom: 0;
        z-index: 1;

        img {
            width: 100%;
        }
    }

    .pathway-blob {
        position: absolute;
        width: 100%;
        bottom: 0;
        right: 0;
        z-index: 1;

        div {}
    }
}

#pathway-modal {
    .modal-content {
        border-radius: 24px;
    }

    .pathway-modal-inner {
        max-height: 90vh;
        overflow-y: auto;
    }

    .close-button {
        cursor: pointer;
    }

    .pathway-text {
        font-size: 1.4rem;
    }

}</style>
  