<template>
  <div id="available-events" class="content-container">
    <link
      rel="stylesheet"
      href="https://use.fontawesome.com/releases/v5.2.0/css/all.css"
      integrity="sha384-hWVjflwFxL6sNzntih27bfxkr27PmbbK/iSvJ+a4+0owXq79v+lsFkW54bOGbiDQ"
      crossorigin="anonymous"
    />
    <div v-if="loading" class="row">
      <b-spinner class="mx-auto"></b-spinner>
    </div>
    <div v-else-if="allEvents.length > 0" class="row">
      <div class="available-events-header col-12 pb-5">
        <b-button-group>
          <b-button
            @click="availableEventsView('upcoming')"
            class="px-4 py-3 mx-3 brand-secondary border-brand-secondary"
            :class="{ active: viewMethod === 'upcoming' }"
          >
            {{componentText.upcoming}}
          </b-button>
          <b-button
            @click="availableEventsView('calendar')"
            class="px-4 py-3 mx-3 brand-secondary border-brand-secondary"
            :class="{ active: viewMethod === 'calendar' }"
          >
            {{componentText.calendar}}
          </b-button>
        </b-button-group>
      </div>
      <div class="section-heading mt-4">
        <h4>{{componentText.next}}</h4>
      </div>
      <EventsCalendar v-if="viewMethod === 'upcoming'" :events="allEvents" class="col-12 px-0" @date-select="eventNav"/>
      <div class="events-calendar col-12" v-else>
        <div class="calendar-nav row pb-5">
          <div class="col-2">
            <a @click="prevMonth" v-if="monthIndex > 0"><font-awesome-icon icon="chevron-left" size="3x" class="brand-highlight-1"></font-awesome-icon></a>
          </div>
          <div class="col-8 calendar-week">
          </div>
          <div class="col-2 calendar-next d-flex justify-content-end">
            <a @click="nextMonth" ><font-awesome-icon icon="chevron-right" size="3x" class="brand-highlight-1"></font-awesome-icon></a>
          </div>
        </div>
        <full-calendar
                v-if="!loading"
          :event-sources="allEvents"
          :config="calendarConfig"
          ref="calendar"
        >
        </full-calendar>
      </div>
    </div>
    <div class="row justify-content-center" v-else>
      <div
        class="col-12 col-md-6 py-5 no-events brand-secondary d-flex align-items-center"
      >
        <div class="no-events-heading before-secondary">
          {{componentText.noEvents}}
        </div>
      </div>
      <div class="col-0 offset-1 col-md-5 no-bookings">
        <img src="/img/placeholder-bookings-generic.svg" />
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
//TODO:: Tidy up calendar appearance
//calendar events render after initial load
import axios from "axios";
import config from "../../config/constants";
import { FullCalendar } from "vue-full-calendar";
import moment from "moment";
import EventsCalendar from "./EventsCalendar";
export default {
  name: "AvailableEvents",
  components: {
    EventsCalendar,
    FullCalendar
  },
  data() {
    return {
      loading: true,
      availableEvents: [],
      viewMethod: "upcoming",
      monthIndex: 0,
      pageCount: "",
      allEvents: [],
      eventSelected: false,
      calendarConfig: {
        locale: this.$store.state.lang,
        selectable: true,
        unselectAuto: true,
        height: "auto",
        fixedWeekCount: false,
        showNonCurrentDates: false,
        columnHeaderFormat: "ddd",
        firstDay: 1,
        defaultView: "month",
        selectConstraint: {
          start: "00:01",
          end: "23:59"
        },
        businessHours: {
          // days of week. an array of zero-based day of week integers (0=Sunday)
          daysOfWeek: [1, 2, 3, 4, 5]
        },
        header: {
          left: "",
          center: "title",
          right: ""
        },
        titleFormat: "MMMM YYYY",
        visibleRange: {
          start: "2019-11-15",
          end: "2025-01-01"
        },
        dayRender: this.dayRender,
        dayClick: this.dayClick,
      }
    };
  },
  mounted() {
    this.getEvents();

  },
  methods: {
    async getEvents() {
      const today = this.$date(new Date(), "YYYY-MM-DD");
      const payload = {
        user_id: this.$store.getters.user_id,
        from_date: today,
        days_limit: 365
      };
      let res = await axios.post(
        config.api_env + "/application/api/u/events/get-events",
        payload,
        config.options
      );
      if (res.data.err && res.data.err.includes("3994")) {
        this.$store.dispatch("logout");
        this.$router.push("/login");
      }
      this.allEvents = [...res.data.data];
      this.loading = false
    },
    availableEventsView(method) {
      this.viewMethod = method;
    },
    dayRender(date, cell) {
      let cur_date = moment(date).format("YYYY-MM-DD");

      this.allEvents.filter(eve => {
        if (eve.date == cur_date) {
          cell.prepend(
            '<i class="far fa-calendar fa-2x cal-icon-color calendar-icon" />'
          );
          cell.addClass("calendar-icon");
        }
      });
    },
    dayClick(date) {
      let cur_date = moment(date).format("YYYY-MM-DD");
      this.allEvents.filter(eve => {
        if (eve.date == cur_date) {
          let niceDate = moment(date).format("dddd, DD MMMM YYYY");
          this.$emit("event-popup", niceDate, eve.events);
        }
      });
    },
    prevMonth(){
      this.$refs.calendar.fireMethod('prev')
      this.monthIndex --
    },
    nextMonth(){
      this.$refs.calendar.fireMethod('next')
      this.monthIndex ++
    },
    eventNav(event) {
      this.$router.push({
        name: "viewEvent",
        params: { id: event.event_date_id }
      });
    }
  },
  computed:{
    componentText() {
      return this.getText.loggedIn.events.availableEvents;
    },
  }
};
</script>
<style lang="less">
#available-events {
  .loading {
    display: flex;
    flex-grow: 1;
  }
  .available-events-header {
    position: relative;
    .btn {
      font-family: "DMSans", sans-serif;
      border-width: 1px;
      &.active {
        border-width: 0;
      }
    }
  }
  .section-heading {
    width: 100%;
    text-align: center;
    position: relative;
    top: -35px;
    h4 {
      margin: 0;
      font-family: "FilsonProLight", sans-serif;
      font-size: 2rem;
    }
  }
}
.events-calendar {
  .fc-week {
    height: 118px;
  }
  .fc .fc-head td,
  .fc .fc-head th {
    border-style: none;
  }
  .fc-day-number {
    position: relative;
    top: 45px;
    right: 5px;
    font-size: 4.4rem;
    font-family: "FilsonProLight";
    opacity: 1;
  }
  .fc-day-header {
    font-family: FilsonProLight, sans-serif;
    text-transform: uppercase;
    font-size: 1.6rem;
  }
  .fc .fc-past,
  .fc .fc-unavailable {
    color: #aeaeae;
    background: #d7d7d7;
    opacity: 0.3;
  }
  .fc-event {
    display: none;
  }
  .fc-unthemed td.fc-today {
    background: #fff;
  }
  .fc {
    .calendar-icon {
      cursor: pointer;
      i {
        font-size: 3rem;
        padding: 5px;
        max-width: 30px;
      }
    }
  }
  .calendar-nav{
    position: absolute;
    width: 100%;
    top: 0;
  }
  @media only screen and (max-width: 576px) {
    .fc-week {
      height: 80px;
    }
    .fc-day-top {
      height: 30px;
    }
    .fc-day-number {
      font-size: 3rem;
      top: 30px;
      right: 0;
    }
    .fc {
      .calendar-icon {
        i {
          font-size: 2rem;
          max-width: 20px;
        }
      }
    }
  }
}
</style>
