<template>
    <div id="LargeBookingButton">
        <div v-if="event.e_type == 'service'">
            <div class="row" v-if="timeslots && timeslots.length > 0 && !event.ed_fully_booked">
                <div class="section-header mt-4 col-12">
                    <h2 class="section-header-title">{{ componentText.selectTime }}</h2>
                </div>
                <div class="col-12">
                    <div class="time-slot my-4 d-flex flex-row justify-content-start align-items-center flex-wrap">
                        <b-button class="mr-4 py-4 px-5 mb-4" v-for="(slot, i) in timeslots" :key="i" squared
                            @click="selectTimeslot(slot)"
                            :variant="selected_timeslot.ts_id == slot.ts_id ? 'cta' : 'cta-outline'">
                            {{ getHoursMinutes(slot.ts_start_date_time) }}
                        </b-button>
                    </div>
                </div>

                <div class="py-5 col-12 event-register" id="service-register-button">
                    <h3 v-if="event.e_employee_series_cost > 0">Price: {{ currencyString(event.e_employee_slot_cost,
                        event.e_currency) }}</h3>
                    <p>{{ componentText.agree }} <a :href="terms" target="_blank">{{ componentText.terms }}</a>
                    </p>
                    <div class="py-5" v-if="!event.ed_u_on_waiting_list && event.ed_fully_booked">
                        <p>{{ componentText.full }}</p>
                        <p>{{ componentText.notified }}</p>
                        <b-button squared @click="registerWaiting" class="cta-button px-5 py-3 mt-5">
                            <span v-if="!loading">{{ componentText.waiting }}</span>
                            <b-spinner v-else></b-spinner>
                        </b-button>
                    </div>

                    <div class="py-5" v-if="event.ed_u_on_waiting_list && event.ed_fully_booked">
                        <p>{{ componentText.onWaiting }}</p>
                        <p>{{ componentText.notified }}</p>
                        <!--<b-button squared @click="registerWaiting" class="brand-secondary bg-brand-highlight-1 px-5 py-3 mt-5">Add to waiting list</b-button>-->
                    </div>
                    <b-button squared v-if="!event.ed_fully_booked" @click="startBooking"
                        class="cta-button px-5 py-3 my-3" :disabled="!selected_timeslot">
                        <span v-if="!loading">{{ selected_timeslot ? event.e_employee_pay_flag ? componentText.payment :
                            componentText.registerSingle : componentText.selectTime }}</span>
                        <b-spinner v-else />
                    </b-button>
                </div>
            </div>
        </div>
        <div v-else-if="event.e_type === 'webinar' || event.e_type === 'external'" class="col-12">
            <div id="event-view-button">
                <div class="py-5 event-register">
                    <p>{{ componentText.webinarAgree }}</p>
                    <p>
                        <a :href="terms" target="_blank">{{ componentText.terms }}</a>
                    </p>
                    <b-button squared class="cta-button px-5 py-3 " :href="event.e_external_link" target="_blank">
                        <span v-if="!loading">{{event.e_type === 'webinar' ? 'View Webinar' : 'View External Event'}}</span>
                        <b-spinner v-else></b-spinner>
                    </b-button>
                </div>
            </div>
        </div>
        <div class="col-12 px-0" v-else>
            <div id="event-register-button" class="event-register">
                <div class="py-5"
                    v-if="event.e_type !== 'event_series' && !event.ed_u_on_waiting_list && !event.ed_u_registered && event.ed_fully_booked">
                    <p>{{ componentText.full }}</p>
                    <p>{{ componentText.notified }}</p>
                    <b-button squared @click="registerWaiting" class="cta-button px-5 py-3 mt-5">
                        <span v-if="!loading">{{componentText.waiting}}</span>
                        <b-snipper v-else></b-snipper>
                    </b-button>
                </div>
                <div class="py-5"
                    v-if="event.e_type === 'event_series' && !event.ed_u_registered && event.ed_fully_booked">
                    <p>{{ componentText.waitingSlot }}</p>
                    <p>{{ componentText.notified }}</p>
                </div>
                <div class="py-5"
                    v-if="event.e_type !== 'event_series' && event.ed_u_on_waiting_list && !event.ed_u_registered && event.ed_fully_booked">
                    <p>{{ componentText.onWaiting }}</p>
                    <p>{{ componentText.notified }}</p>
                    <!--<b-button squared @click="registerWaiting" class="brand-secondary bg-brand-highlight-1 px-5 py-3 mt-5">Add to waiting list</b-button>-->
                </div>

                <div class="py-5" v-if="event.ed_u_registered">
                    <p>{{ componentText.alreadyRegistered }}</p>
                    <b-button squared @click="cancelDateBooking" class="cta-button px-5 py-3 mt-5">
                        <span v-if="!loading">{{componentText.cancel}}</span>
                        <b-spinner v-else></b-spinner>
                    </b-button>
                </div>

                <div class="py-5" v-if="!event.ed_u_registered && !event.ed_fully_booked">
                    <h3 v-if="event.e_employee_series_cost > 0">Price: {{ currencyString(event.e_employee_series_cost,
                        event.e_currency) }}</h3>
                    <p>{{ componentText.agree }} <a :href="terms" target="_blank">{{ componentText.terms }}</a>
                    </p>
                    <b-button squared v-if="!isPast(event.ed_start_date_time)" @click="startBooking" class="cta-button test px-5 py-3 my-3">
                        <span v-if="!loading">{{ event.e_type === 'event_series' ? componentText.registerAll : event.e_employee_pay_flag ?
                            componentText.payment : componentText.registerSingle }}</span>
                        <b-spinner v-else></b-spinner>
                    </b-button>
                    <b-button v-else squared disabled class="cta-button test px-5 py-3 my-3">No longer available</b-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import config from "../../config/constants";
export default {
    name: 'LargeBookingButton',
    props: [
        'event',
    ],
    data() {
        return {
            selected_timeslot: false,
            message: '',
            loading: false,
        }
    },
    computed: {
        terms() {
            //TODO move to hardcoded JSON
            if (this.$store.getters.skin === 8) {
                return "/img/boi/BOI-Wellbeing-TCs.pdf"
            } else if (this.$store.getters.skin === 13) {
                return "/nutzungsbedingungen"
            } else {
                return "/terms-and-conditions"
            }
        },
        timeslots() {
          if (Array.isArray(this.event.ed_timeslots) && this.event.ed_timeslots.length > 0) {
            return this.event.ed_timeslots.filter(timeslot => {
                if (!timeslot.ts_fully_booked 
                && timeslot.ts_time_status !== 'past' 
                && !timeslot.ts_u_registered) {
                    return timeslot
                }
            })
          } else {
            return []
          }
        },
        componentText() {
            return this.getText.loggedIn.event.largeBookingButton;
        },
    },
    methods: {
        startBooking() {
            this.loading = true;
            if (this.event.e_employee_pay_flag) {
                //load stripe
                if (!document.getElementById('stripe-script')) {
                    let script = document.createElement('script')
                    script.type = 'text/javascript'
                    script.src = 'https://js.stripe.com/v3/'
                    script.id = 'stripe-script'
                    document.head.appendChild(script)
                }
                if (this.event.e_type == 'service') {
                    this.requestSlotStripeSession()
                } else if (this.event.e_type == 'event_series') {
                    this.requestDateStripeSession(true)
                } else {
                    this.requestDateStripeSession()
                }
            } else {
                if (this.event.e_type == 'service') {
                    this.bookTimeslot()
                } else if (this.event.e_type == 'event_series') {
                    this.bookDate(true)
                } else {
                    this.bookDate(false)
                }
            }
        },
        requestSlotStripeSession(all_series = false) {
            const url = config.api_env + "/application/api/u/events/get-event-stripe-session-by-slot";
            const payload = {
                user_id: this.$store.getters.user_id,
                timeslot_id: this.selected_timeslot.ts_id,
                all_series: all_series,
                referrer: window.location.hostname
            };
            const opts = {
                'method': 'post',
                'url': url,
                'data': payload
            }
            const _this = this;
            this.axios(opts)
                .then(function (res) {
                    if (res.data.success == true) {
                        _this.redirectToStripe(res.data.session)
                    } else {
                        //TODO display some error
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        requestDateStripeSession(all_series = false) {
            const url = config.api_env + "/application/api/u/events/get-event-stripe-session-by-date";
            const payload = {
                user_id: this.$store.getters.user_id,
                event_date_id: this.event.ed_id,
                all_series: all_series,
                referrer: window.location.hostname
            };
            const opts = {
                'method': 'post',
                'url': url,
                'data': payload
            }
            const _this = this;
            this.axios(opts)
                .then(function (res) {
                    if (res.data.success == true) {
                        _this.redirectToStripe(res.data.session)
                    } else {
                        //TODO display some error
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        async bookDate(all_series = false) {
            const payload = {
                user_id: this.$store.getters.user_id,
                event_date_id: this.event.ed_id,
                all_series: all_series
            };
            let res = await this.axios.post(
                config.api_env + "/application/api/u/events/book-date",
                payload, config.options
            );
            if (res.data.success) {
                this.$parent.getEventDate()
                this.loading = false;
            } else {
                //TODO display alert
            }
        },
        async bookTimeslot(all_series = false) {
          try {
            const payload = {
                user_id: this.$store.getters.user_id,
                timeslot_id: this.selected_timeslot.ts_id,
                all_series: all_series
            };
            let res = await this.axios.post(config.api_env + "/application/api/u/events/book-timeslot", payload, config.options);
            if (res.data.success) {
              this.$parent.getEventDate()
            } else if (!res.data?.success && res.data?.err?.response.includes("Time slot is past")) {
              await this.showMsgModal("The selected time slot has expired.")
            } else {
              await this.showMsgModal(this.componentText.errorMsg)
            }
          } catch(err) {
            console.error(err)
          } finally {
            this.selected_timeslot = false
            this.loading = false;
          }
        },
        async registerWaiting() {
            const payload = {
                user_id: this.$store.getters.user_id,
                event_date_id: this.event.ed_id
            }
            let res = await this.axios.post(config.api_env + "/application/api/u/events/register-waiting-list", payload, config.options)
            if (res.data.success) {
                this.$parent.getEventDate()
                this.loading = false;
            } else {
                //TODO do something else
            }
        },
        async cancelDateBooking() {
            let all_series = this.event.e_type == 'event_series';
            const payload = {
                user_id: this.$store.getters.user_id,
                event_date_id: this.event.ed_id,
                all_series: all_series
            }
            let res = await this.axios.post(config.api_env + '/application/api/u/events/cancel-date-booking', payload, config.options)
            if (res.data.success) {
                const msg = this.event.e_employee_pay_flag ? this.componentText.cancellationMsgPaid + this.currencyString(this.event.e_employee_slot_cost, this.event.b_t_currency) : this.componentText.cancellationMsgFree
                const modalOptions = {
                    title: this.componentText.cancellationSuccess,
                    hideHeader: false,
                    size: "md",
                    buttonSize: "lg",
                    okTitle: "OK",
                    cancelTitle: "NO",
                    footerClass: "p-2",
                    hideHeaderClose: true,
                    centered: true,
                    contentClass: "pop-up-content"
                }
                this.showMsgModal(msg, modalOptions)
                this.$parent.getEventDate()
            } else {
                //TODO do something else
            }
        },
        selectTimeslot(slot) {
            this.selected_timeslot = slot
        },
        redirectToStripe(session) {
            const stripe = Stripe(config.stripe_key)
            stripe.redirectToCheckout({ sessionId: session.id }).then(function (result) { console.log(result) });
        },
    }
}
</script>
<style scoped lang="less">
#LargeBookingButton {
    .btn {
        padding: 12px !important;
        font-size: 1.6rem;
        min-width: 200px;
        min-height: 40px;
    }

    .btn.btn-cta-outline {      
        padding: 12px !important;
        font-size: 1.6rem;
        border-color: #757575 !important;
        color: #343C3D;
    }

    .section-header-title {
        color: #343C3D;
        font-family: "SourceSansProBold";
  } 
}
</style>
