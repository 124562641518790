<template>
  <div
    id="Nav"
    class="d-flex flex-row align-items-center position-relative"
  >
    <div
      class="content-container d-flex flex-row flex-wrap justify-content-between align-items-center w-100"
    >
      <div>
        <img
          class="nav-logo clickable ml-2 ml-sm-0"
          src="/img/axa-health.svg"
          onerror="javascript:this.src='/img/logo-spectrum-life.svg'"
          @click="$router.push('/')"
        />
      </div>
      <button v-if="isCloseButtonShown" class="btn-close-health-score" @click="handleCloseButtonClick">
        Close 
        <i class="fa fa-times"></i>
      </button>
      <div v-else class=" ml-auto d-flex">
        <i
          class="fa-solid fa-calendar fa-3x pr-5 clickable brand-highlight-1"
          @click="showBookings"
        ></i>
        <i
          class="fa-solid fa-bell fa-3x pr-5 clickable brand-highlight-1"
          :class="{ unseenNotifications: unseenNotifications }"
          @click="showNotifications"
        ></i>
        <button class="btn-logout" @click="showSideMenu">
          <i class="fa fa-bars" aria-hidden="true"></i>
          Menu
        </button>
      </div>
    </div>
    <NavSideMenu v-if="showSideMenuView" @close-side-menu="showSideMenu"></NavSideMenu>
    <BookingList v-if="showBookingsView" @close-bookings="showBookings" />
    <NotificationList
      v-if="showNotificationsView"
      @close-notifications="showNotifications"
    />
  </div>
</template>
<script>
import BookingList from "./BookingList.vue";
import NavSideMenu from "./NavSideMenu.vue";
import NotificationList from "./NotificationList.vue";
import EventBus from "@/helpers/eventbus";

export default {
  name: "Navigation",
  components: {
    BookingList,
    NotificationList,
    NavSideMenu
  },
  data() {
    return {
      showUpcomingBookings: true,
      showBookingsView: false,
      bookings: [],
      upcomingBookings: [],
      pastBookings: [],
      unreadInterval: null,
      unseenNotifications: false,
      notifications: [],
      showNotificationsView: false,
      isCloseButtonShown: false,
      showSideMenuView: false,

    };
  },
  mounted() {
    this.checkForNotifications();
    this.unreadInterval = setInterval(() => {
      if (!this.unseenNotifications) this.checkForNotifications();
    }, 1000 * 60);
    EventBus.$on("startHealthScoreAssessment", this.showCloseButton)
    EventBus.$on("finishHealthScoreAssessment", this.hideCloseButton)
  },
  beforeDestroy() {
    if (this.unreadInterval) {
      clearInterval(this.unreadInterval);
    }
  },
  methods: {
    showCloseButton() {
      this.isCloseButtonShown = true
    },
    hideCloseButton() {
      this.isCloseButtonShown = false
    },
    handleCloseButtonClick() {
      this.hideCloseButton()
      this.$router.push('/healthscore')
    },
    setShowUpcomingBookings(value) {
      this.showUpcomingBookings = value;
    },
    showNotifications() {
      this.showBookingsView = false;
      this.showSideMenuView = false;
      this.unseenNotifications = false;
      this.showNotificationsView = !this.showNotificationsView;
      this.$emit("toggleNavModal", this.showNotificationsView);
    },
    showBookings() {
      this.showNotificationsView = false;
      this.showSideMenuView = false;
      this.showBookingsView = !this.showBookingsView;
      this.$emit("toggleNavModal", this.showBookingsView);
    },
    showSideMenu() {
      this.showNotificationsView = false;
      this.showBookingsView = false;
      this.showSideMenuView = !this.showSideMenuView;
      this.$emit("toggleNavModal", this.showSideMenuView);
    },
    async checkForNotifications() {
      const res = await this.api({
        path: "api/u/notifications/unread"
      });
      if (res.success) {
        this.unseenNotifications = res.unread_count && res.unread_count > 0;
      } else {
        this.unseenNotifications = false;
      }
    },
    logout() {
      this.$store.dispatch("logout");
      this.$router.push("/login");
    }
  },
  computed: {}
};
</script>
<style lang="less">
#Nav.bg-white {
  border-bottom: 2px solid #e0e5f5;
}

#Nav {
  padding: 16px 20px;

  .btn-logout {
    font-size: 1.6rem;
    font-weight: 600;
    background-color: unset;
    border: 0;
    color: #00008F;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
  }

  .white-text {
    color: white !important;
  }

  .nav-title {
    font-size: 2rem;
    font-family: "FSElliotBold", sans-serif;
    font-weight: bold;
  }

  .sh-logo {
    padding-right: 10px;
    border-right: 1px solid #e0e5f5;
  }
  .fa-bars{
    font-size: 30px;
  }
  .nav-title-logo {
    max-height: 20px;
  }

  .nav-container {
    max-width: 100%;
    margin: 0;
    border-bottom: 8px solid #e4e4e7;
  }

  .nav-col {
    flex-grow: 1;
    flex-shrink: 1;

    //padding-right: calc((100% - 1170px) / 2);
    .nav-link {
      font-size: 1.6rem;
      padding-right: 25px;
    }

    .dropdown-item {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 50px;
      font-size: 1.6rem;

      .dropdown-item-inner {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        flex-grow: 1;
        align-items: center;
      }

      .nav-arrow {
        opacity: 0;
      }

      &:hover {
        font-weight: bold;

        .nav-arrow {
          opacity: 1 !important;
        }
      }

      &:active {
        background-color: #dedede;
      }
    }

    ul.dropdown-menu {
      z-index: 10000;
      border-radius: 16px;
      padding: 0;

      overflow: hidden;
    }
  }

  .nav-logo {
    max-height: 70px;
  }

  .unseenNotifications {
    position: relative;

    &::after {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      width: 12px;
      height: 12px;
      background: #fd3d3d;
      border-radius: 50%;
    }
  }

  .btn-close-health-score {
    font-family: "SourceSansProBold";
    background: unset;
    border: 0;
    color: #3A5E94;
    font-size: 1.6rem;
    line-height: 2rem;

    .fa {
      font-size: 1.8rem;
      margin-left: 8px;
    }
  }
}
</style>

