<template>
  <div id="ScoreBar">
    <div class="breakpoint-gap">
      <div class="breakpoint-number first">
        <p>|</p>
        <p>0</p>
      </div>
    </div>
    <div
      class="color-bar-section"
      v-for="(breakpoint, index) in breakpoints"
      :key="index"
    >
      <div class="top-container">
        <p
          v-if="breakpoint.label.toLowerCase() === score.outcome.toLowerCase()"
          class="top-container-score"
        >
          {{ score.score }}
        </p>
        <div
          class="color-rectangle"
          :style="{ backgroundColor: breakpoint.color }"
          :class="{ selected: breakpoint.label.toLowerCase() === score.outcome.toLowerCase() }"
        ></div>
        <div class="breakpoint-gap">
          <div class="breakpoint-number">
            <p>|</p>
            <p>{{ breakpoint.score }}</p>
          </div>
        </div>
      </div>
      <p
        class="breakpoint-label"
        :class="{ selected: breakpoint.label.toLowerCase() === score.outcome.toLowerCase() }"
      >
        {{ breakpoint.label }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "ScoreBar",
  props: ["score"],
  data() {
    return {
      breakpoints: [
        {
          score: 20,
          color: "#F07662",
          label: "Very Low"
        },
        {
          score: 40,
          color: "#F4998A",
          label: "Quite Low"
        },
        {
          score: 60,
          color: "#FCD385",
          label: "Fair"
        },
        {
          score: 80,
          color: "#70C490",
          label: "Quite Positive"
        },
        {
          score: 100,
          color: "#1CC54E",
          label: "Very Positive"
        }
      ]
    };
  },
};
</script>

<style>
#ScoreBar {
  display: flex;
  width: 100%;
  max-width: 412px;
  padding-top: 26px;

  .color-bar-section {
    flex: 1 1 20%;
  }

  .top-container {
    position: relative;
    display: flex;
  }

  .top-container-score {
    font-family: "SourceSansProSemibold";
    top: -26px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 1.6rem;
    position: absolute;
    color: #333333;
  }

  .color-rectangle {
    border-radius: 1px;
    width: calc(100% - 1px);
    height: 12px;

    &.selected {
      height: 18px;
      transform: translateY(-20%);
    }
  }

  .breakpoint-gap {
    position: relative;
    width: 2px;
    height: 12px;
    background-color: transparent;
    margin-bottom: 38px;

    .breakpoint-number {
      font-family: "SourceSansProRegular";
      position: absolute;
      top: calc(100% + 2px);
      left: 50%;
      transform: translateX(-50%);
      color: #595959;
      font-size: 1.2rem;

      p {
        text-align: center;
        padding: 0;
        margin: 0;
      }
    }
  }

  .breakpoint-label {
    font-family: "SourceSansProRegular";
    text-align: center;
    padding: 4px 8px;
    font-size: 1.4rem;
    line-height: 1.8rem;
    color: #333333;
    width: 100%;

    &.selected {
      font-family: "SourceSansProBold";
      font-size: 1.6rem;
      line-height: 2.4rem;
      color: #595959;
      padding: 4px 0;
    }
  }
}
</style>
