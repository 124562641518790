<template>
  <div id="TotalMentalHealthSelectCoach">
    <div class="top_buttons" v-if="false">
      <div class="content-container row">
        <div class="col-12 py-5">
          <b-button
            @click="$router.push('/total-mental-health')"
            class="fixed_width"
            variant="purple"
            >Your Coach</b-button
          >
          <!-- <b-button class="fixed_width" variant="outline-purple">Self Care</b-button> -->
        </div>
      </div>
    </div>

    <div class="content-container row">
      <div class="col-12 py-5 d-flex align-items-center">
        <h3 class="py-0 mr-3"><b>Choose a Coach</b></h3>
        <div class="horizontal-divider"></div>
      </div>
    </div>

    <div class="coach_filters row content-container">
      <div class="col-12 mb-2">
        <div class="d-flex row align-items-center">
          <div class="col-2"><h5>Gender Preference:</h5></div>
          <div class="col-10">
            <b-button
              class="fixed_width shadow"
              v-for="(gender, g_i) in gender_options"
              :key="g_i"
              :class="
                filter.gender === gender ? 'outline-button' : 'not-selected outline-button'
              "
              @click="changeFilter('gender', gender)"
              >{{ gender }}</b-button
            >
          </div>
        </div>
      </div>
<!--      <div class="col-12 mb-2">-->
<!--        <div class="d-flex row align-items-center">-->
<!--          <div class="col-2"><h5>Age Range Preference:</h5></div>-->
<!--          <div class="col-10">-->
<!--            <b-button-->
<!--              class="fixed_width shadow"-->
<!--              v-for="(age, a_i) in age_options"-->
<!--              :key="a_i"-->
<!--              :class="filter.age === age ? 'outline-button' : 'not-selected outline-button'"-->
<!--              @click="changeFilter('age', age)"-->
<!--              >{{ age }}</b-button-->
<!--            >-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
      <div class="col-12 mb-2">
        <div class="d-flex row align-items-center">
          <div class="col-2"><h5>Availability:</h5></div>
          <div class="col-10">
            <b-button
              class="fixed_width shadow"
              v-for="(av, av_i) in availability_options"
              :key="av_i"
              :class="
                filter.availability === av ? 'outline-button' : 'not-selected outline-button'
              "
              @click="changeFilter('availability', av)"
              >{{ av }}</b-button
            >
          </div>
        </div>
      </div>
    </div>

    <div class="spacer">
      <br />
      <br />
    </div>
    <div v-if="loading" class="content-container row">
      <div class="col-12">
        <Loader />
      </div>
    </div>
    <div v-else class="content-container row">
      <div class="pl-3" v-if="filtered_coaches.length === 0">
        <h3>
          Sorry, no coaches match for these options, try adjusting your search!
        </h3>
      </div>
      <div
        class="col-md-6 col-sm-12 coach mb-5"
        v-for="(coach, c_i) in filtered_coaches"
        :key="c_i"
        v-else
      >
        <div class="coach_inner">
          <div class="row h-100">
            <div class="col-4 left_box">
              <div class="avatar">
                <img class="avatar_img" :src="coach.avatar_url" />
              </div>
              <div class="coach_details">
                <i class="fas fa-check-circle brand-highlight-1"></i>
                {{ coach.position }} <br />
                <i class="fas fa-graduation-cap brand-highlight-1"></i>
                {{ coach.degree }}
              </div>
            </div>
            <div class="col-8 right_box d-flex flex-column">
<!--              <div class="recommended_wrapper">-->
<!--                <div v-if="coach.recommended" class="recommended">-->
<!--                  <span>Recommended</span>-->
<!--                </div>-->
<!--              </div>-->
              <h4 class="mt-4">{{ coach.name }}</h4>
              <div class="pr-3 pb-3" v-html="coach.description"></div>
              <div class="mx-auto mt-auto mb-4">
                <b-button
                  class="fixed_width outline-button"
                  @click="openCoachModal(coach.id)"
                  >Learn More</b-button
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="spacer">
      <br />
      <br />
    </div>
  </div>
</template>

<script>
import Loader from "../components/Loader";

export default {
  name: "TotalMentalHealthSelectCoach",
  components: { Loader },
  data() {
    return {
      loading: true,
      filter: {
        gender: "Any Gender",
        age: "Any Age",
        availability: "Anytime",
      },
      gender_options: ["Male", "Female", "Any Gender"],
      age_options: ["25-35", "35-45", "45+", "Any Age"],
      availability_options: ["Today", "Tomorrow", "Anytime"],
      coaches: [],
    };
  },
  mounted() {
    this.getCoaches();
    // this.openCoachModal(1);
    this.$root.$on('bookingFail', ()=> {
      this.showMsgModal('Sorry, the session you attempted to book has already been taken.  Please try again.');
    })
  },
  computed: {
    filtered_coaches() {
      let cs = this.coaches.filter((coach) => {
        if (
          (this.filter.age === "Any Age" || this.filter.age === coach.age) &&
          (this.filter.availability === "Anytime" ||
              coach.availability.includes(this.filter.availability)) &&
          (this.filter.gender === "Any Gender" ||
            this.filter.gender === coach.gender)
        ) {
          return true;
        } else {
          return false;
        }
      });
      return cs;
    },
  },
  methods: {
    async getCoaches() {
      let params = {
        path: "api/u/coach/get-coaches",
      };
      let res = await this.api(params);
      if (res.success) {
        this.coaches = res.coaches;
        this.loading = false;
      }
    },
    changeFilter(f, value) {
      this.filter[f] = value;
    },
    async openCoachModal(id) {
      await this.coachModal(id);
    },
  },
};
</script>
<style lang="less">
#TotalMentalHealthSelectCoach {
  .top_buttons {
    background-color: #fafafa;
  }
  background-color: #fff !important;
  .not-selected {
    color: #aaa;
    border: #bbb;
    background-color: white;
  }
  .btn.fixed_width {
    min-width: 100px;
    margin-right: 0.5em;
    margin-bottom: 0.5em;
    padding-top: 12.5px;
    padding-bottom: 12.5px;
    border-width: 2px;
  }
  .coach {
    display: flex;
    .coach_inner {
      font-size: 1.4rem;
      border-radius: 20px;
      overflow: hidden;
      box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.15);
      .left_box {
        background-color: whitesmoke;
        .avatar {
          padding: 10px 5px;
          text-align: center;
          img.avatar_img {
            margin: auto;
            border-radius: 50%;
            max-width: 60%;
            box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.2);
          }
        }
        .coach_details {
          padding: 15px;
        }
      }
      .right_box {
        background-color: white;

        .recommended_wrapper {
          min-height: 30px;
          margin-bottom: 5px;
          position: relative;
          .recommended {
            position: absolute;
            right: -1px;
            top: 0px;
            color: white;
            background-color: goldenrod;
            border-radius: 0px 0px 0px 20px;
            padding: 8px 15px;
          }
        }
        .coach_info {
          margin-bottom: 20px;
        }
      }
    }
  }
  .horizontal-divider {
    background-color: #ccc;
    padding: 1px;
    flex-grow: 1;
  }
}
</style>
