<template>
  <div id="LoadingAnimation">
    <div class="loading-inner">
      <h2 class="loading-title">{{ titleText }}</h2>
      <p class="loading-paragraph">{{ paragraphText }}</p>
      <img class="loading-img" src="/img/health-score/cyclistAnimation.gif" alt="Loading animation">
    </div>
  </div>
</template>

<script>
export default {
  name: "LoadingAnimation",
  props: ["title"],
  computed: {
    titleText() {
      return this.title && this.title === "nearlyThere"
        ? "Nearly there"
        : "We're on it...";
    },
    paragraphText() {
      return this.title && this.title === "nearlyThere"
        ? "Just setting a few things up for you."
        : "Thanks for answering the questions, we’re just gathering the results for you.";
    }
  }
};
</script>

<style lang="less">
@padding-x: 28px;
@max-width: calc(1110px + @padding-x * 2);

.centered {
  max-width: @max-width;
  margin: 0 auto;
}

#LoadingAnimation {
  background-color: #fafafa;
  text-align: center;

  .loading-inner {
    padding: 48px @padding-x 38px;

    .centered();
  }

  .loading-title {
    font-family: "SourceSansProBold";
    font-size: 3.6rem;
    line-height: 2.8rem;
    color: #343c3d;
    margin-bottom: 32px;
  }

  .loading-paragraph {
    font-family: "SourceSansProRegular";
    font-size: 2rem;
    line-height: 2.5rem;
    color: #343c3d;
    margin-bottom: 64px;
  }

  .loading-img {
    max-width: 362px;
    width: 100%;
  }
}
</style>
