<template>
  <div id="LoggedIn" class="nav-versionTwo">
    <div class="theme position-relative d-flex" ref="logged_in">
      <div class="main float-lg-right no-side-bar no-nav-header">
        <b-container fluid class="px-0" :class="{'stop-scroll': !isScrollOn}">
          <Nav @toggleNavModal="handleScroll"></Nav>
          <ContentRouter @scroll-to="scrollTo" />
         <Footer></Footer>
        </b-container>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
// import Navigation from "@/components/Navigation.vue";
import Nav from "@/components/Nav.vue";
import ContentRouter from "@/components/ContentRouter.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "LoggedIn",
  components: {
	  Nav,
    ContentRouter,
    Footer
  },
  data() {
    return {
      isScrollOn: true,
    };
  },
  created() {
    if (!this.$store.state.user.authenticated) {
      this.$store.dispatch("logout", this.$route);
      this.$router.push("/login").catch(err => {});
    }
  },
  mounted() {
    // this.fetchOAuthParams();

    gtag("config", process.env.VUE_APP_GA_TRACKING_ID, {
      user_id: this.$store.getters.user.user.user_id
    });
  },
  onActive() {},
  onIdle() {
    this.$store.dispatch("logout");
    this.$router.push("/login").catch(err => {});
  },
  methods: {
    handleScroll(isModalShown) {
      this.isScrollOn = !isModalShown
    },
    scrollTo(coordinates) {
      this.$refs.logged_in.parentNode.scrollTop = coordinates;
    },
    getParameterFromURL(paramName) {
      const params = new URLSearchParams(window.location.search);
      return params.get(paramName);
    },
    async fetchOAuthParams() {
      let scope = this.getParameterFromURL("scope");
      let oAuthRedirectGoogle = {
        path: "api/u/oauth/google/redirect",
        code: this.getParameterFromURL("code"),
        scope: this.getParameterFromURL("scope")
      };

      if (!(oAuthRedirectGoogle.code && oAuthRedirectGoogle.scope)) {
        return;
      }

      try {
        let res = await this.api(oAuthRedirectGoogle);

        if (res.success) {
          if (scope.includes("fitness.activity.read")) {
            this.$router.push("/challenges");
          } else {
            this.$router.push("/");
          }
        } else {
          alert("Error occurred. Please try again.");
        }
      } catch (e) {
        console.log(e);
      }
    },

    getAllowedRoutes() {
      const nav = this.$store.getters.nav;
      let accessValues = {
        wellbeing_series_access: false,
        digital_gym_access: false,
        wellbeing_studio_access: false,
        wellbeing_benefits_access: false,
        my_organisation_access: false,
        eap_access: false,
        events_access: false,
        be_calm_access: false,
        plans_access: false
      };
      for (const [key, value] of Object.entries(accessValues)) {
        nav.forEach(function(navElement) {
          navElement.items.forEach(function(navItem) {
            //if the formatted nav values and access values match, set access value to true
            if (
              navItem.name
                .split(" ")
                .join("_")
                .toLowerCase() === key.replace("_access", "")
            ) {
              accessValues[key] = true;
            }
          });
        });
      }
      return accessValues;
    }
  },
  computed: {
    navType() {
      return this.$store.getters.navType;
    },
    theme() {
      let skin = this.$store.getters.client.skin;
      return "theme-" + skin;
    },
    showSideBar() {
      if (
        this.$store.state.accessCode ||
        this.$store.getters.subdomain.toLowerCase().includes("laya-wellbeing")
      ) {
        return false;
      } else return true;
    }
  },
  watch: {
    $store() {
      if (!this.$store.state.user.authenticated) {
        this.$router.push("login").catch(err => {});
      }
    }
  }
};
</script>
<style lang="less">
@import "../assets/responsiveness";

.aside {
  width: 60px;
  max-width: 60px !important;
}
.main {
	&.no-nav-header {
		background-color: white !important;
		background-image: none !important;
	}
  .content-container {
    max-width: 1160px;
    margin: auto;
  }
  .page-bg {
    width: 100%;
    padding-bottom: 30px;
  }

  .stop-scroll {
    max-height: 100vh;
    overflow: hidden;
  }
  //.bg-white {
  //  background-color: #fff;
  //}
  //.bg-grey {
  //  background-color: #f7f7f7;
  //}
  //.bg-primary-dark {
  //  background-color: #3e1954;
  //}
  .section-header {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    align-items: center;
    flex-direction: row-reverse;
    &.section-header-right {
      flex-direction: row;
      .section-header-title {
        padding-right: 0;
        padding-left: 15px;
        text-transform: capitalize;
      }
    }
    .section-header-title {
      white-space: nowrap;
      padding-right: 10px;
    }
    .section-header-divider {
      height: 1px;
      background: #cdcdcd;
      width: 100%;
      transform: translateY(5px);
    }
  }
}
.pop-up-content {
  background: #fff;
  width: 100%;
  border-radius: 25px !important;
  padding: 30px 20px;
  font-family: "DMSans", sans-serif;
  font-size: 2rem;
  margin-bottom: 30px;
  text-align: center;
  z-index: 10;
  .modal-header {
    text-align: center;
    border-bottom: none;
    .modal-title {
      margin: auto;
      text-align: center;
      font-size: 3rem;
    }
  }
  .modal-footer {
    border-top: none;
    justify-content: center;
    .btn {
      border: none;
      border-radius: 60px;
      font-size: 2rem;
      line-height: 1;
      border-width: 0;
      padding: 10px 30px;
    }
  }
}
.nav-versionTwo {
  .main {
    width: 100% !important;
    display: inline-block !important;
    background-color: white;
  }
  #Home {
    background-color: white;
  }
}
</style>
