<template>
    <div id="view-article">
        <div class="row" v-if="loading">
            <b-spinner class="mx-auto my-5"></b-spinner>
        </div>
        <div v-else>
            <div class="content-container row">
                <div class="col-md-8">
                    <div class="pt-5">
                        <h1 class="article-title mb-5">{{ article.title }}</h1>
                        <div v-if="article.video_url && article.video_url.includes('iframe')" class="w-100 video mb-4" v-html="article.video_url"></div>
                        <GAVideoPlayer v-else-if="article.video_url" class="w-100 video mb-4" :src="article.video_url + '#t=2'"/>
                        <div v-else-if="article.embed_code" class="w-100 video mb-4" v-html="article.embed_code"></div>
                        <div v-if="article.body" v-html="decodedEmbed"></div>
                        <img class="img-fluid mb-5" onerror="this.style.display='none'" :src="article.feature_img"
                            v-else-if="article.featured_img">
                        <div v-if="article.e_description" class="event-description p-text mb-5 pb-5" v-html="article.e_description"></div>
                    </div>
                </div>
                <div class="col-md-4 pt-5" v-if="articles && articles.length">
                    <h3 class="article-subtitle mb-5">Similar Content</h3>
                    <div class="corporate-event-card clickable" @click="refresh(a.id)"
                        v-if="!articlesLoading" v-for="(a, i) in articles">
                        <img :src="a.image_url" class="article-img" alt="">
                        <div class="details-container">
                          <p class="author-name" v-if="a.author && a.author.author_name">{{ a.author.author_name }}</p>
                            <h4 class="card-title mb-0">{{ a.title }}</h4>
                        </div>
                    </div>
                    <div v-else>
                        <b-spinner class="mx-auto my-5"></b-spinner>
                    </div>
                </div>
                <div class="col-12">
                    <b-button variant="cta-outline" class="px-5" squared @click="navigate('/')">Back</b-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
import axios from "axios";
import config from "../config/constants";
import GAVideoPlayer from "../components/GAVideoPlayer.vue"
export default {
    name: "standaloneArticle",
    components: {
      GAVideoPlayer
    },
    data() {
        return {
            article: {},
            articles: [],
            loading: true,
            articlesLoading: true,
        };
    },
    mounted() {
        this.getArticle();
        this.getArticles();
    },
    methods: {
        async getArticles() {
            const payload = {
                user_id: this.$store.getters.user_id,
            };
            let res = await axios.post(
                config.api_env + "/application/api/u/sapphire/home/get-articles",
                payload,
                config.options
            );
            if (res.data.err && res.data.err.includes("3994")) {
                this.$store.dispatch("logout");
                this.$router.push("/login");
            }
            this.articles = [...res.data.content].slice(0, 4);
            this.articlesLoading = false
        },
        async getArticle() {
            const url = config.api_env + "/application/api/u/sapphire/article/get-article";
            const payload = {
                user_id: this.$store.getters.user_id,
                article_id: this.$route.params.id
            };
            let res = await this.axios.post(
                url,
                payload,
                config.options
            );
            if (res.data.success) {
                this.article = res.data.article;
                this.loading = false;
            } else if (res.data.err && res.data.err.includes("3994")) {
                this.$store.dispatch("logout");
                this.$router.push("/login");
            }
        },
        refresh(id) {
            this.loading = true;
            this.$router.push('/stand-alone-article/' + id);
            this.getArticle(id);
        },
    },
    computed: {
        decodedEmbed() {
            return this.article.body ? atob(this.article.body) : null;
        },
    }
};
</script>
<style lang="less">
#view-article {
  background-color: #FAFAFA;

    p,
    li,
    span {
        font-size: 1.4rem;
    }
    .video{
        min-height: 420px;
    }
    .title-tag {
        display: inline-flex;
        margin-top: 15px;
        background-color: #E5F7F9;
        padding: 10px;
        border-radius: 100px;
        font-size: 1.2rem;
        font-weight: bold;
    }

    .article-title {
      font-family: PublicoHeadlineWebBold;
      font-weight: 3.2rem;
      color: #36383A;
    }

    .article-subtitle {
      font-family: PublicoHeadlineWebMedium;
      font-weight: 3rem;
      color: #36383A;
    }

    .article-img {
      min-height: 100%;
      width: 100px;
      object-fit: cover;
      object-position: center;
    }

    .author-name {
      color: #53565A;
      font-size: 1.2rem;
    }

    .card-title {
      font-family: PublicoHeadlineWebMedium;
      color: #36383A;
    }

    .corporate-event-card {
        display: flex;
        min-height: 120px;
        border-radius: 20px;
        overflow: hidden;
        box-shadow: 0px 2px 2px 0px rgba(51, 51, 51, 0.15);
        background-color: #FFFFFF;
        margin-bottom: 24px;

        .date-container {
            flex-grow: 1;
            min-height: 100%;
            padding: 15px;
            width: 80px;
            min-width: 80px;
            max-width: 80px;

            .day {
                font-size: 2.4rem;
                font-weight: bold;
            }

            .month {
                font-size: 1.2rem;
            }
        }

        .details-container {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            padding: 16px;
        }

        .time-container {
            align-self: center;
            font-size: 1.2rem;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding-right: 10px;

            .date {
                text-wrap: nowrap;
                margin-bottom: 8px;
            }

            .time {
                text-wrap: nowrap;
                font-weight: bold;
            }
        }

        .tag {
            margin-top: 0px;
            background-color: #E5F7F9;
            padding: 5px;
            border-radius: 12px;
        }
    }

    .white-text {
        color: white !important
    }

    .img-fluid {
        border-radius: 8px;
    }

    .page-header {
        padding: 60px 0;
    }

    .page-title {
        text-align: left;

        h2 {
            text-align: left;
            font-size: 3.8rem;
            letter-spacing: 0.7rem;
            font-family: "FilsonProBold", sans-serif;
            color: #fff;
        }

        .return {
            background-color: transparent;
            position: absolute;
            bottom: -70px;
            left: 10px;

            svg {
                width: 35px;

                .cls-1 {
                    fill: #ea3975;
                }

                .cls-2 {
                    fill: #fff;
                }
            }

            &:hover {
                .cls-1 {
                    fill: darken(#ea3975, 10%);
                }
            }
        }
    }

    .content-container {
        padding: 20px 0;
    }

    .event-card {
        position: relative;
        border-left: 1px solid #ccc;
        border-top: 1px solid #ccc;
        border-bottom: 1px solid #ccc;
        margin-right: 50px;
        background-color: #f4f4f4;
        padding: 45px 0 55px 30px;
        box-shadow: 6px -2px 8px 0 rgba(0, 0, 0, 0.25);

        &:before {
            content: "";
            position: absolute;
            top: -1px;
            right: 0;
            height: 60px;
            width: 50px;
            display: inline-block;
            border-right: 1px solid #ccc;
            border-top: 1px solid #ccc;
            box-shadow: 9px 0 5px -4px rgba(0, 0, 0, 0.25);
            transform: skew(40deg) translateX(50%);
            background-color: #f4f4f4;
            z-index: 1;
        }

        &:after {
            content: "";
            position: absolute;
            right: 0;
            bottom: -1px;
            height: calc(100% - 58px);
            width: 50px;
            border-right: 1px solid #ccc;
            border-bottom: 1px solid #ccc;
            background-color: #f4f4f4;
            transform: translateX(100%);
            box-shadow: 4px -4px 5px 0 rgba(0, 0, 0, 0.25);
        }

        h3 {
            font-size: 3.1rem;
            font-family: FilsonProBold;
            width: 70%;
            text-transform: capitalize;
            margin-bottom: 25px;
            letter-spacing: 0;
            padding-top: 0;
        }

        .event-description {
            font-size: 1.7rem;
            color: #394961;
        }
    }

    .waiting-list {
        font-size: 1.7rem;
        color: #394961;
    }

    .event-info {
        font-size: 1.6rem;

        .past-event {
            background-color: #989898;
            margin: 0 -38px;
            padding-left: 25px;
            padding-right: 23px;

            &:first-child {
                margin: -38px -38px 0 -38px;
                border-radius: 20px 20px 0 0;
            }

            .disabled {
                &:hover {
                    background-color: #fff !important;
                }
            }
        }

        .event-info-item-wrap {
            font-size: 17px;
            display: flex;
            align-items: center;

            .event-info-icon {
                width: 26px;
                display: inline-flex;
                justify-content: center;

                &>i {
                    font-size: 22px;
                }
            }

            .event-info-item {
                margin-left: 20px;
            }
        }
    }


    .event-register {
        font-family: "DMSans", sans-serif;
        font-size: 1.6rem;

        a {
            color: #212529;
            text-decoration: underline;
        }
    }

    .no-slots {
        font-size: 1.7rem;
        border: 1px solid #CED1D2;
        border-radius: 20px;
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;

        h4 {
            color: red;
            margin-bottom: 0;
        }
    }

    .event-pop-up {
        display: flex;
        align-items: center;
        z-index: 500;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        text-align: center;

        .pop-up-content {
            background: #fff;
            max-width: 400px;
            width: 100%;
            margin: auto;
            border-radius: 25px;
            padding: 30px 20px;
            font-size: 4rem;
            text-align: center;
            z-index: 10;

            .event-details {
                border-top-width: 1px !important;
                border-color: #dee2e6;
                position: relative;

                h4,
                a {
                    font-size: 2.5rem;
                    font-family: 'FilsonProBold';
                    line-height: 0;
                    white-space: nowrap;
                }

                .event-title {
                    height: 40px;
                    text-align: left;
                    position: relative;
                    transform: translateY(-50%);
                }

                .event-info {
                    font-size: 1.2rem;
                    margin: auto;
                }
            }
        }

        .pop-up-background {
            width: 100vw;
            height: 100vh;
            position: absolute;
            left: 0;
            right: 0;
            z-index: 5;
            background: transparent;
        }
    }
}
</style>
