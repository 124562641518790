<template>
    <div id="DmhBooking">
        <div v-if="loading">
            <b-spinner class="brand-primary"></b-spinner>
        </div>
        <div class="gp-select-container mb-5 pb-5" ref="gpSelectTop" v-else>
            <div class="d-flex flex-column align-items-center content-container booking-container-width w-100 animate__animated animate__fadeIn"
                v-if="selectedView === 'appointmentSelect'">
                <div id="BookingSelection">
                    <div v-if="dates" class="pb-3">
                        <h2 class="pb-5 brand-text dmh-start-title">Thank you! let's book you in with your coach </h2>
                    </div>
                    <div
                        class="content-container d-flex flex-column justify-content-center align-items-center animate__animated animate__fadeIn">
                        <h4 class="dmh-section-title w-100 brand-text">What's the best date for you?</h4>
                        <div class="date-selector-container mb-5" v-if="dates">
                            <slick ref="slickRef" class="slick-class" :options="slickOptions">
                                <div v-for="(value, key, index) in dates"
                                    class="d-flex align-items-center justify-content-center">
                                    <b-button squared size="lg" :variant="key === selectedDate ? 'cta' : 'outline'"
                                        class="mx-3 w-100 py-4" @click="selectDate(key)">{{ getFormatedDate(key,
                                            'DD/MM/YY') }}</b-button>

                                </div>

                            </slick>
                        </div>
                        <div class="dmh-clinicians-container mb-5" v-if="selectedDate">
                            <h4 class="dmh-section-title w-100 brand-text">Choose a clinician</h4>
                            <div class="dmh-clinician"
                                :class="{ 'active bg-brand-primary': i === selectedClinicianIndex }"
                                v-for="(clinician, i) in dates[selectedDate]"
                                @click="selectClinicianIndex(i, clinician.clinicianId)">
                                <div class="dmh-clinician-avatar"><img class="img-fluid" :src="clinician.image"></div>
                                <div class="dmh-clinician-name" :class="{ 'brand-text': i !== selectedClinicianIndex }">
                                    {{ clinician.name }}</div>
                                <div class="dmh-clinician-info ml-auto"
                                    v-b-tooltip.hover="{ customClass: 'clinician-tooltip' }"
                                    :title="getTitleString(clinician.tags)">
                                    <i class="fa-solid fa-info-circle fa-2x text-white"
                                        :class="{ 'brand-primary': i !== selectedClinicianIndex }"></i>
                                </div>
                            </div>
                        </div>
                        <div class="w-100" v-if="selectedClinicianIndex !== null">
                            <h4 class="dmh-section-title brand-text w-100">Choose a time</h4>
                            <div class="dmh-timeslots-container row">
                                <div class="col-12 col-sm-4 d-flex justify-content-center"
                                    v-for="timeslot in dates[selectedDate][selectedClinicianIndex].timeslots">
                                    <b-button size="lg" :variant="timeslot === selectedTimeslot ? 'cta' : 'outline'"
                                        class="w-100 py-4" squared @click="selectTimeslot(timeslot)">{{ timeslot
                                        }}</b-button>
                                </div>
                            </div>
                        </div>

                        <div v-if="selectedClinicianIndex !== null">
                            <h4 class="mb-5">What happens next?</h4>
                            <p class="p-text mb-4">
                                1. Look out for an email with a link. You can use this if you need to change your
                                booking.
                            </p>
                            <p class="p-text mb-4">
                                2. We'll send a video call link 1 or 2 days before your appointment. If you don't
                                get
                                it, please
                                check your spam inbox, or email <b class="brand-primary"><a class="brand-primary"
                                        >{{supportContacts.email.uk.length>0 ? supportContacts.email.uk : supportContacts.email.default}}</a></b>.
                            </p>
                            <p class="p-text mb-4">
                                3. Please log in using the link 5 minutes before your appointment, so you can check
                                everything is
                                working correctly.
                            </p>
                            <h4 class="mb-5 mt-5">Any questions?</h4>
                            <p class="p-text mb-5">
                                If you have any problems, email <b class="brand-primary"><a class="brand-primary"
                                        >{{supportContacts.email.uk.length>0 ? supportContacts.email.uk : supportContacts.email.default}}</a></b> or send us a message using
                                live chat.
                            </p>
                        </div>
                        <div class="d-flex justify-content-center pt-5">
                            <b-button variant="cta-outline" size="lg" class="py-4 px-5 mr-4" squared
                                @click="previous(questionHistory)">Previous</b-button>
                            <b-button variant="cta" size="lg" squared :disabled="!selectedTimeslot"
                                @click="createAppointment()"><span v-if="!appointmentLoading">Create
                                    Booking</span><b-spinner v-else></b-spinner></b-button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="d-flex flex-column align-items-center content-container container-width brand-text text-center animate__animated animate__fadeIn booking-confirm-container border-brand-tertiary"
                v-if="selectedView === 'bookingConfirm'">
                <h3 class="dmh-start-title brand-text pb-5 mb-3">Session Booked!</h3>
                <img :src="dates[selectedDate][selectedClinicianIndex].image" class="preview-image img-fluid">
                <div class="brand-text pb-5 mb-0">You have booked a session with
                    <b>{{ dates[selectedDate][selectedClinicianIndex].name }}</b> for <b>{{
                        getFormatedDate(selectedDate,
                            'DD/MM/YY') }}</b> at <b>{{ selectedTimeslot }}</b>
                </div>
                <ol class="pb-4">
                    <li class="pb-3">You will receive an <b>email reminder</b> for this in your email inbox 30 minutes
                        before the start of your session.</li>
                    <li class="pb-3">The upcoming sessions are also visible in the Bookings Area. Click on the calendar
                        icon
                        in the top right to view all upcoming and past appointments.</li>
                </ol>
                <div>
                    <b-button variant="cta" size="lg" id="return-to-home-button" class="py-4 px-5" squared
                        @click="$router.push('/')">Back to Home</b-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref } from 'vue';
import axios from "axios";
import config from "../../config/constants";
import slick from "vue-slick";
import ErrorModal from "../GP/ErrorModal.vue";
import moment from "moment";
import EventBus from "@/helpers/eventbus"


export default {
    name: "GpSelect",
    props: ['reset', 'previous', 'questionHistory'],
    components: {
        slick,
        ErrorModal,
    },
    data() {
        return {
            loading: true,
            selectedView: "appointmentSelect",
            selectedDate: null,
            selectedFilter: 'morning',
            selectedClinicianIndex: null,
            selectedClinicianId: null,
            selectedTimeslot: null,
            dates: false,
            clinicians: [],
            timeslots: [],
            timeslotsLoading: true,
            appointmentLoading: false,
            noDates: false,
            userDetails: { symptoms: '', photos: [] },
            tcsAccepted: false,
            slickOptions: {
                infinite: false,
                autoplay: false,
                dots: false,
                nextArrow:
                    '<div class="h-100 slack-arrow-container-right2">' +
                    '<button class="slick-arrow mx-3" type="button" style="border-radius: 50%"><i class="fas brand-secondary fa-caret-right fa-2xs"></i></button>' +
                    '</div>',
                prevArrow:
                    '<div class="h-100 slack-arrow-container-left2">' +
                    '<button class="slick-arrow mx-3" type="button" style="border-radius: 50%"><i class="fas brand-secondary fa-caret-left  fa-2xs"></i></button>' +
                    '</div>',
                slidesToScroll: 3,
                slidesToShow: 3,
                centerPadding: "10",
                responsive: [
                    {
                        breakpoint: 520,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1
                        },
                    }
                ],
            },
        };
    },
    mounted() {
        this.getDates();
    },
    methods: {
        selectDate(date) {
            this.selectedDate = date;
            this.selectedClinicianIndex = null;
            this.selectedTimeslot = null;
            // this.getTimeslots();
        },
        toggleTcAcceptence() {
            this.tcsAccepted = !this.tcsAccepted;
        },
        selectTimeslot(time) {
            this.selectedTimeslot = time;
        },
        selectClinicianIndex(i, id) {
            this.selectedClinicianId = id;
            this.selectedTimeslot = null;
            this.selectedClinicianIndex = i;
        },
        buttonDisabled(date) {
            let today = moment().format('YYYY-MM-DD')
            return moment(date).isBefore(today);
        },
        openTcs() {
            window.open(window.location.origin + '/terms-and-conditions', '_blank').focus();
        },
        async getDates() {
            let self = this;
            const payload = {
                user_id: this.$store.getters.user_id,
                gender_preference: "No Preference",
                service_id: 'axa_dmh'
            };
            let res = await axios.post(
                config.api_env + "/application/api/u/dmh/get-other-clinicians-dates",
                payload,
                config.options
            );
            if (res.data.success) {
                this.dates = res.data.dates;
                this.loading = false;
            }
        },
        getTitleString(tags) {
            let title = '';
            tags.forEach((tag) => {
                title = title + tag + ', ';
            })
            title = title.substring(0, title.length - 2);
            return title;
        },
        async createAppointment() {
            this.appointmentLoading = true;
            const payload = {
                user_id: this.$store.getters.user_id,
                clinician_id: this.selectedClinicianId,
                start_date: this.selectedDate,
                start_time: this.selectedTimeslot,
                service_id: 'axa_dmh'
            };
            let res = await axios.post(
                config.api_env + "/application/api/u/dmh/book-appointment",
                payload,
                config.options
            );
            if (res.data.success) {
                if (res.data.apiStatus === 'Appointment has been already booked for the same slot. Please choose different clinician or slot.') {
                    this.appointmentLoading = false;
                    this.openErrorModal("Sorry, this appointment is no longer available", "Please choose another appointment");
                } else {
                    EventBus.$emit('getBookings');
                    this.appointmentLoading = false;
                    this.selectedView = 'bookingConfirm';
                }
            } else {
                this.appointmentLoading = false;
                this.openErrorModal("Sorry, something went wrong", "Please try again");
            }
        },

        openErrorModal(title, error, redirect, goBack) {
            const content = this.$createElement(ErrorModal, { props: { title, error, redirect, goBack } })
            const skin = this.$store.getters.skin;
            let opts = {
                hideHeader: true,
                hideFooter: true,
                modalClass: "coach_modal theme-" + skin,
                size: "md",
                buttonSize: "sm",
                centered: true,
                contentClass: "coach_modal",
                id: 'error-modal'
            }
            return this.$bvModal.msgBoxOk(content, opts);
        },
    },
    watch: {
        // selectedView: function (view) {
        //     let self = this;
        //     let day = moment().day();
        //     let width = window.innerWidth;
        //     if (view === 'appointmentSelect') {
        //         this.$nextTick(() => {
        //             if(width < 786){
        //                     self.$refs.slickRef.goTo(day, true);
        //             }
        //         });
        //     }
        // }
    },
    computed: {
        matchTechOptions() {
            return this.getMatchTechOptions();
        },
        supportContacts() {
            return this.$store.state.user.support_contacts;
        }
    },

};
</script>
<style lang="less">
#DmhBooking {
    display: flex;
    align-items: center;
    justify-content: center;

    .tcs-link {
        font-family: 'DMSans', sans-serif;
        text-decoration: underline;
    }

    .tc-checkbox {
        font-size: 2rem;
        padding-left: 5px;
    }

    .dmh-clinicians-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;

        .dmh-clinician {
            display: flex;
            flex-direction: row;
            align-items: center;
            cursor: pointer;
            width: 100%;
            border: 2px solid #DEDEDE;
            padding: 25px;
            border-radius: 8px;
            margin-bottom: 10px;

            .dmh-clinician-name {
                font-family: 'DMSans', serif;
                font-weight: bold;
            }

            .dmh-clinician-avatar {
                border-radius: 100px;

                img {
                    height: 55px;
                    margin-right: 25px;
                    border-radius: 1000px;
                    overflow: hidden;
                }
            }
        }

        .dmh-clinician.active {
            .dmh-clinician-name {
                color: white;
            }
        }
    }

    .dmh-timeslots-container {}

    .fixed-width {
        max-width: 150px;
    }

    .sentence-case::first-letter {
        text-transform: uppercase;
    }

    .shadow {
        box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.2);
    }


    .booking-confirm-container {
        font-size: 1.8rem;
    }


    .slick-container {
        width: 100%;
    }

    .slick-button {
        width: 100%;
    }

    .booking-button {
        min-width: 200px !important;
    }

    @media only screen and (max-width: 521px) {
        .slack-arrow-container-right2 {
            right: 45px !important;
        }

        .slack-arrow-container-left2 {
            left: 45px !important;
        }
    }



    .slick-next {
        right: -30px;
        top: 15px;
    }

    .slick-prev {
        left: -25px;
        top: 15px;
    }

    .VueCarousel-navigation-button {
        font-size: 15px;
        top: 18px;
    }

    .slick-slide {
        transform: scale(1) !important;
    }

    .container-width {
        max-width: 450px;
    }

    .booking-container-width {
        max-width: 700px;
    }

    .btn {
        min-width: 100px;
        margin-bottom: 10px;
    }

    .timeslot-button {
        color: #21272A;
    }

    .btn-cta {
        color: white;
    }

    .selected-date {
        color: white;

        &:hover {
            color: white;
        }
    }

    .preview-image {
        border-radius: 1000px;
        border: 6px solid;
        border-color: inherit;
        margin-bottom: 25px;
    }

    .no-appointments {
        font-size: 1.6rem;
    }

    .no-appointments-text {
        padding-top: 25px;
        font-size: 1.6rem;
    }

    .slick-class {
        width: calc(100% - 60px);
        margin: 0 auto;
    }

    .slick-arrow {
        padding: 0;
        display: flex;
        align-items: center;
    }

    .slack-arrow-container-right2 {
        background-color: rgba(255, 255, 255, 0) !important;
        right: -45px;
        width: 50px !important;
    }

    .slack-arrow-container-left2 {
        background-color: rgba(255, 255, 255, 0) !important;
        left: -45px;
        width: 50px !important;
    }

    .slick-disabled {
        .bg-brand-primary {
            background-color: #ccc !important;
        }
    }

    .dmh-start-title {
        text-align: center;
        font-size: 2.4rem;
        letter-spacing: 0;
        font-family: "DMSans", sans-serif;
        font-weight: bold;
    }

    .dmh-section-title {

        text-align: left;
        margin-bottom: 20px;
        font-size: 1.8rem;
        letter-spacing: 0;
        font-family: "DMSans", sans-serif;
    }

    .tc-link {
        color: #0079c8;
        text-decoration: underline;
    }



    .no-appointments {
        font-size: 1.6rem;
    }

    .no-appointments-text {
        padding-top: 25px;
        font-size: 1.6rem;
    }

    .fa-4x {
        color: green;
    }

    .review-details {
        width: 100%;

        .text-prompt-container {
            max-width: 700px;
            font-size: 1.6rem;

            h3 {
                padding-top: 0;
                margin-top: 0;
                letter-spacing: normal;
                font-size: 1.8rem;
            }

            .details-container {
                width: 100%;
                background-color: white;
                border: 1px solid #dde1e6;

                .details-row {
                    border-bottom: 1px solid #dde1e6;
                    padding: 20px;
                    word-wrap: break-word;
                    text-align: left;

                    .details-row-title {
                        font-weight: 500;
                        margin-bottom: 0;
                        padding-bottom: 0;
                    }

                    .edit-button {
                        align-self: start;
                        cursor: pointer;
                        font-weight: bold;
                    }
                }
            }
        }

        .preview-image {
            max-width: 150px;
            min-width: 150px;
            min-height: 150px;
            max-height: 150px;
            object-fit: scale-down;
            border-radius: 16px;
            background-color: #eee;
        }

    }

    b {
        font-family: 'DMSans', serif;
    }

    #BookingSelection {
        max-width: 650px;
        width: 100%;
        font-size: 1.6rem;

        .button-group {
            border: 1px solid #0079c8;
            border-radius: 5px;
            background-color: white;
        }

        .date-selector-container {
            max-width: 100%;
            border-radius: 12px;
            padding: 20px;
            margin-bottom: 10px;
        }


        .date-select-button {
            background-color: white;
            cursor: pointer;
            padding: 10px;
            font-size: 1.8rem !important;
            border: 1px solid white;
            color: #0079c8;
            border-radius: 8px;

            &:hover {
                background-color: #eee;
                border: 1px solid #eee;
            }

            .month-class {
                font-size: 1.4rem;
                color: #ccc;
            }
        }

        .preview-image {
            height: 80px;
            border-radius: 1000px;
            border: 3px solid;
            border-color: inherit;
        }

        .date-select-button:disabled,
        .date-select-button[disabled] {
            cursor: not-allowed;
            background-color: white;
            padding: 10px;
            font-size: 1.8rem !important;
            border: 1px solid white;
            border-radius: 8px;
            color: #ccc;

            &:hover {
                border: 1px solid white;
            }
        }

        .timeslot-button {
            color: #21272A;
            border: 1px solid #0079c8;
        }

        .btn-cta {
            color: white;
        }

        .selected-date {
            background-color: #0079c8;
            color: white;

            &:hover {
                background-color: #0079c8;
                color: white !important;
            }
        }

        .no-appointments {
            font-size: 1.6rem;
        }

        .no-appointments-text {
            padding-top: 25px;
            font-size: 1.6rem;
        }

        .slick-class {
            width: calc(100% - 60px);
            margin: 0 auto;
        }

        .slick-arrow {
            border-width: 0 !important;
            position: absolute;
            top: 45%;
            transform: translateY(-50%);
            z-index: 10;
            color: #fff;
            font-size: 3rem;
            height: 40px;
            width: 40px;
            padding: 0;
            line-height: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            text-align: center;

            &:focus {
                outline: none;
            }

            &.slick-next {
                right: 0;
            }
        }

        .slack-arrow-container-right {
            background-color: rgba(255, 255, 255, 0.8) !important;
            right: -45px !important;
            width: 50px !important;
        }

        .slack-arrow-container-left {
            background-color: rgba(255, 255, 255, 0.8) !important;
            left: -45px !important;
            width: 50px !important;
        }

        .slick-disabled {
            .bg-brand-primary {
                background-color: #ccc !important;
            }
        }
    }

}
</style>
