<template>
  <div class="AudioParser">
    <audio :src="content" controls />
  </div>
</template>

<script>
export default {
  components: {},
  name: "AudioParser",
  props: ["content", "options"],
  data() {
    return {};
  },
  mounted() {},
  methods: {}
};
</script>
<style lang="less">
.AudioParser {
}
</style>
