<template>
    <div id="DmhFaqItem">
        <div class="faq-item d-flex flex-row align-items-start shadow clickable animate__animated w-100" @click="open()" v-if="!opened">
            <div class="bg-brand-highlight-1 faq-icon-container">
                <i class="fa-solid fa-magnifying-glass text-white"></i>
            </div>
            <div class="pl-4">
                <h3 class="faq-item-title brand-highlight-1">{{ item.title ? item.title : item.question }}</h3>
                <p class="faq-item-excerpt" v-if="!opened">{{ item.excerpt ? item.excerpt : item.answer }}</p>
                <p class="faq-item-excerpt brand-text" v-else v-html="decode(item.content)"></p>
            </div>
        </div>
        <div class="faq-article-item" v-else >
            <h2 class="faq-item-title brand-highlight-1">{{ item.title ? item.title : item.question }}</h2>
            <div class="faq-item-content brand-text" v-html="decode(item.content)"></div>
            <div class="mt-4 mb-4 d-flex justify-content-end">
                <b-button   @click="open()" variant="cta" >Close</b-button>
            </div>
        </div>
    </div>
</template>
  
<script>

export default {
    name: "DmhFaqItem",
    props: ['item', 'type'],
    components: {
    },
    mounted() { },
    data() {
        return {
            opened: false,
        };
    },
    mounted() {
    },
    methods: {
        decode(data) {
            try {
                return atob(data)
            } catch (err) {
                console.log(err);
            }
        },
        open() {
            console.log('open')
            this.opened = !this.opened;
        }
    },
    computed: {
    },
};
</script>
<style lang="less">
#DmhFaqItem {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    .faq-item {
        flex: 1;
        flex-grow: 1;
        background-color: white;
        padding: 30px;
        margin-bottom: 30px;
        border-radius: 12px;
        min-width: 100%;
        max-width: 600px;

        .faq-icon-container {
            border-radius: 100px;
            min-height: 45px;
            min-width: 45px;
            display: flex;
            align-items: center;
            justify-content: center;

            i {
                font-size: 1.6rem;
            }

        }

        .faq-item-title {
            margin-bottom: 20px;
            padding: 0 !important;
            text-align: left;
            font-size: 1.8rem;
            letter-spacing: 0;
            font-family: "DMSans", sans-serif;
            font-weight: bold;
        }

        .faq-item-excerpt {
            width: 100%;
            flex-grow: 1;
            font-size: 1.6rem;
            font-family: "FSElliotBook", sans-serif;
            padding: 0;
            margin: 0;

        }
        
    }
    .faq-article-item {
        margin-top: 50px;
        margin-bottom: 50px;
        border-radius: 12px;
        max-width: 600px;
        .faq-item-title{
            margin-bottom: 20px;
            padding-bottom: 20px;
            text-align: left;
            letter-spacing: 0;
            font-family: "DMSans", sans-serif;
            font-weight: bold;
            font-size: 2.6rem;
            padding-bottom: 20px;
            border-bottom: 3px solid;
            border-color: #00008f;
        }
    }
    .faq-item-content{
        font-size: 1.6rem;
        padding-bottom: 20px;
        border-bottom: 3px solid;
        border-color: #00008f;

        h1,h2,h3,h4,h5,h6{
            letter-spacing: normal;
            font-family: "DMSans", sans-serif;
            text-align: left;
            font-size: 2.2rem;
            color: inherit;
            
        }
        p{
            margin-bottom: 20px;
            color: inherit;
        }
        img{
            max-width: 100%;
        }
        div{
            color: inherit;
        }
    }
}
</style>
  