<template>
    <div id="DmhHardcodedOne">
        <p class="brand-text pb-5">
            On the next part of your journey, we'll book you in for a mental health support session. If you need it, we'll also arrange a counselling referral. <br /><br />
            Need to speak to someone right now? please call:
        </p>
        <div class="w-100 warning-container mb-5">
            <p class="p-text bold pb-0 mb-0"><b>UK: {{supportContacts?.phone.uk}}</b></p>
            <!-- <p class="p-text bold pb-2"><b>IE: {{supportContacts?.phone.ie}}</b></p>
            <p class="p-text bold pb-0 mb-0"><b>International: {{supportContacts?.phone.international}}</b></p> -->
        </div>
        <p class="brand-text pb-5">
            Feeling like you’re at risk? The Samaritans are a charity that have someone on hand, 24/7. If you feel like you could be a risk to yourself or others, you can call them on <a href="tel:116123" class="semibold brand-highlight-1 underlined">116 123</a>.
        </p>
        <p class="brand-text pb-5">
            You could also contact <a class="semibold underlined brand-highlight-1" href="tel: 111">111</a> or <a class="semibold underlined brand-highlight-1" href="tel: 999">999</a> for the NHS emergency services.
        </p>
    </div>
</template>
  
<script>
import { RouterLink } from 'vue-router';

export default {
    name: "DmhHardcodedOne",
    data() {
        return {
            selectedOptionIndex: null,
        };
    },
    computed: {
      supportContacts() {
        return this.$store.state.user.support_contacts;
      }
    },
};
</script>
<style lang="less">
#DmhHardcodedOne {
    display: flex;
    flex-direction: column;
    align-items: center;
    .warning-container{
        border-radius: 16px;
        padding: 15px;
        background-color: #ECF1F8;
        border: 2px solid #4976BA;
    }
    .privacy-link {
        font-family: 'DMSans', sans-serif;
        text-decoration: underline;
    }

    p {
        font-size: 1.8rem;
        white-space: pre-wrap;
        text-align: center;
    }

    img {
        max-width: 600px;
        border-radius: 16px;
        cursor: not-allowed;
    }
}
</style>
  