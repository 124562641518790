<template>
  <div id="RandoxKitOrder">
    <div class="hero">
      <div class="container">
        <h2 class="title">Remote Health assessment</h2>
      </div>
    </div>
    <div class="main">
      <div class="container">
        <p class="paragraph">
          To receive your home blood test kit, powered by Randox Health, simply complete the form below. Randox Health
          will send the blood test kit to you, complete with a return pre-paid envelope. Randox Health will carry out
          the blood test and then share your results with an AXA Health Physiologist.
        </p>
        <p class="paragraph">
          AXA Health collects your information, and shares it with Randox Health, on the understanding that it is private and confidential. AXA Health and Randox Health will use your information in accordance with their respective privacy policies, which can be accessed via the following links:
        </p>
        <a class="link" href="https://randoxhealth.com/privacy-policy-axa/" target="_blank">https://randoxhealth.com/privacy-policy-axa/</a>
        <form @submit.prevent="submitForm" class="form">
          <!-- First Name -->
          <label class="label" for="firstName">First Name (required)</label>
          <input class="input" type="text" id="firstName" v-model="formData.firstName">
          <p v-if="errors && errors.firstName" class="error">{{ errors.firstName }}</p>

          <!-- Last Name -->
          <label class="label" for="lastName">Last Name (required)</label>
          <input class="input" type="text" id="lastName" v-model="formData.lastName">
          <p v-if="errors && errors.lastName" class="error">{{ errors.lastName }}</p>

          <!-- Date of Birth -->
          <label class="label" for="dob">Date of Birth (required)</label>
          <input class="input" type="date" id="dob" v-model="formData.dob">
          <p v-if="errors && errors.dob" class="error">{{ errors.dob }}</p>

          <!-- Email -->
          <label class="label" for="email">Email address (required)</label>
          <input class="input" type="text" id="email" v-model="formData.email">
          <p v-if="errors && errors.email" class="error">{{ errors.email }}</p>

          <!-- Company -->
          <label class="label" for="company">What company do you work for? (required)</label>
          <input class="input" type="text" id="company" v-model="formData.company">
          <p v-if="errors && errors.company" class="error">{{ errors.company }}</p>

          <!-- Phone -->
          <label class="label" for="phone_number">Phone number (required)</label>
          <input class="input" type="tel" id="phone_number" v-model="formData.phone_number">
          <p v-if="errors && errors.phone_number" class="error">{{ errors.phone_number }}</p>

          <!-- Postcode -->
          <label class="label" for="post_code">Postcode (required)</label>
          <input class="input" type="text" id="post_code" v-model="formData.post_code">
          <p v-if="errors && errors.post_code" class="error">{{ errors.post_code }}</p>

          <!-- Address -->
          <label class="label" for="address">Address line 1 (required)</label>
          <input class="input" type="text" id="address" v-model="formData.address">
          <p v-if="errors && errors.address" class="error">{{ errors.address }}</p>

          <!-- City -->
          <label class="label" for="city">City (required)</label>
          <input class="input" type="text" id="city" v-model="formData.city">
          <p v-if="errors && errors.city" class="error">{{ errors.city }}</p>

          <!-- Sex at Birth -->
          <label class="label">Sex at Birth:</label>
          <div class="input-container">
            <div>
              <input class="input" type="radio" id="male" value="male" v-model="formData.selected_sex" />
              <label class="label" for="male">Male</label>
            </div>
            <div>
              <input class="input" type="radio" id="female" value="female" v-model="formData.selected_sex" />
              <label class="label" for="female">Female</label>
            </div>
          </div>
          <p v-if="errors && errors.selected_sex" class="error">{{ errors.selected_sex }}</p>

          <!-- Checkboxes -->
          <div class="input-container">
            <input type="checkbox" id="not_pregnant" v-model="formData.not_pregnant" class="mr-3">
            <label class="label" for="not_pregnant">I confirm I am not pregnant (required)</label>
          </div>
          <p v-if="errors && errors.not_pregnant" class="error">{{ errors.not_pregnant }}</p>

          <div class="input-container">
            <input type="checkbox" id="sample" v-model="formData.sample" class="mr-3">
            <label class="label" for="sample">I confirm I am able to take my own blood sample using a finger prick test
              without the risk of fainting (required)</label>
            </div>
            <p v-if="errors && errors.sample" class="error">{{ errors.sample }}</p>

          <div class="input-container">
            <input type="checkbox" id="consent" v-model="formData.consent" class="mr-3">
            <label class="label" for="consent">I hereby give clinical consent for AXA Health and Randox Health to carry
              out my home blood test in the way that has been explained to me (required)</label>
            </div>
            <p v-if="errors && errors.consent" class="error">{{ errors.consent }}</p>

          <button type="submit" class="btn-dark">Submit</button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import config from "@/config/constants";

export default {
  name: "RandoxKitOrder",
  data() {
    return {
      formData: {
        firstName: "",
        lastName: "",
        dob: "",
        email: "",
        company: "",
        phone_number: "",
        address: "",
        city: "",
        selected_sex: null,
        not_pregnant: false,
        sample: false,
        consent: false
      },
      errors: {}
    };
  },
  methods: {
    validateForm() {
      this.errors = {};

      // Required Fields Validation
      if (!this.formData.firstName) this.errors.firstName = "First name is required.";
      if (!this.formData.lastName) this.errors.lastName = "Last name is required.";
      if (!this.formData.dob) this.errors.dob = "Date of Birth is required."
        else if (!this.validateAdultDob(this.formData.dob)) this.errors.dob = "Must be at least 16 years old.";
      if (!this.formData.email) this.errors.email = "Email address is required.";
      else if (!/\S+@\S+\.\S+/.test(this.formData.email)) this.errors.email = "Invalid email format.";
      if (!this.formData.company) this.errors.company = "Company name is required.";
      if (!this.formData.phone_number) this.errors.phone_number = "Phone number is required."
        else if(!this.validatePhoneNumber(this.formData.phone_number)) this.errors.phone_number = "Must be a valid phone number with at least 8 digits.";
      if (!this.formData.post_code) this.errors.post_code = "Postcode is required.";
      if (!this.formData.address) this.errors.address = "Address is required.";
      if (!this.formData.city) this.errors.city = "City is required.";

      // Checkboxes Validation
      if (!this.formData.not_pregnant) this.errors.not_pregnant = "You must confirm you are not pregnant.";
      if (!this.formData.sample) this.errors.sample = "You must confirm you can take the blood test.";
      if (!this.formData.consent) this.errors.consent = "You must provide clinical consent.";

      return Object.keys(this.errors).length === 0;
    },
    async submitForm() {
      if (this.validateForm()) {
        await this.submitOrder()
      }
    },
    async submitOrder(){
      try {
        const payload = {
          user_id: this.$store.getters.user_id,
          ...this.formData,
          name: `${this.formData.firstName} ${this.formData.lastName}`,
        };
        const res = await axios.post(
          config.api_env +
            "/application/api/u/randox/order",
          payload,
          config.options
        );
        if(res.data.success) {
          this.$emit("changeView", "submitted");
        } else {
          this.$emit("changeView", "error");
        }
      } catch(err) {
        this.$emit("changeView", "error");
      }
    }
  }
};
</script>
<style lang="less">
#RandoxKitOrder {
  .hero {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 460px;
    background-color: #00008F;

    .title {
      color: #fff;
      white-space: pre-wrap;
      text-align: left;
      font-weight: 700;
      font-size: 8rem;
      line-height: 8rem;
    }
  }

  .main {
    background-color: #FAFAFA;
    padding: 20px 0;

    .paragraph {
      text-align: center;
      color: #343C3D;
      font-size: 1.6rem;
      line-height: 2.4rem;
    }

    .link {
      text-align: center;
      color: #3A5E94;
      display: block;
      font-size: 1.6rem;
      line-height: 2.4rem;
    }

    .form {
      .input, .label {
        display: block;
        color: #343C3D;
        font-size: 1.8rem;
        line-height: 2.7rem;
        text-align: left;
      }

      .paragraph {
        color: #343C3D;
        font-size: 1.6rem;
        line-height: 2.4rem;
        text-align: left;
        margin-bottom: 28px;
      }

      .label {
        margin: 20px 0 0;
      }

      .input {
        width: 100%;
        background-color: #fff;
        border: 1px solid #757575;
        border-radius: 6px;
        padding: 12px;
        margin-top: 8px;
      }

      .input-container {
        text-align: left;
        background-color: #fff;
        border: 1px solid #757575;
        padding: 12px;
        margin-top: 20px;

        .input, .label {
          display: inline;
          width: auto;
          margin-bottom: 0;
        }

        .input {
          margin-right: 12px;
        }
      }
    }
  }

  .error {
    color: red;
    font-size: 14px;
    margin: 8px 0 0;
  }
}
</style>