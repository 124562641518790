<template>
  <div class="booking_inner bg-white">
    <div class="row no-gutters">
      <div class="col-9 px-1">
        <span class="coach">Session with {{ booking.coach_title }}</span>
        <br />
        <span class="day">{{ getDateLineText(booking.start) }}</span>
        <br />
        <span class="hour"
          ><i class="fas fa-clock" aria-hidden="true"></i>
          {{ getHoursMinutes(booking.start) }} -
          {{ getHoursMinutes(booking.end) }}</span
        >
      </div>
      <div class="col-3 text-right">
        <span class="date">{{ getDay(booking.start) }}</span>
        <br />
        <span class="month">{{ getMonth(booking.start) }}</span>
      </div>
    </div>
    <div class="corner">
      <div
        v-if="booking.delivery_type === 'video' && true"
        @click="openBookingPage(booking.id)"
        class="video corner_inner bg-brand-highlight-1"
      >
        <span>Start Video </span> &nbsp;&nbsp;&nbsp;<i class="fas fa-video"></i>
      </div>
      <div
        v-else-if="booking.delivery_type !== 'video'"
        class="chat corner_inner bg-brand-highlight-1"
      >
        <span>Chat </span> &nbsp;&nbsp;&nbsp;<i class="fas fa-keyboard"></i>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import MeetingRoomModal from "@/components/GP/MeetingRoomModal.vue";

export default {
  name: "BookingCard",
  props: {
    booking: Object,
    isEarliest: Boolean,
  },
  data() {
    return {
      shouldShowLink: false,
    };
  },
  mounted() {
    let self = this;
    let now = moment();
    let bookingStart = moment(this.booking.start);
    if (this.isEarliest) {
      if (now.isSameOrAfter(bookingStart.subtract(10, "minute"))) {
        this.shouldShowLink = true;
      } else {
        let offset = bookingStart.diff(now);
        if (offset < 2147483647) {
          setTimeout(() => {
            self.shouldShowLink = true;
          }, offset);
        }
      }
    }
  },
  methods: {
    openBookingPage(id) {
      //TODO change it to object instead of string
      this.$router.push("/booking-video-room/" + id + "/device-tests");
    },
    openBookingModal(booking) {
			const content = this.$createElement(MeetingRoomModal, { props: { booking: booking } })
			const skin = this.$store.getters.skin;
			let opts = {
				title: "My Coaching Session",
				hideHeader: true,
				hideFooter: true,
				modalClass: "coach_modal theme-" + skin,
				headerClass: "coach_modal_header bg-brand-secondary d-flex justify-content-center",
				size: "lg",
				buttonSize: "sm",
				centered: true,
				contentClass: "coach_modal",
				id: 'bupa_gp_modal'
			}
			return this.$bvModal.msgBoxOk(content, opts);
		},
  },
};
</script>

<style lang="less">
#BookingCard {
}
</style>
