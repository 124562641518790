<template>
	<div class="achievement-badge row" :class="getStatus()">
		<div class="achievement-background" :class="getStatus()"></div>
		<div class="achievement-star"></div>
		<div class="col-3">
			<div class="img_wrapper">
				<div><img :alt="badge.title" :class="getStatus()" :src="getStatus() === 'normal' ? '/img/totalMentalHealth/achievements/Badges/'+badge.icon+'.png' : '/img/totalMentalHealth/achievements/Padlock.png'" class="img-fluid"/>
        </div>
      </div>
		</div>
		<div class="py-4 pl-0 pr-2" :class="getClass()">
			<span v-if="getStatus() === 'normal'" class="sub_title">{{badge.sub_title}}</span>
      <div class="greyedoutText w-25" v-else></div>
			<h3 :class="getStatus()" class="title">{{badge.title}}</h3>
			<p class="description" v-if="getStatus() !== 'grey'">{{badge.description}}</p>
      <div v-else>
        <div class="greyedoutText"></div>
        <div class="greyedoutText"></div>
        <div class="greyedoutText"></div>
        <div class="greyedoutText w-50"></div>
      </div>
		</div>
		<div v-if="getType() === 'user_achievements'" class="col-3 text-center py-4 collected-column">
			<div class="impact_wrapper d-flex align-items-start ">
								<span class="impact" v-if="badge.reward_amount>0">
                  <i v-if="badge.reward_type==='trees'" class="fas fa-tree"></i>
                        <!--          The image below should be replaced by a Font Awesome icon -->
                  <i v-else class="fas fa-bottle-water"/>
                {{getRewardText()}}
              </span>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	name: "Badge",
	props : ['badge'],
	methods : {
		getType(){
			return this.badge.hasOwnProperty('achieved_by_user') ? 'all_achievements' : 'user_achievements'
		},
		getClass(){
			return this.getType() === 'user_achievements' ? 'col-6' : 'col-9'
		},
		getStatus(){
			return this.getType() === 'all_achievements' && this.badge.achieved_by_user === false ? 'grey' : 'normal'
		},
		getStyle(){
			let colour = this.badge.colour;
			if(this.getStatus() === 'grey'){
				colour = '#ddd'
			}
			return { backgroundColor: colour }
		},
		getRewardText(){
			let txt = this.badge.reward_amount+' '
			if(this.badge.reward_type === 'trees'){
				txt+= ' Tree'+(this.badge.reward_amount === 1 ? "" : "s")+' Planted'
			} else if(this.badge.reward_type === 'bottles') {
				txt+= ' Bottle'+(this.badge.reward_amount === 1 ? "" : "s")+' Collected'
			}
			return txt
		}
	}
};
</script>
<style lang="less">
.bottle{
  height: 9px;
}
.achievement-badge {
  min-height: 180px;
	overflow: hidden;
	border-radius: 15px;
	margin-bottom: 15px;
	border: 2px solid #3a4a61;
	position: relative;
	&.grey {
		box-shadow: none;
	}

  .greyedoutText {
    margin-bottom: 8px;
    width: 100%;
    height: 10px;
    background-color:rgb(221, 221, 221);
  }
	.achievement-background {
		background-color: white;
		position: absolute;
		width: 100%;
		height: 100%;
		opacity: 0.6;
		&.grey {
			opacity: 0.5;
		}
	}
	.achievement-star {
		position: absolute;
		width: 100%;
		height: 100%;
		background: url('/public/img/totalMentalHealth/achievements/Star.png') no-repeat right center;
		background-size: contain;
	}
	.img_wrapper {
		height: 100%;
		display: flex;
		align-items: start;
    padding-top: 15px;
		img {
			max-width: 100%;
			position: relative;
			border: 3px solid #3a4961;
			border-radius: 50%;
			margin: auto;
			&.grey {
				border: 3px solid dimgrey;
			}
		}
	}
	.sub_title {

	}
	h3.title {
		margin: 0px;
		padding: 0px;
		font-size: 20px;
		letter-spacing: 0px;
		&.grey {
			color: rgb(221, 221, 221) !important;
		}
	}
	.description {
		margin: 0px;
	}
	.impact_wrapper {
		height: 100%;
		display: flex;
		align-items: center;
		.impact {
		
		}
	}
}
</style>
