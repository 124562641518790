<template>
    <div id="WaitingRoom">
        <div class="d-flex flex-column justify-content-center align-items-center py-5 my-3">
            <h1 class="waiting-room-title">You're Early!</h1>
            <div class="waiting-room-meta d-flex flex-row flex-nowrap">
                <span class="bold">Service Provider: {{ booking.clinician.name }}</span>
                <span class="mx-3">|</span>
                <span>
                    {{ getHoursMinutes(booking.startTime) }} -
                    {{ getHoursMinutes(booking.endTime) }}
                </span>
            </div>
            <div class="waiting-room-video-container d-flex flex-column justify-content-center align-items-center">
                <div class="waiting-room-video d-flex justify-content-center align-items-center">
                    <div class="waiting-room-countdown">
                        <div class="waiting-room-counter mb-4">
                            Session Starting In<br />
                          <span class="waiting-room-timer" v-if="days">{{ days }} {{days > 1 ? 'days' : 'day'}}</span>
                            <span class="waiting-room-timer"
                                v-else-if="hours !== ''">{{ hours }}:{{ minutes }}:{{ seconds }}</span>
                        </div>
                    </div>
                </div>
            </div>
            <p class="waiting-room-p" @click="updateParentTimeout()">
                Wait here for your session to start or alternatively check back close to when your session is due to
                start.
            </p>
            <b-button class="px-5 btn-close" @click="close()">Close</b-button>
        </div>
    </div>
</template>
<script>
import moment from "moment";

export default {
    props: ['booking'],
    name: "WaitingRoom",
    data() {
        return {
            isEarly: true,
            timeRemaining: '',
            intervalFunction: '',
            seconds: '',
            minutes: '',
            hours: '',
            days: ''
        };
    },
    mounted() {
        let self = this;
        this.intervalFunction = setInterval(function () {
            const timeLeft = moment(moment(self.booking.startTime).diff(moment(), "milliseconds")); // get difference between now and timestamp
            self.days = moment.duration(timeLeft).days();
            if(moment.duration(timeLeft).days()) {
              const apptTime = moment(self.booking.startTime);
              const now = moment();
              if(apptTime.hour() > now.hour() || (apptTime.hour() === now.hour() && apptTime.minute() >= now.minute())) {
                self.days = moment.duration(timeLeft).days()
              } else {
                self.days = moment.duration(timeLeft).days() + 1
              }
            }
            self.seconds = moment.duration(timeLeft).seconds();
            self.minutes = moment.duration(timeLeft).minutes();
            self.hours = Math.trunc(moment.duration(timeLeft).asHours());
            if (self.seconds < 10) self.seconds = '0' + self.seconds
            if (self.minutes < 10) self.minutes = '0' + self.minutes
            if (timeLeft < 1500) {
                clearInterval(self.intervalFunction);
                self.updateParentTimeout();
            }
        }, 1000);
    },
    beforeDestroy() {
        clearInterval(this.intervalFunction);
    },
    methods: {
        updateParentTimeout() {
            this.$emit('timeoutOver')
        },
        close() {
            this.$bvModal.hide("digital_clinics_modal");
        },
    },

};
</script>
<style lang="less">
#WaitingRoom {
  .btn-close {
    width: 100%;
    max-width: 360px;
    font-size: 1.6rem;
    line-height: 2rem;
    padding: 16px 20px;
    border-radius: 16px;
    background-color: #00008F;
    box-shadow: 0px 4px 4px 0px #00000040;
  }
  .bold {
    font-weight: 700;
  }
    .waiting-room-title {
      font-family: PublicoHeadlineWebBold;
      color: #000000;
      font-size: 2.5rem;
      margin-bottom: 18px;
    }

    .waiting-room-meta {
      color: #000000;
        font-size: 1.8rem;
        margin-bottom: 22px;
    }

    .waiting-room-video-container {
        max-width: 600px;
        width: 100%;
        aspect-ratio: 16/9;
        position: relative;
        margin-bottom: 18px;
        display: flex;

        .waiting-room-video {
            background: rgb(15,15,15);
            background: linear-gradient(225deg, rgba(15,15,15,1) 0%, rgba(97,97,97,1) 73%, rgba(28,28,28,1) 100%);
            width: 100%;
            flex-grow: 1;
        }
    }

    .waiting-room-counter {
      font-family: PublicoHeadlineWebMedium;
        text-align: center;
        color: white;
        font-size: 2rem;

        .waiting-room-timer {
            font-size: 3rem;
            font-family: PublicoHeadlineWebMedium;
        }
    }

    .waiting-room-p {
        max-width: 600px;
        text-align: center;
        color: #000000;
        font-size: 1.4rem;
        margin-bottom: 56px;
    }
}
</style>
