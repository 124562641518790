<template>
    <div id="DmhConfirm">
        <p class="brand-text" v-if="question.question_type === 'text'"
            v-html="question.sub_text_html ? decodeData(question.sub_text_html) : question.subtext">
        </p>
        <img src="/img/dmh/map.png" class="img-fluid" v-else />
        <p v-if="question.question_id === 9" class="pt-3 semibold brand-primary"><i class="far fa-info-circle fa-lg mr-3"></i>If you want to speak to someone right now? Please call: {{supportContacts?.phone.uk}}</p>
        <p v-if="question.sub_heading === 'Informed Consent'" class="pt-3">Learn more about our Privacy Policy <a
                class="privacy-link" @click="openPrivacyPolicy()">here</a>.</p>

    </div>
</template>
  
<script>
export default {
    name: "DmhConfirm",
    props: ['question'],
    components: {
    },
    mounted() { },
    data() {
        return {
            selectedOptionIndex: null,
        };
    },
    mounted() {

    },
    methods: {
        decodeData(data) {
            try {
                return atob(data);
            } catch (error) {
                return data;
            }
        },
        selectOptionIndex(i) {
            this.setAnswer(i);
            this.selectedOptionIndex = i;
        },
        openPrivacyPolicy() {
            window.open(window.location.origin + '/privacy-policy', '_blank').focus();
        }
    },
    computed: {
      supportContacts() {
        return this.$store.state.user.support_contacts;
      }
    },
};
</script>
<style lang="less">
#DmhConfirm {
    display: flex;
    flex-direction: column;
    align-items: center;

    .privacy-link {
        font-family: 'DMSans', sans-serif;
        text-decoration: underline;
    }
    a{
        color: #00008F;
        font-weight: 600;
    }

    p {
        font-size: 1.8rem;
        white-space: pre-wrap;
        text-align: center;
    }

    img {
        max-width: 600px;
        border-radius: 16px;
        cursor: not-allowed;
    }
}</style>
  