<template>
  <div id="HealthCoachingSelectCoach">

    <div class="content-container row">
      <div class="col-12 py-5 d-flex align-items-center">
        <h1 class="py-0 mr-3 brand-highlight-1 mt-5"><b>Choose a Coach</b></h1>
      </div>
    </div>

    <div class="coach_filters row content-container">
      <div class="col-12 mb-2">
        <div class="d-flex row align-items-center">
          <div class="col-3">
            <h5>Do you have a preference for whether you see a male or female coach?</h5>
          </div>
          <div class="col-9">
            <b-button class="fixed_width" v-for="(gender, g_i) in gender_options" :key="g_i" :variant="filter.gender === gender ? 'cta' : 'outline'
              " @click="changeFilter('gender', gender)" squared>{{ gender }}</b-button>
          </div>
        </div>
      </div>
      <!-- <div class="col-12 mb-2">
        <div class="d-flex row align-items-center">
          <div class="col-3">
            <h5>Age Range Preference:</h5>
          </div>
          <div class="col-9">
            <b-button class="fixed_width" v-for="(age, a_i) in age_options" :key="a_i"
              :variant="filter.age === age ? 'cta' : 'outline'" @click="changeFilter('age', age)" squared>{{ age
              }}</b-button>
          </div>
        </div>
      </div> -->
      <div class="col-12 mb-2">
        <div class="d-flex row align-items-center">
          <div class="col-3">
            <h5>When they're available:</h5>
          </div>
          <div class="col-9">
            <b-button class="fixed_width" v-for="(av, av_i) in availability_options" :key="av_i" :variant="filter.availability === av ? 'cta' : 'outline'
              " @click="changeFilter('availability', av)" squared>{{ av }}</b-button>
          </div>
        </div>
      </div>
    </div>

    <div class="spacer">
      <br />
      <br />
    </div>
    <div v-if="loading" class="content-container row">
      <div class="col-12">
        <Loader />
      </div>
    </div>
    <div v-else class="content-container row justify-content-center">
      <div class="pl-3" v-if="filtered_coaches.length === 0">
        <h3>
          Sorry, no coaches match for these options, try adjusting your search!
        </h3>
      </div>
      <div class="col-md-9 col-sm-12 coach mb-5 animate__animated animate__fadeIn"
        v-for="(coach, c_i) in filtered_coaches" :key="c_i" v-else>
        <div class="coach_inner w-100 brand-text mb-3">
          <div class="row h-100">
            <div class="col-6 left_box">
              <div class="d-flex flex-row align-items-center">
                <div class="avatar">
                  <img class="avatar_img" :src="coach.avatar_url" />
                </div>
                <div>
                  <h2 class="pl-3 brand-highlight-1">{{ coach.name }}</h2>
                  <span v-if="coach.availability.includes('Today')" class="pl-3"><i
                      class="fas fa-circle fa-sm pr-2 active-today"></i>Available today</span>
                </div>
              </div>

              <div class="coach_details">
                <div class="d-flex align-items-center pb-2">
                  <i class="fas fa-badge-check brand-highlight-1 pr-5 fa-lg fa-fw"></i>
                  <div class="text-capitalize">{{ coach.position ? coach.position : 'Healthcoach' }}</div>
                </div>
                <div class="d-flex align-items-center pb-2">
                  <i class="fas fa-calendar-days brand-highlight-1 pr-5 fa-lg fa-fw"></i>
                  <div class="text-capitalize">Since {{ getFormatedDate(coach.position, 'MMMM YYYY') }}</div>
                </div>
                <div class="d-flex align-items-center">
                  <i class="fas fa-graduation-cap brand-highlight-1 pr-5 fa-lg fa-fw"></i>
                  <div class="text-capitalize">{{ coach.degree }}</div>
                </div>

              </div>
            </div>
            <div class="col-6 right_box d-flex flex-column">
              <!--              <div class="recommended_wrapper">-->
              <!--                <div v-if="coach.recommended" class="recommended">-->
              <!--                  <span>Recommended</span>-->
              <!--                </div>-->
              <!--              </div>-->
              <div class="pr-3 pb-3 mb-4" v-html="coach.description"></div>
              <div class="mx-auto mt-auto mb-4 w-100">
                <b-button class="w-100" variant="cta" @click="selectCoach(coach.id)" squared>Book Now</b-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="spacer">
      <br />
      <br />
    </div>
  </div>
</template>

<script>
import Loader from "../components/Loader";

export default {
  name: "HealthCoachingSelectCoach",
  components: { Loader },
  data() {
    return {
      loading: true,
      filter: {
        gender: "Any Gender",
        age: "Any Age",
        availability: "Anytime",
      },
      gender_options: ["Male", "Female", "Any Gender"],
      age_options: ["25-35", "35-45", "45+", "Any Age"],
      availability_options: ["Today", "Tomorrow", "This Month", "Anytime"],
      coaches: [],
    };
  },
  mounted() {
    this.getCoaches();
    // this.openCoachModal(1);
    this.$root.$on('bookingFail', () => {
      this.showMsgModal('Sorry, the session you attempted to book has already been taken.  Please try again.');
    })
  },
  computed: {
    filtered_coaches() {
      let cs = this.coaches.filter((coach) => {
        if (
          (this.filter.age === "Any Age" || this.filter.age === coach.age) &&
          (this.filter.availability === "Anytime" ||
            coach.availability.includes(this.filter.availability)) &&
          (this.filter.gender === "Any Gender" ||
            this.filter.gender === coach.gender)
        ) {
          return true;
        } else {
          return false;
        }
      });
      return cs;
    },
  },
  methods: {
    async getCoaches() {
      let params = {
        path: "api/u/coach/get-coaches",
        type: 'healthcoach'
      };
      let res = await this.api(params);
      if (res.success) {
        this.coaches = res.coaches;
        this.loading = false;
      }
    },
    changeFilter(f, value) {
      this.filter[f] = value;
    },
    selectCoach(id) {
      this.$router.push('/health-coaching/select-coach/' + id);
    },
  },
};
</script>
<style lang="less">
#HealthCoachingSelectCoach {
  .active-today {
    color: green;
  }

  h1 {
    font-size: 4rem;
  }

  h5 {
    font-weight: bold;
    font-size: 1.6rem;
  }

  background-color: #fff !important;

  .not-selected {
    color: #aaa;
    border: #bbb;
    background-color: white;
  }

  .btn.fixed_width {
    min-width: 100px;
    margin-right: 0.5em;
    margin-bottom: 0.5em;
    padding-top: 12.5px;
    padding-bottom: 12.5px;
    border-width: 2px;
  }

  .coach {
    display: flex;

    .coach_inner {
      font-size: 1.4rem;
      border-radius: 8px;
      overflow: hidden;
      box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.15);

      .left_box {
        padding: 15px 30px;
        background-color: #F0EFEF;

        h2 {
          font-size: 2.4rem;
        }

        .avatar {
          padding: 10px 5px;
          text-align: center;

          img.avatar_img {
            margin: auto;
            border-radius: 50%;
            max-height: 100px;
            width: auto;
          }
        }

        .coach_details {
          font-size: 1.6rem;
          padding: 15px;
        }
      }

      .right_box {
        background-color: white;
        padding: 15px 30px;

        .recommended_wrapper {
          min-height: 30px;
          margin-bottom: 5px;
          position: relative;

          .recommended {
            position: absolute;
            right: -1px;
            top: 0px;
            color: white;
            background-color: goldenrod;
            border-radius: 0px 0px 0px 20px;
            padding: 8px 15px;
          }
        }

        .coach_info {
          margin-bottom: 20px;
        }
      }
    }
  }

  .horizontal-divider {
    background-color: #ccc;
    padding: 1px;
    flex-grow: 1;
  }
}
</style>
  
