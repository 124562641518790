<template>
  <div id="YourMHCoach">
    <div class="" v-if="loading">
      <Loader class="mt-5 pt-5"/>
    </div>
    <div class="" v-else>
      <div class="content-container row">
        <div class="col-md-6 col-12">
          <div class="d-flex flex-row align-items-center mt-5 mb-5">
            <h2 class="evolve-choose-coach-header pt-0 pb-1 mr-3 brand-highlight-1">
              Your Upcoming Cancer Coach Sessions
            </h2>
            <div class="horizontal-divider"></div>
          </div>
          <div class="bookings">
            <div v-if="upcoming_bookings.length === 0">
              <h5 class="my-5 pb-3 brand-text">Sorry you don't have any bookings at the moment</h5>
            </div>
            <div v-else>
              <div class="row">
                <div
                  v-for="(b, b_i) in upcoming_bookings"
                  :key="b_i"
                  class="col-12 col-lg-6 session-card"
                >
                  <BookingCard :booking="b" :isEarliest="b_i === 0"></BookingCard>
                </div>
              </div>
            </div>
            <!--<div class="">-->
            <!--<div class="row">-->
            <!--<div class="col-12">-->
            <!--<h3 class="evolve-choose-coach-header"><b>Past sessions for testing below</b></h3>-->
            <!--</div>-->
            <!--<div v-for="(b, b_i) in past_bookings" :key="b_i" class="col-6">-->
            <!--<div class="booking_inner">-->
            <!--<div class="row no-gutters">-->
            <!--<div class="col-6">-->
            <!--<span class="day">{{getDayStringLong(b.start)}}</span>-->
            <!--<br/>-->
            <!--<span class="hour"><i class="fas fa-clock" aria-hidden="true"></i> {{getHoursMinutes(b.start)}} - {{getHoursMinutes(b.end)}}</span>-->
            <!--</div>-->
            <!--<div class="col-6 text-right">-->
            <!--<span class="date">{{getDay(b.start)}}</span>-->
            <!--<br/>-->
            <!--<span class="month">{{getMonth(b.start)}}</span>-->
            <!--</div>-->
            <!--</div>-->
            <!--<div class="start_booking">-->
            <!--<div @click="openBookingPage(b.id)" class="booking_inner">-->
            <!--<span>Start Video </span> &nbsp;&nbsp;&nbsp;<i class="fas fa-video" aria-hidden="true"></i>-->
            <!--</div>-->
            <!--</div>-->
            <!--</div>-->
            <!--</div>-->
            <!--</div>-->
            <!--</div>-->
          </div>
          <div class="d-flex flex-row align-items-center mt-4">
            <h2 class="evolve-choose-coach-header pt-0 pb-1 mr-3 brand-highlight-1">
              Your Journey
            </h2>
            <div class="horizontal-divider"></div>
          </div>
          <div class="py-5" v-if="orphanTasks.length !== 0">
            <div class="orphan-tasks bg-brand-highlight-1 shadow">
              <h2 class="orphan-tasks-text">
                Continue to <b>grow</b> with <b>bonus tasks</b>
              </h2>
              <div v-for="task in orphanTasks" :key="task.id">
                <EvolveTask @clicked="showTaskModal" :task="task"></EvolveTask>
              </div>
            </div>
          </div>
          <div class="goals">
            <div v-if="goals.length === 0 && upcoming_bookings.length === 0">
              <h5 class="my-5">
                Sorry you don't have any goals set yet, reach out to coach to
                get some
              </h5>
            </div>
            <div v-else-if="goals.length === 0 && orphanTasks.length === 0 && upcoming_bookings.length > 0">
              <div class="no-goals-container">
                <img class="sun-img" src="/img/evolve/sun-cropped.svg" />
                <div class="no-goals-yet bg-brand-highlight-1">
                  <div class="no-gutters row" v-if="isProfileCompleted">
                    <div class="col-8 text-container" >
                      <div class="no-goal-title">Hold Tight!</div>
                      <div class="no-goal-text">
                        Once you have had your kick-off session with
                        {{ upcoming_bookings[0].coach_title }}, they will put
                        your goals here along with some recommended resources
                        and worksheets that will help you on your journey to
                        achieving your goals.
                      </div>
                    </div>
                    <div
                      class="
                        col-4
                        d-flex
                        justify-content-center
                        align-items-center
                        img-container
                      "
                    >
                      <img src="/img/evolve/drinking-coffee.png" />
                    </div>
                  </div>
                  <div class="no-gutters row" v-else>
                    <CoachingProfileDetails :user_profile="user_profile" @updateSuccess="updateSuccess"/>
                  </div>
                </div>
              </div>
            </div>
            <div v-else class="goals mb-5">
              <div v-for="goal in goals" :key="goal.id">
                <EvolveGoal @clicked="showTaskModal" :goal="goal"></EvolveGoal>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-12 d-none d-md-block">
          <div class="sticky">
            <ConversationWrapper :type="'cancer'"/>
            <div class="widgets-container">
              <div
                class="widget-container"
                v-for="calendarId in calendarIds"
                :key="calendarId"
              >
                <Journal :id="calendarId"></Journal>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="content-container mt-5 row">
        <div class="d-flex flex-row align-items-center mt-4 mb-5 w-100">
          <h2 class="evolve-choose-coach-header pt-0 pb-1 mr-3 brand-highlight-1">
            Your Progress
          </h2>
          <div class="horizontal-divider"></div>
        </div>
	      <AchievementsWrapper />
      </div>
    </div>
    <b-modal
      id="task-modal"
      size="lg"
      v-bind:modal-class="'task-modal ' + getTheme()"
      hide-footer
      hide-header
      @hide="handleHideModal"
    >
      <div class="">
        <span
          class="close-button float-right"
          @click="$bvModal.hide('task-modal')"
          ><i class="fas fa-times brand-highlight-1 fa-4x mr-4 mt-3"></i
        ></span>
        <ContentParser :id="taskModalId"
                       :selectedDate="selectedDate"
                       v-on:closeJournal="closeBothModals"
                       v-on:setAsJournal="setAsJournal"
                       v-on:selected-date="selectDate"
        />
      </div>
    </b-modal>
    <b-modal
        v-bind:modal-class="'task-modal ' + getTheme()"
        class="mt-5"
        id="confirm-modal"
        hide-footer
        hide-header
    >
      <div class="mb-4">
        <h3 class="confirmModalHeader">Close Without Saving</h3>
        <p class="brand-secondary">Are you sure you want to close this entry? You will lose any unsaved information.</p>
        <h4 class="brand-secondary">All changes will be lost</h4>
      </div>
      <div class="">
        <div class="d-flex justify-content-around align-items-center">
          <b-button
              class=" confirmModalButton outline-button"
              @click="closeBothModals"
          >Close without saving</b-button
          >
          <b-button
              class="bg-brand-highlight-1 confirmModalButton cta-button"
              @click="closeConfirmModal"
          >Take me back</b-button
          >
        </div>
      </div>
    </b-modal>
  </div>

</template>

<script>
import EventBus from "../helpers/eventbus";
import ConversationWrapper from "../components/TotalMentalHealth/ConversationWrapper.vue";
import EvolveGoal from "../components/TotalMentalHealth/EvolveGoal.vue";
import EvolveTask from "../components/TotalMentalHealth/EvolveTask.vue";
import BookingCard from "../components/TotalMentalHealth/BookingCard.vue";
import Journal from "../components/TotalMentalHealth/Journal.vue";
import ContentParser from "../components/ContentParser/ContentParser.vue";

import AchievementsWrapper from "../components/TotalMentalHealth/Achievements/AchievementsWrapper";

import moment from "moment";

import LineChart from "../components/LineChart.vue";

import Loader from "../components/Loader";
import CoachingProfileDetails from "../components/TotalMentalHealth/CoachingProfileDetails";
import axios from "axios";
import config from "../config/constants";

export default {
  components: {
    CoachingProfileDetails,
    ConversationWrapper,
    EvolveGoal,
    EvolveTask,
    BookingCard,
    Journal,
    ContentParser,
    Loader,
    LineChart,
	  AchievementsWrapper
  },
  name: "TotalMentalHealthHome",
  data() {
    return {
      user_profile: {},
      calendars: [],
      answerArray: [],
      loading: true,
      upcoming_bookings: [],
      past_bookings: [],
      goals: [],
      orphanTasks: [],
      isProfileCompleted: false,
      journalModal: true,
      datacollection: null,
      taskModalId: null,
      selectedDate: null,
      calendarIds: [],
      chartoptions: {
        height: 100,
        responsive: true,
        layout: {
          padding: 20,
        },
        legend: {
          display: false,
        },
        scales: {
          xAxes: [
            {
              gridLines: {
                drawBorder: null,
                display: null,
              },
              ticks: {
                drawOnChartArea: null,
                beginAtZero: null,
                display: null,
              },
            },
          ],
          yAxes: [
            {
              gridLines: {
                drawBorder: null,
                display: null,
              },
              ticks: {
                beginAtZero: null,
                display: null,
                suggestedMax: null,
              },
            },
          ],
        },
        plugins: {
          title: {
            display: false,
          },
        },
      },
    };
  },
  mounted() {
    if (
      this.$store.getters.user.coach === false ||
      this.$store.getters.user.coach === undefined
    ){
      this.$router.push("/cancer-coaching/start");
    }
    let self = this;
    this.getCoachingData();
    this.fillData();

    EventBus.$on("getCoachingData", function () {
      self.getCoachingData();
    });
  },
  methods: {
    handleHideModal(evt) {
      if(this.journalModal ) {
        evt.preventDefault();
        this.$bvModal.show("confirm-modal");
      } else {
        this.$bvModal.hide("task-modal");
      }
    },
    closeConfirmModal() {
      this.$bvModal.hide("confirm-modal");
    },
    closeBothModals() {
      this.$bvModal.hide("confirm-modal");
      this.journalModal = false;
      this.$bvModal.hide("task-modal");
    },
    setAsJournal() {
      this.journalModal = true;
    },
    updateSuccess() {
      this.isProfileCompleted = true;
    },
    decodeData(data) {
      try {
        return atob(data);
      } catch (error) {
        return data;
      }
    },
    setAnswerArray(arr) {
      this.answerArray = arr;
    },
    showTaskModal(id, date, journal = false) {
      this.answersArray = [];
      this.taskModalId = id;
      this.journalModal = journal;
      if (date === null) {
        this.selectedDate = null;
      } else {
        this.selectedDate = date;
      }
      this.$bvModal.show("task-modal");
    },
    async getCoachingData() {
      let params = {
        path: "api/u/coaching/get-data",
        type: 'cancer'
      };
      let res = await this.api(params);
      if (res.success) {
        if (res.bookings.upcoming.length + res.bookings.past.length === 0) {
          this.$router.push("/cancer-coaching/start");
        }
        if (res.closed_journey) {
          this.$store.commit('setCoach',false);
          let promptKey = "PROMPT-" + this.$store.getters.user.user.user_id;
          await localStorage.removeItem(promptKey);
          this.$router.push("/total-mental-health/start");
          return
        }
        this.upcoming_bookings = res.bookings.upcoming;
        this.past_bookings = res.bookings.past;
        this.goals = res.tasks.goals;
        this.isProfileCompleted = res.user_profile_completed;
        this.orphanTasks = res.tasks.orphans;
        this.getCalendarIds();
        if(!res.user_profile_completed) await this.getProfileData();
        this.loading = false;

      }
    },
    async getProfileData() {
      let params = {
        path: 'api/u/user/get-user-profile',
        user_id: this.$store.getters.user_id
      }
      let res = await this.api(params);
      if(res.success) {
        this.user_profile = res.user_profile;
      }
    },
    async getCalendar(id) {
      const payload = {
        user_id: this.$store.getters.user_id,
        task_id: id,
        month: moment().format("YYYY-MM"),
      };
      let res = await this.axios.post(
        process.env.VUE_APP_API_PATH +
          "/application/api/u/coaching/get-calendar",
        payload, config.options
      );
      if (res.data.success) {
        this.calendars.push({
          task_id: id,
          dates: res.data.dates,
          title: res.data.title,
        });
      } else {
        console.log("didnt get calendar");
      }
    },
    getCalendarIds() {
      let goals = this.goals;
      let orphans = this.orphanTasks;
      let calendarIds = [];
      let self = this;
      goals.forEach(function (goal) {
        goal.milestones.forEach(function (milestone) {
          milestone.tasks.forEach(function (task) {
            if (task.has_widget) calendarIds.push(task.id);
          });
        });
      });
      orphans.forEach(function (task) {
        if (task.has_widget) calendarIds.push(task.id);
      });
      calendarIds = calendarIds.filter(function (item, pos) {
        return calendarIds.indexOf(item) == pos;
      });
      this.calendarIds = calendarIds;
    },

    showStartVideoCorner(timestamp) {
      return moment().isAfter(moment(timestamp).subtract(10, "minutes"));
    },
    fillData() {
      this.datacollection = {
        labels: ["Data1", "Data2", "Data3", "Data4", "Data5"],
        datasets: [
          {
            label: "Data One",
            fill: false,
            backgroundColor: "#ffffff",
            borderColor: "#6b8d9d",
            data: [0, 3, 2, 5, 7],
          },
        ],
      };
    },
    selectDate(timestamp) {
      this.selectedDate = timestamp;
    },
  },
};
</script>
<style lang="less">

#confirm-modal {
  .confirmModalHeader {
    font-size: 3rem;
    text-align: center;
    font-family: PublicoHeadlineWebBoldneWebBold, sans-serif;
    margin-bottom: 3rem;
  }
  p {
    font-family: 'DMSans', sans-serif;
    font-size: 1.5rem;
    text-align: center;
    margin-bottom: 2rem;

  }
  h4 {
    text-align: center;
    font-family: PublicoHeadlineWebBoldneWebBold, sans-serif;
    margin-bottom: 3rem;
  }
  .confirmModalButton {
    width: 40%;
    border: 0px;
    box-shadow: 0 3px 3px rgba(0,0,0,0.2);
  }
  .modal-dialog {
     margin-top: 50px;
  }
  .modal-body {
    padding: 30px;
  }
}
#task-modal, #confirm-modal {
  .modal-content {
    border-radius: 24px;
    overflow: hidden;
  }
  .modal-body {
    max-height: 96vh !important;
    overflow-x: hidden;
    overflow-y: auto;
  }
  .close-button {
    cursor: pointer;
  }
  .task-content {
    font-size: 1.6rem;
  }
  .task-complete-button {
    font-size: 1.6rem;
    padding-top: 10px;
    padding-bottom: 10px;
    min-width: 200px;
  }
  .task-modal-title {
    font-size: 3rem;
    font-weight: bold;
    text-align: center;
    padding: 30px 30px 0px 30px;
  }
  ::-webkit-scrollbar {
    padding-right: 10px;
    width: 13px;
    height: 13px;
  }
  ::-webkit-scrollbar-thumb {
    background: linear-gradient(40deg, #87d3dc 50%, #6b8d9d 100%);
    border-radius: 0px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: linear-gradient(40deg, #87d3dc 50%, #6b8d9d 100%);
  }
  ::-webkit-scrollbar-track {
    background: #fafafa;
    border-radius: 10px;
    box-shadow: inset 0px 0px 0px 0px #f0f0f0;
  }
}
#YourMHCoach {
  background-color: #fafafa;
  .evolve-choose-coach-header{
    font-size: 2.4rem;
  }
  .horizontal-divider {
    background-color: #ccc;
    padding: 1px;
    flex-grow: 1;
  }

  .sticky {
    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: 0;
  }
  .session-card {
    max-width: 35rem;
  }
  .no-goals-container {
    .sun-img {
      height: 120px;
      width: 120px;
      z-index: 1;
    }

    .no-goals-yet {
      color: white;
      box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.2);
      position: relative;
      left: 30px;
      top: -60px;
      z-index: 2;
      width: 90%;
      img {
        width: 100%;
      }
      .img-container {
        padding-left: 0 !important;
      }
      .text-container {
        padding-right: 3px;
      }
      div {
        padding: 6px 10px;
      }

      .no-goal-title {
        font-size: 2.3rem;
        font-weight: bold;
        font-family: "PublicoHeadlineWebBoldneWebBold", sans-serif;
      }

      .no-goal-text {
        font-size: 1.3rem;
        padding-right: 0px;
      }

      padding-bottom: 15px;
      margin-bottom: 15px;
      border-radius: 15px 15px 15px 15px;
    }
  }

  .booking_inner {
    border: 2px solid #3a4a61;
    box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.2);
    div {
      padding: 6px 10px;
    }
    margin-bottom: 15px;
    border-radius: 15px 15px 0px 15px;

    .coach {
      font-size: 1.5rem;
    }
    .day {
      font-size: 1.5rem;
    }
    .hour {
      font-weight: lighter;
      font-size: 1.3rem;
    }
    .date {
      font-family: 'PublicoHeadlineWebBold', DMSans;
      line-height: 1;

      font-size: 3.5rem;
    }
    .month {
      font-family: 'PublicoHeadlineWebBold', DMSans;
      font-size: 1.75rem;
      font-weight: lighter;
    }
    .corner {
      position: relative;
      padding: 0px;
      min-height: 25px;
      .corner_inner {
        color: white;
        position: absolute;
        right: 0px;
        bottom: 0px;
        padding: 7px 25px;
        border-radius: 15px 0px 0px 0px;
        &.video {
          &:hover {
            cursor: pointer;
          }
        }
      }
    }
  }
  .orphan-tasks {
    border-radius: 16px;
    padding: 15px 25px;
    h2 {
      color: white;
      font-size: 1.6rem;
      margin-bottom: 15px;
    }
  }

  .grad-icon-completed {
    color: white;
  }
  .shadow {
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.15) !important;
  }
}
</style>
