<template>
  <div id="HomeFilters">
    <div class="container">
      <div ref="HomeFilters"></div>
    </div>
    <div v-if="loading" class="pb-3 container d-flex flex-row justify-space-between align-items-center">
      <HomeLoading />
    </div>
    <div v-else>
      <div class=" mb-5 container d-flex flex-row justify-space-between align-items-center">
        <div class="row">
          <h2 class="home-title">Explore our content library - support for your everyday</h2>
          <div class="home-title-line bg-brand-secondary"></div>
        </div>

      </div>
      <div class="container mb-5">
        <div class="row position-relative">
          <b-input class="filterSearchBar mx-auto brand-text" v-model="searchTerm" placeholder="Search..." />
          <i class="fa-solid fa-magnifying-glass position-absolute" style="top: 15px; right: 30px; font-size: 2.0rem"
            v-if="!searchTerm.length"></i>
          <span @click="searchTerm = ''" class="position-absolute brand-highlight-1"
            style="cursor: pointer; top: 15px; right: 30px; font-size: 1.5rem; font-family: FilsonProBold, Arial, Helvetica, sans-serif; text-decoration: underline"
            v-else>Clear Search</span>
        </div>

      </div>
      <div class="container mb-5">
        <div class="row">
          <div class="tagContainer">
            <b-button squared :style="{ fontSize: '1.4rem' }" class="mr-4 my-2 btn"
              :variant="selectedFilter === 'Show all' ? 'cta' : 'cta-outline'" @click="clearFilter()"
              ><span>Show all</span> <i class="ml-2 fa-regular fa-globe"></i></b-button>
            <b-button squared v-for="(tag, i) in tags" :style="{ fontSize: getFontSize(tag) }" :key="'tag' + tag"
              class="mr-4 my-2 btn" :variant="selectedFilter === tag ? 'cta' : 'cta-outline'"
              @click="selectFilter(tag)"><span>{{ tag }}</span> <i class="ml-2" :class="getIconClass(tag)"></i></b-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EventBus from "@/helpers/eventbus";
import HomeLoading from "@/components/Home/New/HomeLoading.vue";
import { debounce } from "@/helpers/debounce"


export default {
  name: "HomeFilters",
  components: {
    HomeLoading
  },
  data() {
    const tags = [];

    return {
      selectedFilter: 'Show all',
      tags: ['Life', 'Mind', 'Body'],
      excludedTags: ['Training Plans', 'Courses', 'Surge', 'Draft', 'Ignite', 'Healthcoach', 'Try One Of These'],
      hardCodedTags: tags,
      loading: false,
      expanded: false,
      unexpandedNumber: 10,
      searchTerm: "",
      debouncedSearchTerm: "",
      displayWidth: 0
    };
  },
  mounted() {
  },
  destroyed() {
    window.removeEventListener('resize', this.getWidth)
  },
  created() {
    window.addEventListener('resize', this.getWidth);
  },
  computed: {

  },
  methods: {
    getWidth() {
      console.log('gettingWidth')
      this.displayWidth = this.$refs.HomeFilters.clientWidth;
      this.unexpandedNumber = Math.max(Math.floor(this.displayWidth / 160) - 2, 4);
    },
    getFontSize(text) {
      if (text.length > 17) return '0.9rem'
      if (text.length > 13) return '1.2rem'
      return '1.4rem';
    },
    getIconClass(text) {
      switch (text) {
        case "Life":
          return 'fa-regular fa-heart-pulse';
        case "Mind":
          return 'fa-regular fa-person-rays';
        case "Body":
          return 'fa-regular fa-person-running';
        case "Events":
          return 'fa-regular fa-calendar';
        case "Videos":
          return 'fa-regular fa-video';
        case "Articles":
          return 'fa-regular fa-memo';
        case "Fitness":
          return 'fa-regular fa-dumbbell';
        case "Courses":
          return 'fa-regular fa-book-open';
        case "Workout plans":
          return 'fa-regular fa-radar';
        // case "My favourites":
        //   return 'fa-regular fa-heart';
        default:
          return 'fa-regular fa-cross'
      }
    },

    clearFilter() {
      this.selectedFilter = 'Show all'
      EventBus.$emit('setTag', null)
    },
    selectFilter(tag) {
      this.selectedFilter = tag;
      EventBus.$emit('setTag', tag);
    },
    
  },
  watch: {
    searchTerm: debounce(function (newQuery) {
      this.debouncedSearchTerm = newQuery;
    }, 500),
    debouncedSearchTerm: function(searchTerm) {
      EventBus.$emit('setSearch', searchTerm );
    },
  },
};
</script>

<style lang="less">
#HomeFilters {
  .btn-cta-outline {
    background-color: #FAFAFA;
  }

  .btn,
  button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 16px 24px;
    i{
      margin-left: 5px;
      font-size: 2rem;
    }
  }

  .home-title {
    font-size: 2.8rem;
  }

  .filterSearchBar {
    all: unset;
    width: 98%;
    height: 40px;
    background-color: rgba(255, 255, 255, 1);
    border: 1px solid rgba(211, 211, 211, 1);
    border-radius: 6px;
    padding: 5px 20px;
    font-size: 1.5rem;
    font-family: FilsonProBold, Arial, Helvetica, sans-serif !important;

  }

  .seeMoreBtn {
    background-color: transparent;
    width: 140px;
    padding-right: 15px;
    cursor: pointer;
    font-size: 1.4rem;
    text-align: right;
    height: 37px;
    display: inline-block;
    font-family: FilsonProBold, Arial, Helvetica, sans-serif;

    div {
      height: 100%;
      width: 100%;
    }
  }

  .suggested {
    padding: 10px 15px;
    font-size: 1.4rem;
    height: 35px;
    font-family: FilsonProBold, Arial, Helvetica, sans-serif;
  }

  .tagContainer {
    width: 100%;
  }
}
</style>
