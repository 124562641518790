<template>
  <div class="SliderParser">
    <div class="slider-parser-container w-100">
      <slick ref="slick" :options="slickOptions" class="w-100">
        <div v-for="item in content" :key="item.text">
          <div class="slide-inner shadow">
            <div
              v-for="(i, x) in item"
              :key="x"
              :class="{ radius: i.type === 'image' }"
            >
              <TextParser
                :content="i.content"
                :options="i.options"
                v-if="i.type === 'text'"
              />
              <ImageParser
                :content="i.content"
                :options="i.options"
                v-if="i.type === 'image'"
              />
              <AudioParser
                :content="i.content"
                :options="i.options"
                v-if="i.type === 'audio'"
              />
              <VideoParser
                :content="i.content"
                :options="i.options"
                v-if="i.type === 'video'"
              />
            </div>
          </div>
        </div>
      </slick>
    </div>
  </div>
</template>

<script>
import TextParser from "./TextParser.vue";
import ImageParser from "./ImageParser.vue";
import AudioParser from "./AudioParser.vue";
import VideoParser from "./VideoParser.vue";
import QuestionaireParser from "./QuestionaireParser.vue";
import slick from "vue-slick";

export default {
  components: {
    TextParser,
    ImageParser,
    AudioParser,
    VideoParser,
    QuestionaireParser,
    slick
  },
  name: "SliderParser",
  props: ["content"],
  data() {
    return {
      isLoaded: false,
      slickOptions: {
        nextArrow:
          '<button class="slick-next mt-2" type="button" style=""><i class="fas fa-caret-right brand-highlight-1"></i></button>',
        prevArrow:
          '<button class="slick-prev mt-2" type="button" style=""><i class="fas fa-caret-left brand-highlight-1"></button>',
        centerPadding: "50px",
        slidesToScroll: 1,
        slidesToShow: 1,
        adaptiveHeight: true
      }
    };
  },
  mounted() {
    let self = this;
    setTimeout(() => self.$refs.slick.setPosition(), 300);
  },
  methods: {}
};
</script>
<style lang="less">
.SliderParser {
  img {
    margin-bottom: 1.5rem;
  }
  h2,
  strong {
    font-family: PublicoHeadlineWebBold;
    margin-bottom: 1.5rem;
  }
  p {
    margin-bottom: 1.5rem;
  }
  width: 100%;
  .slide-inner {
    background-color: #f3f3f3;
    height: auto;
    padding-bottom: 20px;
    border-radius: 0;
  }
  .slick-next {
    right: -40px;
  }
  .slick-prev {
    left: -40px;
  }
  .slick-slide {
    border-radius: 2.4rem;
    transform: none;
    -webkit-transform: none;
  }
}
</style>
