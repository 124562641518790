<template>
  <div id="VideoRoom">
    <h3 v-if="meetingStatus === 'testRoom'" class="text-center pb-3 px-3 brand-secondary">Camera Test</h3>
    <h3 v-if="meetingStatus === 'active'" class="text-center pb-3 px-3 brand-secondary">Session Started</h3>
    <h3 v-if="meetingStatus === 'ended'" class="text-center pb-3 px-3 brand-secondary">Session Ended</h3>
    <div class="col-md-12 no-gutters px-0" id="remote_media_box">
      <transition name="fade">
        <div v-if="general_status === 'connected'" id="remote_media" class="animate__animated animate__fadeIn"></div>
        <div v-else id="remote_media_placeholder"
          class="animate__animated animate__fadeIn d-flex justify-content-center align-items-center"
          :class="{ 'test-local-video-placeholder': meetingStatus === 'testRoom' }">
          <p class="" v-html="status_message">
          </p>
        </div>
      </transition>


      <transition name="fade">
        <div v-if="general_status === 'connected'" id="remote_media_status_container"
          class="animate__animated animate__fadeIn">
          <div key="a1" v-if="remote_audio_enabled" class="remote_vid_button mic  animate__animated animate__fadeIn">
            <div v-b-tooltip.hover title="Remote Audio is ON" class="bt_inner">
              <i class="fa fa-microphone" aria-hidden="true"></i>
            </div>
          </div>
          <div key="a2" v-else class="remote_vid_button mic off  animate__animated animate__fadeIn">
            <div v-b-tooltip.hover title="Remote Audio is OFF" class="bt_inner off">
              <i class="fa fa-microphone-slash" aria-hidden="true"></i>
            </div>
          </div>
          <div key="v1" v-if="remote_video_enabled" class="remote_vid_button camera  animate__animated animate__fadeIn">
            <div v-b-tooltip.hover title="Remote Video is ON" class="bt_inner">
              <i class="fa fa-video" aria-hidden="true"></i>
            </div>
          </div>
          <div key="v2" v-else class="remote_vid_button camera off animate__animated animate__fadeIn">
            <div v-b-tooltip.hover title="Remote Video is OFF" class="bt_inner off">
              <i class="fa fa-video-slash" aria-hidden="true"></i>
            </div>
          </div>
        </div>
        <div v-else></div>
      </transition>
      <div id="local_video_wrapper" class="animate__animated animate__fadeIn"
        :class="{ 'test-local-video': meetingStatus === 'testRoom' }">
        <div id="local_video" class="embed-responsive-item"></div>
      </div>
    </div>


    <div class="col-md-12 utilities_container">
      <div id="remote_audio"></div>
      <div id="video_buttons">
        <div v-if="!video_processors_supported" class="row d-flex flex-row justify-content-center text-center">
          <div key="a1" v-if="audio_enabled" class="vid_button mic animate__animated animate__fadeIn">
            <div class="bt_inner" @click="toggleAudio">
              <i class="fas fa-microphone"></i>
            </div>
            <span>Your microphone is <b>ON</b></span>
          </div>
          <div key="a2" v-else class="vid_button mic animate__animated animate__fadeIn">
            <div class="bt_inner off" @click="toggleAudio">
              <i class="fas fa-microphone-slash"></i>
            </div>
            <span>Your microphone is <b>OFF</b></span>
          </div>
          <div key="v1" v-if="video_enabled" class="vid_button camera animate__animated animate__fadeIn">
            <div class="bt_inner" @click="toggleVideo">
              <i class="fas fa-video"></i>
            </div>
            <span>Your video is <b>ON</b></span>
          </div>
          <div key="v2" v-else class="vid_button camera animate__animated animate__fadeIn">
            <div class="bt_inner off" @click="toggleVideo">
              <i class="fas fa-video-slash"></i>
            </div>
            <span>Your video is <b>OFF</b></span>
          </div>
        </div>
        <div v-else>
          <div class="row d-flex flex-row justify-content-center text-center">
            <div class="mx-5">
              <div key="a1" v-if="audio_enabled" class="vid_button mic animate__animated animate__fadeIn">
                <div class="bt_inner" style="margin: auto" @click="toggleAudio">
                  <i class="fas fa-microphone"></i>
                </div>
              </div>
              <div key="a2" v-else class="vid_button mic animate__animated animate__fadeIn">
                <div class="bt_inner off" style="margin: auto" @click="toggleAudio">
                  <i class="fas fa-microphone-slash"></i>
                </div>
              </div>
            </div>
            <div class="mx-5">
              <div key="v1" v-if="video_enabled" class="vid_button camera animate__animated animate__fadeIn">
                <div class="bt_inner" style="margin: auto" @click="toggleVideo">
                  <i class="fas fa-video"></i>
                </div>
              </div>
              <div key="v2" v-else class="vid_button camera animate__animated animate__fadeIn">
                <div class="bt_inner off" style="margin: auto" @click="toggleVideo">
                  <i class="fas fa-video-slash"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <b-button variant="cta" class="mt-5" v-if="!permissionsStatus" disabled block>
        Accept Permissions
      </b-button>
      <b-button variant="cta" class="mt-5" @click="requestBookingRoom()" v-else-if="meetingStatus === 'testRoom'" block>
        Join Meeting
      </b-button>
      <b-button variant="cta" class="mt-5" @click="endSession" v-else-if="meetingStatus === 'active'" block>
        End Meeting
      </b-button>
      <b-button variant="cta" class="mt-5" @click="closeModal" v-else-if="meetingStatus === 'ended'" block>
        Close
      </b-button>

    </div>
  </div>
</template>

<script>
const { connect, LocalVideoTrack, LocalAudioTrack, RemoteVideoTrack, RemoteAudioTrack } = require('twilio-video');

import * as VideoProcessors from '@twilio/video-processors';
import moment from 'moment';
import * as Sentry from "@sentry/vue";

export default {
  name: "VideoRoom",
  data() {
    return {
      // asdasdhat@mailinator.com
      video_processors_supported: false,
      video_processor: false,
      participants: [],
      is_request_available: true,
      general_status: 'waiting',
      status_message: 'Please allow camera and microphone access...',
      connected: false,
      room_object: false,
      loopIntervalId: null,
      meetingStatus: 'testRoom',
      meetingStart: null,
      meetingEnd: null,
      permissionsStatus: false,
      audio_enabled: true,
      video_enabled: true,
      remote_audio_enabled: false,
      remote_video_enabled: false,
      duration: 0,
      token: null,
    }
  },
  mounted() {
    let self = this;
      navigator.permissions.query({ name: 'camera' })
      .then(function (result) {
      if (result.state == 'granted') {
        self.status_message = 'Establishing video connection...';
        self.permissionsStatus = true;
        Sentry.captureMessage('Camera permissions granted: ' + self.$store.getters.user.user.user_id);
      } else if (result.state == 'prompt') {
        self.status_message = 'Please allow camera and microphone access...';
      } else {
        self.status_message = 'Please allow camera and microphone permissions and refresh the page...'
      }
    }).catch((error) => {
      self.status_message = 'Please allow camera and microphone access...'
      self.permissionsStatus = false;
	    Sentry.captureMessage('Camera permissions not accepted: ' + self.$store.getters.user.user.user_id);
    });
    this.video_processors_supported = VideoProcessors.isSupported
    console.log('supported video processors : ', this.video_processors_supported)

    // below unfortunetely has to stay for scenario where local track is passed from parent as device tests
    // we could remove that passing media tracks bullshit all together and just destroy and create again in this component
    // we need to think about it, and see how is that in terms of performance
    if (this.local_video_track instanceof LocalVideoTrack) {
      this.attachLocalVideo()
    }
    // both just in case, can't use await here probably we will need to refactor this
    //this aren't triggering in time for them to be attached to the meeting or osmething like that?
    // this.createLocalVideo()
    // this.createLocalAudio()
    //
    this.initializeLocalTracks();

    // this.requestBookingRoom()
  },
  beforeDestroy() {
    let self = this;
    clearInterval(self.loopIntervalId)
    this.leaveRoom()
    this.$emit('terminateVideoAndAudio');
    if(this.duration > 60 * 5) {
    this.$root.$emit('show_dc_feedback_modal');
    }
  },
  props: ['booking', 'internalBookingId', 'coach', 'local_video_track', 'local_audio_track'],
  inject: ['createLocalAudio', 'destroyLocalAudio', 'createLocalVideo', 'destroyLocalVideo', 'createRoomTracks'],
  watch: {
    local_video_track(new_var) {
      if (new_var instanceof LocalVideoTrack) {
        this.attachLocalVideo()
      } else {
        this.detachLocalVideo()
      }
    }
  },
  methods: {
    gtag() {
      window.dataLayer.push(arguments);
    },
    sendStartEvent() {
      let self = this;
      this.gtag('event', 'appointment-start', {
        'user_id': self.$store.getters.user.user.user_id,
        'booking_id': self.internalBookingId,
      });
    },
    sendEndEvent() {
      let self = this;
      this.gtag('event', 'appointment-end', {
        'user_id': self.$store.getters.user.user.user_id,
        'booking_id': self.internalBookingId,
        'length': moment.utc(self.duration * 1000).format('HH:mm:ss')
      });
    },
    sendCoachParticipationEvent() {
      let self = this;
      this.gtag('event', 'coach-participation', {
        'user_id': self.$store.getters.user.user.user_id,
        'booking_id': self.internalBookingId,
      });
    },
    async requestBookingRoom(initalCall = false) {
      console.log('bookingRoomReq')
      let self = this;
      if (initalCall) {
        this.status_message = 'Joining the meeting ...'
      }
      this.meetingStatus = 'active'
      this.meetingStart = moment();
      if (this.is_request_available) {
        this.is_request_available = false
        let params = {

          path: "api/u/dmh/get-appointment-access-token",
          appointment_id: this.booking.appointmentId,
          start: this.booking.startTime,
          end: this.booking.endTime,
          clinician_id: this.booking.clinician.clinicianId
        }
        let res = await this.api(params)
        this.is_request_available = true
        if (res.success === true && res.access_token !== null) {
          clearInterval(self.loopIntervalId)
          this.loopIntervalId = null
          this.token = res.access_token;
          this.joinRoom()
        } else {
          if (this.meetingStatus !== 'ended') this.status_message = res.error;
          if (this.loopIntervalId === null) {
            this.loopForRequestingBookingRoom();
            console.log('starting loop')
          }
        }
      }
    },
    loopForRequestingBookingRoom() {
      let self = this;
      if (this.loopIntervalId === null) {
        this.loopIntervalId = setInterval(() => {
	        Sentry.captureMessage('Requesting booking room: ' + self.$store.getters.user.user.user_id);
          self.requestBookingRoom()
        }, 2000)
      }
    },
    reset(msg = 'Meeting will start soon ...') {
      this.is_request_available = true
      this.general_status = 'waiting'
      this.status_message = msg
      this.connected = false
    },
    async attachLocalVideo() {
      const divContainer = document.getElementById('local_video');
      const videoElement = this.local_video_track.attach();
      divContainer.appendChild(videoElement);
    },
    async detachLocalVideo() {
      document.getElementById('local_video').innerHTML = ''
    },
    async toggleVideo() {
      if (this.local_video_track.isEnabled) {
        this.local_video_track.disable()
      } else {
        this.local_video_track.enable()
      }

      this.video_enabled = this.local_video_track.isEnabled
      console.log('video track enabled : ', this.local_video_track.isEnabled)
      console.log('video track : ', this.local_video_track)
    },
    async toggleAudio() {
      if (this.local_audio_track.isEnabled) {
        this.local_audio_track.disable()
      } else {
        this.local_audio_track.enable()
      }
      this.audio_enabled = this.local_audio_track.isEnabled
      console.log('audio track enabled : ', this.local_audio_track.isEnabled)
      console.log('audio track : ', this.local_audio_track)
    },
    async leaveRoom(redirectHome = false) { //TODO change param to redirect to router routeName
      this.general_status = 'leaving the room in 2 secs'
      if (document.getElementById('remote_media')) {
        document.getElementById('remote_media').innerHTML = ''
      }
      if (this.room_object) {
        this.room_object.disconnect();
        this.room_object = false
      }

      if (this.local_audio_track) {
        await this.destroyLocalAudio()
      }

      if (this.local_video_track) {
        await this.destroyLocalVideo()
      }
    },
    async closeModal() {
      this.$bvModal.hide('digital_clinics_modal');
      // feedback only requested if the session lasted longer than 5 minutes
    },
    endSession() {
      let self = this;
      this.meetingStatus = 'ended';
      this.general_status = 'ending session';
      clearInterval(self.loopIntervalId)
      let duration = moment().diff(self.meetingStart, 'seconds');
      this.duration = duration;
      this.status_message = 'Thanks for joining.<br />Your Session Has Now Ended<br /><b>Duration: ' + moment.utc(duration * 1000).format('HH:mm:ss') + '</b>';
      this.sendEndEvent();
      this.leaveRoom();
    },
    async blurBg() {
      await this.removeBg()
      const bg = new VideoProcessors.GaussianBlurBackgroundProcessor({
        assetsPath: '/assets/',
        maskBlurRadius: 7,
        blurFilterRadius: 15,
      });
      await bg.loadModel();
      this.local_video_track.addProcessor(bg);
      this.video_processor = bg
    },
    async imgBg(img_name = 'flowers') {
      this.removeBg()
      const t = this
      let img = new Image();
      if (img_name === 'flowers') {
        img.src = '/img/video_backgrounds/flowers.jpg'
      } else {
        img.src = '/img/video_backgrounds/forest.jpg'
      }
      img.onload = async () => {
        const bg = new VideoProcessors.VirtualBackgroundProcessor({
          assetsPath: '/assets/',
          maskBlurRadius: 7,
          backgroundImage: img
        });
        await bg.loadModel();
        this.local_video_track.addProcessor(bg);
        t.video_processor = bg
      }
    },

    async removeBg() {
      if (this.video_processor) {
        await this.local_video_track.removeProcessor(this.video_processor)
        this.video_processor = false
      }
    },
    async initializeLocalTracks() {
      let videoCreated, audioCreated;
      audioCreated = await this.createLocalAudio();
      videoCreated = await this.createLocalVideo();
      if(videoCreated && audioCreated){
        this.permissionsStatus = true;
      }
    },


    async resetRemoteMediaDiv() {
      let media_div = document.getElementById('remote_media_placeholder');
      if (media_div) {
        media_div.innerHTML = ''
      }
    },

    async joinRoom() {

      await this.resetRemoteMediaDiv()
      let tracks = []

      tracks.push(this.local_audio_track)
      tracks.push(this.local_video_track)

      try {
        this.room_object = await connect(this.token, {
          tracks
        });
        this.meetingStatus = 'active'
      } catch (e) {
				let sentry_log_message = 'connection error'
				if(e?.constructor === String){
					sentry_log_message = e
				}
	      Sentry.captureMessage('Twilio connect error: ' + this.$store.getters.user.user.user_id +', error: '+ sentry_log_message);
        console.log(this.token);
        console.warn(e)
        console.log({
          tracks
        })
        return;
      }


      // t.logVideoEvent('Joining room for booking: ' + this.$route.params.booking_id)
      this.general_status = 'connected';

      this.room_object.participants.forEach(coach => {
        coach.on('trackSubscribed', track => {
          // do not attach dataTrack
          if (track.kind === 'video') {
            // https://sdk.twilio.com/js/video/releases/2.19.1/docs/RemoteVideoTrack.html
            if (track.isEnabled) {
              this.remote_video_enabled = true
            }
            this.resetRemoteMediaDiv();
            document.getElementById('remote_media').appendChild(track.attach());
            track.on('started', () => {
              console.log('remote video started')
            })

            track.on('enabled', () => {
              console.log('remote video enabled')
              this.remote_video_enabled = true
            })

            track.on('disabled', () => {
              console.log('remote video disabled')
              this.remote_video_enabled = false
            })

            track.on('switchedOff', () => {
              this.remote_video_enabled = false
              console.log('remote video switchedOff')
            })

            track.on('switchedOn', () => {
              this.remote_video_enabled = true
              console.log('remote video switchedOn')
            })

            track.on('dimensionsChanged', () => {
              console.log('remote video dimensionsChanged')
            })
          }
          if (track.kind === 'audio') {
            // https://sdk.twilio.com/js/video/releases/2.19.1/docs/RemoteAudioTrack.html
            if (track.isEnabled) {
              this.remote_audio_enabled = true
            }

            document.getElementById('remote_audio').appendChild(track.attach());
            track.on('started', () => {
              console.log('remote audio started')
            })
            track.on('enabled', () => {
              console.log('remote audio enabled')
              this.remote_audio_enabled = true
            })

            track.on('disabled', () => {
              console.log('remote audio disabled')
              this.remote_audio_enabled = false
            })

            track.on('switchedOff', () => {
              this.remote_audio_enabled = false
              console.log('remote audio switchedOff')
            })

            track.on('switchedOn', () => {
              this.remote_audio_enabled = true
              console.log('remote audio switchedOn')
            })
          }
        });
      });

      this.room_object.on('participantConnected', participant => {
        this.sendCoachParticipationEvent();
	      Sentry.captureMessage('clinician connected to working room, user_id :' + this.$store.getters.user.user.user_id);
        participant.on('trackSubscribed', track => {
          document.getElementById('remote_media').appendChild(track.attach());
        });

        participant.on('disconnected', participant => {
          // this.$store.commit('setAlert', {
          //   visible: true,
          //   text: participant.identity + ' left the Room',
          //   color: 'warning'
          // });

        });

        // this.$store.commit('setAlert', {
        //   visible: true,
        //   text: participant.identity + ' connected',
        //   color: 'success'
        // });

      });


      this.room_object.on('participantDisconnected', (coach, error) => {
	      Sentry.captureMessage('clinician disconnected from working room, user_id :' + this.$store.getters.user.user.user_id);
        console.log('Participant disconnected');
        this.is_request_available = true
        this.general_status = 'disconnected'
        this.endSession();
        // this.status_message = 'Service provider disconnected...'
        // this.requestBookingRoom()
        // this.reset('Participant disconnected')
        // this.loopForRequestingBookingRoom()
      });
      this.room_object.on('disconnected', (room, error) => {
        console.log('Room disconnected');
        let self = this;
        document.getElementById('remote_media').innerHTML = ''
        this.is_request_available = false
        this.general_status = 'completed';
        this.endSession();
        this.connected = false
        // this.$router.back()
        // this.reset()
        // this.loopForRequestingBookingRoom()
      })
      this.sendStartEvent();
    },

    logVideoEvent(message) {
      let params = {
        path: "api/u/video/log-info",
        message: message = JSON.stringify(message)
      }
      this.api(params)
    }
  }
};
</script>

<style lang="less">
#VideoRoom {
  h3{
    font-family: "DMSans", serif;
  }
  #video_page {
    background-color: white;

    .img_wrapper {
      text-align: center;
      padding: 0px 20px;

      img {
        border-radius: 50%;
        border: 7px solid #eee;
        box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.09);
      }
    }

    .meeting_time {
      padding: 30px;

      .meeting_time_inner {
        box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.2);
        background-color: #403D56;
        color: white;

        div {
          padding: 6px 10px;
        }

        margin-bottom: 15px;
        border-radius: 15px 15px 0px 15px;

        .day {
          font-size: 17px;
        }

        .hour {
          font-weight: lighter;
          font-size: 14px;
        }

        .date {
          font-size: 30px;
        }

        .month {
          font-size: 17px;
          font-weight: lighter;
        }

        .start_booking {
          position: relative;
          padding: 0px;
          min-height: 25px;

          .start_booking_inner {
            position: absolute;
            right: 0px;
            bottom: 0px;
            background-color: #E83B75;
            padding: 7px 25px;
            border-radius: 15px 0px 0px 0px;

            &:hover {
              cursor: pointer;
            }
          }
        }
      }
    }

    .clock_icon {
      text-align: center;
      font-size: 50px;
    }
  }

  #remote_media_box {
    position: relative;
    min-height: 400px;
    max-height: 400px;

    #remote_media {
      background: rgb(15, 15, 15);
      background: linear-gradient(225deg, rgba(15, 15, 15, 1) 0%, rgba(97, 97, 97, 1) 73%, rgba(28, 28, 28, 1) 100%);
      max-height: 400px;

      video {
        width: auto;
        height: 400px;
      }
    }

    #local_video_wrapper {
      max-width: 200px;
      position: absolute;
      bottom: 0px;
      right: 0px;
      overflow: hidden;
      border-radius: 10px 0 10px 0;

      #local_video {
        height: fit-content;
        font-size: 0px;

        video {
          box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.2);
          width: 100%;
          font-size: 0px;
        }
      }
    }
    @media only screen and (max-width: 992px) {
      #local_video_wrapper {
        max-width: 150px;
      }
    }
    @media only screen and (max-width: 576px) {
      #local_video_wrapper {
        max-width: 100px;
      }
    }
    .test-local-video {
      max-width: none !important;
      bottom: 0;
      left: 0;
      display: flex;
      justify-content: center;

      #local_video {
        max-height: 400px;
        height: 100%;
        font-size: 0px;
        overflow: hidden;

        video {
          width: 100%;
          font-size: 0px;
        }
      }
    }

    #remote_media_status_container {
      opacity: 0;
      position: absolute;
      top: 0px;
      left: 30px;
      width: 50px;
      text-align: center;
      height: 200px;

      .remote_vid_button {
        //text-align: center;
        //width: 40px;
        //height: 40px;
        //font-size: 25px;
        //border-radius: 50%;
        //background-color: white;
        //opacity: 0.7;

        z-index: 1000;
        text-align: center;
        font-size: 20px;
        border-radius: 50%;
        display: inline-block;
        background-color: rgba(0, 230, 184, 0.25);
        width: 50px;
        height: 50px;
        color: white;
        margin-right: 1em;
        margin-top: 15px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        &.off {
          background-color: rgba(0, 230, 184, 0.08);
        }
      }
    }

    #remote_media {
      box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.25);
      background-color: #ccc;
      font-size: 0;

      video {
        width: 100%;
        max-width: 100%;
        border-radius: 5px;
        //box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.25);
      }
    }

    .test-local-video-placeholder {
      background: rgb(15, 15, 15);
      background: linear-gradient(225deg, rgba(15, 15, 15, 1) 0%, rgba(97, 97, 97, 1) 73%, rgba(28, 28, 28, 1) 100%);
      box-shadow: none !important;

      p {
        color: #fff !important;
      }
    }

    #remote_media_placeholder {
      background: rgb(15, 15, 15);
      background: linear-gradient(225deg, rgba(15, 15, 15, 1) 0%, rgba(97, 97, 97, 1) 73%, rgba(28, 28, 28, 1) 100%);
      border-radius: 5px;
      box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.25);
      min-height: 400px;
      text-align: center;

      h2,
      h4 {
        font-weight: bold;
        color: white;
      }

      p {
        color: white !important;
        font-size: 1.8rem;
        b{
          font-size: 1.6rem;
          font-family: 'DMSans', sans-serif;
        }
      }

      video {
        display: none;
      }
    }
  }

  #video_buttons {
    .vid_button {
      position: relative;
      margin-top: 1.5rem;
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0 10px;

      .bt_inner {
        text-align: center;
        font-size: 25px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #00e6b8;
        width: 60px;
        height: 60px;
        color: white;
        margin-right: 1em;
        box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.1);

        &.off {
          background-color: #4d4d4f;
        }

        &:hover {
          cursor: pointer;
        }
      }

      span {
        padding-top: 10px;
        position: relative;
        bottom: 5px;
      }
    }
  }

  .utilities_container {
    position: relative;

    .bg_images {
      img {
        border-radius: 5px;
        box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.1);

        &:hover {
          cursor: pointer;
        }
      }
    }


    .cta-button {
      max-width: 200px;
      text-align: center;
      margin: 0 auto;
    }
  }

  #local_media,
  .remote_media {
    background-color: whitesmoke;
  }

  .animated.infinite {
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
  }

  .animated {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
  }

  .pulse {
    -webkit-animation-name: pulse;
    animation-name: pulse;
  }
}
</style>
