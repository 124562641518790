<template>
    <div id="DigitalClinicsEarlyRoom">
        <div class="brand-text">
            <div class="early-start">Session is scheduled for:</div>
            <div class="early-date brand-highlight-1">{{ getFormatedDate(booking.start, 'Do MMMM') }} </div>
            <div class="early-time">
                Time:
                {{ getHoursMinutes(booking.start) }} -
                {{ getHoursMinutes(booking.end) }}</div>
            <div class="early-p d-flex flex-row justify-content-center">
                <p>
                    Your sessions is scheduled with <b>{{ serviceProvider.name }}</b> for <b>{{
                            getFormatedDate(booking.start, 'ddd Do MMMM')
                    }}</b>. Check back closer to the time to
                    start your session, we will also send an email reminder coming up to your session. If this time no
                    longer suits you can <a class="early-cancel-link">cancel your session</a>.
                </p>
            </div>
            <div>
                <b-button class="cta-button px-5" @click="closeModal()">Close</b-button>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: ['booking', 'serviceProvider'],
    components: {
    },
    name: "EarlyRoomModal",
    data() {
        return {
            loading: true,
            timeoutOver: false,
        };
    },
    mounted() {
    },
    methods: {
        closeModal() {
            this.$bvModal.hide("digital_clinics_early_modal");

        }
    },
    computed: {

    },
    watch: {

    }
};
</script>
<style lang="less">
#DigitalClinicsEarlyRoom {
    padding: 60px 20px;
    text-align: center;

    .early-start {
        font-size: 1.6rem;
    }

    .early-date {
        font-size: 3rem;
        font-family: "DMSans", sans-serif;
    }

    .early-time {
        font-size: 1.6rem;
        font-family: "DMSans", sans-serif;
        padding-bottom: 30px;
    }

    .early-p {
        max-width: 350px;
        font-size: 1.4rem;
        margin: 0 auto;
        padding-bottom: 25px;
        font-family: "FSElliotBook", sans-serif;
    }

    b {
        font-family: "DMSans", sans-serif;
    }

    .early-cancel-link {}
}
</style>